import { connect } from "react-redux";

import { Sort as SortTypes } from "../../Enum/Models";

const Sort = props => {

    const { sort, changeSort, Dictionary } = props;

    const sortTypes = [
        { title: Dictionary.price, type: SortTypes.price },
        { title: Dictionary.hotelName, type: SortTypes.name },
        { title: Dictionary.starRating, type: SortTypes.rate }
    ]

    return (
        <div className="results-sorting-block">
            <div className="clearfix">
                <div className="pull-start-small">
                    {Dictionary.sortBy}:
                </div>
                <div className="pull-end-small">
                    {sortTypes.map(sortItem => (
                        <button
                            key={sortItem.title}
                            type="button"
                            className={`button results-sort-btn ${sort.name !== sortItem.type ? "disable" : sort.reverse ? "" : "low"}`}
                            onClick={() => {changeSort(sortItem.type);}}
                        >
                            {sortItem.title}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    Dictionary: state.VisualData.Dictionary
});

export default connect(mapStateToProps)(Sort);
