import { Input, Form, Radio } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import { useState, useEffect } from "react";

import { CountryCode } from "../../Enum/Models";
import { onChangeValidation } from "../Common/Validation";
import QuantityInput from "../Common/QuantityInput";
import Price from "../Common/Price";
import { showAccountWindowIR } from "../../Actions/VisualDataAction";
import Loading from "../Common/Loading";
import TravellerItem from "../Common/TravellerItem";
import { deleteTraveller } from "../../Actions/TravellerAction";

const DomesticHotelCheckoutRoomItem = props => {

    const { roomItem, roomIndex, Dictionary, travellers } = props;

    const { getFieldDecorator } = props.form;

    const [hasChild, setHasChild] = useState(false);
    const [childAge, setChildAge] = useState();
    const [extraBedCount, setExtraBedCount] = useState(0);

    const [travellersOpen, setTravellersOpen] = useState(false);

    const [deletingItemId, setDeletingItemId] = useState();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deletingConfirmMode, setDeletingConfirmMode] = useState(false);

    const [passengerNameFilter, setPassengerNameFilter] = useState("");

    const [pendingToOpenTravellers, setPendingToOpenTravellers] = useState(false);

    const extraBedPrice = roomItem.pricing?.find((item) => item.type === 'ExtraBed' && item.ageCategoryType === 'ADL')?.amount || 0;
    const childPrice = roomItem.pricing?.find((item) => item.type === 'Room' && item.ageCategoryType === 'CHD')?.amount || 0;

    useEffect(() => {
        props.updateRoomChildAndExtraBed(roomIndex, "selectedChild", hasChild, childPrice);
        if (hasChild) {
            props.form.setFieldsValue({ [`passengers[${roomIndex}].childrenAge`]: [childAge] });
        } else {
            props.form.setFieldsValue({ [`passengers[${roomIndex}].childrenAge`]: [] });
        }
    }, [hasChild, childAge]);

    useEffect(() => {
        props.updateRoomChildAndExtraBed(roomIndex, "selectedExtraBed", extraBedCount, extraBedPrice);
        props.form.setFieldsValue({ [`passengers[${roomIndex}].extraBed`]: extraBedCount });
    }, [extraBedCount]);

    const fillPassengerInformation = traveller => {

        const values = {
            [`passengers[${roomIndex}].gender`]: traveller.gender === false ? false : true,
            [`passengers[${roomIndex}].firstName`]: traveller.firstnamePersian || traveller.firstname || "",
            [`passengers[${roomIndex}].lastName`]: traveller.lastnamePersian || traveller.lastname || "",
            [`passengers[${roomIndex}].nationality`]: traveller.nationality || "IR",
            [`passengers[${roomIndex}].nationalId`]: traveller.nationalId || "",
        };

        props.form.setFieldsValue(values);

        setTravellersOpen(false);

    }

    useEffect(() => {
        if (props.Verification && pendingToOpenTravellers) {
            setTravellersOpen(true);
        }
    }, [props.Verification, pendingToOpenTravellers]);

    const filteredTravellers = travellers?.filter(traveller => {
        if (
            passengerNameFilter &&
            !traveller.firstname.toLowerCase().includes(passengerNameFilter.toLocaleLowerCase()) &&
            !traveller.lastname.toLowerCase().includes(passengerNameFilter.toLocaleLowerCase())
        ) {
            return false
        }
        return true;
    });

    const openDeleteConfirm = (id) => {
        setDeletingItemId(id);
        setDeletingConfirmMode(true);
    }

    const deleteItem = async () => {
        if (!deletingItemId || !props.refetchTravellersData) return;

        setDeleteLoading(true);
        const response = await deleteTraveller(deletingItemId);
        setDeleteLoading(false);
        if (response.data) {
            setDeletingItemId();
            setDeletingConfirmMode(false);
            props.refetchTravellersData();
        }
    }

    return (<>
        <div className="bg-white rounded padding-v-15 relative margin-bottom" key={roomIndex}>
            <div className="padding-h-15">
                <h5 className="no-margin-bottom font-16 md-font-18 semi-bold">
                    {Dictionary.room} {roomIndex + 1}
                </h5>
            </div>
            <hr className="margin-top-half margin-bottom-half" />
            <div className="padding-h-15">
                <div className="card-padding box-border border-2 border-dashed margin-top margin-bottom">
                    <div className="flex-ends middle margin-bottom-light">
                        <span className="inline-block font-16 semi-bold" dir="ltr">
                            {roomItem.name}
                        </span>
                        <span className="gray-tag small-tag font-12 no-wrap">
                            {roomItem.board}
                        </span>
                    </div>
                    {roomItem.cancellationPolicyStatus === "Refundable" ? (
                        <div className="flex-ends middle">
                            <div className="gray-tag font-12">قابل استرداد</div>
                        </div>
                    ) :
                        <div className="flex-ends middle">
                            <div className="gray-tag font-12">غیر قابل استرداد</div>
                        </div>
                    }
                </div>

                {!!(process.env.REACT_APP_THEME === "ITOURS_IR" && props.Verification) && <div className={`itours-modal ${travellersOpen ? "visible" : ""}`} >

                    <div className="modal-overlay" onClick={() => { setTravellersOpen(false); setDeletingConfirmMode(false); setDeletingItemId(); }} />

                    <div className="modal-inner" >

                        <div className="padding-h-20 padding-v-20">
                            {(props.travellersLoading || deleteLoading) ? (
                                <div className="padding-h-20 padding-v-20">
                                    <Loading />
                                </div>
                            ) : deletingConfirmMode ? (
                                <div className="padding-h-20 padding-v-20 text-center">
                                    <h5 className="normal-title">
                                        آیا مطمئنید اطلاعات این مسافر پاک شود؟
                                    </h5>
                                    <br />
                                    <div>

                                        <button
                                            type="button"
                                            className="button blue-btn"
                                            onClick={() => { setDeletingConfirmMode(false); setDeletingItemId(); }}
                                        >
                                            انصراف
                                        </button>
                                        <button
                                            type="button"
                                            className="button red-btn min-w-55 margin-start"
                                            onClick={deleteItem}
                                        >
                                            حذف
                                        </button>
                                    </div>
                                </div>
                            ) : travellers?.length ? (
                                <>
                                    <div className="clearfix padding-h-5">
                                        <h5 className="pull-right font-16 md-font-18 semi-bold padding-h-10 padding-top-half no-margin-bottom"> لیست مسافران سابق </h5>
                                        <span
                                            onClick={() => { setTravellersOpen(false) }}
                                            className="itours-icon gray-close-icon icon-large pull-left pointer"
                                        />
                                    </div>
                                    <div className="modal-main">
                                        <div className="margin-bottom">
                                            <input
                                                placeholder="جستجوی نام مسافر"
                                                className="form-input padding-h-15 filter-past-passengers"
                                                value={passengerNameFilter}
                                                onChange={e => { setPassengerNameFilter(e.target.value) }}
                                            />
                                        </div>

                                        {filteredTravellers?.length ? (
                                            <div className="itours-table-holder responsive-table no-scroll-responsive-table">
                                                <table className="itours-table text-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" > نام و نام خانوادگی</th>
                                                            <th className="text-center" > جنسیت</th>
                                                            <th className="text-center" > کد ملی / شماره پاسپورت </th>
                                                            <th className="text-center" > تاریخ تولد </th>
                                                            <th className="text-center" ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredTravellers.map((traveller, travellerIndex) => (
                                                            <TravellerItem
                                                                key={travellerIndex}
                                                                traveller={traveller}
                                                                fillPassengerInformation={fillPassengerInformation}
                                                                openDeleteConfirm={openDeleteConfirm}
                                                                isHotel
                                                            />
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div className=" text-center">
                                                <br />
                                                <div className="margin-bottom">
                                                    مسافری با نام مورد نظر شما یافت نشد.
                                                </div>
                                                <button
                                                    type="button"
                                                    className="button blue-btn"
                                                    onClick={() => { setPassengerNameFilter("") }}
                                                >
                                                    نمایش همه مسافران
                                                </button>
                                                <br />
                                                <br />
                                            </div>
                                        )}

                                    </div>
                                </>
                            ) : (
                                <div className=" text-center">
                                    <br />
                                    <div className="margin-bottom">
                                        اطلاعاتی از مسافران سابق یافت نشد!
                                    </div>
                                    <button
                                        type="button"
                                        className="button blue-btn"
                                        onClick={() => { setTravellersOpen(false); }}
                                    >
                                        بستن
                                    </button>
                                    <br />
                                    <br />
                                </div>
                            )}
                        </div>

                    </div>
                </div>}


                <div className="pair-row margin-bottom-15">
                    <b>مشخصات سرپرست</b>

                    {!!(process.env.REACT_APP_THEME === "ITOURS_IR") && (
                        <button
                            type="button"
                            onClick={() => {
                                if (props.Verification) {
                                    setTravellersOpen(true);
                                } else {
                                    setPendingToOpenTravellers(true);
                                    props.openIrLoginModal();
                                }
                            }}
                            className="button link-style-b-btn"
                        >
                            انتخاب مسافران سابق
                        </button>
                    )}
                </div>

                <Form.Item className="form-group">
                    {getFieldDecorator(`passengers[${roomIndex}].roomNumber`, {
                        initialValue: roomIndex + 1,
                    })(<Input hidden />,
                    )}
                </Form.Item>

                <Form.Item className="form-group no-margin-bottom">
                    {getFieldDecorator(`passengers[${roomIndex}].gender`, {
                        initialValue: true,
                    })(
                        <Radio.Group onChange={props.disableUserIsPassenger} >
                            <Radio className="no-margin" value={true}>{Dictionary.male}</Radio>
                            <Radio value={false}>{Dictionary.female}</Radio>
                        </Radio.Group>,
                    )}
                </Form.Item>

                <div className="float-row">
                    <div className="col-small-12 col-medium-6 clear-medium-2">
                        <Form.Item label={Dictionary.firstName} className="form-group">
                            {getFieldDecorator(`passengers[${roomIndex}].firstName`, {
                                rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                            })(
                                <Input
                                    className="form-input"
                                    placeholder={Dictionary.firstName}
                                    onChange={(e) => {
                                        onChangeValidation(e, 'alphabet');
                                        props.disableUserIsPassenger();
                                    }}
                                />,
                            )}
                        </Form.Item>
                    </div>
                    <div className="col-small-12 col-medium-6 clear-medium-2">
                        <Form.Item label={Dictionary.lastName} className="form-group">
                            {getFieldDecorator(`passengers[${roomIndex}].lastName`, {
                                rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                            })(
                                <Input
                                    className="form-input"
                                    placeholder={Dictionary.lastName}
                                    onChange={(e) => {
                                        onChangeValidation(e, 'alphabet');
                                        props.disableUserIsPassenger();
                                    }}
                                />,
                            )}
                        </Form.Item>
                    </div>
                    <div className="col-small-12 col-medium-6 clear-medium-2">
                        <Form.Item className="no-margin form-group" label={Dictionary.nationalCode}>
                            {getFieldDecorator(`passengers[${roomIndex}].nationalId`, {
                                rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                            })(
                                <Input
                                    className="form-input"
                                    placeholder={Dictionary.nationalCode}
                                    onChange={e => {
                                        onChangeValidation(e, 'number');
                                        props.disableUserIsPassenger();
                                    }}
                                />
                            )}
                        </Form.Item>
                    </div>
                    <div className="col-small-12 col-medium-6 clear-medium-2">
                        <Form.Item className="no-margin form-group" label={Dictionary.nationality}>
                            {getFieldDecorator(`passengers[${roomIndex}].nationality`, {
                                rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                            })(
                                <select className="form-select">
                                    <option disabled selected value="false">--</option>
                                    {CountryCode.map((item) => (
                                        <option value={item.value} key={item.value}>
                                            {item.code}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </Form.Item>
                    </div>
                </div>

                {/* {!!roomItem.maxChildAge && (
                    <div className='margin-top'>
                        <div className="flex-ends middle">
                            <strong>
                                <Tooltip title={`برای کودکان بالای ${roomItem.maxChildAge} سال باید از تخت اضافه استفاده کنید.`} >
                                    <span className="itours-icon info-icon icon-small" /> {Dictionary.child}
                                </Tooltip>
                            </strong>
                            <div className="domestic-hotel-child-control">
                                <span className="no-wrap margin-end-half">سن کودک (سال)</span>
                                <Tooltip title="سن کودک" >
                                    <select
                                        placeholder="سن کودک"
                                        onChange={e => { setChildAge(+e.target.value) }}>
                                        {_.range(1, roomItem.maxChildAge + 1).map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </Tooltip>

                                <button
                                    type="button"
                                    className="button blue-btn inline-btn medium-button margin-start no-wrap"
                                    onClick={() => { setHasChild(true) }}
                                    disabled={hasChild || !childAge}
                                >
                                    {hasChild ? "افزوده شد" : "افزودن"}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <Form.Item hidden>
                    {getFieldDecorator(`passengers[${roomIndex}].childrenAge`, {
                        initialValue: []
                    })(<Input />)}
                </Form.Item> */}

                <Form.Item hidden>
                    {getFieldDecorator(`passengers[${roomIndex}].extraBed`, {
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>

                {!!roomItem.extraBed && (<>
                    <div className='margin-top'>

                        <div className="flex-ends middle">
                            <strong>
                                {Dictionary.ExtraBed}
                                {!!extraBedPrice && <small className="margin-start-light">
                                    (<Price currency={props.currencyCode}>{extraBedPrice}</Price> برای هر شب)
                                </small>}
                            </strong>
                            <QuantityInput min={0} max={roomItem.extraBed} onChange={setExtraBedCount} small />
                        </div>
                    </div>

                </>
                )}


            </div>

        </div>


    </>
    )
}

const mapStateToProps = (state) => ({
    error: state.VisualData.error,
    currencyCode: state.UserData.Currency,
    userData: state.UserData.userData,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
    Verification: state.VisualData.Verification
});

const dispatchToProps = (dispatch) => ({
    openIrLoginModal: () => { dispatch(showAccountWindowIR("usernameModal")) }
});

export default connect(mapStateToProps, dispatchToProps)(DomesticHotelCheckoutRoomItem);