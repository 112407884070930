import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { showError } from "../../Actions/VisualDataAction";

const ResetError = () => {
    
    const location  = useLocation();

    const dispatch = useDispatch();
    
    useEffect(()=>{
    
        dispatch(showError({ visibility: false, content: "" }));
    
    },[location.pathname]);
    
    return(null);

}

export default ResetError;