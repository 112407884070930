import { useState } from "react";
import { useSelector } from "react-redux";
import { Skeleton, Col, Row } from "antd";

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Price from "../Common/Price";
import LayoutConfig from "../../Config/layoutConfig";

const AlternativePrices = props => {

  const [selectedFlightKey, setSelectedFlightKey] = useState(props.flightKey);
  const Dictionary = useSelector(state => state.VisualData?.Dictionary)

  if (props.alternativePrices && props.alternativePrices === "loading") {
    return (
      <div>
        <div className={`text-center modal-header ${process.env.REACT_APP_THEME ==='AYANAIRCOM'?"bg-purple white":"bg-yellow"}`}>{Dictionary.flightAlternatives}</div>
        <div className="padding-h-15 padding-v-15">
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={8} ><Skeleton active /></Col>
            <Col xs={24} sm={8} ><Skeleton active /></Col>
            <Col xs={24} sm={8} ><Skeleton active /></Col>
          </Row>
        </div>
      </div>
    )
  }

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div>
        <div className={`text-center modal-header ${process.env.REACT_APP_THEME ==='AYANAIRCOM'?"bg-purple white":"bg-yellow"}`}>{Dictionary.flightAlternatives}</div>
        <div className="flight-alternative-carousel">
          <Slider {...settings}>
            {props.alternativePrices?.map((item) => (
              <div className="flight-alternative-item" key={item.key}>
                <div className={`item-inner ${selectedFlightKey === item.key ? "is-selected" : ""}`}>
                  <h5 className="item-title padding-h-10">{item.brandName}</h5>
                  <div className="item-main">
                    <ul className="list-style-check">
                      {item.information.map((infoItem) => (
                        <li key={infoItem}>
                          {infoItem}
                        </li>
                      ))}
                    </ul>
                    <footer className="text-center">
                      <Price currency={props.currency} className="bold font-18">
                        {item.totalFare}
                      </Price>
                      <div className="padding-h-15">
                        <hr />
                      </div>
                      <button
                        onClick={() => setSelectedFlightKey(item.key)}
                        disabled={selectedFlightKey === item.key}
                        className={`button margin-top-half select-alternative-btn ${selectedFlightKey === item.key ? "blue-btn text-white" : "grey-btn"}`}
                      >
                        {selectedFlightKey === item.key ? Dictionary.selected : Dictionary.select}
                      </button>
                    </footer>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="text-center padding-bottom margin-bottom">
        <button
          type="button"
          className="button grey-btn flight-alternative-cancel-btn margin-end"
          onClick={() => { props.closeAlternativesModal(); }}
        >
          {Dictionary.cancel}
        </button>
        <button
          type="button"
          className={`button ${LayoutConfig.themeClassName === 'style-3'?"color-1-btn":"red-btn"} flight-alternative-continue-btn`}
          onClick={() => props.bookFlight(selectedFlightKey)}
        >
          {Dictionary.continue}
        </button>
      </div>
    </>
  )
}

export default AlternativePrices;