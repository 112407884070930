const Style3SupportSection = () => {
    return(
        <section className="style-3-support-section">
            <div className='page-container'>
                <div className='style-3-support-inner'>
                    <div className='style-3-support-heading'>
                        <svg viewBox="0 0 61 61.7" className='style-3-support-icon'>
                                <path d="M34.1,54.2c-0.2-0.5-0.8-0.5-1.2-0.7c-1.8-1-2.7-2.6-2.7-4.6c0-2.1,1.1-3.6,2.9-4.5c1.9-0.9,3.8-0.8,5.3,0.7    c1.3,1.3,2.4,0.7,3.7,0.2c1.3-0.6,2.2-1.6,2.2-3.1c0-2.5,0-5,0-7.4c0-1.9,0-3.8,0-5.6c-0.1-7-5.9-12.7-13-12.7    c-6.9,0-12.8,5.6-13,12.5c-0.1,4.1,0,8.2,0,12.4c0,2.5-1.2,3.7-3.7,3.7c-4.7,0-8.6-2.8-10.2-7.2c-0.1-0.3-0.1-0.7-0.5-0.8    c0-1.5,0-3,0-4.6c0.4-0.1,0.3-0.5,0.4-0.8c1.2-3.4,3.6-5.6,7-6.7c0.9-0.3,1.3-0.7,1.5-1.5c2.5-7.8,9.9-13.1,17.7-13.3    c9.1-0.3,16.8,5.8,19.2,13.3c0.3,0.9,0.7,1.3,1.6,1.6c5.4,1.7,8.4,6.9,7,12.2c-1,3.7-3.5,6-7,7.2c-0.5,0.2-0.9,0.3-1.2,0.9    c-1.5,4.1-4.6,6.3-8.7,6.9c-2,0.3-3.5,1.3-5.2,2.1C35.6,54.2,34.9,54.2,34.1,54.2z"/>
                        </svg>
                        <strong>نیاز به پشتیبانی دارید؟</strong>
                    </div>

                    <div className='phone-number'>
                        <svg viewBox="0 -960 960 960" className="icon"><path d="M795.478-114.022q-117.435 0-239.011-56.478T331.174-330.696Q227.217-434.652 170.619-556.348q-56.597-121.695-56.597-238.891 0-21.391 14.674-36.185 14.674-14.794 36.065-14.794h140q18.065 0 30.337 11.555 12.272 11.554 16.989 30.38l27 122.174q2.478 16.87-.739 30.403-3.217 13.532-13.13 22.967l-100.957 99.565q25.043 42.087 53.087 78.652 28.043 36.565 62.326 69.609 36.043 37.283 75.25 67.467 39.206 30.185 81.815 52.75l96.196-98.717q11.674-12.674 26.587-17.152 14.913-4.479 30.543-1.522l114.218 25.282q18.826 5.196 30.38 19.109 11.555 13.913 11.555 32.5v136.435q0 21.391-14.794 36.065t-35.946 14.674Z"/></svg>
                        <strong>021-91008091</strong>
                    </div>

                    <div className='email'>
                        <svg viewBox="0 -960 960 960" className="icon"><path d="M142.152-154.022q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865v-515.696q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337h675.696q27.697 0 48.033 20.337 20.337 20.336 20.337 48.033v515.696q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H142.152ZM480-454.348l337.848-223v-60.5L480-519.848l-337.848-218v60.5l337.848 223Z"/></svg>
                        <strong className='en-font'>Online@itours.ir</strong>
                    </div>

                    <div className='support'>
                        <strong>پشتیبانی همه روزه از ساعت <span className='yellow'> 9 </span> تا <span className='yellow'> 23 </span> </strong>
                        <p className="semi-bold">جهت ارتباط سریع تر از طریق ایمیل با ما در ارتباط باشید.</p>
                    </div>
                </div>
            </div>
        </section>        
    )
}

export default Style3SupportSection;