import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import axios from "axios";

import { ServerAddress, Payment as PaymentURL, Header } from "../../Enum/Urls";
import { getDepositBankList } from "../../Actions/PaymentAction";
import { setIpLocation, showError } from "../../Actions/VisualDataAction";
import PaymentRadioList from "../../components/Common/PaymentRadioList";
import { getIpLocation } from "../../Actions/UserAction";

const ChargeDeposit = () => {

    const dispatch = useDispatch();
    const banks = useSelector(state => state.PaymentData.depositBanks);
    const ipLocation = useSelector(state => state.VisualData?.ipLocation)
    const Dictionary = useSelector(state => state.VisualData.Dictionary);
    const [bank, setBank] = useState();
    const [paymentLoading,setPaymentLoading] = useState(false);
    const [validationMessage, setValidationMessage] = useState({ amount: null, bank: null });

    const amountRef = useRef();

    useEffect(() => {
        const parameters = {
            CurrencyType: "IRR",
            acceptLanguage: "fa-IR"
        }

        dispatch(getDepositBankList(parameters));  
        
        const fetchIpLocation = async () => {
            try{
              const response = await getIpLocation();
              if(response.data?.response?.alpha2){
                  localStorage.setItem('ipLocation', response.data.result.alpha2);
                  dispatch(setIpLocation(response.data.result.alpha2));            
              }
      
            }catch (err){
              console.log("ipLocationError", err);  
            }
          }
          fetchIpLocation();

    }, []);

    const selectBank = e => {
        setValidationMessage(prevMessages => ({ ...prevMessages, bank: null }))
        setBank(+e.target.value);
    }

    const onChangeValidation = event => {

        const number = event.target.value.replace(/,/g,"");
        const regex = /^[0-9۰-۹٠-٩]*$/;;

        if (!regex.test(number)) {
            const prevVal = number.substring(0, number.length - 1);
            if (regex.test(prevVal)) {
                event.target.value = prevVal.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                event.target.value = '';
            }
        } else {
            event.target.value = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setValidationMessage(prevMessages => ({ ...prevMessages, amount: null }))
        }
    }

    const makeBankToken = (params) => {
        let token = localStorage.getItem("userToken");
        let HeaderAthu;
        if (token) {
            HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
        } else {
            return;
        }
        axios({
            method: "post",
            url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.MakeTokenDeposit}`,
            data: params,
            headers: HeaderAthu,
        })
            .then((response) => {
                const token = response.data?.result?.tokenId;
                if (token){
                    window.location.href = `http://payline2.itours.ir/fa/User/Payment/PaymentRequest?tokenId=${token}`;
                }
            })
            .catch((error) => {
                try {
                    dispatch(showError({
                        visibility: true,
                        content: error.response.data.error.message,
                        closable: true
                    }));
                } catch (e) {
                    dispatch(showError({
                        visibility: true,
                        content: "Internal Error,Please refresh the page",
                        closable: true
                    }));
                }
            });
    }

    const submitHandler = () => {
        const amount = amountRef.current.value;
        if (!amount) {
            setValidationMessage(prevMessages => ({ ...prevMessages, amount: "مبلغ افزایش اعتبار را وارد نمایید." }));
        }
        if (bank == null) {
            setValidationMessage(prevMessages => ({ ...prevMessages, bank: "یکی از گزینه ها را برای پرداخت انتخاب کنید." }));
        }
        if (bank && amount) {
            setPaymentLoading(true);
            const number = amount.replace(/,/g,"");

            const callBackUrl = `${ServerAddress.Type}${ServerAddress.localServer}/profile?mode=chargeDeposit`;
            const parameters = {
                gatewayId: bank,
                ipAddress: ipLocation,
                amount: +number,
                callBackUrl: callBackUrl,
                currencyType: "IRR",
                formParams: {}

            }
            makeBankToken(parameters);
        }
    }

    return (
        <div className="float-row">
            <div className="col-small-12">
                <div className="card card-padding message-block error-message box-border margin-bottom">
                    <h3 className="normal-title">
                        <span className="itours-icon icon-large red-info-icon margin-end-half" />
                        لطفا توجه داشته باشید که درگاه پرداخت فقط با آی پی  داخل ایران قابل استفاده می باشد.
                    </h3>
                </div>
            </div>
            <div className="col-small-12 col-medium-6">
                {!banks ? (
                    `${Dictionary.loadingData} ...`
                ) : (
                    <div className="form-group relative required">
                        <label className="form-label">انتخاب بانک</label>
                        <PaymentRadioList
                            banks={banks}
                            onSelectBank={selectBank}
                        />
                        {validationMessage.bank && <p className="purple margin-bottom-light">{validationMessage.bank}</p>}
                    </div>
                )}
            </div>
            <div className="col-small-12 col-medium-6">
                <div className="form-group relative required">
                    <label className="form-label">مبلغ (ریال)</label>
                    <input
                        onChange={onChangeValidation}
                        dir="ltr"
                        className="form-input rightPlaceholder"
                        placeholder="مبلغ افزایش اعتبار"
                        type="text"
                        ref={amountRef}
                    />
                    {validationMessage.amount && <p className="purple margin-bottom-light">{validationMessage.amount}</p>}
                </div>
            </div>
            <div className="col-small-12">
                <button type="button" className="button color-1-btn margin-top" onClick={submitHandler}>
                    تایید و پرداخت
                    {paymentLoading && <span className="loading-inline-circle margin-start-half" />}
                </button>
            </div>

        </div>
    )
}
export default withRouter(ChargeDeposit);