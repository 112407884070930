import { useEffect, useRef, useState } from "react";
import axios from "axios";
import queryString from 'query-string';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { ServerAddress, Header, DomesticHotelApiUrls } from "../../Enum/Urls";
import { showError } from "../../Actions/VisualDataAction";
import { setSafarmarketHotelPixel } from "../../Actions/HotelAction";
import Loading from "../Common/Loading";
import LayoutConfig from "../../Config/layoutConfig";
import ImageGallery from "../Common/ImageGallery";
import Rating from "../Common/Rating";
import DomesticHotelDetailRooms from "./DomesticHotelDetailRooms";
import DomesticHotelPoint from "../Common/DomesticHotelPoint";
import DomesticHotelDetailAbout from "./DomesticHotelDetailAbout";
import DomesticHotelSearchForm from "./DomesticHotelSearchForm";
import DomesticHotelDetailComments from "./DomesticHotelDetailComments";
import Map from "../Common/Map";
import DomesticHotelDetailRules from "./DomesticHotelDetailRules";
import DomesticHotelRoomSkeleton from "./DomesticHotelRoomSkeleton";
import SimilarHotels from "./SimilarHotels";
import FetchProgress from "../Common/FetchProgress";
import HotelDetailSkeleton from "../Common/HotelDetailSkeleton";

const DomesticHotelDetail = props => {

    const parameters = queryString.parse(props.location.search);
    const { Dictionary } = props;
    const roomsHolder = useRef();

    const [hotelInformation, setHotelInformation] = useState();
    const [roomsInformation, setRoomsInformation] = useState();
    const [pageInformation, setPageInformation] = useState();
    const [scoreInformation, setScoreInformation] = useState();
    const [accommodationInformation, setAccommodationInformation] = useState();
    const [loading, setLoading] = useState(true);

    const [selectedRoomToken,setSelectedRoomToken] = useState();

    const checkin = parameters.checkin || moment().format("YYYY-MM-DD");
    const checkout = parameters.checkout || moment().add(1, 'days').format("YYYY-MM-DD");
    
    const selectRoomHandle = (token, count) => {

        setSelectedRoomToken(token);

        const validateRoom = async () => {

            const params = {
                bookingToken: token,
                checkin: checkin,
                checkout: checkout,
                count: count
            }

            const response = await axios.post(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.Validate}`,
                params,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            );
            if (response?.data?.result) {
                props.history.push(
                    `/domesticHotel/getPassengers?preReserveKey=${response.data.result.preReserveKey}`
                );
            }
        }

        validateRoom();

    }

    useEffect(() =>{

        let smId;
        let cookies = decodeURIComponent(document.cookie).split(';');
        for (const item of cookies){
          if (item.includes("safarMarketHotelId=")){
            smId=item.split("=")[1];
          }
        }
        if (LayoutConfig.safarmarket && props.currency ==="IRR" && smId) {
            props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/hotel/v1/pixel/itours/2/0/?smId=${smId}`));
        }

        const fetchRoomsData = async () => {
            const hotelId = parameters.id;
            setRoomsInformation();
            axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.GetRooms}?CheckIn=${checkin}&CheckOut=${checkout}&Id=${hotelId}`,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            ).then(response => {
                if (response?.data) {
                    setRoomsInformation(response.data.result);
                }
            }).catch(error => {
                setRoomsInformation("error");
                console.dir(error?.response?.data?.error);
            });
        }
        
        const fetchHotelInformation = async () => {
            setLoading(true);
            setHotelInformation();
            const hotelId = parameters.id;
            const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetHotelById}?hotelId=${hotelId}`,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            );
            if (response?.data) {
                setLoading(false);
                setHotelInformation(response.data);
            }
        }      

        const fetchAccommodationInformation = async () => {
            setLoading(true);
            setAccommodationInformation();
            const hotelId = parameters.id;
            const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelC}${DomesticHotelApiUrls.GetAccommodation}?Id=${hotelId}`,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            );
            if (response?.data) {
                setAccommodationInformation(response.data.result);
            }
        }
        try{
            fetchRoomsData();
            fetchHotelInformation();
            fetchAccommodationInformation();
        }catch(err){
            //check why this doesn't work
        }

    }, [props.location.search]);

    let pageUrl;
    if (hotelInformation) {
        pageUrl = hotelInformation.Url;
    }

    useEffect(() => {
        if (pageUrl) {
            const fetchPageByUrl = async () => {

                const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.getPageByUrl}?isNewVisitor=true&url=${pageUrl}`,
                    {
                        headers: {
                            ...Header,
                            apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                            'Accept-Language': 'fa-IR'
                        }
                    }
                );
                if (response?.data) {
                    setPageInformation(response.data);
                }
            }
            fetchPageByUrl();
        }
    }, [pageUrl]);


    const pageId = pageInformation?.Id || undefined;

    useEffect(() => {
        if (pageId) {

            const fetchScore = async () => {
                setScoreInformation();
                const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetScore}?pageId=${pageId}`,
                    {
                        headers: {
                            ...Header,
                            apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                            'Accept-Language': 'fa-IR'
                        }
                    }
                );
                if (response?.data) {
                    setScoreInformation(response.data);
                }
            }
            fetchScore();
        }
    }, [pageId]);

    let modifiedImages;
    if (hotelInformation?.Gallery?.length) {
        modifiedImages = hotelInformation.Gallery.filter(item => item.ImageThumb || item.Image).map(item => ({
            large: item.Image || item.ImageThumb,
            thumbnail: item.Image || item.ImageThumb
        }))
    }

    let rating;
    if (scoreInformation) {
        rating = <DomesticHotelPoint point={scoreInformation.Satisfaction} reviews={scoreInformation.CommentCount} className="margin-bottom" />;
    }


    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    return (
        <div className="hotel-details">
            {!!isStyle3 && <FetchProgress compeleted={!loading} />}
            <div className="page-container no-padding-mobile">
                {loading ?
                    <>
                        {isStyle3 ? (
                            <HotelDetailSkeleton />
                        ) : (
                            <Loading fullPage description={Dictionary.getHotelInformation + " ..."} />
                        )}
                    </>
                : hotelInformation ? (
                    <div className="section-vertical-padding hotel-details domestic-hotel-details">
                        {/* {this.state.isFromSafarmarket && <h5 className="bg-white box-border margin-bottom-large card-padding normal-title safarmarket-welcome">
                                شما از موتور جستجو <b className="purple">سفرمارکت</b> به <b className="purple">آیتورز</b> هدایت شده اید. لطفا فرایند رزرو هتل خود را در این صفحه ادامه دهید.
                            </h5>} */}
                        <div className="hotel-head-info">
                            <div className="full-width">
                                <h3 className="hotel-title">{hotelInformation.HotelCategoryName} {hotelInformation.HotelName} {hotelInformation.CityName} </h3>
                                {hotelInformation.Address && (
                                    <div className="hotel-address">
                                        <span className="itours-icon location-dark margin-end-light icon-small" />
                                        {hotelInformation.Address}
                                    </div>
                                )}
                                <div className="hotelRange">
                                    <Rating number={hotelInformation.HotelRating} className="inline-block-middle margin-end-half" />
                                </div>
                            </div>
                            <div className="text-right">
                                {rating}
                            </div>

                        </div>

                        <div className={`box-border bg-white margin-bottom detail-section ${isStyle3?"":"blue-border-right"}`}>
                            <div className="float-row no-gap ltr">
                                <div
                                    className={`col-small-12 ${hotelInformation?.Facilities?.length > 0 && LayoutConfig.themeClassName !== "padide-com-theme" ? "col-large-9" : ""}`}
                                    dir="ltr"
                                >
                                    {modifiedImages?.length >= 1 ? (
                                        <ImageGallery images={modifiedImages} />
                                    ) : hotelInformation.ImageUrl ? (
                                        <img
                                            src={hotelInformation.ImageUrl}
                                            alt={hotelInformation.HotelCategoryName + " " + hotelInformation.HotelName + " " + hotelInformation.CityName}
                                            className="full-width hotel-main-1photo"
                                        />
                                    ) : (
                                        <div className="card hotel-no-picture box-border text-center body-direction">
                                            <div className="no-image-hint ">
                                                <div className="margin-bottom">
                                                    {Dictionary.noImageHeadingText}
                                                </div>
                                                <small>{Dictionary.noImageDetailText}</small>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {hotelInformation?.Facilities?.length > 0 ? (
                                    <div
                                        className={`col-small-12 ${LayoutConfig.themeClassName === "padide-com-theme" ? "" : "col-large-3"}`}
                                    >
                                        <div className="card-padding">
                                            <h5 className="normal-title">
                                                {Dictionary.specialFacilities}
                                            </h5>

                                            <div className="feature-list">
                                                <div className="feature-items">
                                                    {hotelInformation.Facilities.map(facilityItem => <div
                                                        className={facilityItem.IsSpecial ? "is-important" : ""}
                                                        key={facilityItem.Keyword}
                                                    >
                                                        {facilityItem.Title}
                                                    </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="mobile-hr-padding">

                            <div className="margin-bottom-large">
                                <h5 className="normal-title">{Dictionary.modifySearch}</h5>
                                <DomesticHotelSearchForm noTabs />
                            </div>

                            <div className="room-select-card" id="roomSelectSection" ref={roomsHolder} >
                                <h5 className="normal-title select-room-title">{Dictionary.selectRoom} </h5>
                                <div id="roomSelect" className={"select-room-wrapper"}>
                                    {!roomsInformation ?  (
                                        <DomesticHotelRoomSkeleton />
                                    )
                                    :(roomsInformation === "error" || roomsInformation?.availabilities?.length === 0) ? <div className="box-border margin-bottom card-padding bg-white text-center">
                                    <div className="margin-bottom">
                                    <span className="itours-icon error-icon icon-xlarge" />
                                    </div>
                                    <div className="text-center font-18">
                                    <div className="margin-bottom">
                                        <strong>{Dictionary.SorryNoRoomFoundRightNow}!</strong>
                                    </div>
                                    <p>{Dictionary.pleaseTryAnotherHotel}</p>
                                    </div>
                                </div>
                                    : roomsInformation?.availabilities?.map((availabilityItem, index) => <DomesticHotelDetailRooms
                                        key={index}
                                        selectedRoomToken={selectedRoomToken}
                                        selectRoomHandle={selectRoomHandle}
                                        availabilityItem={availabilityItem}
                                        duration={roomsInformation?.date?.duration}
                                    />)}
                                </div>
                            </div>

                            {hotelInformation?.Facilities?.length > 0 && (
                                <div id="hotel_facilities">
                                    <div className={`card card-padding bg-white box-border margin-bottom ${isStyle3?"":"blue-border-right"}`}>
                                        <h5 className="normal-title">{Dictionary.hotelFacilities}</h5>
                                        <div className="textContent margin-top-large">
                                            {hotelInformation?.Facilities.map(item => <div key={item.Keyword} className="facility-row margin-bottom-large" >
                                                <img src={item.ImageUrl} className="facilityIcon margin-end-half" alt={item.Title} />
                                                <div>
                                                    <b className="font-16 semi-bold">{item.Title}</b>
                                                    <p className="no-margin-bottom">{item.Description}</p>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <DomesticHotelDetailAbout hotel={hotelInformation} />

                            <DomesticHotelDetailRules accommodationInformation={accommodationInformation} policies={hotelInformation?.Policies} />

                            <DomesticHotelDetailComments scoreInformation={scoreInformation} pageId={pageId} />

                            {!!hotelInformation && hotelInformation.Latitude && hotelInformation.Longitude && <div className="box-border map-holder">
                                <Map
                                    zoom={hotelInformation.Zoom || 14}
                                    hotels={[{ latitude: hotelInformation.Latitude, longitude: hotelInformation.Longitude }]}
                                />
                            </div>}

                            <SimilarHotels hotels={hotelInformation?.Similars} checkin={checkin} checkout={checkout} />
                        </div>

                    </div>
                ) : null }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    error: state.VisualData.error,
    currency: state.UserData.Currency,
    Dictionary: state.VisualData.Dictionary,
    selectedFilters: state.DomesticHotel.selectedFilters
});
const mapDispatchToProps = (dispatch) => ({
    showError: (param) => { dispatch(showError(param)); },
    setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketHotelPixel(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomesticHotelDetail));