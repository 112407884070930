import { withRouter } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { connect } from "react-redux";
import LayoutConfig from '../../Config/layoutConfig'

const NextAndPrev = props => {

    const {Dictionary} = props;

    const isNaminTravel = process.env.REACT_APP_THEME ==='NAMINTRAVELCOM';
    const isAyanAir = process.env.REACT_APP_THEME === 'AYANAIRCOM';
    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    const changeDate = next => {
        const parameters = queryString.parse(props.location.search);

        const datesArray = parameters.departureDateTimes.split("|");
        
        let changedDatesArray;

        if (next){
            changedDatesArray = datesArray.map(date => moment(date,'YYYY-MM-DD').add(1,'days').format("YYYY-MM-DD"))
        }else{
            changedDatesArray = datesArray.map(date => moment(date,'YYYY-MM-DD').subtract(1,'days').format("YYYY-MM-DD"))
        }

        const updatedDepartureDateTimes = changedDatesArray.join("|");

        const newSearchParameters = `?originCodes=${parameters.originCodes}` +
        `&originNames=${parameters.originNames}` +
        `&destinationCodes=${parameters.destinationCodes}` +
        `&destinationNames=${parameters.destinationNames}` +
        `&departureDateTimes=${updatedDepartureDateTimes}` +
        `&adult=${parameters.adult}` +
        `&child=${parameters.child}` +
        `&infant=${parameters.infant}` +
        `&cabinClassCode=${parameters.cabinClassCode}` +
        `&airTripType=${parameters.airTripType}` +
        `&nonStop=${parameters.nonStop}` +
        `&airlineCode=${parameters.airlineCode}` +
        `&airlineText=${parameters.airlineText}`;

        const travelType = props.domesticFlight ? "domesticflight" : "flight"

        props.history.push(`/${travelType}/result${newSearchParameters}`);

    }

    let buttonsClassName = "";
    if(isAyanAir){
        buttonsClassName = "button nowrap light-grey-btn"
    }else if(isNaminTravel){
        buttonsClassName = "button nowrap namin-yellow-btn"
    }else if (isStyle3){
        buttonsClassName = "button nowrap yellow-btn pale"
    }else{
        buttonsClassName = "button nowrap color-1-btn"
    }

    return (
        <div className="next-previous-group">
            <button
                type="button"
                className={buttonsClassName}
                onClick={() => changeDate(false)}
            >
                {Dictionary.previousDay}
            </button>

            <button
                type="button"
                className={buttonsClassName}
                onClick={() => changeDate(true)}
            >
                {Dictionary.nextDay}
            </button>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
  });

export default withRouter(connect(mapStateToProps)(NextAndPrev));