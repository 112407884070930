
//import libraries
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setCurrency } from "../../Actions/UserAction";
import { Tooltip } from "antd";
//import redux actions
import { toggleAccountWindow, SwitchAccountWindow, setLang } from "../../Actions/VisualDataAction";
//load UI components
import ProfileBallon from "../Admin/ProfileBallon";

import LayoutConfig from "../../Config/layoutConfig";
import Languages from "./Languages";
import CurrencyButton from "./CurrencyButton";

//declaration Class Component
class AyanairHeader extends React.Component {
  state = {
    routerInfo: null,
    disableLink: false,
    trackEmail: null,
    trackReserveID: null,
    showTrack: false,
    MenuClass: "",
  };
  handleClickOutsideTrackingOrder = (e) => {
    if (this.trackingOrderBox && !this.trackingOrderBox.contains(e.target)) {
      this.setState((prevState) => ({
        showTrack: false,
      }));
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideTrackingOrder);

    this.setState({
      routerInfo: this.props.location.pathname.split("/")[1],
    });
    let path = this.props.history.location.pathname.split("/");
    if (path[1] === "payment") {
      this.setState({
        disableLink: true,
      });
    } else {
      this.setState({
        disableLink: false,
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideTrackingOrder);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        routerInfo: this.props.location.pathname.split("/")[1],
      });
    }
    if (this.props.currency !== prevProps.currency) {
      window.location.reload();
    }
    if (this.props !== prevProps) {
      let path = this.props.history.location.pathname.split("/");
      if (path[1] === "payment") {
        this.setState({
          disableLink: true,
        });
      } else {
        this.setState({
          disableLink: false,
        });
      }
    }
  }
  changeCurrency = (currency) => {
    this.props.getCurrency(currency);
  };

  setToggleTopMenuClass = () => {
    if (this.state.MenuClass !== "expanded") {
      this.setState({
        MenuClass: "expanded",
      });
    } else {
      this.setState({
        MenuClass: "",
      });
    }
  };

  render() {
    const { Dictionary, LAN } = this.props;
    const {
      SwitchAccountWindow,
      Verification,
      toggleAccountWindow,
    } = this.props;

    const trackingOrder = (<div className="relative naminTravel-tracking-order-wrapper" ref={(element) => { this.trackingOrderBox = element }}>
      <button
        type="button"
        className="ayan-outline-btn track-order-btn"
        onClick={() => {
          this.setState((prevState) => ({
            showTrack: !prevState.showTrack,
          }));
        }}
      >
        {Dictionary.trackOrder}
      </button>
      {this.state.showTrack && (
        <div className={"tracking"}>
          <div className={"trackingContainer"}>
            <div className={"username form-group"}>
              <label htmlFor="trackingUsername">
                *{Dictionary.email}
              </label>
              <input
                className="form-input placeholder-right"
                id={"trackingUsername"}
                value={this.state.trackEmail}
                onChange={(e) => {
                  this.setState({ trackEmail: e.target.value });
                }}
                type={"text"}
              />
            </div>
            <div className={"reserveNumber form-group"}>
              <label htmlFor="trackingUsername">
                *{Dictionary.OurReference} <Tooltip title={Dictionary.theReferenceNumberOfOurSystemWhichWasSentToYourEmail}><span className="itours-icon info-icon icon-small" /></Tooltip>
              </label>
              <input
                className="form-input placeholder-right"
                id={"trackingUsername"}
                value={this.state.trackReserveID}
                onChange={(e) => {
                  this.setState({ trackReserveID: e.target.value });
                }}
                type="text"
              />
            </div>
            <div className="clearfix">
              <button
                type="button"
                className="button color-1-btn pull-start"
                disabled={
                  !(this.state.trackEmail && this.state.trackReserveID)
                }
                onClick={() => {
                  this.setState({ showTrack: false });
                  this.props.history.push(
                    `/profile/details?userName=${this.state.trackEmail}&reserveId=${this.state.trackReserveID}`
                  );
                }}
              >
                {Dictionary.send}
              </button>
              <button
                type="button"
                className="button red-btn pull-end"
                onClick={() => {
                  this.setState({ showTrack: false });
                }}
              >
                {Dictionary.close}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>);


    return (
      <header className="ayanair-header">
        <div className="page-container">
          <div className="ayanair-header-sides">

            <button
              type="button"
              className="menu-toggle-btn"
              onClick={this.setToggleTopMenuClass}
            >
              <span className="menu-btn-bar icon-top-bar"></span>
              <span className="menu-btn-bar icon-middle-bar"></span>
              <span className="menu-btn-bar icon-bottom-bar"></span>
            </button>

            <div className="ayanair-start-header">
              <Link className="ayan-logo-link" to={"/"}>
                <img src={LayoutConfig.logo} alt={LayoutConfig.companyName} className="ayanair-logo" />
              </Link>

              <nav className="ayanair-navigation">

                {this.state.MenuClass === "expanded" && (
                  <span
                    className={"menu-overlay"}
                    onClick={this.setToggleTopMenuClass}
                  />
                )}
                <div className={`main-menu-holder ${this.state.MenuClass}`}>
                  <div className="clearfix">
                    <button
                      type="button"
                      className="opened menu-toggle-btn red-arrow-right itours-icon mirror-ltr"
                      onClick={this.setToggleTopMenuClass}
                    />
                  </div>

                  <ul className="main-menu">

                    <li className={`menu-item mobile-tablet-only ${this.state.routerInfo === "" ? "active" : ""}`} >
                      <Link
                        to={"/"}
                        className="menu-link"
                        onClick={this.setToggleTopMenuClass}
                      >
                        {Dictionary.flight}
                      </Link>
                    </li>

                    <li className={`menu-item mobile-tablet-only ${this.state.routerInfo === "hotel" ? "active" : ""}`} >
                      <Link
                        to={"/hotel"}
                        className="menu-link"
                        onClick={this.setToggleTopMenuClass}
                      >
                        {Dictionary.hotel}
                      </Link>
                    </li>


                    <li className={`menu-item has-divider ${this.state.routerInfo === "terms" ? "active" : ""}`} >
                      <Link
                        to={"/terms"}
                        className="menu-link"
                        onClick={this.setToggleTopMenuClass}
                      >
                        {Dictionary.termsAndConditions}
                      </Link>
                    </li>
                    <li className={`menu-item has-divider ${this.state.routerInfo === "about" ? "active" : ""}`} >
                      <Link
                        to={"/about"}
                        className="menu-link"
                        onClick={this.setToggleTopMenuClass}
                      >
                        <span className="itours-icon about-us hidden-medium hidden-large margin-end-light" />
                        {Dictionary.aboutUs}
                      </Link>
                    </li>

                    <li className={`menu-item ${this.state.routerInfo === "contact" ? "active" : ""}`} >
                      <Link
                        to={"/contact"}
                        className="menu-link"
                        onClick={this.setToggleTopMenuClass}
                      >
                        <span className="itours-icon contact-us hidden-medium hidden-large margin-end-light" />
                        {Dictionary.contactUs}
                      </Link>
                    </li>
                  </ul>

                  <div className="padding-h-15 padding-v-5 hidden-medium hidden-large">
                    <br/>
                    <Languages currentLanguage={LAN} />
                  </div>

                </div>
              </nav>

            </div>

            <div className="ayanair-end-header">

              <div className="currency-acount">
                {!Verification ? (
                  <div className="account">
                    <div
                      className="ayan-outline-btn margin-end-half"
                      onClick={() => {
                        SwitchAccountWindow(false);
                        toggleAccountWindow();
                      }}
                    >
                      <span className="itours-icon profile hidden-small hidden-medium hidden-large" />
                      <span className="hidden-xsmall">{Dictionary.login}</span>

                    </div>
                  </div>
                ) : (
                  <ProfileBallon />
                )}
              </div>

              {trackingOrder}

              <span className="desktop-language-btn"><Languages currentLanguage={LAN} /></span>

              <span className="desktop-currency-btn"><CurrencyButton
                  currencyList={["USD"]}
                  currentCurency={this.props.currency}
              />
              </span>
            </div>

          </div>

        </div>
      </header>
    );
  }
}
//map state to Props
const MapStateToProps = (state) => ({
  Verification: state.VisualData.Verification,
  userData: state.UserData,
  currency: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
//map action to Props
const MapAction = (dispatch) => ({
  toggleAccountWindow: () => dispatch(toggleAccountWindow()),
  SwitchAccountWindow: (mode) => {
    dispatch(SwitchAccountWindow(mode));
  },
  setCurrency: (value) => {
    dispatch(setCurrency(value));
  },
  setLang: lan => { dispatch(setLang(lan)) }
});
const routerInfo = withRouter(AyanairHeader);
export default connect(MapStateToProps, MapAction)(routerInfo);
