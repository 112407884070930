import { Skeleton } from "antd";

const HotelGetpassengerSkeleton = () => {
    return (
        <div className="page-container padding-top">

            {/* <Loading fullPage description={Dictionary.GettingPassengerInformation + ' ...'} /> */}

            <div className="steps-skleton padding-top margin-bottom-large hidden-xsmall">
                <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-center" />
                <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
            </div>

            <div className="float-row margin-bottom-large">
                <div className="col-small-12 col-medium-8 margin-bottom">

                    <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70%" }} className="one-line-skeleton margin-bottom-large" />

                    <div className="box-border bg-white card-padding margin-bottom" >

                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom-large margin-top" />

                        <div className="float-row">
                            {[1, 2, 3, 4].map(item => (
                                <div className="col-small-12 col-medium-6" key={item}>
                                    <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom" />
                                    <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton margin-bottom" />
                                </div>
                            ))}
                        </div>
                    </div>


                    <div className="box-border bg-white card-padding margin-bottom" >

                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom-large margin-top" />
                        <Skeleton active paragraph={{ rows: 0 }} title={{ width: "50%" }} className="one-line-skeleton margin-bottom-large" />

                        <div className="float-row">
                            {[1, 2, 3, 4, 5, 6].map(item => (
                                <div className="col-small-12 col-medium-4" key={item}>
                                    <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom" />
                                    <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton margin-bottom" />
                                </div>
                            ))}
                        </div>

                        <hr className="margin-top margin-bottom" />

                        <Skeleton active paragraph={{ rows: 0 }} title={{ width: "50%" }} className="one-line-skeleton" />

                    </div>

                    <div className="box-border bg-white card-padding margin-bottom" >
                        <Skeleton active />
                    </div>

                </div>
                <div className="col-small-12 col-medium-4 margin-bottom hidden-xsmall hidden-small">
                    <div className="box-border bg-white card-padding">

                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />

                        <hr className="margin-top margin-bottom" />

                        <div className="hotel-summery-image-skeleton margin-bottom" dir="ltr">
                            <div className="image" />
                            <Skeleton active paragraph={{ rows: 2 }} />
                        </div>

                        <div className="justify-between margin-bottom">
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                        </div>

                        <div className="justify-between margin-bottom">
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                        </div>

                        <div className="justify-between margin-bottom">
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                        </div>

                        <hr className="margin-top margin-bottom" />

                        <Skeleton active paragraph={{ rows: 2 }} />

                        <hr className="margin-top margin-bottom" />

                        <Skeleton active />

                        <hr className="margin-top margin-bottom" />

                        <div className="justify-between margin-bottom-large">
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                        </div>

                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton" />

                    </div>
                </div>
            </div>

        </div>
    )
}

export default HotelGetpassengerSkeleton;