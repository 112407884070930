//load  libraries
import React from "react";
import ReactGA from "react-ga";
import { Route, Switch,Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
//load Layout Part Components
import Header from "../components/Views/Header";
import NaminHeader from "../components/Views/NaminTravelHeader";
import AyanairHeader from "../components/Views/AyanairHeader";
import SafarmarketHeader from "../components/Views/SafarmarketHeader";
import SafarmarketFooter from "../components/Views/SafarmarketFooter";
import NaminFooter from "../components/Views/NaminTravelFooter";
import Footer from "../components/Views/Footer";
import Home from "./Home";
import AccountWindow from "../components/Views/Account";
import ErrorShow from "../components/Views/Error";
import ReserveDetails from "../page/profile/ReserveDetails";
import FlightResult from "../page/flight/FlightResult";
import domesticFlightResult from "../page/flight/domesticFlightResult";
import AboutUS from "../page/US/AboutUS";
import Terms from "../page/US/Terms/Terms";
import AyanAirPersonalDataPolicy from '../page/US/Terms/ayanair/PersonalDataPolicy';
import AyanAirPrivacyPolicy from '../page/US/Terms/ayanair/PrivacyPolicy';
import AyanAirBookingPolicy from '../page/US/Terms/ayanair/BookingPolicy';
import ContactUS from "../page/US/ContactUS";
import GetPassengerFlight from "../page/flight/GetPassengerFlight";
import GetPassengerDomesticFlight from "../page/flight/GetPassengerDomesticFlight";
import GetPassengerPackage from "../page/package/GetPassengerPackage";
import DomesticHotelGetPassengers from "./domesticHotel/DomesticHotelGetPassengers";
import DomesticHotelCapacity from "./domesticHotel/DomesticHotelCapacity";
import UnderConstruction from "./UnderConstruction";
import { getUser,logoutUser,getBalance, getIpLocation } from "../Actions/UserAction";
import {setIpLocation} from '../Actions/VisualDataAction';
import { showError } from "../Actions/VisualDataAction";

import UnderConstructionImage from "../image/underconstruc.jpg";
import moment from "moment-jalaali";
import {
  toggleVarification,
  toggleErrorWindow,
  setLang,
} from "../Actions/VisualDataAction";
import GaurdRouter from "../components/Common/GaurdRouter";
import Index from "../page/profile/Index";
import HotelResult from "../page/hotel/HotelResult";
import DomesticHotelResult from "../page/domesticHotel/DomesticHotelResult";
import PackageResult from "../page/package/PackageResult";
import Confrim from "../page/profile/Confrim";
import ForgotPassword from "../page/profile/ForgotPassword";
import HotelDetails from "../page/hotel/HotelDetails";
import PackageDetails from "../page/package/PackageDetails";
import NotFound from "../page/NotFound";
import GetPassengers from "./hotel/GetPassengers";
import Reserve from "../page/payment/Reserve";
import Result from "../page/payment/Result";
import HotelIndex from "../page/hotel/HotelIndex";
import DomesticHotelIndex from "./domesticHotel/DomesticHotelIndex";
import PackageIndex from "../page/package/PackageIndex";
import ForeignFlightIndex from "../page/flight/foreignFlightIndex"; 
import MessageAccept from "../components/Common/MessageAccept";
import Loading from "../components/Common/Loading";
import padide81FakePageScrrenShot from "../image/padideh81.png";
import "antd/dist/antd.css";
import "../scss/layout.scss";
import LayoutConfig from "../Config/layoutConfig";
import DomesticHotelDetails from "./domesticHotel/DomesticHotelDetails";
import AyanAirFooter from "../components/Views/AyanAirFooter";
import ResetError from "../components/Common/ResetError";

// Import React material
class Layout extends React.Component {

  logoutAfter5hours = () =>{
    const newTime = moment().format();
    const loginExpireTime = moment(localStorage.getItem("userTokenCreateTime")).add(5, 'hours').format();
    if (!(localStorage.getItem("userTokenCreateTime")) || (moment(newTime).isAfter(loginExpireTime))){
      this.props.logout();
      this.props.toggleVarification(false);
    }
  }

  componentDidMount() {
    this.initializeGA();
    this.logoutAfter5hours();
    this.props.getUser();
    let defaultLAN = process.env.REACT_APP_SERVER_DOMAIN;
    const storageLAN = localStorage.getItem('selectedLanguage');
    if ((process.env.REACT_APP_THEME === "AYANAIRCOM" || process.env.REACT_APP_THEME === "NAMINTRAVELCOM") && storageLAN){
      defaultLAN = storageLAN;
    }
    this.props.setLang(defaultLAN);

    const AppAddress = process.env.REACT_APP_SERVER_LOCALSERVER; 
    const token=localStorage.getItem('userToken');
    if (token && AppAddress ==="itours.ir"){
      this.props.getUserBalance(token);
    }
    const ipLocation = localStorage.getItem("ipLocation");
    if (ipLocation){
      this.props.setIpLocation(ipLocation);
    }else{
      const fetchIpLocation = async () => {
        try{
          const response = await getIpLocation();
          if(response.data?.result?.alpha2){
            localStorage.setItem('ipLocation', response.data.result.alpha2);
            this.props.setIpLocation(response.data.result.alpha2);
          }
  
        }catch (err){
          console.log("ipLocationError", err);  
        }
      }
      fetchIpLocation();
    }


    if (LayoutConfig.favicon) {
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = LayoutConfig.favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && process.env.REACT_APP_SERVER_GA_ID){
      this.useGaTracker();
    }
    if (this.props.LAN && this.props.LAN === "IR") {
      document.body.classList.remove("ltr");
      document.body.classList.remove("arabic");
    } else if (this.props.LAN && this.props.LAN === "AR") {
      document.body.classList.remove("ltr");
      document.body.classList.add("arabic");
    } else if (this.props.LAN && this.props.LAN === "KU") {
      document.body.classList.remove("ltr");
      document.body.classList.remove("arabic");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("arabic");
    }
    if (LayoutConfig.themeClassName) {
      document.body.classList.add(LayoutConfig.themeClassName);
    }
  }

  initializeGA = () => {
    const ga_id = process.env.REACT_APP_SERVER_GA_ID;
    if (ga_id) {
      ReactGA.initialize(ga_id,{debug:true});
      this.useGaTracker();
    }
  }

  useGaTracker = ()=>{
    ReactGA.pageview(this.props.location.pathname + this.props.location.search);
  }

  
  render() {

    const { pathname } = this.props.location;

    let layoutHolderClassName = "";

    switch (pathname) {
      case "/":
        layoutHolderClassName = "is-home is-flight";
        break;
      case "/foreignflight":
        layoutHolderClassName = "is-home is-foreign-flight";
        break;
      case "/domesticHotel":
        layoutHolderClassName = "is-home is-domestic-hotel";
        break;
      case "/hotel":
        layoutHolderClassName = "is-home is-hotel";
        break;
      case "/package":
        layoutHolderClassName = "is-home is-package";
        break;

      default:
        layoutHolderClassName = "";
    }
    
    let header = <Header />;
  
    if (this.props.safarmarketLayout){
      header = <SafarmarketHeader /> ;
    }
  
    if (process.env.REACT_APP_THEME === 'NAMINTRAVELCOM') {
      header = <NaminHeader />;
    }

    if (process.env.REACT_APP_THEME ==='AYANAIRCOM'){
      header = <AyanairHeader />;
    }

    if (process.env.REACT_APP_UNDER_CONSTRUCTION){
      return(
        <div className="under-constriction-wrapper">
          <img src={UnderConstructionImage} />
        </div>
      )
    }


    const isUnderConstruction= process.env.REACT_APP_THEME==='NAMINTRAVELCOM___' || process.env.REACT_APP_THEME === "NORTRAVELSE";
    let turkishSiteClass = "";
    if (process.env.REACT_APP_SERVER_LOCALSERVER === 'travelport.as'){
      turkishSiteClass = "is-turkish"
    }
    const {
      showAccountWindow,
      showErrorWindow,
      closableError,
      boldError,
      errorContent,
      registerMode,
      Verification,
    } = this.props;
    return !Verification && !this.props.LAN ? (
      <Loading fullPage />
    ) : LayoutConfig.themeClassName === "padide-com-theme______" &&
      this.props.LAN === "IR" ? (
      <img
        src={padide81FakePageScrrenShot}
        alt="padide 81"
        className="full-width"
      />
    ) : (
      <div className={`container language-${this.props.LAN || "US"} full layout-holder ${layoutHolderClassName} ${turkishSiteClass} ${this.props.scrollable && !this.props.showErrorWindow?"":"no-scroll"}`}>
        {showAccountWindow ? <AccountWindow mode={registerMode} /> : null}
        
        {showErrorWindow ? <ErrorShow 
          closableError={closableError} 
          icon={this.props.errorIcon} 
          buttonLabel={this.props.errorButtonLabel}
          boldError={boldError}
          >
            {errorContent}
          </ErrorShow> 
        :
        null}
        
        {(LayoutConfig.themeClassName === 'style-2' && this.props.HomeStyle) || header }
        
        <main className="main-content">
        
        {LayoutConfig.themeClassName === 'style-2' && this.props.HomeStyle && header }

          {isUnderConstruction?
              
                  <Switch>
                    <Route path="/" exact={true} component={Home} />
                    <Route path="/flight" exact={true} component={Home} />
                    <Route path="/hotel" exact={true} component={HotelIndex} />
                    <Route path="/package" exact={true} component={PackageIndex} />
                    <Route path="/underConstruction" exact={true} component={UnderConstruction} />
                    <Redirect from='/' to='/underConstruction' />
                  </Switch>
              
          :
              
                  <Switch>
                    <Route path="/" exact={true} component={Home} />
                                        
                    <Route path="/contact" component={ContactUS} />

                    <Route path="/about" component={AboutUS} />                
            
                    {process.env.REACT_APP_THEME ==='AYANAIRCOM' && <Route path="/privacyPolicy" component={AyanAirPrivacyPolicy} />}
                    {process.env.REACT_APP_THEME ==='AYANAIRCOM' && <Route path="/bookingPolicy" component={AyanAirBookingPolicy} />}
                    {process.env.REACT_APP_THEME ==='AYANAIRCOM' && <Route path="/personalDataPolicy" component={AyanAirPersonalDataPolicy} />}
                               
                    <Route path="/terms" component={Terms} />
                    
                    <Route path="/flight" exact={true} component={Home} />
                    <Route path="/hotel" exact={true} component={HotelIndex} />
                    {LayoutConfig.hasDomesticHotel && <Route path="/domestichotel" exact={true} component={DomesticHotelIndex} />}

                    {LayoutConfig.noPakage || <Route path="/package" exact={true} component={PackageIndex} />}
                    <Route path="/foreignflight" exact={true} component={ForeignFlightIndex} />                    
                    <Route path="/underConstruction" exact={true} component={UnderConstruction} />
                    <Route
                        path="/hotel/result"
                        exact={true}
                        component={HotelResult}
                    />    
                    {LayoutConfig.hasDomesticHotel && <Route
                        path="/domesticHotel/result"
                        exact={true}
                        component={DomesticHotelResult}
                    /> }                   
                    {LayoutConfig.noPakage || <Route
                        path="/package/result"
                        exact={true}
                        component={PackageResult}
                    />}
                    {LayoutConfig.hasDomesticHotel && <Route
                        path="/domesticHotel/details"
                        exact={true}
                        component={DomesticHotelDetails}
                    />}
                    
                    <Route
                        path="/hotel/details"
                        exact={true}
                        component={HotelDetails}
                    />
                    {LayoutConfig.noPakage || <Route
                        path="/package/details"
                        exact={true}
                        component={PackageDetails}
                    />}
                    <Route
                        path="/hotel/getPassengers"
                        exact={true}
                        component={GetPassengers}
                    />
                    {LayoutConfig.noPakage || <Route
                        path="/package/getPassengers"
                        exact={true}
                        component={GetPassengerPackage}
                    />}
                    {LayoutConfig.hasDomesticHotel && <Route
                        path="/domesticHotel/getPassengers"
                        exact={true}
                        component={DomesticHotelGetPassengers}
                    />}
                    {LayoutConfig.hasDomesticHotel && <Route
                        path="/domesticHotel/capacity"
                        exact={true}
                        component={DomesticHotelCapacity}
                    />}
                    
                    <GaurdRouter
                        component={Index}
                        exact={true}
                        path="/profile"
                        auth={Verification}
                    />
                    <Route path="/profile/details" component={ReserveDetails} />
                    <Route
                        component={Confrim}
                        path="/confrim"
                        auth={Verification}
                    />
                    <Route path="/ForgotPassword" component={ForgotPassword} />
                    <Route component={Reserve} exact={true} path="/payment/reserve" />
                    <Route component={Result} exact={true} path="/payment/result" />
                    <Route
                        component={FlightResult}
                        exact={true}
                        path="/flight/result"
                    />
                    <Route
                        component={domesticFlightResult}
                        exact={true}
                        path="/domesticflight/result"
                    />                    
                    <Route
                        component={GetPassengerFlight}
                        path="/flight/getpassenger"
                        exact={true}
                    />                  
                    <Route
                        component={GetPassengerDomesticFlight}
                        path="/domesticflight/getpassenger"
                        exact={true}
                    />
                    {/* {process.env.REACT_APP_THEME !=='NAMINTRAVELCOM' && process.env.REACT_APP_THEME !=='AYANAIRCOM' && <>
                      <Route path="/contact" component={ContactUS} />
                      <Route path="/terms" component={Terms} />
                      <Route path="/about" component={AboutUS} />
                    </>} */}

                    <Route component={NotFound} />
                  </Switch>
              
          }




        </main>
        
        <ResetError />

        {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' ? 
        <NaminFooter />
        :process.env.REACT_APP_THEME ==='AYANAIRCOM' ?
        <AyanAirFooter />
        :this.props.safarmarketLayout ? <SafarmarketFooter />  
        :<Footer />
        }
        {LayoutConfig.themeClassName === "padide-com-theme" || (
          <MessageAccept />
        )}
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  return {
    showAccountWindow: state.VisualData.showAccountWindow,
    showErrorWindow: state.VisualData.error.visibility,
    errorContent: state.VisualData.error.content,
    closableError:state.VisualData.error.closable,
    boldError:state.VisualData.error.bold,
    errorButtonLabel:state.VisualData.error.buttonLabel,
    errorIcon:state.VisualData.error.icon,
    registerMode: state.VisualData.registerMode,
    userToken: state.UserData.token,
    Verification: state.VisualData.Verification,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
    HomeStyle:state.VisualData.HomeStyle,
    scrollable:state.VisualData.scrollability,
    safarmarketLayout : state.VisualData.safarmarketLayout,
    ipLocation : state.VisualData.ipLocation
  };
};
const MapDispatchToProps = (dispatch) => ({
  toggleVarification: (data) => dispatch(toggleVarification(data)),
  toggleErrorWindow: (params) => dispatch(toggleErrorWindow(params)),
  getUser: (data) => dispatch(getUser(data)),
  setLang: (lan) => dispatch(setLang(lan)),
  logout: (data) => dispatch (logoutUser(data)),
  getUserBalance: (token) => dispatch(getBalance(token)),
  setIpLocation : data => dispatch (setIpLocation(data)),
  showError: (param) => {dispatch(showError(param));}
});
export default withRouter(connect(MapStateToProps, MapDispatchToProps)(Layout));
