import {Filter, FilterName, FilterType, Hotel} from '../Enum/Models';
import _ from "lodash";
export const HotelSearch=(state=new Hotel(),action)=>{
    switch (action.type) {
        case "HotelDetails":
            let hoteldetails=action.result;
            if(hoteldetails){
                if(hoteldetails.segments!==undefined){
                    hoteldetails.segments.forEach((item)=>{
                        item.rooms.forEach((itemIN)=>{
                            itemIN["cancelationInfo"]=null
                        })
                    })
                }
            }
            return {
                ...state,
                selectedHotel:hoteldetails
            };
        case "HotelReserve":
            return {
                ...state,
                selectedHotel:action.accommodation
            }
        case "SetReserveID":{
            return{
                ...state,
                reserveId:action.reserveId
            }
        }
        case "RESET_FILTER":
            return {
                ...state,
                selectedFilters:new Filter()
            }
        case "SET_FILTER":
            return {
                ...state,
                selectedFilters:{
                    ...state.selectedFilters,
                    [action.data.filterType]: action.data.value
                }
            }
        case "SET_HOTEL_AVAILABLE_FILTERS":
            return {
                ...state,
                availableFilters:action.data
            }

        case "editVoucher":
            return{
                ...state,
                reserveHotel:action.data
            }

        case "setVoucherDetails":
            return{
                ...state,
                voucherDetails:action.result
            }
        case "setCancelationRoom":
            let selectedHotel=JSON.stringify(state.selectedHotel);
            selectedHotel=JSON.parse(selectedHotel);
            selectedHotel.segments.forEach((item)=>{
                item.rooms.forEach((itemIn)=>{
                    if(itemIn.bookingToken===action.result.bookingID){
                        itemIn.cancelationInfo=action.result.cancelationInfo;
                    }
                });
            });
            return {
                ...state,
                selectedHotel

            }
        case "safarmarketHotelPixel":
            return{
                ...state,
                safarmarketPixel:action.data  
                }
        case 'SET_HOTEL_BOOKING_UNCONFIRM':
            return {
                ...state,
                hotelBookingUnconfirm: action.data
            }
        default:
            return  state;
    }
};
