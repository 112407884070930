import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Logo from '../../image/itours-logo-2.svg';
import style3Logo from '../../image/style3-loading.svg';
import Logo2 from '../../image/loading-2.svg';
import LayoutConfig from '../../Config/layoutConfig';

const Loading = props => {
    const {Dictionary} = props;
    let loading = (<div className="logo-loading">
        <img src={Logo} alt={'iTours logo'} title={'iTours logo'} />
    </div>);
    const wrapperClass = [];
    if (props.fullPage){
        wrapperClass.push("loading-full-page");
    }
    if (props.className){
        wrapperClass.push(props.className);
    }
    if (LayoutConfig.themeClassName === 'naminTravel-theme'){
        loading = <div className="loading-3 margin-bottom"><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /></div>;
    }
    if (LayoutConfig.themeClassName === 'ayanair-theme'){
        loading = <div className="margin-bottom">
            <div class="loading-4"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>;
    }
    if (LayoutConfig.themeClassName === 'style-2'){
        loading =<>
            {!props.noIcon && <img src={Logo2} alt="itours pending" className='loading-icon-style2' width={120} height={120} />}
            <div className='loading3dot'>
                <span/>
                <span/>
                <span/>
            </div>
        </>
    }
    if (LayoutConfig.themeClassName === 'style-3') {
        loading = (<div className="logo-loading">
            <img src={style3Logo} alt={'iTours logo'} title={'iTours logo'} />
        </div>);
    }
    return(
        <div className={wrapperClass.join(" ")}>
            <div className="loading-section text-center">
                {loading}
                <div>
                    {Dictionary ? <strong className={LayoutConfig.themeClassName === 'style-2'?"blue":"description"}>{props.description || `${Dictionary.loadingData}${Dictionary.comma} ${Dictionary.pleaseWait} ...`}</strong> : null }
                </div>
            </div>
        </div>      
    )
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary
});
const RouterDom=withRouter(Loading);
export default connect(mapStateToProps)(RouterDom);






