import React from 'react';
import {connect} from "react-redux";
import TermsFa from './Terms-fa';
import TermsEn from './Terms-en';
import TermsAr from './Terms-ar';
import TermsNo from './Terms-no';
import TermsTr from './Terms-tr';
import TermsSe from './Terms-se';
import TermsNamin from './Terms-namin';
import TermsAyanair from './Terms-ayan';

class Terms extends React.PureComponent{
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        const {Dictionary,LAN} = this.props;
        return (
            <div className={`terms-page section-vertical-padding ${(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') ? "" : "bg-white"}`}>
                <div className="page-container">
                    {LAN === "IR"?
                        null
                    :(process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM')?
                        <div className={`text-center bg-purple card-padding margin-bottom blue-border capitalized ${process.env.REACT_APP_THEME ==='AYANAIRCOM'?"white":""}`}>
                            <h2 className="font-23 bold">{Dictionary.termsAndConditions}</h2>
                            <p className="no-margin semi-bold">{Dictionary.ItIsTheBuyerAndTheTravelersResponsibilityTo}</p>
                        </div>
                    :
                        <h2 className="page-title">{Dictionary.termsAndConditions}</h2>
                    }
                  
                    <div className="wrapper justified">
                        {
                            process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?
                                <TermsNamin />                            
                            :
                            process.env.REACT_APP_THEME ==='AYANAIRCOM'?
                                <TermsAyanair />                            
                            :
                            (LAN === "IR")?
                                <TermsFa />
                            :(LAN === "NO")?
                                <TermsNo />
                            :(LAN === "AR")?
                                <TermsAr />
                            :(LAN === "TR")?
                                <TermsTr />
                            : (LAN === "SE")?
                                <TermsSe />
                            :
                                <TermsEn />
                        }                    
                    </div> 
                </div>
            </div>
        )
    }

}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Terms)