import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import queryString from "query-string";
import { withRouter } from 'react-router-dom';
import _ from "lodash";

import { setAvailableFilters, resetFilters } from "../../Actions/FlightAction";
import { Header, Flight as FlightURL, ServerAddress } from "../../Enum/Urls";
import FilterForm from "../../components/Flight/FilterForm";
import ListItem from "../../components/Common/ListItem";
import { Travel } from "../../Enum/Models";
import Loading from '../../components/Common/Loading';
import SearchForm from "../../components/Flight/SearchForm";
import AirlineFilter from "../../components/Flight/AirlineFilter";
import Page from "../../components/Common/Page";
import { showError } from "../../Actions/VisualDataAction";
import LayoutConfig from "../../Config/layoutConfig";
import FlightFormSearchTOF from "../../components/TOF/Flight-Form-Search-TOF";
import NextAndPrev from "../../components/Flight/NextAndPrev";
import { Skeleton } from "antd";
import FetchProgress from "../../components/Common/FetchProgress";

class domesticFlightResult extends React.Component {
  state = {
    flights: [],
    loading: false,
    firstItemIndex: 0,
    lastItemIndex: 9,
    showfilter: false,
    showsearch: false,
    sort:"cheapest",
    infoModalVisibility: false,
    timeoutModalVisibility: false
  };

  setPageItems(first, last) {
    this.setState({ firstItemIndex: first, lastItemIndex: last });
  }

  closeInfoModal = () => {
    this.setState({ infoModalVisibility: false });
  }

  resultsTimer = null;

  search() {
    this.props.resetAllFilters();
    const params = this.getParameters();

    this.setState({
      flights: [],
      loading: true
    });
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetFlights}`,
      data: { ...params },
      headers: { ...Header }
    }).then((response) => {
      this.setState({
        flights: response.data.result?.pricedItineraries
      });
      this.setPageItems(0,9);
      this.props.setAvailableFilters(response.data.result?.pricedItineraries);
      this.resultsTimer = setTimeout(() => { this.setState({ timeoutModalVisibility: true }) }, 600000);

    }).catch((error) => {
      try {
        this.props.showError({ visibility: true, content: error.response.data.error.message })
      } catch (e) {
        this.props.showError({ visibility: true, content: "Internal Error,Please refresh the page" })
      }
    }).finally(() => {
      this.setState({
        loading: false
      });
    });
  }

  showInfoModalIfFlightTypeIsRoundTrip() {
    if (this.props.location.search) {
      let searchedAirTripType = this.props.location.search.split("airTripType=")[1].split("&")[0];
      if (searchedAirTripType === "RoundTrip") {
        this.setState({ infoModalVisibility: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if( prevState.sort !== this.state.sort){      
      this.setState({
        firstItemIndex:0
      });
    }

    if (this.props.location.search !== prevProps.location.search) {
      this.search();
    }

    if (this.state.loading !== prevState.loading) {
      this.showInfoModalIfFlightTypeIsRoundTrip();
    }

    if (this.props.location !== prevProps.location) {
      clearTimeout(this.resultsTimer);
    }
    if (this.state.loading !== prevState.loading || prevState.firstItemIndex !== this.state.firstItemIndex) {
      this.scrollToResults();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.resultsTimer);
  }

  componentDidMount() {
    
    window.scrollTo(0, 0);

    this.showInfoModalIfFlightTypeIsRoundTrip();
    this.search();
  }

  handleSort = (type) => {
    this.setState({
      sort: type
    });
  };
  

  getParameters() {
    const parameters = queryString.parse(this.props.location.search);
    parameters.adult = +parameters.adult;
    parameters.child = +parameters.child;
    parameters.infant = +parameters.infant;
    parameters.departureDateTimes = parameters.departureDateTimes.split("|");
    parameters.originCodes = parameters.originCodes.split("|");
    parameters.originNames = parameters.originNames.split("|");
    parameters.destinationCodes = parameters.destinationCodes.split("|");
    parameters.destinationNames = parameters.destinationNames.split("|");
    parameters.isEthnic = process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' ? true : undefined;
    //parameters.supplier = (process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM')?"Ethnic":undefined;    
    if (parameters.airTripType === "RoundTrip") {
      parameters.originCodes.push(parameters.destinationCodes[0]);
      parameters.destinationCodes.push(parameters.originCodes[0]);
    }

    return parameters;
  }

  searchRepeatHandle = () => {
    this.search();
  }

  scrollToResults = () => { if (this.availsHolder) { this.availsHolder.scrollIntoView({ behavior: "smooth" }) } };

  render() {
    const { Dictionary,sort } = this.props;
    const isStyle3 = LayoutConfig.themeClassName === 'style-3';
    if (this.state.loading) {
      return (
        <>
          {!!isStyle3 && <FetchProgress compeleted={!this.state.loading} />}

          {isStyle3 ? (
            <div className="page-container margin-bottom-large">
              <div className="float-row padding-top">

                <div className="col-small-12 col-medium-3 margin-bottom hidden-xsmall hidden-small">
                  <div className="box-border bg-white card-padding">
                    <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                    <hr className="margin-top" />
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                  </div>
                </div>

                <div className="col-small-12 col-medium-9 margin-bottom">

                  <div className="route-skeleton margin-bottom">
                    <Skeleton active paragraph={{ rows: 1 }} className="origin" />
                    <Skeleton active paragraph={{ rows: 1 }} className="origin text-end" />
                    <Skeleton active paragraph={{ rows: 1 }} className="origin hidden-xsmall" />
                    <Skeleton active paragraph={{ rows: 1 }} className="origin hidden-xsmall" />
                    <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton hidden-xsmall" />
                  </div>
                  <hr className="margin-top margin-bottom" />
                  <div className="flight-skeleton-footer margin-bottom">
                    <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton" />
                    <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end button-skeleton" />
                  </div>


                  {[1, 2, 3, 4, 5].map(item => (
                    <div
                      key={item}
                      className="box-border bg-white card-padding margin-bottom flight-item-skeleton"
                    >
                      <div className="route-skeleton">
                        <span className="airline" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton hidden-xsmall hidden-small" />
                        <Skeleton active paragraph={{ rows: 2 }} className="origin" />
                        <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} className="one-line-skeleton hidden-xsmall" />
                        <Skeleton active paragraph={{ rows: 2 }} className="origin text-end" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-center hidden-xsmall" />
                      </div>
                      <hr className="margin-top margin-bottom" />
                      <div className="flight-skeleton-footer">
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end button-skeleton" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <Loading fullPage description={Dictionary.SearchingBestPrices + " ..."} />
          )}
        </>
      )
    }

    const { flights, sortAscending } = this.state;
    let filteredFlights;

    if (flights.length) {

      const filterFunction = item => {

        const { selectedFilters } = this.props;

        //filter stop:
        let itemStop;
        if (item.airItinerary.originDestinationOptions.some(option => option.numberOfStop > 1)) {
          itemStop = 2;
        } else if (item.airItinerary.originDestinationOptions.every(option => option.numberOfStop === 0)) {
          itemStop = 0;
        } else {
          itemStop = 1;
        }
        if (selectedFilters.stop.length && !selectedFilters.stop.includes(itemStop)) {
          return false
        }

        //filter airline:
        const itemAirline = item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.name;
        if (selectedFilters.airline.length && !selectedFilters.airline.includes(itemAirline)) {
          return false
        }

        //filter cabinClass:
        const itemCabinClass = item.airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass.name;
        if (selectedFilters.cabinClass.length && !selectedFilters.cabinClass.includes(itemCabinClass)) {
          return false
        }


        const getMinutes = time => {
          const timeArray = time.split("T")[1]?.split(":");
          return (+timeArray[0] * 60 + +timeArray[1]);
        }
        //filter departureTime:
        if (selectedFilters.departureTime.length) {

          const itemDepartureDateTime = item.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime;
          const itemDepartureMinutes = getMinutes(itemDepartureDateTime);

          if (selectedFilters.departureTime.every(filterItem => {
            const min = +filterItem.split("-")[0];
            const max = +filterItem.split("-")[1];
            if (itemDepartureMinutes > max || itemDepartureMinutes < min) {
              return true
            } else {
              return false
            }
          })) {
            return false
          }
        }

        //filter arrivalTime:
        if (selectedFilters.arrivalTime.length) {
          const itemLastRoute = item.airItinerary.originDestinationOptions[item.airItinerary.originDestinationOptions.length - 1];
          const itemLastSegment = itemLastRoute.flightSegments[itemLastRoute.flightSegments.length - 1];
          const itemArrivalDateTime = itemLastSegment.arrivalDateTime;
          const itemArrivalMinutes = getMinutes(itemArrivalDateTime);

          if (selectedFilters.arrivalTime.every(filterItem => {
            const min = +filterItem.split("-")[0];
            const max = +filterItem.split("-")[1];
            if (itemArrivalMinutes > max || itemArrivalMinutes < min) {
              return true
            } else {
              return false
            }
          })) {
            return false
          }
        }


        //filter airlinePrice:
        if (selectedFilters.airlinePrice.length && selectedFilters.airlinePrice.every(filterItem => {
          const filterItemAirline = filterItem.split("_")[0];
          const filterItemHasStop = filterItem.split("_")[1] === "withStop" ? true : false;
          const filterItemEthnic = filterItem.split("_")[2];

          const flightItemAirline = item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.code;
          const flightItemHasStop = item.airItinerary.originDestinationOptions.some(itineraryItem => itineraryItem.numberOfStop > 0);
          const flightItemIsEthnic = item.isEthnic;

          if (flightItemAirline !== filterItemAirline || flightItemHasStop !== filterItemHasStop) {
            return true;
          }
          if ((filterItemEthnic === "ethnic" && !flightItemIsEthnic) || (filterItemEthnic === "noEthnic" && flightItemIsEthnic)) {
            return true
          }
        })) {
          return false;
        }

        //filter baggage:        
        if (selectedFilters.baggage?.length === 1) {

          const allSegments = item.airItinerary.originDestinationOptions.reduce((segments, originDestinationItem) => ([...segments, ...originDestinationItem.flightSegments]), []);
          const getSegmentBaggage = segment => segment.baggageInformation.find(baggageInfoItem => baggageInfoItem.passengerType === "ADT");
          const itemHasBaggage = allSegments.every(segmentItem => getSegmentBaggage(segmentItem).baggageAllowance > 0);

          if ((selectedFilters.baggage.includes("withBaggage") && !itemHasBaggage) || (selectedFilters.baggage.includes("withoutBaggage") && itemHasBaggage)) {
            return false;
          }
        }

        return true
      }

      const calcDurationMinutes = duration => {

        let days, hours, minutes;

        if (duration.includes('.')) {
          days = +duration.split(".")[0];
          hours = +duration.split(".")[1].split(":")[0];
          minutes = +duration.split(".")[1].split(":")[1];
        } else {
          days = 0;
          hours = +duration.split(":")[0];
          minutes = +duration.split(":")[1];
        }

        return (minutes + 60 * hours + 24 * 60 * days);

      }

      const getOriginDestinationOptionsMinutes = options => {

        if (options.some(option =>  option.journeyDuration === "00:00:00")){
          return 0;
        }
        
        const minutes =  options.reduce((sum, current) => {
          const currentMinutes = calcDurationMinutes(current.journeyDuration);
          return sum + currentMinutes
        }, 0);

        return minutes;
      }


 const sortFunction = (b, a) => {
        
        if (!sort) return 1;

        const bFare = b.airItineraryPricingInfo.itinTotalFare.totalFare;
        const aFare = a.airItineraryPricingInfo.itinTotalFare.totalFare;

        if (sort === "theMostExpensive") {
          return (aFare - bFare);
        }

        if (sort === "cheapest") {
          return (bFare - aFare);
        }

        if (sort === "fastest") {

          const aDuration = getOriginDestinationOptionsMinutes(a.airItinerary.originDestinationOptions);
          const bDuration = getOriginDestinationOptionsMinutes(b.airItinerary.originDestinationOptions);
          
          if (aDuration === 0) {
            return -1
          }

          return (aDuration !== 0 && bDuration !== 0 && bDuration - aDuration);
        }

        return 1;
      }

      filteredFlights = flights.filter(filterFunction).sort(sortFunction);

    }
    return (
      <div className="flight search-result-page">
        {this.state.timeoutModalVisibility &&
          <div className="itours-modal visible">
            <div className="modal-overlay" onClick={this.closeItinerariModal} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <div className="text-center margin-top margin-bottom">
                  <div className="margin-bottom">
                    <span className="itours-icon gray-clock-icon icon-xlarge" />
                  </div>
                  <h4 className="normal-title margin-bottom">
                    {Dictionary.flightStillAroundTitle}
                  </h4>
                  <p className="timer-modal-text">
                    {Dictionary.flightStillAroundP1} {Dictionary.flightStillAroundP2}
                  </p>
                  <button
                    type="button"
                    className="button color-1-btn"
                    onClick={() => window.location.reload()}
                  >
                    {Dictionary.searchAgain}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }

        {(this.state.infoModalVisibility && flights.length) &&
          <div className="itours-modal visible">
            <div className="modal-overlay" onClick={this.closeModal} />
            <div className="modal-inner small-width">
              {/* <div className="modal-header">توجه</div> */}
              <div className="modal-main">
                <p className="font-20 justified semi-bold">
                  <span className="itours-icon info-icon margin-end-light" />
                  پرواز های زیادی برای این مسیر وجود دارد اما باید به صورت دو پرواز یکطرفه رزرو شوند در حال حاضر فقط پرواز های ماهان امکان رزرو به صورت رفت و برگشت را دارند.</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="button color-1-btn"
                  onClick={this.closeInfoModal}
                >
                  {Dictionary.ok}
                </button>
              </div>
            </div>
          </div>
        }

        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "naminTravel-theme__") && (
          <div className="result-inline-search-section">
            <div className="page-container">
              <FlightFormSearchTOF domesticFlight inline onSearchRepeate={this.searchRepeatHandle} />
            </div>
          </div>
        )}

        {this.props.airlineFilter.length !== 0 ? (
          <div className="airline-filter-holder">
            <AirlineFilter />
          </div>
        ) : null}

        <div className="page-container" ref={element => { this.availsHolder = element }}>
          <div className="avails-row float-row flight-avails-row">
            <button
              type="button"
              className="filter-responsive-btn"
              onClick={() => {
                this.setState((prevState) => ({
                  showfilter: !prevState.showfilter,
                }));
              }}
            >
              {Dictionary.filters}
            </button>

            <div className="col-small-12 col-medium-3">
              <div className="sidebar medium-box-border bg-white">
                {/* <div className="sidebar-item clearfix">
                                    <span className="results-number-holder">
                                        <b> {<span>{this.state.result && this.state.result.length}</span>} </b> flyreise funnet
                                    </span>
                                    <button type="button" className="button grey-btn pull-end filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                                        Filtre
                                    </button>
                                </div> */}
                <div className={`filters-holder ${this.state.showfilter ? "showfilter" : ""}`} >
                  <FilterForm domesticFlight={true} />
                  <button
                    type="button"
                    className="button red-btn filter-close-btn"
                    onClick={() => {
                      this.setState({
                        showfilter: false,
                      });
                    }}
                  >
                    {Dictionary.close}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-small-12 col-medium-9">
              {LayoutConfig.themeClassName === "padide-com-theme" || (
                <div className="search-form-wrapper ">
                  <SearchForm
                    onSearchRepeate={this.searchRepeatHandle}
                    domesticFlight={true}
                    inline={true}
                    originCity={flights[0]?.airItinerary.originDestinationOptions[0].flightSegments[0].departureAirport.cityName || ""}
                    destinationCity={flights[0] ? _.last(flights[0].airItinerary.originDestinationOptions[0].flightSegments).arrivalAirport.cityName : ""}
                  />
                </div>
              )}
              <div>
                {filteredFlights?.length ? (
                  <div className="avails-holder">
                    <div className="sort-next-prev margin-bottom-half">
                      <NextAndPrev />
                      <div className="sort-container">
                        <label className="margin-end-light" htmlFor="sort">
                          {Dictionary.sortBy}:{" "}
                        </label>
                        
                        {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ? (
                          <div className="namin-sort-btn-group">
                            <button 
                              type="button" 
                              onClick={() => this.handleSort("cheapest")} 
                              className={`btn ${this.state.sort==="cheapest"?"active":""}`}
                            >
                              {Dictionary.cheapest}
                            </button>
                            <button 
                              type="button" 
                              onClick={() => this.handleSort("fastest")} 
                              className={`btn ${this.state.sort==="fastest"?"active":""}`}
                            >
                              {Dictionary.fastest}
                            </button>
                          </div>
                        ) : (
                          <select onChange={e => this.handleSort(e.target.value)} className="results-sort-select">
                            <option value="cheapest">{Dictionary.cheapest}</option>
                            <option value="theMostExpensive">{Dictionary.theMostExpensive}</option>
                            <option value="fastest">{Dictionary.fastest}</option>
                          </select>
                        )}
                      </div>
                    </div>
                    <ListItem
                      data={filteredFlights}
                      last={this.state.lastItemIndex}
                      start={this.state.firstItemIndex}
                      type={Travel.flight}
                      domestic={true}
                    />
                    {filteredFlights.length > 10 && (
                      <Page
                        length={filteredFlights.length}
                        itemCurrent={this.state.firstItemIndex}
                        itemPerPage={10}
                        onChangeValue={this.setPageItems.bind(this)}
                      />
                    )}
                    {filteredFlights.length > 0 && (
                      <button
                        type="button"
                        className="filter-responsive-btn"
                        onClick={() => {
                          this.setState((prevState) => ({
                            showfilter: !prevState.showfilter,
                          }));
                        }}
                      >
                        {Dictionary.filters}
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="section-vertical-padding text-center">
                    <div>
                      <span className="itours-icon icon-xlarge error-icon" />
                    </div>
                    <h5 className="normal-title">
                      {Dictionary.noFlightFoundForYourRequest}.
                      <div className="page-subtitle">
                        {Dictionary.pleaseTryAgain}.
                      </div>
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  airlineFilter: state.FlightSearch.filterShow.airlinePrice,
  selectedFilters: state.FlightSearch.filterSelect,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchToProps = (dispatch) => ({
  setAvailableFilters: flights => {
    dispatch(setAvailableFilters(flights))
  },
  showError: (param) => {
    dispatch(showError(param));
  },
  resetAllFilters: () => {
    dispatch(resetFilters());
  }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(domesticFlightResult));
