import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import adv1 from '../../../image/namintravel-slider-images/adv-1.jpg';
import adv2 from '../../../image/namintravel-slider-images/adv-2.jpg';
import adv3 from '../../../image/namintravel-slider-images/adv-3.jpg';
import leftArr from '../../../image/arrow-left.svg'
import rightArr from '../../../image/arrow-right.svg'



const NaminHomeAds = props =>{
    const {Dictionary} = props;
    const settings = {
        nextArrow: <img src={rightArr} alt="next" />,
        prevArrow: <img src={leftArr} alt="previous" />,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        rtl: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 850,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1
              }
            }
          ]
      };
    return(<section className="namin-adv-section">
    <div className="page-container">
        <Slider {...settings}>
            <div>
                <div style={{backgroundImage:`url("${adv1}")`}} className="adv-item">
                    <h3 className="adv-title">{Dictionary.kg15extraBaggage}</h3>
                </div>
            </div>
            <div>
                <div style={{backgroundImage:`url("${adv2}")`}} className="adv-item">
                    <h3 className="adv-title">{Dictionary.BestPrices}</h3>
                </div>
            </div>
            <div>
                <div style={{backgroundImage:`url("${adv3}")`}} className="adv-item">
                    <h3 className="adv-title">{Dictionary.SpecialRates}</h3>
                </div>
            </div><div>
                <div style={{backgroundImage:`url("${adv1}")`}} className="adv-item">
                    <h3 className="adv-title">{Dictionary.kg15extraBaggage}</h3>
                </div>
            </div>
            <div>
                <div style={{backgroundImage:`url("${adv2}")`}} className="adv-item">
                    <h3 className="adv-title">{Dictionary.BestPrices}</h3>
                </div>
            </div>
            <div>
                <div style={{backgroundImage:`url("${adv3}")`}} className="adv-item">
                    <h3 className="adv-title">{Dictionary.SpecialRates}</h3>
                </div>
            </div>
        </Slider>
    </div>
</section>)
}

const stateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary
});

export default connect(stateToProps)(NaminHomeAds)