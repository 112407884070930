
import { connect } from "react-redux";

import defaultRoomImage from "../../image/default-hotel-image.svg";
import defaultRoomImageStyle2 from "../../image/hotel-vector.svg";
import LayoutConfig from "../../Config/layoutConfig";
import DomesticHotelDetailRateItem from "./DomesticHotelDetailRateItem";

const DomesticHotelDetailRooms = props => {
  const { Dictionary, availabilityItem } = props;

  let imageUrl = LayoutConfig.themeClassName === "style-2" ? defaultRoomImageStyle2 : defaultRoomImage;
  if (availabilityItem?.rooms[0]?.image){
    imageUrl = availabilityItem.rooms[0].image
  }

  return (
    <div className="card box-border margin-bottom bg-white domestic-hotel-availibility-item">
      <div className="card-padding border-bottom normal-title">
        {availabilityItem?.rooms[0]?.name}
      </div>
      <div className="card-padding">
        <div className="float-row">
          <div className="col-small-12 col-medium-2">
            <div className={`room-image-holder ${availabilityItem?.rooms[0]?.image ? "api-image":"default-image" }`}>
              <img 
              src={imageUrl}
              className="room-image" alt="room" />
            </div>
            { !!availabilityItem?.rates?.length && availabilityItem.rates[0].availablityType !== 'Completion' && !!availabilityItem?.rooms[0]?.capacity && <div className="margin-bottom">              
              {Dictionary.Capacity} : {availabilityItem.rooms[0].capacity.count} {availabilityItem.rooms[0].capacity.count > 2 ? Dictionary.guestPlurar : Dictionary.guest}
              <br />
              {/* <span className={availabilityItem.rooms[0].capacity.extraBed > 0 ? "" : "deleted-text"}>
                {Dictionary.ExtraBed} : {availabilityItem.rooms[0].capacity.extraBed}
              </span> */}
              {availabilityItem.rooms[0].capacity.extraBed > 0 ? "تخت اضافه دارد":<span className="deleted-text">تخت اضافه ندارد</span>}
            </div>}
          </div>
          <div className="col-small-12 col-medium-10">
            <div className="table-holder">
              <table className="hotel-room-table">
                <thead>
                  <tr className="hidden-xsmall">
                    <th>{Dictionary.WhatsIncluded}</th>
                    <th>{Dictionary.NumberOfRooms}</th>
                    <th>{Dictionary.totalForStay} ({props.duration} {Dictionary.nights})</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {availabilityItem?.rates?.map(rate => {
                    return <DomesticHotelDetailRateItem 
                      key={rate.bookingToken} 
                      rate={rate} 
                      selectedRoomToken={props.selectedRoomToken}
                      selectRoomHandle={props.selectRoomHandle}
                      room={availabilityItem.rooms[0]} 
                      duration={props.duration} 
                    />;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>

        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  Dictionary: state.VisualData.Dictionary
});

export default connect(mapStateToProps)(DomesticHotelDetailRooms);