import React, {useState} from "react";
import { Form, Input } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import { ServerAddress, User, Header } from "../../Enum/Urls";

const AyanAirNewsletter = props => {

    const { Dictionary } = props;
    const { getFieldDecorator } = props.form;

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newsLetterFormMessage, setNewsLetterFormMessage] = useState(undefined);

    const handleReset = () => {
        props.form.resetFields();
    };

    const closeModal = () => {
        if (!loading) {
            setShowModal(false)
        }
    }

    const handleSubmitNewsletter = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {

                setShowModal(true);
                setLoading(true);

                const params = { "email": values.email }
                axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.CreateNewsLetter}`, params, { headers: { ...Header, }, })
                    .then(({ data }) => {
                        setLoading(false);
                        if (data.error) {
                            setNewsLetterFormMessage({ text: data.error.message, success: data.success });
                        } else {
                            handleReset();
                            setNewsLetterFormMessage({ text: Dictionary.youveSuccessfullySubscribedToOurNewsletter, success: data.success });
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        setNewsLetterFormMessage({ text: error.response.data.error.message, success: false });
                    });
            }
        });
    }



    return (<>
        <div
            className={`newslettrModal itours-modal ${showModal ? "itours-modal visible" : ""}`}
        >
            <div className="modal-overlay" onClick={closeModal} />
            <div className="modal-inner small-width text-center">
                <div className="modal-main">
                    {loading ? (
                        <div className="loading-outer-container">
                            <div>
                                <div className="loading-inner-container">
                                    <div className="item-1"></div>
                                    <div className="item-2"></div>
                                    <div className="item-3"></div>
                                    <div className="item-4"></div>
                                    <div className="item-5"></div>
                                </div>
                                <div className="text-center">
                                    {Dictionary.loadingData} ...
                                </div>
                            </div>
                        </div>
                    ) : (
                        newsLetterFormMessage &&
                        <div>
                            <br /><br />
                            {newsLetterFormMessage.success ?
                                <span className="itours-icon check-green icon-xlarge" />
                                :
                                <span className="itours-icon error-icon icon-xlarge" />
                            }
                            <div className="margin-top margin-bottom">
                                {newsLetterFormMessage.text}.
                            </div>
                            <br />
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="button color-1-btn"
                        onClick={closeModal}
                        disabled={loading}
                    >
                        {Dictionary.ok}
                    </button>
                </div>
            </div>
        </div>
        <section className="ayanair-newsletter-section">
            <div className="page-container">
                <div className="ayanair-newsletter-Inner">

                    <h4 className="title uppercase">
                        {Dictionary.newsLetter}
                    </h4>

                    <div className="uppercase font-13">
                        {Dictionary.SubscribeToTheNewsletterToReceiveOffersAndNewPlacesToDiscover}
                    </div>

                    <Form onSubmit={handleSubmitNewsletter} >
                        <div className="form-group inline-newsletter-form">
                            <Form.Item className="no-margin">
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, message: Dictionary.EnterYourEmailAddress },
                                    { type: "email", message: Dictionary.enteredEmailAddressIsNotValid2 }],
                                })(
                                    <Input
                                        type="text"
                                        placeholder={Dictionary.EnterYourEmailAddress}
                                        className="form-input"
                                    />,
                                )}
                            </Form.Item>
                            <button type="submit" className="button red-btn">
                                {Dictionary.subscribe}
                            </button>
                        </div>
                    </Form>

                    <div className="font-13 ">
                        {Dictionary.YourEmailIsSafeWithUsWeDontSpam}
                    </div>

                </div>
            </div>
        </section>
    </>)
}

const stateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary
});

const WrappedNewsletterForm = Form.create({ name: 'newsLetterForm' })(AyanAirNewsletter);

export default connect(stateToProps)(WrappedNewsletterForm);