import React from 'react';
import {connect} from 'react-redux';
import {CountryPostalCode} from '../../Enum/Models';
import vipps from "../../image/vipps.svg";
import visa from "../../image/visa.svg";
import mastercard from "../../image/mastercard.svg";
import LayoutConfig from '../../Config/layoutConfig';

class GatwayItem extends React.Component{
    state={
        formStatus:false,
        formFeilds:[],
        postalCode:"0047",
        showForm:false
    };
    bindData(filedName,value){
        let formData=this.state.formFeilds;
        formData[filedName]=value;
        this.setState({
            formFeilds:formData
        })
    }
    openForm =()=>{
        this.setState({showForm:!this.state.showForm});
    }
    handlePaymentChoice(gatewaysItem){
        let form=[];
        if(gatewaysItem.form.elements.length===0){
            this.setState({
                showForm:false
            },()=>{
                this.props.selectedBankthis(gatewaysItem.id);
            })
        }else{
            this.setState({
                showForm:true
            },()=>{
                gatewaysItem.form.elements.forEach(item=>{
                    form[item.key]=this.state.formFeilds[item.key];
                });
                this.setState({
                    formFeilds:form,
                    formStatus:true
                },()=>{
                    let formStatus=true;
                    for(let itemFeild in this.state.formFeilds){
                        if(!this.state.formFeilds[itemFeild]){
                            formStatus=false;
                        }
                    }
                    if(formStatus){
                        let formData=this.state.formFeilds;
                        if (formData["PhoneNumber"]){
                            formData["PhoneNumber"]=this.state.postalCode+formData["PhoneNumber"];
                        }
                        this.props.selectedBankthis(gatewaysItem.id,this.state.formFeilds);
                    }
                })
            })

        }

    }

    
    render() {
        const {Dictionary} = this.props;
        const {gatewayItem} = this.props;
        const isStyle3 = LayoutConfig.themeClassName === 'style-3';
        return(
            <div className="gateway-item">
                <div className="item-main">
                    <div className={"bankItemlogo"}>
                        <div className={"bankImageContainer"}>
                            <img src={gatewayItem.image.path} title={gatewayItem.image.titleAttribute} alt={gatewayItem.image.altAttribute} className="bank-item-logo"/>
                            <b>{gatewayItem.name}</b>
                            {gatewayItem.name === "Worldline" && (LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') &&<>
                                <img className='small-logo margin-start-half'  src={visa} alt="visa" />
                                <img className='small-logo' src={mastercard} alt="mastercard" />
                                <img className='small-logo' src={vipps} alt="vipps" />
                            </>}
                        </div>
                    </div>
                    {
                        gatewayItem.form && gatewayItem.form.elements && (gatewayItem.form.elements.length > 0) && this.state.showForm ?
                        <button className="button grey-btn" type="button" onClick={this.openForm} >
                            {Dictionary.close}
                        </button>
                        :
                        <button 
                            className={`button ${isStyle3?"yellow-btn":"green-btn"}`} 
                            type="button" 
                            onClick={gatewayItem.form && gatewayItem.form.elements && (gatewayItem.form.elements.length > 0)  ? 
                                this.openForm
                                :
                                this.handlePaymentChoice.bind(this,gatewayItem)
                            }
                        >
                            {Dictionary.selectPayment}
                        </button>
                    }
                </div>
                {this.state.showForm && gatewayItem.form.elements && (gatewayItem.form.elements.length>0) &&
                    <form className="gateway-form">
                        {gatewayItem.form.elements.map((formItem,key) => {
                            let fieldLabel;
                            switch(formItem.key) {
                                case "Firstname":
                                    fieldLabel = Dictionary.firstName;
                                    break;
                                case "Lastname":
                                    fieldLabel = Dictionary.lastName;
                                    break;
                                case "Fullname":
                                    fieldLabel = Dictionary.fullName;
                                    break;
                                case "Email":
                                    fieldLabel = Dictionary.email;
                                    break;
                                case "PhoneNumber":
                                    fieldLabel = Dictionary.phoneNumber;
                                    break;
                                case "CountryCode":
                                    fieldLabel = Dictionary.CountryCode;
                                    break;
                                case "PostalArea":
                                    fieldLabel = Dictionary.PostalArea;
                                    break;
                                case "PostalCode":
                                    fieldLabel = Dictionary.PostalCode;
                                    break;
                                case "Address":
                                    fieldLabel = Dictionary.Address;
                                    break;
                                default:
                                    fieldLabel = formItem.key;
                            }
                            if (gatewayItem.name==="Revolut"){
                                if (formItem.key==="DisplayName"){
                                    fieldLabel = "Card Holder Name";
                                }else if(formItem.key==="Email"){
                                    fieldLabel = "Card Holder Email";
                                }
                            }
                            if (formItem.type !== "select") {
                                return (<div key={key} className="form-group">
                                    <label htmlFor={formItem.key} className="form-label">{fieldLabel}</label>
                                    {
                                        formItem.key==="PhoneNumber" &&
                                        <select className="form-select country-code" onChange={(e)=>this.setState({postalCode:e.target.value})} value={this.state.postalCode}>
                                            {CountryPostalCode.sort((a,b)=>{
                                            let x = a.code.toLowerCase();
                                            let y = b.code.toLowerCase();
                                            if (x < y) {return -1;}
                                            if (x > y) {return 1;}
                                            return 0;
                                            }).map((item,index)=><option key={index}  value={item.value}>{item.code}</option>)}
                                        </select>
                                    }
                                    <input id={formItem.key} className="form-input"
                                            type={formItem.type}
                                            onChange={(e)=>this.bindData(formItem.key,e.target.value)}
                                            name={formItem.key} defaultValue={formItem.defaultValue}/>
                                    {(this.state.formStatus&&!this.state.formFeilds[formItem.key])&&<div style={{color:'red'}}>{Dictionary.thisFieldIsRequired}</div>}
                                </div>)
                            } else {
                                return (
                                    <div key={key} className="form-group">
                                        <label
                                            htmlFor={formItem.key} className="form-label">{fieldLabel}</label>
                                        <select className="form-select" onChange={(e)=>this.bindData(formItem.key,e.target.value)} id={formItem.key} name={formItem.key}
                                                defaultValue={formItem.defaultValue}>
                                            <option value={null}></option>
                                            {formItem.values.map((optItem,optIndex) => <option key={optIndex} value={optItem.value}>{optItem.label}</option>)}
                                        </select>
                                        {(this.state.formStatus&&!this.state.formFeilds[formItem.key])&&<div style={{color:'red'}}>{Dictionary.thisFieldIsRequired}</div>}

                                    </div>
                                )
                            }

                            
                        })}
                        <div className="submit-container text-end">
                            <button 
                                className="button green-btn" 
                                type="button" 
                                onClick={this.handlePaymentChoice.bind(this,gatewayItem)}
                            >
                                {Dictionary.send}
                            </button>
                        </div>
                    </form>
                }
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(GatwayItem);