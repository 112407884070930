import { Skeleton } from "antd";

import defaultRoomImage from "../../image/default-hotel-image.svg";
import defaultRoomImageStyle2 from "../../image/hotel-vector.svg";
import LayoutConfig from "../../Config/layoutConfig";

const DomesticHotelRoomSkeleton = () => {
    const oneLineskeleton = <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />;
    return (
        <div className="card box-border margin-bottom bg-white domestic-hotel-availibility-item">
            <div className="card-padding border-bottom normal-title">
                {oneLineskeleton}
            </div>
            <div className="card-padding">
                <div className="float-row">
                    <div className="col-small-12 col-medium-2">
                        <div className="room-image-holder">
                            <img src={defaultRoomImage} className="room-image" alt="room" />
                        </div>
                        <Skeleton active paragraph={{ rows: 1 }} />
                    </div>
                    <div className="col-small-12 col-medium-10">
                        <div className="table-holder">
                            <table className="hotel-room-table">
                                <thead>
                                    <tr className="hidden-xsmall">
                                        <th>{oneLineskeleton}</th>
                                        <th>{oneLineskeleton}</th>
                                        <th>{oneLineskeleton}</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {[1, 2].map(item => <tr key={item}>
                                        <td>
                                            <div className="hidden-small hidden-medium hidden-large">
                                                {oneLineskeleton}
                                            </div>
                                            <div className="margin-bottom-light">
                                                {oneLineskeleton}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="hidden-small hidden-medium hidden-large">
                                                {oneLineskeleton}
                                            </div>

                                            <div>
                                                {oneLineskeleton}
                                            </div>
                                        </td>
                                        <td className="no-select-highlight">
                                            <div className="hidden-small hidden-medium hidden-large">
                                                {oneLineskeleton}
                                            </div>
                                            <div>
                                                {oneLineskeleton}
                                            </div>
                                        </td>
                                        <td>
                                            {oneLineskeleton}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
    )
}

export default DomesticHotelRoomSkeleton;