export const DomesticFlight = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" className={`svgIcon ${props.className || ""}`}>
			<g>
				<path d="M49.9,1.7c21.5,0,39.4,17.5,39.7,39c0.2,10.7-4.8,19.9-10.7,28.4c-7.2,10.3-16.1,19.3-25.6,27.4   c-1.8,1.6-3.6,2.2-5.6,0.6C35.2,86.1,23.4,74.2,15.3,59.5C0.2,32.1,21.2,1.7,49.9,1.7z M14.9,40.4c-0.2,4.8,1,9.3,3,13.7   c7,15.3,18.7,26.8,30.8,38c1.2,1.2,2,0.4,3-0.4c9.7-8.5,18.9-17.9,25.8-29.2c3.8-6,6.8-12.3,7.2-19.7C85.6,23.7,70.3,7,51.3,6.4   C31.4,5.8,15.3,20.9,14.9,40.4z" />
				<path d="M30,44.4c3.6,0.2,6.6,1.4,8.7-1.6c1-1.4,1.2-2-0.4-2.8c-2.8-1.4-5.6-3-8.3-4.6c-4.2-2.2-4.2-4.2-1.4-7.8   c3.2-4,6.8-5.2,11.7-3.2c3.2,1.4,6.6,1.8,9.7,2.8c2.2,0.8,4,0.4,5.8-1.4c1.8-2,4-3.8,6-5.4c2.4-2,5.2-3.2,8.5-2.6   c3.8,0.8,6,4.2,5.2,8.1s-3.4,7-6.4,9.5c-4,3.2-4.6,6.4-2.6,10.9c1.4,3,2,6.4,2.8,9.3c1,3.6-3.2,9.9-6.8,10.3   c-2,0.4-3.4-0.6-4.4-2.4c-1.4-2.6-3-5.4-4.4-7.9c-0.6-1-0.8-2.8-2-2.4c-1.4,0.6-3.2,1.8-3,4c0,0.6,0.2,1.2,0.2,1.8   c0.8,4.4-0.4,6.8-4.2,9.1c-1.6,1-3.2,1-4.4-0.6c-0.6-0.6-1-1.2-1.4-2c-2.2-5.2-5.8-8.9-10.9-11.1c-2-0.8-3.8-2.2-3.2-4.8   C24.8,47.4,28.6,44.4,30,44.4z M32.8,49.4c-1.2,0-2.2-0.4-2.6,0.6c-0.4,1.2,0.8,1.4,1.4,1.6c4.4,2,7.6,4.8,9.5,9.3   c0.4,0.8,0.6,2.8,2,2.2c1.4-0.4,1-2.2,0.6-3.2c-1.4-3.8,0.2-6.6,3.2-8.7c1-0.8,1.8-1.8,2.8-2.8c2.2-2.4,3.8-1.8,5.2,0.8   c1.8,3.4,3.8,6.8,5.6,10.1c0.8,1.6,1.6,1.8,2.8,0.4c1.2-1.2,1.2-2.4,0.8-3.8c-1.4-4.6-2.6-9.3-4-13.9c-0.8-2.4-0.4-4,1.4-5.6   c2.2-2,4.4-4.2,6.4-6.6c1.6-1.8,4.4-4.4,2.2-6.6c-2.2-2.2-4.8,0.2-6.6,2c-2.2,1.8-4.4,3.6-6.2,5.8c-1.8,2-3.8,2.4-6.4,1.6   c-4.6-1.4-9.3-2.4-13.9-3.8c-2.2-0.6-3.6,0.2-4.4,1.8C32,32.3,34,32.5,35,32.9c3.2,1.8,6.4,3.6,9.5,5.2c1.8,1,2.6,2.6,1.2,4   C41.7,45.4,39.3,52,32.8,49.4z" />
			</g>
		</svg>
	)
};

export const ForeignFlight = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" className={`svgIcon ${props.className || ""}`}>
			<g id="airplane">
				<path d="M3.5,62.5c1.7-2.1,3.5-4.2,5.2-6.4c1.5-1.9,3.3-2.5,5.6-1.9c3.1,0.6,6.4,1,9.4,1.3c0.6,0,1,0.2,1.5-0.2
		c3.3-3.3,6.6-6.6,10-10c-0.6-0.4-1.2-0.8-1.7-1c-7.5-4-14.8-8.1-22.3-12.1c-1-0.6-1.9-1.2-2.5-2.1c-1.3-1.9-1.3-4.4,0.4-6.2
		c2.7-2.9,5.6-5.8,8.5-8.5c1.7-1.7,4-1.5,6.2-0.8c10.4,3.1,21.2,5.8,31.6,8.5c1.2,0.4,1.9,0,2.9-0.8C63,18,67.9,13.6,72.7,9.3
		c3.3-2.9,7.1-5,11.6-5.6c5.8-1,11.4,2.5,12.5,7.9c0.8,3.9-0.4,7.5-2.1,11c-2.3,4.4-6,7.9-9.4,11.4c-2.7,2.9-5.6,5.8-8.5,8.7
		c-0.8,0.8-0.8,1.3-0.6,2.3c2.9,10.2,5.8,20.4,8.7,30.6c1,3.3,0.4,6.2-2.3,8.5c-2.1,1.9-4.2,4-6.2,6.2c-2.9,2.9-6.7,2.5-8.9-1.2
		c-1.7-2.9-3.1-5.8-4.8-8.7c-2.9-5-5.6-10.2-8.7-15.4c-3.1,3.1-6.2,6.2-9.2,9.2c-0.4,0.4-0.4,1-0.2,1.3c0.6,3.1,1,6.2,1.3,9.1
		c0.6,2.9-0.2,5.2-2.5,7.1c-2.1,1.7-4,3.7-6.2,5c-1,0-1.9,0-3.1,0c-1.2-1-2.3-1.9-2.9-3.3c-2.7-5.2-5.8-10-8.7-15
		c-0.6-0.8-1.2-1.3-2.1-1.9c-3.9-2.1-7.7-4.4-11.6-6.6c-2.3-1.2-4.2-2.5-5.8-4.6C3.5,64.4,3.5,63.5,3.5,62.5z M92.3,14.7
		c0-4.8-3.7-7.3-8.1-6.4c-3.3,0.8-6.2,2.5-8.7,4.8c-5.2,4.6-10.4,9.4-15.6,14.3c-1,1-2.1,1.2-3.5,0.8c-11.2-2.9-22.3-5.8-33.5-9.1
		c-1.3-0.4-2.3-0.2-3.3,0.8c-1.7,1.9-3.9,3.9-5.8,5.8c-1.5,1.5-1.5,1.5,0.4,2.7c8.5,4.6,17,9.1,25.4,13.7c2.3,1.2,2.5,2.7,0.8,4.4
		c-4,4.2-8.3,8.3-12.5,12.5c-1,1-2.1,1.3-3.5,1.2c-2.3-0.4-4.6-0.8-6.7-1c-5.2-0.8-5-0.8-8.3,3.3c-1.2,1.3-0.8,1.9,0.6,2.7
		c4.6,2.5,9.2,5.2,13.9,7.7c1.2,0.6,2.1,1.5,2.7,2.7c3.1,5,6.2,10.2,8.9,15.4c0.6,1,1,1,1.7,0.2c1-0.8,1.7-1.7,2.9-2.3
		c1.5-1.2,1.9-2.5,1.5-4.4c-0.6-3.3-1-6.4-1.5-9.6c-0.2-1.2,0-2.3,1-3.3c3.9-3.9,7.7-7.9,11.8-11.8c1.7-1.7,3.3-1.5,4.6,0.6
		c2.3,4,4.4,7.9,6.7,11.9c2.5,4.4,5,9.1,7.5,13.5c1,1.7,1.2,1.7,2.3,0.4c1.2-1.2,2.3-2.3,3.5-3.5c4.6-4.6,4.2-2.9,2.3-9.4
		c-2.7-9.8-5.6-19.5-8.3-29.3c-0.4-1.5-0.2-2.7,1.2-4c5.6-5.2,11-10.6,16-16.4C90.6,21.1,92.1,17.8,92.3,14.7z"/>
			</g>
		</svg>
	)
};

export const LocationSvg = props => {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" className={`svgIcon ${props.className || ""}`}>
			<path d="M480.089-490Q509-490 529.5-510.589q20.5-20.588 20.5-49.5Q550-589 529.411-609.5q-20.588-20.5-49.5-20.5Q451-630 430.5-609.411q-20.5 20.588-20.5 49.5Q410-531 430.589-510.5q20.588 20.5 49.5 20.5ZM480-159q133-121 196.5-219.5T740-552q0-117.79-75.292-192.895Q589.417-820 480-820t-184.708 75.105Q220-669.79 220-552q0 75 65 173.5T480-159Zm0 79Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/>
		</svg>
	)
};

export const Location2Svg = props => {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" className={`svgIcon ${props.className || ""}`}>
			<path d="M480-298q103.95-83.86 156.975-161.43Q690-537 690-604q0-59-21.5-100t-53.009-66.88q-31.51-25.881-68.271-37.5Q510.459-820 480-820q-30.459 0-67.22 11.62-36.761 11.619-68.271 37.5Q313-745 291.5-704T270-604q0 67 53.025 144.57T480-298Zm0 76Q343-325 276.5-419.199q-66.5-94.2-66.5-184.554Q210-672 234.5-723.5T298-810q39-35 86.98-52.5 47.98-17.5 95-17.5T575-862.5q48 17.5 87 52.5t63.5 86.533Q750-671.934 750-603.544 750-513 683.5-419 617-325 480-222Zm.089-318Q509-540 529.5-560.589q20.5-20.588 20.5-49.5Q550-639 529.411-659.5q-20.588-20.5-49.5-20.5Q451-680 430.5-659.411q-20.5 20.588-20.5 49.5Q410-581 430.589-560.5q20.588 20.5 49.5 20.5ZM210-80v-60h540v60H210Zm270-524Z"/>
		</svg>
	)
};

export const HotelApartment = props => {
	return(
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			className={`svgIcon ${props.className || ""}`}
			height="48" 
			viewBox="0 -960 960 960" 
			width="48"
		>
			<path d="M120-120v-555h165v-165h390v330h165v390H533v-165H427v165H120Zm60-60h105v-105H180v105Zm0-165h105v-105H180v105Zm0-165h105v-105H180v105Zm165 165h105v-105H345v105Zm0-165h105v-105H345v105Zm0-165h105v-105H345v105Zm165 330h105v-105H510v105Zm0-165h105v-105H510v105Zm0-165h105v-105H510v105Zm165 495h105v-105H675v105Zm0-165h105v-105H675v105Z"/>
		</svg>
	)
};

export const ForeignHotel = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" className={`svgIcon ${props.className || ""}`}>
			<g id="hotel">
				<path d="M97,80.2c0,4.9,0,9.9,0,14.8c0,1.2-0.2,1.4-1.4,1.4c-9.4,0-18.7,0-28.2,0c-20.8,0-41.9,0-62.7,0
		c-1.2-1-1.4-2.3-1.4-3.9c0.2-3.1,0-6.4,0-9.5c0-3.5,0.4-3.9,3.9-3.9c1.6,0,1.9-0.4,1.9-1.9c0-21.8,0-43.4,0-65.3
		C9.1,7,11.9,4,16.9,4c22.2,0,44.4,0,66.8,0c0.8,0,1.6,0,2.1,0.2c3.7,0.8,5.6,3.5,5.6,7.8c0,21.8,0,43.4,0,65.3
		c0,1.4,0.4,1.9,1.8,1.8C94.7,78.8,96,79,97,80.2z M50.4,8.7c-11.1,0-22.4,0-33.5,0c-2.3,0-3.1,0.8-3.1,2.7c0,21.8,0,43.6,0,65.6
		c0,1.2,0.2,1.4,1.4,1.4c7.4,0,14.6,0,22,0c1.2,0,1.4-0.2,1.4-1.4c0-4.3,0-8.4,0-12.7c0-4.5,2.9-7.8,7.6-8.2c2.7-0.2,5.6-0.2,8.4,0
		c4.5,0.4,7.4,3.5,7.4,8c0,4.3,0,8.4,0,12.7c0,1.2,0.4,1.6,1.6,1.6c7.2,0,14.6,0,21.8,0c1.4,0,1.6-0.4,1.6-1.8c0-21.4,0-43.1,0-64.5
		c0-2.7-0.8-3.5-3.5-3.5C72.5,8.7,61.4,8.7,50.4,8.7z M50.4,91.7c13.4,0,26.7,0,40.1,0c1.8,0,2.1-0.6,2.1-2.1
		c-0.2-1.4-0.2-2.7,0-4.1c0.2-1.6-0.4-1.9-1.9-1.9c-26.9,0-53.8,0-80.6,0c-0.2,0-0.4,0-0.6,0c-0.8-0.2-1.2,0.2-1.2,1.2
		c0,1.8,0.2,3.5,0,5.3c-0.2,1.6,0.4,1.8,1.8,1.8C23.4,91.7,36.8,91.7,50.4,91.7z M57.3,70.8L57.3,70.8c0-2.1,0-4.5,0-6.6
		c0-2.1-1.4-3.3-3.5-3.5c-2.3,0-4.5,0-6.8,0c-2.5,0-3.7,1.4-3.7,3.7c0,4.3,0,8.4,0,12.7c0,1,0.2,1.4,1.2,1.2c3.9,0,7.8,0,11.7,0
		c1.2,0,1.4-0.4,1.4-1.4C57.3,75.1,57.3,73,57.3,70.8z"/>
				<path d="M33.7,67.5c0,2.3,0,4.5,0,6.8c0,1.8-0.8,2.5-2.5,2.5c-3.7,0-7.4,0-10.9,0c-1.8,0-2.5-1-2.5-2.7
		c0-4.5,0-9,0-13.4c0-1.8,0.8-2.5,2.5-2.5c3.7,0,7.4,0,10.9,0c1.8,0,2.5,0.8,2.5,2.5C33.7,62.9,33.7,65.2,33.7,67.5z M29,67.1
		c0-0.6,0-1.2,0-1.9c0-2.7,0-2.7-2.7-2.7c-3.9,0-4.1,0-3.9,4.1c0,1.8-0.8,4.3,0.4,5.1c1.2,1,3.5,0.2,5.3,0.2h0.2c0.8,0,1-0.2,1-1
		C29,69.9,29,68.5,29,67.1z"/>
				<path d="M33.7,20c0,2.1,0,4.3,0,6.4s-0.8,2.7-2.9,2.9c-3.5,0-6.8,0-10.3,0c-2.1,0-2.9-0.8-2.9-2.9c0-4.3,0-8.6,0-13.1
		c0-2.1,0.8-2.9,2.9-2.9c3.5,0,7,0,10.5,0c1.9,0,2.7,0.8,2.7,2.7C33.7,15.5,33.7,17.9,33.7,20z M29,20c0-1.4,0-2.5,0-3.7
		c0-1-0.2-1.4-1.2-1.4c-1.4,0-2.9,0-4.5,0c-1,0-1.2,0.4-1.2,1.4c0,2.1,0.2,4.1,0,6.2c-0.2,1.8,0.4,2.1,2.1,1.9
		c1.6-0.2,3.5,0.6,4.5-0.4C29.8,23.3,28.8,21.4,29,20z"/>
				<path d="M33.7,43.2c0,2.3,0,4.7,0,7c0,1.8-0.8,2.5-2.5,2.5c-3.5,0-7.2,0-10.7,0c-1.8,0-2.7-0.8-2.7-2.7
		c0-4.5,0-9,0-13.2c0-1.9,0.8-2.7,2.7-2.7c3.5,0,7,0,10.7,0c1.9,0,2.7,0.8,2.7,2.7C33.7,38.9,33.7,41,33.7,43.2z M29,43.4
		c-0.2-1.4,0.6-3.5-0.4-4.5c-1-1-2.9-0.2-4.5-0.2c-0.2,0-0.4,0-0.6,0c-1-0.2-1.4,0.2-1.4,1.4c0,1.9,0,3.9,0,5.8c0,2.1,0,2.3,2.1,2.1
		c1.4,0,3.3,0.6,4.3-0.2C29.8,46.9,28.8,44.9,29,43.4z"/>
				<path d="M42.3,43.2c0-2.1,0-4.5,0-6.6c0-1.8,0.8-2.5,2.5-2.5c3.7,0,7.4,0,11.1,0c1.6,0,2.5,0.8,2.5,2.3
		c0,4.7,0,9.2,0,13.8c0,1.8-1,2.5-2.5,2.5c-3.7,0-7.4,0-10.9,0c-1.8,0-2.5-1-2.5-2.7C42.3,47.7,42.3,45.3,42.3,43.2z M53.8,43.4
		c0-0.6,0-1.4,0-1.9c0-2.7,0-2.7-2.7-2.7c-4.1,0-4.1,0-4.1,4.1c0,1.8-0.6,4.1,0.2,5.1c1.2,1.2,3.5,0.2,5.3,0.4
		c0.8,0,1.2-0.2,1.2-1.2C53.8,45.7,53.8,44.6,53.8,43.4z"/>
				<path d="M58.4,20c0,2.1,0,4.3,0,6.4s-0.8,2.9-2.7,2.9c-3.5,0-7,0-10.5,0c-1.9,0-2.7-0.8-2.7-2.7c0-4.5,0-9,0-13.2
		c0-1.8,0.8-2.7,2.7-2.7c3.5,0,7,0,10.7,0c1.9,0,2.7,0.8,2.7,2.7C58.4,15.3,58.4,17.7,58.4,20z M47.1,19.8c0,1.6-0.6,3.5,0.2,4.5
		c1,1,2.9,0.2,4.5,0.4c0.2,0,0.6,0,0.8,0c1,0.2,1.2-0.4,1.2-1.2c0-2.1-0.2-4.1,0-6.2c0.2-1.8-0.6-2.3-2.1-2.1
		c-1.6,0.2-3.5-0.6-4.3,0.4C46.4,16.5,47.1,18.4,47.1,19.8z"/>
				<path d="M83,67.3c0,2.3,0,4.7,0,7c0,1.8-0.8,2.5-2.5,2.5c-3.7,0-7.4,0-10.9,0c-1.8,0-2.5-1-2.5-2.7c0-4.5,0-9,0-13.4
		c0-1.8,0.8-2.5,2.5-2.5c3.7,0,7.4,0,10.9,0c1.8,0,2.3,0.8,2.5,2.5C83,62.9,83,65,83,67.3z M71.5,67c0,1.4,0,2.7,0,3.9
		s0.4,1.4,1.4,1.4c1.4,0,2.7-0.2,4.1,0c1.2,0,1.4-0.4,1.4-1.4c0-1.9,0-3.9,0-5.8c0-2.5,0.2-2.1-2.1-2.1c-1.4,0-3.3-0.6-4.3,0.2
		C70.9,63.8,71.7,65.8,71.5,67z"/>
				<path d="M83,43.4c0,2.3,0,4.5,0,6.8c0,1.8-0.8,2.5-2.5,2.5c-3.7,0-7.4,0-11.1,0c-1.6,0-2.3-0.8-2.3-2.3
		c0-4.7,0-9.2,0-13.8c0-1.6,0.8-2.3,2.3-2.3c3.7,0,7.4,0,11.1,0c1.8,0,2.5,0.8,2.5,2.5C83,38.9,83,41,83,43.4z M78.3,43
		c-0.2-1.2,0.6-3.1-0.2-3.9c-1-1-2.9-0.2-4.3-0.4c-0.2,0-0.6,0-0.8,0c-1.2-0.2-1.4,0.4-1.4,1.4c0,2.3,0,4.7,0,6.8
		c0,0.8,0.2,1.2,1.2,1.2c1.6,0,3.1,0,4.5,0c1,0,1.2-0.4,1.2-1.2C78.3,45.7,78.3,44.4,78.3,43z"/>
				<path d="M83,19.8c0,2.3,0,4.5,0,6.8c0,1.8-0.8,2.5-2.3,2.5c-3.7,0-7.6,0-11.3,0c-1.6,0-2.3-1-2.3-2.5
		c0-4.5,0-9.2,0-13.6c0-1.8,0.8-2.5,2.5-2.5c3.7,0,7.4,0,11.1,0c1.6,0,2.3,0.8,2.3,2.5C83,15.3,83,17.7,83,19.8z M78.3,20.4
		c0-1.6,0-2.9,0-4.1c0-0.8-0.2-1-1-1c-1.6,0-3.1,0-4.7,0c-0.8,0-1.2,0.4-1,1.2c0,2.1,0,4.1,0,6.2c0,2.5-0.2,2.1,2.1,2.1
		c1.4,0,3.3,0.4,4.3-0.2C78.9,23.5,78.1,21.6,78.3,20.4z"/>
			</g>
		</svg>
	)
};

export const DomesticHotel = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" className={`svgIcon ${props.className || ""}`}>
			<g>
				<path d="M96.1,84.9c-5.3,0-10.6,0-15.9,0c0-1.6,0-3.1,0-4.7c0-1.7,0-1.7-1.7-1.7c-18.8,0-37.7,0-56.5,0c-0.3,0-0.5,0-0.8,0
			c-0.6,0-0.9,0.2-0.9,0.8c0,1.3,0,2.6,0,3.9c0.1,1-0.4,1.4-1.4,1.3c-4.3,0-8.6,0-12.8,0c-1.4,0-1.5,0-1.5-1.5c0-8.9,0-17.8,0-26.7
			c0-2.6,0.9-5,2.7-6.9c0.5-0.6,0.7-1.1,0.7-1.9c0-6.7,0-13.4,0-20.1c0-6.4,5.1-11.5,11.5-11.5c20.6,0,41.2,0,61.8,0
			c7.3,0,11.5,6.1,11.4,11.3c-0.2,6.6,0,13.2-0.1,19.8c0,1,0.2,1.7,1,2.5c1.6,1.6,2.5,3.4,2.4,5.8c-0.1,4.7,0,9.4,0,14.1
			C96,74.6,96.1,79.7,96.1,84.9z M50.5,51.7c-12,0-24,0-36.1,0c-2.8,0-4.5,1.7-4.5,4.5c0,1.5,0,2.9,0,4.4c0,5.9,0,11.8,0,17.8
			c0,0.9,0.2,1.3,1.2,1.2c1.1-0.1,2.2,0,3.2,0c0.7,0,0.9-0.3,0.9-0.9c0-1.2,0-2.4,0-3.6c0-1.7,0-1.7,1.7-1.7c22.2,0,44.4,0,66.5,0
			c0.2,0,0.4,0,0.7,0c0.8-0.1,1.1,0.3,1,1.1c0,1.3,0,2.7,0,4c0,0.8,0.2,1.2,1.1,1.2c1.2-0.1,2.3-0.1,3.5,0c0.9,0,1.2-0.3,1.2-1.1
			c0-7.7,0-15.4,0-23.1c0-0.8-0.3-1.5-0.8-2c-1-1.1-2.2-1.6-3.7-1.6C74.5,51.7,62.5,51.7,50.5,51.7z M50.5,21.1c-10.3,0-20.7,0-31,0
			c-3.2,0-6.1,2.6-6.1,5.7c-0.1,6.3,0,12.6,0,18.9c0,0.7,0.2,0.9,0.9,0.9c2,0,4,0,6,0c0.8,0,1.1-0.3,1.1-1.1c0-2,0-4.1,0-6.1
			c0-3.6,2.6-6.4,6.2-6.4c6.1-0.1,12.3,0,18.4,0c1.5,0,2.7,0.5,3.8,1.4c0.6,0.5,0.9,0.5,1.5,0c1.1-1,2.5-1.5,4-1.5
			c6.1,0,12.2,0,18.3,0c3.4,0,6,2.7,6,6.2c0,2.1,0,4.2,0,6.4c0,0.8,0.3,1.1,1.1,1.1c1.9,0,3.8-0.1,5.7,0c1.1,0,1.4-0.3,1.4-1.4
			c0-5.9,0-11.8,0-17.6c0-3.8-2.7-6.4-6.5-6.4C70.9,21.1,60.7,21.1,50.5,21.1z M64.1,46.6c3.1,0,6.2,0,9.3,0c0.8,0,1.1-0.3,1.1-1.1
			c0-1.9,0-3.9,0-5.8c0-1.2-0.5-1.8-1.8-1.8c-5.6,0-11.3,0-16.9,0c-1.3,0-1.8,0.5-1.8,1.8c0,1.9,0,3.8,0,5.7c0,0.9,0.3,1.2,1.2,1.2
			C58.2,46.5,61.1,46.6,64.1,46.6z M36.5,46.6c3.1,0,6.2,0,9.3,0c0.7,0,1.2-0.1,1.2-0.9c0-2,0-4,0-6c0-1-0.6-1.7-1.7-1.7
			c-5.7,0-11.5,0-17.2,0c-1.1,0-1.6,0.5-1.6,1.7c0,1.9,0,3.9,0,5.8c0,0.9,0.2,1.2,1.1,1.2C30.6,46.5,33.5,46.6,36.5,46.6z"/>
			</g>
		</svg>
	)
};
export const Package = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" className={`svgIcon ${props.className || ""}`}>
			<g>
				<path d="M5.2,81.7c0-16.3,0-32.7,0-49c0.6-0.2,0.4-0.7,0.4-1.1c2.1-7.3,8-12,15.5-12c2.2,0,4.7-0.2,6.9,0
		c1.5,0,1.9-0.4,1.7-1.9c-0.2-1.1,0-2.2,0-3.4c0-4.9,1.1-6.5,5.4-8.4c9.9,0,20,0,29.9,0c0.2,0.2,0.2,0.2,0.4,0.4
		c3.4,1.1,4.9,3.4,4.9,6.7c0,1.7,0,3.4,0,5.1c0,1.1,0.2,1.5,1.5,1.5c2.8,0,5.6,0,8.4,0c8,0.2,14.8,7.1,14.8,15.2c0,15,0,29.7,0,44.7
		c0,0.7,0,1.5-0.2,2.2c-1.5,7.7-7.9,12.7-15.9,12.9c-17.4,0-34.8,0-52.2,0c-2.2,0-4.5,0-6.7,0c-6.7-0.4-12.5-4.9-14.4-11.4
		C5.8,82.5,5.8,81.9,5.2,81.7z M54.2,24.1c4.3,4.5,6.2,7.3,6.7,11.2c0.4,2.8,1.5,5.2,2.6,7.7c0.6,1.1,1.1,2.4,1.7,3.6
		c0.6,1.1,0.4,2.1-0.2,3c-4.3,6.2-8.8,12.2-13.1,18.3c-0.9,1.3-2.1,1.5-3.6,0.6c-4.1-2.8-8.2-5.8-12.2-8.6c-1.5-1.1-1.7-2.2-0.6-3.7
		c4.3-5.8,8.8-11.6,13.1-17.4c0.6-0.7,1.3-1.1,2.4-1.1c1.7,0,3.2,0,4.9,0c0.6,0,0.9,0,0.9-0.9c-0.7-6-7.3-12.2-13.1-12.5
		c-3.7-0.2-7.5-0.2-11.4-0.2c-1.1,0-1.3,0.4-1.3,1.3c0,21.1,0,42.1,0,63.2c0,0.9,0.2,1.3,1.3,1.3c12,0,23.9,0,36.1,0
		c0.9,0,1.1-0.4,1.1-1.3c0-2.8,0-5.8,0-8.6c0-18.1,0-36.1,0-54.3c0-1.5-0.4-1.9-1.9-1.9c-2.2,0.2-4.5,0-6.7,0
		C58.9,24.1,56.6,24.1,54.2,24.1z M26.5,57.2c0-10.5,0-21,0-31.4c0-1.3-0.4-1.7-1.7-1.7c-1.3,0.2-2.6,0-3.9,0
		c-6.4,0-11.4,4.9-11.4,11c0,14.6,0,29.2,0,43.8c0,6,4.9,10.7,10.9,11c1.5,0,3,0,4.3,0c1.3,0.2,1.7-0.4,1.7-1.7
		C26.5,77.8,26.5,67.5,26.5,57.2z M73.9,56.8c0,1.9,0,3.9,0,5.8c0,8.6,0,17,0,25.6c0,1.1,0.2,1.7,1.5,1.5c1.5-0.2,3,0,4.5,0
		c6-0.2,10.9-5.1,10.9-11c0-14.6,0-29.2,0-43.8c0-6-5.1-10.9-11-10.9c-1.5,0-2.8,0-4.3,0c-1.3-0.2-1.7,0.4-1.7,1.7
		C73.9,36.1,73.9,46.4,73.9,56.8z M50.3,19.6c4.9,0,9.5,0,14.4,0c0.9,0,1.3-0.2,1.3-1.3c0-1.9,0-3.6,0-5.4c0-1.7-0.7-2.4-2.4-2.4
		c-8.8,0-17.6,0-26.4,0c-1.7,0-2.6,0.9-2.6,2.6s0,3.4,0,5.1c0,1.1,0.2,1.5,1.5,1.5C40.7,19.6,45.4,19.6,50.3,19.6z M54.8,42.1
		c-1.1,0.4-2.6-0.7-3.7,0.7c-3.4,4.5-6.7,9.2-10.3,13.7c-0.6,0.7-0.4,1.1,0.2,1.5c2.4,1.7,4.7,3.2,6.9,5.1c0.9,0.6,1.3,0.6,1.9-0.4
		c3-4.3,6-8.6,9.2-12.7c1.3-1.7,1.3-3,0.6-5.1C58.7,42.6,57.6,41.3,54.8,42.1z"/>
			</g>
		</svg>
	)
};

export const Flight1 = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" className={`svgIcon ${props.className || ""}`}>
			<path d="M92,68.5v-9.3L55,36V10.6c0-3.9-3.1-7-7-7s-7,3.1-7,7v25.6L4,59.4v9.3l37-11.6v25.6l-9.3,7v7
	L48,92l16.3,4.7v-7l-9.3-7V56.9L92,68.5z"/>
		</svg>
	)
};


export const Hotel1 = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" className={`svgIcon ${props.className || ""}`}>
			<path d="M28.5,52c7.2,0,12.9-5.7,12.9-12.9s-5.7-12.9-12.9-12.9S15.7,32,15.7,39.2S21.6,52,28.5,52z
	 M79.8,26.5H45.7v29.8H11.6V17.9H3v64.2h8.6V69.2h76.8v12.9H97V43.7C97,34.1,89.4,26.5,79.8,26.5z"/>
		</svg>
	)
};


export const Package1 = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" className={`svgIcon ${props.className || ""}`}>
			<g>
				<polygon points="57.7,50.7 53.4,53.4 53.4,96.8 91,74.9 91,31.6 	" />
				<polygon points="64.4,11.9 50,3.5 11.2,25.8 25.8,34.2 	" />
				<polygon points="88.6,25.8 71.8,16.2 33.3,38.8 35.4,39.7 50,48.1 64.4,40 	" />
				<polygon points="30.4,54.6 23.4,51 23.4,40 9.3,31.8 9.3,74.9 46.4,96.5 46.4,53.4 30.4,44 	" />
			</g>
		</svg>
	)
};

export const FormIcon = () => {
	return (
		<svg  fill="currentcolor" viewBox="0 0 26 29">
			<rect width="23.881" height="28.889" rx="1.8"/>
			<path fill="#FFF" d="M13.96 22H3.72c-.398 0-.721.253-.721.564 0 .312.323.564.721.564h10.24c.398 0 .72-.252.72-.564 0-.311-.322-.564-.72-.564zm-2.503-3h-7.9a.56.56 0 0 0-.557.564c0 .312.25.564.556.564h7.9a.56.56 0 0 0 .557-.564.56.56 0 0 0-.556-.564zm2.503-3H3.72c-.398 0-.721.253-.721.564 0 .312.323.564.721.564h10.24c.398 0 .72-.252.72-.564 0-.311-.322-.564-.72-.564zm1.403-7H3.813C3.364 9 3 9.253 3 9.564c0 .312.364.564.813.564h11.55c.449 0 .813-.252.813-.564 0-.311-.364-.564-.813-.564zm-1.403 3H3.72c-.398 0-.721.253-.721.564 0 .312.323.564.721.564h10.24c.398 0 .72-.252.72-.564 0-.311-.322-.564-.72-.564zm3.012-6H3.919C3.412 6 3 6.253 3 6.564c0 .312.412.564.92.564h13.052c.507 0 .92-.252.92-.564 0-.311-.413-.564-.92-.564z"/>
			<path fill="#FFF" class="stroke" stroke-width=".6" d="M23.91 5.113a3.074 3.074 0 0 1 1.124 4.194l-8.302 14.38a.959.959 0 0 1-.262.293l-4.241 3.123a.959.959 0 0 1-1.523-.879l.584-5.234a.961.961 0 0 1 .123-.374l8.302-14.38a3.053 3.053 0 0 1 1.865-1.43 3.053 3.053 0 0 1 2.33.307zM12.857 24.257l1.293-.952-1.115-.644-.178 1.596zm2.692-2.36l6.363-11.02-1.995-1.152-6.362 11.02 1.994 1.151zm5.328-13.834l1.994 1.151.5-.866c.155-.267.195-.576.116-.874a1.142 1.142 0 0 0-.537-.7 1.142 1.142 0 0 0-.874-.114c-.297.08-.545.27-.699.536l-.5.867z"/>
		</svg>
	)
}
