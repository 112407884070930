import axios from "axios";
import { push } from "connected-react-router";

import { ServerAddress , DomesticHotelApiUrls , Header } from "../Enum/Urls";
import { editMessage,showError } from "./VisualDataAction";

export const setDomesticHotelFilter = (filterType, value) => ({
    type: "SetDomesticHotelFilter",
    filterType,
    value
});

export const setReservedHotel = (data) => ({
    type: "SetReservedDomesticHotel",
    data
});

export const setHotelByIdInfo = (data) => ({
    type: "SetDomesticHotelByIdInfo",
    data
});

export const DomesticHotelPreReserve = async (param, token, currentLang = 'fa') => {
    try {
      
      let HeaderAuth={
        ...Header,
        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
        'Accept-Language': `${currentLang === 'fa' ? 'fa-IR' : 'ar-AE'}`
      };

      if(token){
        HeaderAuth={
            ...Header,
            apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
            'Accept-Language': `${currentLang === 'fa' ? 'fa-IR' : 'ar-AE'}`,
            Authorization:`Bearer ${token}`
          };
      }

      let response = await axios.post(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.PreReserveHotel}`,
        param,
        {
          headers: HeaderAuth
        },
      )
      return response
    } catch (error) {
      return error
    }
  }

export const getReserveByIdDomesticHotel = (params, currentLang = 'fa', withHotelByIdRequest = false) => {
  return (dispatch) => {
    axios({
      method: "get",
      params,
      url: `${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.GetReserveById}`,
      headers: {
        ...Header,
        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
        'Accept-Language': `${currentLang === 'fa' ? 'fa-IR' : 'ar-AE'}`
      }
    })
      .then((response) => {
        dispatch(setReservedHotel(response.data.result));
        const hotelId = response.data.result?.accommodation?.id;
        if (withHotelByIdRequest && hotelId) {
          axios({
            method: "get",
            url: `${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetHotelById}?hotelId=${hotelId}`,
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': `${currentLang === 'fa' ? 'fa-IR' : 'ar-AE'}`
            }
          }).then((response) => {
            dispatch(setHotelByIdInfo(response.data));
            dispatch(editMessage(true));
          })

        } else {
          dispatch(editMessage(true));
        }
      })
      .catch((error) => {
        try {
          dispatch(showError({ visibility: true, content: error.response.data.error.message }))
        } catch (e) {
          dispatch(showError({ visibility: true, content: "Internal Error,Please refresh the page" }))
        }
      });
  }
};

export const confirmDomesticHotel = (params,currentLang="fa") => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.Confirm}`,
      data: { ...params },
      headers: {
        ...Header,
        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
        'Accept-Language': `${currentLang === 'fa' ? 'fa-IR' : 'ar-AE'}`
      }
    })
      .then((responsive) => {
        dispatch(
          push(
            `/profile/details?userName=${params.username}&reserveId=${params.reserveId}`
          )
        );
      })
      .catch((error) => {
        try {
          dispatch(
            push(
              `/profile/details?userName=${params.username}&reserveId=${params.reserveId}`
            )
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};