import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import queryString from "query-string";
import { Card } from 'antd';
import { withRouter} from 'react-router-dom';

import { ServerAddress, Payment as PaymentURL, Header } from "../../Enum/Urls";
import { showAccountWindowIR,showError } from '../../Actions/VisualDataAction';
import { getDepositBankList,paymentByMethod } from '../../Actions/PaymentAction';
import { getBalance } from '../../Actions/UserAction';
import PaymentRadioList from './PaymentRadioList';
import Price from './Price';
import Loading from './Loading';

const PaymentByDeposit = (props) => {
  const dispatch = useDispatch();
  const Dictionary = useSelector((state) => state.VisualData.Dictionary);
  const banks = useSelector(state => state.PaymentData.depositBanks);
  const Verification = useSelector((state) => state.VisualData.Verification);
  const userData = useSelector((state) => state.UserData);
  const balance = useSelector((state) => state.UserData.userBalance);
  const directTicketByIdResult = useSelector(state => state.FlightSearch?.directTicketByIdResult)

  const [requiredLoginModalVisibility, setRequiredLoginModalVisibility] = useState(false);
  const [paymentModalVisibility, setPaymentModalVisibility] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);
  const [bankValidationMessage,setBankValidationMessage] = useState(null);
  const [selectedBank,setSelectedBank] = useState(null);
  const [paymentLoading,setPaymentLoading] = useState(false);
  const [bankStatus,setBankStatus] = useState();
  const [confirmLoading,setConfirmLoading] = useState(false);
  const [refundConfirmCompleted,setRefundConfirmCompleted] = useState(false);

  const goHandler = () => {
    if (Verification) {
      setPaymentModalVisibility(true);
    } else {
      setRequiredLoginModalVisibility(true);
      setLoginClicked(true);
    }
  };
  
  useEffect(()=>{
    if (directTicketByIdResult){
      setConfirmLoading(false);
      setRefundConfirmCompleted(true);
      setPaymentModalVisibility(false);
    }
  },[directTicketByIdResult]);

  useEffect(()=>{
    const parameters = queryString.parse(props.location.search);
    const status = parameters.status;
    if (status === "0"){
      setBankStatus("error");
    }else if (status === "1"){
      setBankStatus("success");
    }
  },[props.location.search]);

  useEffect(() => {
    if (Verification && loginClicked) {
      setPaymentModalVisibility(true);
    }
  }, [Verification, loginClicked]);

  useEffect(()=>{
    if (props.initialDepositOpen){
      goHandler();
    }
  },[props.initialDepositOpen]);

  useEffect(() => {
    let requiredAmount;
    let userCurrency;
    if(balance && balance.currencyType){
      userCurrency = balance.currencyType;
    }else if(process.env.REACT_APP_SERVER_LOCALSERVER ==="santana.imt.as" || process.env.REACT_APP_SERVER_LOCALSERVER ==="itours.ir"){
      userCurrency = "IRR";
    }  

    if (balance && props.totalPrice && props.currency === userCurrency) {
      if (balance.amount){
        requiredAmount = props.totalPrice - balance.amount;
      }else{
        requiredAmount = props.totalPrice;
      }

      if (requiredAmount && requiredAmount > 0 && paymentModalVisibility){
        const parameters = {
          CurrencyType: "IRR",
          acceptLanguage: "fa-IR"
        }
        dispatch(getDepositBankList(parameters));    
      }
    }
  }, [paymentModalVisibility,balance]);

  let requiredAmount;
  let user_Currency;
  if(balance && balance.currencyType){
    user_Currency = balance.currencyType;
  }else if(process.env.REACT_APP_SERVER_LOCALSERVER ==="santana.imt.as" || process.env.REACT_APP_SERVER_LOCALSERVER ==="itours.ir"){
    user_Currency = "IRR";
  }  
  if (balance && props.totalPrice && props.currency === user_Currency) {
    if (balance.amount){
      requiredAmount = props.totalPrice - balance.amount;
    }else{
      requiredAmount = props.totalPrice;
    }
  }
  let steps = [];
  const maximumAmount = props.maxAmount;
  let lastStepAmount = 0;
  if (requiredAmount > maximumAmount){
    const stepsLength = parseInt(requiredAmount/maximumAmount);
    for(let i=0; i < stepsLength ; i++){
      steps.push(i);
    }
    lastStepAmount = requiredAmount % maximumAmount;
  }

  const selectBank = e => {
    setBankValidationMessage(null);
    setSelectedBank(+e.target.value);
  }  

  const makeBankToken = (params) => {
    let token = localStorage.getItem("userToken");
    let HeaderAthu;
    if (token) {
        HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
        return;
    }
    axios({
        method: "post",
        url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.MakeTokenDeposit}`,
        data: params,
        headers: HeaderAthu,
    })
    .then((response) => {
        const token = response.data?.result?.tokenId;
        if (token){
            window.location.href = `http://payline2.itours.ir/fa/User/Payment/PaymentRequest?tokenId=${token}`;
        }
    })
    .catch((error) => {
        try {
            dispatch(showError({
                visibility: true,
                content: error.response.data.error.message,
                closable: true
            }));
        } catch (e) {
          console.error("e-6");
            dispatch(showError({
                visibility: true,
                content: "Internal Error,Please refresh the page",
                closable: true
            }));
        }
    });
  }

  const chargeDepositHandler = () => {
    let amount;
    if (steps.length === 0){
      amount = requiredAmount;
    }else{
      amount = maximumAmount;
    }

    if (selectedBank) {
        setPaymentLoading(true);

        const callBackUrl = `${ServerAddress.Type}${ServerAddress.localServer}/payment/reserve?reserveId=${props.reservId}&mode=depositCharge`;
        const parameters = {
            gatewayId: selectedBank,
            ipAddress: "192.168.1.2",
            amount: +amount,
            callBackUrl: callBackUrl,
            currencyType: props.currency,
            formParams: {}

        }
        makeBankToken(parameters);      
    }else{
      setBankValidationMessage("یکی از گزینه ها را برای پرداخت انتخاب کنید.");
    }
  }

  let statusMessage = null;
  if (bankStatus && bankStatus==="success"){
    statusMessage = (
      <div className="green-card card card-padding message-block box-border margin-bottom">
        افزایش اعتبار کیف پول شما با موفقیت انجام شد.
      </div>
    )
  };
  if (bankStatus && bankStatus==="error" && requiredAmount > 0){
    statusMessage = (
      <div className="red-card card card-padding message-block box-border margin-bottom">
        افزایش اعتبار کیف پول شما با خطا روبرو شد، لطفا دوباره تلاش کنید.
      </div>
    )
  };
  
  const confirm = () => {
    setConfirmLoading(true);
    let token = localStorage.getItem("userToken");
    let HeaderAthu;
    if (token) {
        HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
        return;
    }
    const param = {
      "reserveId": +props.reservId,
      "username": props.username
    }
    axios({
        method: "post",
        url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.ConfirmByDeposit}`,
        data: param,
        headers: HeaderAthu,
    })
    .then((response) => {
      if(response.data?.result?.isSuccess){

        //update balance:
        dispatch(getBalance());
        const token=localStorage.getItem('userToken');
        if (token){
          dispatch(getBalance(token));
        }

        let params = new URLSearchParams();
        params.append("reserveId", +props.reservId);
        props.username && params.append("username", props.username);

        dispatch(paymentByMethod(params))
      }
    })
    .catch((error) => {
      setConfirmLoading(false);
        try {
            dispatch(showError({
                visibility: true,
                content: error.response.data.error.message,
                closable: true
            }));
        } catch (e) {
          console.error("e-5");
            dispatch(showError({
                visibility: true,
                content: "Internal Error,Please refresh the page",
                closable: true
            }));
        }
    });
  }

  const AppAddress = process.env.REACT_APP_SERVER_LOCALSERVER; 
  const isItoursIr = AppAddress ==="itours.ir";
  if (!isItoursIr){
    return null;
  }

  return (
    <>
      {requiredLoginModalVisibility && (
        <div className="itours-modal login-to-book-modal visible">
          <div
            className="modal-overlay"
            onClick={() => {
              setRequiredLoginModalVisibility(false);
            }}
          />
          <div className="modal-inner small-width">
            <div className="modal-main">
              <span className="itours-icon user-login icon-xxlarge" />
              <h3 className="normal-title">{userData && userData.userName ? 'please Confirm Your Email To Continue' : Dictionary.pleaseLoginToContinue} ...</h3>
              <p className="text-center gray semi-bold margin-bottom-large">
                <span className="itours-icon info-icon icon-small margin-end-light" />
                جهت ادامه فرایند پرداخت از کیف پول وارد حساب کاربری خود شوید.
              </p>
              <div className="text-center margin-top-large margin-bottom-large">
                {userData && userData.userName ? (
                  <button
                    type="button"
                    className="button blue-btn large-btn"
                    onClick={() => {
                      window.location.reload();
                    }}>
                    {Dictionary.Reload}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button red-btn large-btn"
                    onClick={() => {
                      setRequiredLoginModalVisibility(false);
                      dispatch(showAccountWindowIR('usernameModal'));
                    }}>
                    {Dictionary.login}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {paymentModalVisibility && (
        <div className="itours-modal visible">
          <div className="modal-overlay" />
          <div className={`modal-inner ${paymentLoading || confirmLoading?"xsmall-width":(requiredAmount && requiredAmount > 0)?"small-width":"small-width"}`}>
            <div className="modal-main">
              {paymentLoading || confirmLoading ? <Loading className="margin-top-large margin-bottom-large" />:(
                <>
                  <h3 className="normal-title margin-bottom">پرداخت از کیف پول آیتورز</h3>
                  {statusMessage}
                  <div className="margin-bottom">
                    <div className="flex-ends-medium middle">
                      <span>موجودی کیف پول : </span>
                      {balance==="loading"?<span className="loading-inline-circle" />: balance.amount > 0 ? <Price currency={user_Currency}>{balance.amount}</Price> : 0}
                    </div>
                    <div className="flex-ends-medium middle">
                      <span>مبلغ قابل پرداخت : </span>
                      <Price currency={props.currency}>{props.totalPrice}</Price>
                    </div>
                  </div>
                  {(!!requiredAmount && requiredAmount > 0) && (
                    <>
                      {steps.length === 0 ? (
                        <p className="justified yellow">
                          <span className="itours-icon icon-small yellow-warning margin-end-light" />
                          موجودی کیف پول شما کمتر از مبلغ رزرو است، کیف پول خود را شارژ نمایید.
                        </p>
                      ):(
                        <>
                          <p className="justified yellow">
                            <span className="itours-icon icon-small yellow-warning margin-end-light" />
                            موجودی کیف پول شما کمتر از مبلغ رزرو است و با توجه به محدودیت سقف پرداخت از طریق درگاه های اینترنتی و بیشتر بودن مبلغ مورد نیاز برای نهایی کردن رزرو، افزایش موجودی کیف پول شما در چند مرحله انجام میشود:
                          </p>
                          <ul  className='gray'>
                            {steps.map(item =><li key={item} ><Price className="bold" currency={props.currency}>{maximumAmount}</Price></li> )}
                            {lastStepAmount > 0 && <li><Price className="bold" currency={props.currency}>{lastStepAmount}</Price></li>}
                          </ul>
                        </>
                      )}

                      <div className="flex-ends-medium middle semi-bold font-18 margin-bottom">
                        <span>مبلغ افزایش اعتبار : </span>
                        <Price className="bold" currency={props.currency}>{steps.length === 0 ? requiredAmount : maximumAmount}</Price>
                      </div>

                      <div>

                      <div className="card card-padding message-block error-message box-border margin-bottom">
                        <h3 className="normal-title">
                          <span className="itours-icon icon-large red-info-icon margin-end-half" />
                          لطفا توجه داشته باشید که درگاه پرداخت فقط با آی پی داخل ایران قابل استفاده می باشد.
                        </h3>
                      </div>

                      {!banks ? (
                        <div className="form-group relative required">
                          <label className="form-label">انتخاب بانک</label>
                          <div className='margin-bottom'>
                            <Card size="small" title={<span className='loading-inline-circle medium' />}>
                              <span className='loading-inline-circle medium' />
                            </Card>                 
                          </div>
                        </div>
                        ) : (
                            <div className="form-group relative required">
                                <label className="form-label">انتخاب بانک</label>
                                <PaymentRadioList
                                    banks={banks}
                                    onSelectBank={selectBank}
                                />
                                {bankValidationMessage && <p className="purple margin-bottom-light">{bankValidationMessage}</p>}
                            </div>
                        )}
                      </div>
                      
                    </>
                  )}
                </>
              )}
            </div>
            <div className="modal-footer">
              {!confirmLoading && (requiredAmount && requiredAmount > 0) ? (
                <div className='clearfix'>
                  <button type='button' className='button color-1-btn pull-start' onClick={chargeDepositHandler} disabled={paymentLoading}>
                    افزایش اعتبار کیف پول آیتورز
                  </button>
                  <button
                    type="button"
                    disabled={paymentLoading}
                    className="button grey-btn pull-end"
                    onClick={() => {setPaymentModalVisibility(false);}}
                  >
                    لغو
                  </button>
                </div>
              ):(
                <div className='clearfix'>
                  <button
                    type="button"
                    className="button color-1-btn pull-start"
                    onClick={confirm}
                    disabled={paymentLoading || confirmLoading}
                  >
                    پرداخت
                  </button>
                  <button
                    type="button"
                    className="button grey-btn pull-end"
                    onClick={() => {setPaymentModalVisibility(false);}}
                    disabled={paymentLoading || confirmLoading}
                  >
                    لغو
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white box-border margin-bottom card-padding">
        <div className="flex-ends middle">
          <div>
            <span className="itours-icon wallet margin-end-half icon-large" />
            <strong>پرداخت از کیف پول آیتورز</strong>
          </div>
          {refundConfirmCompleted?
            <span className='loading-inline-circle medium blue margin-end-half' />
          :
            <button type="button" disabled={paymentLoading || confirmLoading} className="button color-1-btn" onClick={goHandler}>
              ادامه
            </button>
          }

        </div>
      </div>
    </>
  );
};

export default withRouter(PaymentByDeposit);
