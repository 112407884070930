import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrency } from '../../Actions/UserAction';

const CurrencyButton = props => {

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const [activeCurrency,setActiveCurrency] = useState(props.currentCurency);

    const CurrencyWrapper = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(()=>{
        setOpen(false);
        dispatch(setCurrency(activeCurrency));
    },[activeCurrency]);

    const handleClickOutside = (e) => {
        if (CurrencyWrapper.current && !CurrencyWrapper.current.contains(e.target)) {
            setOpen(false);
        }
    };

    return (
        <div className={`relative ${process.env.REACT_APP_THEME ==='AYANAIRCOM'?"margin-start-half":"margin-end-half"} currency-wrapper`}>

            <button type='button' onClick={() => { setOpen(prevState => !prevState) }} className={`currency-btn ${process.env.REACT_APP_THEME ==='AYANAIRCOM'?"ayan-outline-btn":""}`}>
                {activeCurrency}
            </button>

            <div className={`currency-popup ${open ? "open" : ""}`} ref={CurrencyWrapper}>
                {props.currencyList.map(item => (
                    <div
                        key={item}
                        className={`currency-item ${item === activeCurrency ? "active" : ""}`}
                        onClick={() => {
                            setActiveCurrency(item);
                            setTimeout(()=>{
                                window.location.reload()
                              },[200])
                        }}
                    >
                        {item}
                        {item === activeCurrency && <span className='itours-icon icon-small green-checkmark margin-start-light' />}
                    </div>
                ))}
            </div>

        </div>
    )
}

export default CurrencyButton;