import axios from "axios";
import moment from "moment-jalaali";
import {ServerAddress, User, Header,Payment as PaymentURL} from "../Enum/Urls";
import {Reducers,CountryPostalCode} from "../Enum/Models"
import {toggleVarification,toggleAccountWindow,showError,editMessage} from "../Actions/VisualDataAction";
import Currency from '../Enum/Currency';

export const setUserBalance = data => ({type:Reducers.GET_BALANCE,data:data});

export const logoutUser=()=>{
    localStorage.removeItem("userToken");
    localStorage.removeItem("userTokenCreateTime");
    localStorage.removeItem("userData");
    return {type:Reducers.LOGOUT}
};
//create user
export const  getUserAsync =(data) =>({
    type:Reducers.CREATEUSER,userData:data
});
//set Currency
export const setCurrency=(currency)=> {
    Currency.setCurrency(currency);
    return {
        type: Reducers.SETCURRENCY, Currency: Currency.getCurrency()
    }
};
export const getCurrency=()=>{
    return{
        type:Reducers.SETCURRENCY,Currency:Currency.getCurrency()
    }
}
//verification user by tokenID
export const getUser=()=>{
  return dispatch=>{
      let currency=Currency.getCurrency();
      dispatch(setCurrency(currency));
      let token=localStorage.getItem('userToken');
      if(token){
          axios({
              method:'get',
              url:`${ServerAddress.Type}${ServerAddress.User}${User.VarficationUser}`,
              headers:{Authorization:`Bearer ${token}`,...Header}
          })
          .then((responsive)=>{
              dispatch(getUserAsync(responsive.data.result));
              dispatch(toggleVarification(true));

          })
          .catch((error)=>{
                  dispatch(toggleVarification(false))
              })
      }else{
          dispatch(toggleVarification(false))
      }

  }
};
//login user
export const loginUser=(params)=>{
    return (dispatch)=>{
            axios({
                method:'post',
                url:`${ServerAddress.Type}${ServerAddress.User}${User.Login}`,
                data:{...params},
                headers:{...Header}
            }).then((response)=>{
                const user = response.data.result.user;

                localStorage.setItem('userData',JSON.stringify(user));
                localStorage.setItem('userToken',response.data.result.accessToken);
                localStorage.setItem('userTokenCreateTime',moment().format());

                dispatch(getUserAsync(response.data.result.user));
                dispatch(toggleVarification(true));
                dispatch(toggleAccountWindow(false));
                
                const currentPagePath = window.location.pathname;
                if (currentPagePath === "/confrim" && response.data.result.user.isEmailConfirmed){
                    window.location.href='/';
                }

                // if(response.data.result.user.isEmailConfirmed){
                //     window.location.href='/';
                // }else{
                //     window.location.href='/profile?mode=userInformation';
                // }


            }).catch((error)=>{
                try{
                    dispatch(toggleVarification(false));
                    dispatch(showError({visibility:true,content:error.response.data.error.message,closable:true}))
                }catch (e) {
                  console.error("e-15");
                    dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page",closable:true}))
                }
            })

    }
};

//register user
export const registerUser=(params)=>{
    return(dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.Register}`,
            data:{...params},
            headers:{...Header}
        })
        .then((response)=>{
               dispatch(loginUser(params));

        })
        .catch((error)=>{
          try{
            dispatch(toggleVarification(false));
            dispatch(showError({visibility:true,content:error.response.data.error.message}))
          }catch (e) {
                console.error("e-14");
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
}

//register user
export const registerNaminUser=(params)=>{
    return(dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.RegisterByInfo}`,
            data:{...params},
            headers:{...Header}
        })
        .then((response)=>{
            let newParams = {
                emailOrPhoneNumber:params.emailAddress,
                password:params.password
            }
               dispatch(loginUser(newParams));

        })
        .catch((error)=>{
          try{
            dispatch(toggleVarification(false));
            dispatch(showError({visibility:true,content:error.response.data.error.message}))
          }catch (e) {
              console.error("e-13");
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
}
//confrimEmail
export const confrimEmail=(params)=>{
    return (dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.ActivateEmail}`,
            data:{...params},
            headers:{...Header}
        }).then((response)=>{
            dispatch(editMessage(true));
        }).catch((error)=>{
          try{
            dispatch(showError({visibility:true,content:error.response.data.error.message}))
          }catch (e) {
            console.error("e-12");
            dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }

        });
    }

}

//reset email by revocery link
export const resetEmail=(params)=>{
    return (dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.ResetPassword}`,
            data:{...params},
            headers:{...Header}
        }).then((result)=>{
            dispatch(editMessage(true));
        }).catch((error)=>{
          try{
            dispatch(showError({visibility:true,content:error.response.data.error.message}))
          }catch (e) {
              console.error("e-11");
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }

        })
    }

}
export const updateUser = async (param) => {
    let token=localStorage.getItem("userToken");
    try {
      let response = await axios.put(`${ServerAddress.Type}${ServerAddress.User}${User.Update}`,
       param,
       {headers: {Authorization:`Bearer ${token}`,...Header}}
       );
      return response;
    } catch (error) {
      console.error("e-16");
      return error.response?.data || "Internal Error,Please try again";
    }
};

//resetEmail
//forgotEmail

//only for itours ir
export const userExist = async (param) => {
    try {
      let response = await axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.IsUsernameExist}`,
       param,
       {headers: {...Header}}
       );
      return response;
    } catch (error) {
      return error.response?.data?.error;
    }
};
export const sendOTP = async (param,acceptLanguage) => {
    try {
      let response = await axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.SendOTP}`,
       param,
       {headers: {
        ...Header,
        'Accept-Language': acceptLanguage
      }}
       );
      return response;
    } catch (error) {
      return error.response.data.error;
    }
};
export const RegisterOrLogin = async (param) => {
    try {
      let response = await axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.RegisterOrLogin}`,
       param,
       {headers: {...Header}}
       );
      return response;
    } catch (error) {
      return error.response.data.error;
    }
};
export const loginUserIr = async (params) => {
  try {
    let response = await axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.Login}`, params, { headers: { ...Header } });
    return response;
  } catch (error) {
    return error.response.data.error;
  }
};
export const recoverPassword= async (params) => {
    try {
      let response = await axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.Forgot}`, params, { headers: { ...Header } });
      return response;
    } catch (error) {
      return error.response.data;
    }
}
export const recoverPasswordByMobile= async (params) => {
    try {
      let response = await axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.ForgotPasswordByPhoneNumber}`, params, { headers: { ...Header } });
      return response;
    } catch (error) {
      return error.response.data;
    }
}
export const ResetPasswordByPhoneNumber= async (params) => {
    try {
      let response = await axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.ResetPasswordByPhoneNumber}`, params, { headers: { ...Header } });
      return response;
    } catch (error) {
      return error.response.data;
    }
}


export const getBalance = (token) => {
    return (dispatch) => {
      if (token) {
        dispatch(setUserBalance("loading"));
        const HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
        axios({
          method: "get",
          url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.GetBalance}`,
          headers: HeaderAthu,
        })
          .then((response) => {
            dispatch(setUserBalance(response.data.result));
          })
          .catch((error) => {
            dispatch(setUserBalance());
            try {
              dispatch(
                showError({
                  visibility: true,
                  content: error.response.data.error.message,
                })
              );
            } catch (e) {
              console.error("e-10");
              dispatch(
                showError({
                  visibility: true,
                  content: "Internal Error,Please refresh the page",
                })
              );
            }
          });
      } 
    };
  };

  export const splitCodeAndNumber = (number) => {
    let formatedNumber = number;
    let countryCode,phoneNumber;
    if (number[0] === "+"){
      formatedNumber = number.substring(1);
    }
    formatedNumber = "00" + formatedNumber;
    const country = CountryPostalCode.find(countryItem => formatedNumber.includes(countryItem.value));
    if (country){
      countryCode = country.value;
      phoneNumber = formatedNumber.split(country.value)[1];
    }
    return[countryCode,phoneNumber];
  }

export const getIpLocation = async () => {
  try {
    let response = await axios.get(
      "https://flight.itours.no/GetIpLocation",
      {
        headers: Header,
      },
    )
    return response
  } catch (error) {
    return error
  }
}