import React from "react";
import { connect } from "react-redux";
import { Checkbox, Collapse,Slider } from "antd";

import { setFilter } from "../../Actions/FlightAction";

const { Panel } = Collapse;

const FilterForm = props => {

  const { Dictionary,filtershow } = props;

  let durationMin,durationMax,stopTimeMin,stopTimeMax;
  if(filtershow?.duration){
    durationMin = Math.floor(filtershow.duration[0]/60);
    durationMax = Math.ceil(filtershow.duration[1]/60);
  }
  if (filtershow?.stopTime){
    stopTimeMin = Math.floor(filtershow.stopTime[0]/60);
    stopTimeMax = Math.ceil(filtershow.stopTime[1]/60);
  }

  return (
    <>
      <strong className="filter-header">{Dictionary.filters}</strong>
      <div className="padding-h-10">
        <Collapse
          expandIconPosition={"right"}
          bordered={false}
          defaultActiveKey={["1", "2", "3", "4", "5","6","7","8","9"]}
        >
          <Panel header={<span className="okr-bg">{Dictionary.stops}</span>} key={"1"}>
            <Checkbox.Group onChange={value => { props.setFilter({value,filterType:"stop"})}}>
              <div>
                <Checkbox value={0} className="antd-custom-checkbox" >
                  {Dictionary.direct}
                </Checkbox>
              </div>
              <div>
                <Checkbox value={1} className="antd-custom-checkbox" >
                  1 {Dictionary.stop}
                </Checkbox>
              </div>
              <div>
                <Checkbox value={2} className="antd-custom-checkbox" >
                  1+ {Dictionary.stop}
                </Checkbox>
              </div>
            </Checkbox.Group>
          </Panel>

          <Panel header={<span className="okr-bg">{Dictionary.classCabin}</span>} key={"2"}>
            {!!(filtershow.cabinClass?.length) && <Checkbox.Group onChange={value => { props.setFilter({ value, filterType: "cabinClass" }) }}>
              {filtershow.cabinClass.map(item => {
                return (
                  <div key={item}>
                    <Checkbox value={item} className="antd-custom-checkbox" >
                      {item}
                    </Checkbox>
                  </div>
                );
              })}
            </Checkbox.Group>}
          </Panel>

          <Panel header={<span className="okr-bg">{Dictionary.airline}</span>} key={"3"}>
            {!!(filtershow.airline?.length) && <Checkbox.Group onChange={value => { props.setFilter({ value, filterType: "airline" }) }}>
              {filtershow.airline.map(item => (
                <div key={item}>
                  <Checkbox value={item} className="antd-custom-checkbox" >
                    {item}
                  </Checkbox>
                </div>
              )
              )}
            </Checkbox.Group>}
          </Panel>

          {props.domesticFlight && filtershow.flightType.length > 1 && <Panel header={<span className="okr-bg">{Dictionary.flightType}</span>} key={"6"}>              
            <Checkbox.Group onChange={value => { props.setFilter({ value, filterType: "flightType" }) }}>
              {filtershow.flightType.map(item => (
                <div key={item}>
                  <Checkbox value={item} className="antd-custom-checkbox" >
                    {item === "Systemic" ? Dictionary.Systemic : item === "Charter" ? Dictionary.Charter : item}
                  </Checkbox>
                </div>
              )
              )}
            </Checkbox.Group>
          </Panel>}

          {!!(filtershow.baggageDetail?.length) &&<Panel header={<span className="okr-bg">{Dictionary.baggage} ({Dictionary.adult})</span>} key={"9"}>
            <Checkbox.Group onChange={value => { props.setFilter({ value, filterType: "baggageDetail" }) }}>
              {filtershow.baggageDetail.sort((a,b)=>{
                if (!a) return -1;
                if (!b) return 1;
                const [aAmount,aUnit] = a.split("_");
                const [bAmount,bUnit] = b.split("_");
                if (aUnit < bUnit) {
                  return -1;
                }
                else if (aUnit > bUnit) {
                  return 1;
                }else return +aAmount - +bAmount
              }).map(item => {
                return (
                  <div key={item}>
                    <Checkbox value={item} className={`antd-custom-checkbox ${item?"en-font":""}`} >
                      {item ? item.replace("_","") : Dictionary.noBaggage}
                    </Checkbox>
                  </div>
                );
              })}
            </Checkbox.Group>
          </Panel>}

          {/* {(process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'||process.env.REACT_APP_THEME ==='AYANAIRCOM') && <Panel header={Dictionary.Baggage} key={"4"}>
              <div className="antd-custom-checkbox">
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  value="baggage"
                  onChange={(e) => {
                    this.handleSelect(e, FilterName.baggage, FilterType.Union);
                  }}
                />
                <label className="checkbox-label">{Dictionary.baggage}</label>
              </div>
              <div className="antd-custom-checkbox">
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  value="extraBaggage"
                  onChange={(e) => {
                    this.handleSelect(e, FilterName.baggage, FilterType.Union);
                  }}
                />
                <label className="checkbox-label">{"Extra Baggage"}</label>
              </div>
            </Panel>
          } */}

          <Panel header={<span className="okr-bg">{Dictionary.departureTime}</span>} key={"5"}>
            <div className="time-filters" >
              <Checkbox.Group onChange={value => { props.setFilter({ value, filterType: "departureTime" }) }}>

                <Checkbox value={`0-${5*60+59}`}>
                  <div>
                    <span className="itours-icon icon-small fill-sun" />
                  </div>
                  <div>00:00</div>
                  <div>-</div>
                  <div>06:00</div>
                </Checkbox>

                <Checkbox value={`${6*60}-${11*60+59}`} >
                  <div>
                    <span className="itours-icon icon-small empty-sun" />
                  </div>
                  <div>06:00</div>
                  <div>-</div>
                  <div>12:00</div>
                </Checkbox>

                <Checkbox value={`${12*60}-${17*60+59}`} >
                  <div>
                    <span className="itours-icon icon-small fill-moon" />
                  </div>
                  <div>12:00</div>
                  <div>-</div>
                  <div>18:00</div>
                </Checkbox>

                <Checkbox value={`${18*60}-${23*60+59}`} >
                  <div>
                    <span className="itours-icon icon-small empty-moon" />
                  </div>
                  <div>18:00</div>
                  <div>-</div>
                  <div>00:00</div>
                </Checkbox>

              </Checkbox.Group>

            </div>
          </Panel>

          <Panel header={<span className="okr-bg">{Dictionary.arrivalTime}</span>} key={"6"}>
            <div className="time-filters" >
              <Checkbox.Group onChange={value => { props.setFilter({ value, filterType: "arrivalTime" }) }}>

                <Checkbox value={`0-${5*60+59}`}>
                  <div>
                    <span className="itours-icon icon-small fill-sun" />
                  </div>
                  <div>00:00</div>
                  <div>-</div>
                  <div>06:00</div>
                </Checkbox>

                <Checkbox value={`${6*60}-${11*60+59}`} >
                  <div>
                    <span className="itours-icon icon-small empty-sun" />
                  </div>
                  <div>06:00</div>
                  <div>-</div>
                  <div>12:00</div>
                </Checkbox>

                <Checkbox value={`${12*60}-${17*60+59}`} >
                  <div>
                    <span className="itours-icon icon-small fill-moon" />
                  </div>
                  <div>12:00</div>
                  <div>-</div>
                  <div>18:00</div>
                </Checkbox>

                <Checkbox value={`${18*60}-${23*60+59}`} >
                  <div>
                    <span className="itours-icon icon-small empty-moon" />
                  </div>
                  <div>18:00</div>
                  <div>-</div>
                  <div>00:00</div>
                </Checkbox>

              </Checkbox.Group>
            </div>
          </Panel>

          <Panel  header={<span className="okr-bg">{Dictionary.journeyDuration} ({Dictionary.hour})</span>} key={"7"}>
            <Slider 
              dir="ltr"
              onChange={value => { props.setFilter({ value, filterType: "duration" }) }}
              className="margin-start margin-end"
              range 
              min={durationMin} 
              max={durationMax} 
              defaultValue={[durationMin,durationMax]} 
              marks={{
                [durationMin] : durationMin+ "'",
                [durationMax] :durationMax+ "'"
              }} 
            />
          </Panel>

          <Panel  header={<span className="okr-bg">{process.env.REACT_APP_THEME ==='AYANAIRCOM' ? Dictionary.connectionTime : Dictionary.waitingTime} ({Dictionary.hour})</span>} key={"8"}>
            <Slider 
              dir="ltr"
              onChange={value => { props.setFilter({ value, filterType: "stopTime" }) }}
              className="margin-start margin-end"
              range 
              min={stopTimeMin} 
              max={stopTimeMax} 
              defaultValue={[stopTimeMin,stopTimeMax]} 
              marks={{
                [stopTimeMin] : stopTimeMin+ "'",
                [stopTimeMax] :stopTimeMax+ "'"
              }} 
            />
          </Panel>

        </Collapse>
      </div>
    </>
  );
}
const dispatchToProps = (dispatch) => ({
  setFilter: data => dispatch (setFilter(data))
});
const stateToProps = (state) => ({
  filtershow: state.FlightSearch.filterShow,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});

export default connect(stateToProps, dispatchToProps)(FilterForm);
