import {React, useEffect, useState } from "react";
import axios from "axios";
import queryString from 'query-string';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment-jalaali";
import { Tooltip } from "antd";

import { ServerAddress, Header, DomesticHotelApiUrls } from "../../Enum/Urls";
import { setSafarmarketHotelPixel } from "../../Actions/HotelAction";
import Price from "../Common/Price";
import { showError } from "../../Actions/VisualDataAction";
import Loading from "../Common/Loading";
import LayoutConfig from "../../Config/layoutConfig";
import ImageGallery from "../Common/ImageGallery";
import Rating from "../Common/Rating";
import DomesticHotelDetailAbout from "./DomesticHotelDetailAbout";
import DomesticHotelSearchForm from "./DomesticHotelSearchForm";
import DomesticHotelDetailComments from "./DomesticHotelDetailComments";
import Map from "../Common/Map";
import DomesticHotelDetailRules from "./DomesticHotelDetailRules";
import { changeSafarmarketLayout } from "../../Actions/VisualDataAction";
import Wrapper from '../Common/Wrapper';
import DomesticHotelSmRoom from "./DomesticHotelSmRoom";
import QuantityInput from "../Common/QuantityInput";
import SafarmarketWelcome from "../Common/SafarmarketWelcome";
import StickySides from "../Common/StickySides";

const DomesticHotelDetailSafarmarket = props => {

    const parameters = queryString.parse(props.location.search);
    const { Dictionary } = props;

    const [hotelInformation, setHotelInformation] = useState();
    const [roomsInformation, setRoomsInformation] = useState();
    const [pageInformation, setPageInformation] = useState();
    const [scoreInformation, setScoreInformation] = useState();
    const [accommodationInformation, setAccommodationInformation] = useState();
    const [loading, setLoading] = useState(true);
    const [reserveLoading, setReserveLoading] = useState(false);

    const [selectedRoom,setSelectedRoom] = useState();
    const [count,setCount] = useState(1);
    
    const [selectedRoomDetailsVisible,setSelectedRoomDetailsVisible] = useState(false);

    const checkin = parameters.checkin || moment().format("YYYY-MM-DD");
    const checkout = parameters.checkout || moment().add(1, 'days').format("YYYY-MM-DD");
    const nights = moment(checkout).diff(checkin,'days');

    const selectRoomHandle = (room,rate) => {
        setSelectedRoom({room:room,rate:rate});
        setCount(1);        
    }

    useEffect(()=>{
        if (roomsInformation && hotelInformation){
            setLoading(false);
        }
    },[roomsInformation,hotelInformation]);

    useEffect(() => {

        const fetchRoomsData = async () => {
            setRoomsInformation();
            const hotelId = parameters.id;
            const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.GetRooms}?CheckIn=${checkin}&CheckOut=${checkout}&Id=${hotelId}`,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            );
            if (response?.data) {
                setRoomsInformation(response.data.result);
            }
        }
        fetchRoomsData();

        const fetchHotelInformation = async () => {
            setHotelInformation();
            const hotelId = parameters.id;
            const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetHotelById}?hotelId=${hotelId}`,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            );
            if (response?.data) {
                setHotelInformation(response.data);
            }
        }

        fetchHotelInformation();

        const fetchAccommodationInformation = async () => {
            const hotelId = parameters.id;
            setAccommodationInformation();
            const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelC}${DomesticHotelApiUrls.GetAccommodation}?Id=${hotelId}`,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            );
            if (response?.data) {
                setAccommodationInformation(response.data.result);
            }
        }
        fetchAccommodationInformation();
    }, [props.location.search]);

    let pageUrl;
    if (hotelInformation) {
        pageUrl = hotelInformation.Url;
    }

    useEffect(() => {
        if (pageUrl) {
            const fetchPageByUrl = async () => {
                setPageInformation();
                const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.getPageByUrl}?isNewVisitor=true&url=${pageUrl}`,
                    {
                        headers: {
                            ...Header,
                            apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                            'Accept-Language': 'fa-IR'
                        }
                    }
                );
                if (response?.data) {
                    setPageInformation(response.data);
                }
            }
            fetchPageByUrl();
        }
    }, [pageUrl]);

    const pageId = pageInformation?.Id || undefined;

    useEffect(() => {
        if (pageId) {

            const fetchScore = async () => {

                const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetScore}?pageId=${pageId}`,
                    {
                        headers: {
                            ...Header,
                            apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                            'Accept-Language': 'fa-IR'
                        }
                    }
                );
                if (response?.data) {
                    setScoreInformation(response.data);
                }
            }
            fetchScore();
        }
    }, [pageId]);

    useEffect(() => {

        let parameters = queryString.parse(props.location.search); 
        const smId = parameters.safarmarketId;
        const expDate = new Date();
        expDate.setTime(expDate.getTime() + (7*24*60*60*1000));
        document.cookie = `safarMarketHotelId=${smId}; expires=${expDate.toUTCString()};path=/`;
        props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/hotel/v1/pixel/itours/2/0/?smId=${smId}`));
        


        props.setSafarmarketLayout(true);
    
        return (() => {
          props.setSafarmarketLayout(false);
          props.setSafarmarketPixelValue(undefined);
        });
      }, [])

    let modifiedImages;
    if (hotelInformation?.Gallery?.length) {
        modifiedImages = hotelInformation.Gallery.filter(item => item.ImageThumb || item.Image).map(item => ({
            large: item.Image || item.ImageThumb,
            thumbnail: item.Image || item.ImageThumb
        }))
    }

    let selectedRoomToken;
    if (selectedRoom){
        selectedRoomToken = selectedRoom.rate.bookingToken;
    }

    const validateRoom = async () => {
        setReserveLoading(true);
        const params = {
            bookingToken: selectedRoomToken,
            checkin: checkin,
            checkout: checkout,
            count: count
        }

        const response = await axios.post(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.Validate}`,
            params,
            {
                headers: {
                    ...Header,
                    apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                    'Accept-Language': 'fa-IR'
                }
            }
        );

        if (response?.data?.result) {
            props.history.push(
                `/domesticHotel/getPassengers?preReserveKey=${response.data.result.preReserveKey}&smid=${parameters.safarmarketId}&isFromSafarmarket=true`
            );
        }

        setReserveLoading(false);
    }

    let roomLayout;

    if (roomsInformation?.availabilities?.length > 0) {
        roomLayout = roomsInformation.availabilities.map((availabilityItem, availabilityIndex) => <Wrapper key={availabilityIndex}>
                {availabilityItem.rates.map((rateItem, rateIndex) => <Wrapper key={availabilityIndex + "_" + rateIndex}>

                    {(
                        (availabilityIndex === 0 && rateIndex === 1)
                        ||
                        (availabilityItem.rates.length === 1 && availabilityIndex === 1 && rateIndex === 0)
                    ) && <h5 className="normal-title no-select-highlight">سایر اتاق های این هتل</h5>}

                    <DomesticHotelSmRoom
                        rate={rateItem}
                        room = {availabilityItem.rooms[0]}
                        duration={nights}
                        selectedRoomToken={selectedRoomToken}
                        selectRoomHandle={selectRoomHandle}
                        setCount={setCount}
                        count={count}
                        reserveLoading={reserveLoading}
                    />

                </Wrapper>)}
            </Wrapper>
        );
    } else {
        roomLayout = (
            <div className="box-border card-padding bg-white text-center">
                <div className="margin-bottom">
                    <span className="itours-icon error-icon icon-xlarge" />
                </div>
                <div className="text-center font-18">
                    <div className="margin-bottom">
                        <strong>{Dictionary.SorryNoRoomFoundRightNow}!</strong>
                    </div>
                    <p>{Dictionary.pleaseTryAnotherHotel}</p>
                </div>
            </div>
        );
    }

    let prices;
    if (selectedRoom) {
        prices = {
            roomPrice: selectedRoom.rate.pricing.find((item) => item.type === 'Room' && item.ageCategoryType === 'ADL')?.amount,
            boardPrice: selectedRoom.rate.pricing.find((item) => item.type === 'RoomBoard' && item.ageCategoryType === 'ADL')?.amount,
            extraBedPrice: selectedRoom.rate.pricing.find((item) => item.type === 'ExtraBed' && item.ageCategoryType === 'ADL')?.amount,
            childPrice: selectedRoom.rate.pricing.find((item) => item.type === 'Room' && item.ageCategoryType === 'CHD')?.amount,
          };
    }

    let capacity = null;
    if (selectedRoom?.room?.capacity?.count) {
        capacity = <>
            <div className="flex-ends middle margin-bottom-half">
                <div>
                    <span className="margin-end-light">ظرفیت اتاق: </span>
                </div>
                <span>{selectedRoom.room.capacity.count} نفر</span>
            </div>
            {!!selectedRoom.room.capacity.extraBed && selectedRoom.room.capacity.extraBed > 0 && <div>

                <div className="flex-ends middle margin-bottom-half">
                    <div>
                        <Tooltip title={!!prices.extraBedPrice && (
                            <div>
                                <span className="margin-end-light">{Dictionary.ExtraBedPrice}:</span>
                                <Price currency={props.currency} >
                                    {prices.extraBedPrice}
                                </Price>
                            </div>
                        )}>
                            <span className="margin-end-light">تخت اضافه <span className="itours-icon info-icon icon-small" /> :</span>
                        </Tooltip>

                    </div>
                    <span>{selectedRoom.room.capacity.extraBed}  عدد</span>
                </div>
            </div>}
        </>
    }

    const isStyle3 = LayoutConfig.themeClassName === 'style-3';
    
    return (
        <div className="page-container no-padding-mobile">
            {loading ?
                <Loading fullPage description={Dictionary.getHotelInformation + " ..."} />
            : hotelInformation ? (
                <div className="padding-top hotel-details domestic-hotel-detail-safarmarket">
                    <SafarmarketWelcome />
                    
                        <StickySides
                            main={<>
                                <div className="relative sm-gallery-wrapper">
                                    {modifiedImages?.length >= 1 ? (<div dir="ltr">
                                        <ImageGallery images={modifiedImages} onePhoto />
                                    </div>
                                    ) : hotelInformation.ImageUrl ? (
                                        <img
                                            src={hotelInformation.ImageUrl}
                                            alt={hotelInformation.HotelCategoryName + " " + hotelInformation.HotelName + " " + hotelInformation.CityName}
                                            className="full-width hotel-main-1photo"
                                        />
                                    ) : (
                                        <div className="card hotel-no-picture box-border text-center body-direction">
                                            <div className="no-image-hint ">
                                                <div className="margin-bottom">
                                                    {Dictionary.noImageHeadingText}
                                                </div>
                                                <small>{Dictionary.noImageDetailText}</small>
                                            </div>
                                        </div>
                                    )}
                                    <div className="night-on-gallery hidden-small">
                                        <span className="itours-icon moon-white icon-small margin-end-light" />
                                        {nights} شب
                                    </div>
                                    <div className="text-on-gallery">
                                        <Rating number={hotelInformation.HotelRating} />
                                        <h3 className="bold font-22 margin-top">{hotelInformation.HotelCategoryName + " " + hotelInformation.HotelName + " " + hotelInformation.CityName}</h3>
                                    </div>
                                </div>
                                <div className="mobile-hr-padding">
                                    <div className="room-select-card" id="roomSelectSection"
                                    //ref={this.roomsHolder} 
                                    >
                                        <div id="roomSelect" className="select-room-wrapper padding-top">
                                            {roomLayout}
                                        </div>

                                    </div>
                                </div>
                            </>
                            }
                            aside={<div className="bg-white card-border card-padding rounded-medium">
                                <div className="sm-check-in-out-wrapper">
                                    <div>
                                        <strong className="margin-end-light">
                                            <span className="itours-icon calendar-dark icon-small margin-end-light font-13" />
                                            تاریخ ورود و خروج:
                                        </strong>
                                        <span className="no-wrap">
                                            {moment(checkin).format("jYYYY/jMM/jDD")} - {moment(checkout).format("jYYYY/jMM/jDD")}
                                        </span>
                                    </div>
                                    <div className="pipe hidden-medium hidden-large" />
                                    <strong className="hidden-medium hidden-large">
                                        <span className="itours-icon moon-dark icon-small margin-end-light" />
                                        {nights} شب
                                    </strong>
                                </div>

                                <div className="sm-fix-bottom-mobile">
                                    {selectedRoom ? <>
                                        <div className="text-center hidden-medium hidden-large">
                                            <button type="button" className="button link-style-btn margin-bottom" onClick={() => { setSelectedRoomDetailsVisible(prevState => !prevState) }}>
                                                {selectedRoomDetailsVisible ? "بستن" : "مشاهده"} جزییات
                                                <span className={`css-icon down-caret-css-icon margin-start-light ${selectedRoomDetailsVisible ? "" : "reverse"}`} />
                                            </button>
                                        </div>
                                        <div className={`mobile-accardion ${selectedRoomDetailsVisible ? "show" : ""}`}>
                                            <div className="selected-room-details font-12 sm-font-14">

                                                <div className="flex-ends margin-bottom-half">
                                                    <strong className="font-14 sm-font-17 semi-bold">{selectedRoom.room.name}</strong>
                                                    {selectedRoom && <span className="font-12 no-wrap no-select pointer" onClick={() => {
                                                        setSelectedRoomDetailsVisible(false);
                                                        setSelectedRoom();
                                                    }}>
                                                        حذف این اتاق <span className="itours-icon icon-small trash" />
                                                    </span>}
                                                </div>

                                                {capacity}

                                                {/* {selectedRooms.rate.regularPrice !== selectedRooms.rate.salePrice ? (
                                            <div className="old-price inline-medium font-16 sm-font-20">
                                                <Price currency={this.props.currencyCode}>
                                                    {selectedRooms.rate.regularPrice}
                                                </Price>
                                            </div>
                                        ) : null} */}

                                                {selectedRoom && <div className="flex-ends middle">
                                                    <div>قیمت اتاق: </div>
                                                    {!!prices && <Price currency={props.currency} >
                                                        {prices.roomPrice}
                                                    </Price>}
                                                </div>}

                                                <div className="flex-ends middle margin-top-half">
                                                    <div>تعداد اتاق:</div>
                                                    <QuantityInput min={1} max={selectedRoom.rate.available} onChange={setCount} value={count} className="style-b" />
                                                </div>



                                            </div>
                                        </div>
                                        <div className="selected-room-footer">
                                            <div className="no-select-highlight">
                                                <div className="inline-medium margin-end-half font-12 sm-font-14 xs-margin-bottom-half">
                                                    مبلغ کل:
                                                </div>
                                                {!!prices && <b className="new-price inline-medium font-16 sm-font-20">
                                                    <Price currency={props.currency} >
                                                        {count * prices.roomPrice}
                                                    </Price>
                                                </b>}
                                            </div>
                                            <button className="button red-btn" type='buttom' onClick={validateRoom} >
                                                رزرو اتاق
                                                {reserveLoading && <span className="loading-inline-circle margin-start-light" />}
                                            </button>
                                        </div>
                                    </>
                                        :
                                        <div className="sm-reserve-room-block">
                                            <p className="message">اتاقی انتخاب نشده است</p>
                                            <button type="submit" disabled className="button color-1-btn" >رزرو اتاق</button>
                                        </div>
                                    }
                                </div>
                            </div>}
                        />
                    <div className="mobile-hr-padding">
                        <div className="margin-bottom-large">
                            <h5 className="normal-title">{Dictionary.modifySearch}</h5>
                            <DomesticHotelSearchForm onlyDates />
                        </div>

                        {hotelInformation?.Facilities?.length > 0 && (
                            <div id="hotel_facilities">
                                <div className={`card card-padding bg-white box-border margin-bottom ${isStyle3?"":"blue-border-right"}`}>
                                    <h5 className="normal-title">{Dictionary.hotelFacilities}</h5>
                                    <div className="textContent margin-top-large">
                                        {hotelInformation?.Facilities.map(item => <div key={item.Keyword} className="facility-row margin-bottom-large" >
                                            <img src={item.ImageUrl} className="facilityIcon margin-end-half" alt={item.Title} />
                                            <div>
                                                <b className="font-16 semi-bold">{item.Title}</b>
                                                <p className="no-margin-bottom">{item.Description}</p>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        )}

                        <DomesticHotelDetailAbout hotel={hotelInformation} />

                        <DomesticHotelDetailRules accommodationInformation={accommodationInformation} policies={hotelInformation?.Policies} />

                        <DomesticHotelDetailComments scoreInformation={scoreInformation} pageId={pageId} />

                        {!!hotelInformation && <div className="box-border map-holder margin-bottom">
                            <Map
                                zoom={hotelInformation.Zoom || 14}
                                hotels={[{ latitude: hotelInformation.Latitude, longitude: hotelInformation.Longitude }]}
                            />
                        </div>}
                    </div>

                </div>
            ) : null }
        </div>
    )
}

const mapStateToProps = (state) => ({
    error: state.VisualData.error,
    currency: state.UserData.Currency,
    Dictionary: state.VisualData.Dictionary,
    selectedFilters: state.DomesticHotel.selectedFilters
});
const mapDispatchToProps = (dispatch) => ({
    showError: param => { dispatch(showError(param))},
    setSafarmarketLayout : data => {dispatch (changeSafarmarketLayout(data))},
    setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketHotelPixel(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomesticHotelDetailSafarmarket));