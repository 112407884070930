import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import LayoutConfig from '../../Config/layoutConfig';

class SelectRoom extends React.Component{
    static propTypes={
        defaultAdult:PropTypes.any,
        defaultChildren:PropTypes.any,
        defaultAges:PropTypes.any,
        onSelectItem:PropTypes.any.isRequired
    };
    state={
        room:[{id:0,adult:2,children:[]}]
    };
    //convert paramters to format
    inputToRoomJSON=()=>{
        let roomInit=[];
        let baseCounter=0;
        this.props.defaultAdult.forEach((item,index)=>{
            let roomUnit={adult:item,children:[],id:"room_"+index+1};
            for(let childCounter=baseCounter;childCounter<=(this.props.defaultChildren[index]+baseCounter-1);childCounter++){
                roomUnit.children.push(this.props.defaultAges[childCounter]);
            }
            baseCounter+=this.props.defaultChildren[index];
            roomInit.push(roomUnit);
        });
        this.setState({
            room:roomInit
        })
    };
    addRoom = () => {
        if( this.state.room.length < 9){
            const newRoom = {adult:2,children:[],id:new Date().getTime()};
            const updatedRoom = [...this.state.room,newRoom];
            this.setState({room : updatedRoom},()=>{
                this.outputToRoomJSON()
            });
        } else{
            return false;
        }     
    }
    deleteRoom = (roomId) =>{
        let roomsArray =this.state.room.filter(item=>item.id !== roomId);
        this.setState({
            room:roomsArray
        },()=>{
            this.outputToRoomJSON()

        });
    }
    outputToRoomJSON=()=>{
        let adult=[];
        let children=[];
        let age=[];
        this.state.room.forEach((value,index)=>{
            adult[index]=value.adult;
            children[index]=value.children.length;
            age.push(...value.children)
        });
        this.props.onSelectItem({adult,children,age})

    };
    //handle input
    handleEvent=(type,number,index,indexChild)=>{
        
        number=Number(number);
        let cash=JSON.stringify(this.state.room);
        let roomItem=JSON.parse(cash);
        if(type==="room"){
            let roomLength=roomItem.length;
            if(number>roomLength) {
                for (let cnt = 1; cnt <= (number - roomLength); cnt++) {
                    roomItem.push({adult:2,children:[]});
                }
            }else{
                roomItem=roomItem.slice(0,number);
            }
        }
        else if(type==="children"){
            let childrenLength=roomItem[index].children.length;
            if(number>childrenLength) {
                for (let cnt = 1; cnt <= (number - childrenLength); cnt++) {
                    roomItem[index].children.push(0);
                }
            }else{
                roomItem[index].children=roomItem[index].children.slice(0,number);
            }
        }
        else if(type==="adult"){
            roomItem[index].adult=number;
        }
        else if(type==="age"){
            roomItem[index].children[indexChild]=number;
        }
        this.setState({
            room:roomItem
        },()=>{
            this.outputToRoomJSON()

        });
    };
    componentDidMount() {
        this.inputToRoomJSON();

    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(prevProps.defaultAdult!==this.props.defaultAdult||prevProps.defaultChildren!==this.props.defaultChildren||prevProps.defaultAges!==this.props.defaultAges){
            
    //         this.inputToRoomJSON();
    //     }
    // }
    render() {
        const {Dictionary} = this.props;
        return(<div className="passengers-options-holder margin-bottom">
            <div className="passengers-options-holder-card">
                <div className={`${this.props.validationError ? "hasValidationError":""} ${this.props.smSearchRoom?"sm-search-room":""}`}>
                    {/* each room guests */}
                    <div className={`float-row hidden-xsmall hidden-small ${this.props.smSearchRoom?"margin-top-light":"margin-bottom"}`}>
                        <div className="col-xsmall-2 col-small-2 min-height-1" />
                        <div className={`col-small-2 ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"semi-bold blue":""}`}>
                            {Dictionary.adults + " (12+)"}
                        </div>
                        <div className={`col-small-2 ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"semi-bold blue":""}`}>
                            {Dictionary.children + " ("+Dictionary.Under12+")"}
                        </div>
                        <div className={`col-small-5 col-large-6 ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"semi-bold blue":""}`}>
                            {Dictionary.ageOfChildren}
                        </div>
                    </div>
                    {this.state.room.map((item,index)=><div key={item.id} className={(this.state.room.length > 1)?"room-guests multyroom":"room-guests"}>                          
                            {/* {(this.state.room.length > 1 && (index === 0)) && <div className="room-number">{Dictionary.room} {index+1}</div>} */}
                            <div className={"float-row"}>
                                <div className="col-small-12 col-medium-2">
                                    <span className="itours-icon hotel-dark icon-small margin-end-light hidden-xsmall hidden-small hidden-medium" /> <b className="item-number"> {Dictionary.room + " " + (index+1)} </b>
                                </div>
                                <div className="col-xsmall-6 col-small-3 col-medium-2">
                                    <div className="form-group">
                                        <select id={"adultNumber"+index} className="form-select no-border-select" value={item.adult} onChange={(e)=>this.handleEvent("adult",e.target.value,index)}>
                                            <option value={1}>1 {Dictionary.adult}</option>
                                            <option value={2}>2 {Dictionary.adults}</option>
                                            <option value={3}>3 {Dictionary.adults}</option>
                                            <option value={4}>4 {Dictionary.adults}</option>
                                            <option value={5}>5 {Dictionary.adults}</option>
                                            <option value={6}>6 {Dictionary.adults}</option>
                                            <option value={7}>7 {Dictionary.adults}</option>
                                            <option value={8}>8 {Dictionary.adults}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xsmall-6 col-small-3 col-medium-2">
                                    <div className="form-group">
                                        <select id={"childNumber" + index} className="form-select no-border-select" value={item.children.length} onChange={(e)=>this.handleEvent("children",e.target.value,index)}>
                                            <option value={0}>0 {Dictionary.child}</option>
                                            <option value={1}>1 {Dictionary.children}</option>
                                            <option value={2}>2 {Dictionary.children}</option>
                                            <option value={3}>3 {Dictionary.children}</option>
                                            <option value={4}>4 {Dictionary.children}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xsmall-12 col-small-12 children-ages-block col-medium-6">
                                    <div className="children-ages">
                                        <div className="form-group">
                                            {(item.children.length)?<label className="margin-end inline-block hidden-medium hidden-large  children-ages-label">{Dictionary.ageOfChildren}: </label>:null}
                                            
                                            {
                                                item.children.map((age,ageIndex)=>{
                                                    return (
                                                        <select key={index.toString()+ageIndex.toString()} id={"childAge"+index.toString()+ageIndex.toString()} className={`form-select no-border-select ${this.props.smSearchRoom?"margin-end-light":"margin-end"}`} value={age} onChange={(e)=>this.handleEvent("age",e.target.value,index,ageIndex)}>
                                                            <option value={0}>0 </option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                            <option value={6}>6</option>
                                                            <option value={7}>7</option>
                                                            <option value={8}>8</option>
                                                            <option value={9}>9</option>
                                                            <option value={10}>10</option>
                                                            <option value={11}>11</option>
                                                            <option value={12}>12</option>
                                                            <option value={13}>13</option>
                                                            <option value={14}>14</option>
                                                            <option value={15}>15</option>
                                                            <option value={16}>16</option>
                                                            <option value={17}>17</option>
                                                            <option value={18}>18</option>
                                                        </select>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    {(this.state.room.length > 1 && index > 0) &&
                                        <button type="button" className="button red-btn remove-room-btn" onClick={() => this.deleteRoom(item.id)}><span className={`itours-icon close-icon ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"icon-small":""}`} /></button>
                                    }
                                </div>
                            </div>
                    </div>)}

                    {this.props.validationError && <div className="purple margin-top">
                        {this.props.validationError}
                    </div>}
                    {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') || <button type="button" className="link-style-btn margin-top" onClick={this.addRoom}><span className="plus">+</span> {Dictionary.addAnotherRoom}</button>}
                </div>
            </div>
            {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') && <button type="button" className={`${LayoutConfig.themeClassName === 'naminTravel-theme'?"yellow":"white"} link-style-btn margin-top semi-bold`} onClick={this.addRoom}><span className={`itours-icon ${LayoutConfig.themeClassName === 'ayanair-theme'?"plus-simple-icon":"plus-blue-icon"} margin-end-light`} /> {Dictionary.addAnotherRoom}</button>}
        </div>);
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(SelectRoom);