import { useEffect, useRef } from "react";

const StickySides = props => {

    const wrapperRef = useRef();
    const asideRef = useRef();
    const mainRef = useRef();

    const setStickyPosition = (elem, position, width, top, left) => {
        if (elem){
            elem.style.position = position;
            elem.style.width = width;
            elem.style.top = top;
            elem.style.left = left;
        }
    }

    const makeSticky = () => {

        const asideHeight = asideRef.current?.offsetHeight;
        const mainHeight = mainRef.current?.offsetHeight;

        let stickySideRef, heightDiff;
        if (asideHeight < mainHeight) {
            stickySideRef = asideRef;
            heightDiff = mainHeight - asideHeight;
        } else {
            stickySideRef = mainRef;
            heightDiff = asideHeight - mainHeight;
        }

        const wrapperTop = wrapperRef.current?.getBoundingClientRect().top;

        if ( !wrapperTop || wrapperTop > 0 || heightDiff === 0 || window.innerWidth < 1100) {

            setStickyPosition(stickySideRef.current, "static", "", "", "");

        } else {

            const stickySideParent = stickySideRef.current.parentElement;
            const stickySideParentWidth = stickySideParent.offsetWidth;
            const stickySideParentLeft = stickySideParent.getBoundingClientRect().left;

            if (-wrapperTop < heightDiff) {
                setStickyPosition(stickySideRef.current, "fixed", (stickySideParentWidth + "px"), 0, (stickySideParentLeft + "px"));
            } else {
                setStickyPosition(stickySideRef.current, "fixed", (stickySideParentWidth + "px"), (heightDiff + wrapperTop + "px"), (stickySideParentLeft + "px"));
            }
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', makeSticky);
        window.addEventListener("resize", makeSticky);

        return (() => {
            document.removeEventListener('scroll', makeSticky);
            window.removeEventListener("resize", makeSticky);
        });
    }, [])

    return (
        <div className={`sticky-row float-row ${props.reverseOrderInMobile?"reverse-order-in-mobile":""}`} ref={wrapperRef}>
            <div className="col-small-12 col-large-8 min-height-1 margin-bottom" >
                <div className="relative">
                    <div ref={mainRef} className="padding-top-half">
                        {props.main}
                    </div>
                </div>
            </div>
            <div className="col-small-12 col-large-4 min-height-1 margin-bottom">
                <div className="relative">
                    <div ref={asideRef} className="padding-top-half">
                        {props.aside}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StickySides;