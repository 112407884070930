import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import instagram from '../../image/ayanair/icons/instagram.svg';
import facebook from '../../image/ayanair/icons/facebook.svg';
import AyanAirNewsletter from "../HomeAyanAir/AyanAirNewsletter";

const AyanAirFooter = props => {

  const { Dictionary } = props;
    
  return (
    <footer className="footer">
      
      <AyanAirNewsletter />

      <div className="main-footer">
        <div className="page-container">
          <div className="float-row">
            <div className="col-xsmall-12 col-small-12 col-medium-4">
              <div className="quick-access-block">
                <h5 className="footer-title">
                  {Dictionary.services}
                </h5>
                <Link to={"/"} className="footerblock-link">
                  {Dictionary.flight}
                </Link>
                <Link to={"/hotel"} className="footerblock-link">
                  {Dictionary.hotel}
                </Link>

                <h5 className="footer-title">
                  {Dictionary.followUs}
                </h5>
                <div className="social-links">
                  <a href="https://www.facebook.com/profile.php?id=100054357679388&mibextid=LQQJ4d" title="facebook" target="_blank">
                    <img src={facebook} alt='facebook' className="icon block facebook-icon" width={40} height={40} />
                  </a>
                  {/* <a href="#" title="twitter" target="_blank">
                    <img src={twitter} alt='twitter' className="icon" />
                  </a> */}
                  <a href="https://instagram.com/ayan.air?igshid=MTgwd3NxaW1hejg1aA%3D%3D&utm_source=qr" title="instagram" target="_blank">
                    <img src={instagram} alt='instagram' className="icon block" width={40} height={40} />
                  </a>
                </div>

              </div>
            </div>
            <div className="col-xsmall-12 col-small-12 col-medium-4">
              <div className="quick-access-block">
                <h5 className="footer-title">
                {Dictionary.content}
                </h5>
                <Link to={"/about"} className="footerblock-link">
                  {Dictionary.aboutUs}
                </Link>
                <h5 className="footer-title">
                  {Dictionary.policies}
                </h5>
                <Link to={"/bookingPolicy"} className="footerblock-link">
                  {Dictionary.bookingPolicy}
                </Link>
                <Link to={"/privacyPolicy"} className="footerblock-link">
                  {Dictionary.privacyPolicy}
                </Link>
                <Link to={"/personalDataPolicy"} className="footerblock-link">
                  {Dictionary.personalDataPolicy}
                </Link>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-12 col-medium-4">
              <div className="quick-access-block">
                <h5 className="footer-title">
                {Dictionary.contactUs}
                </h5>
                <div>
                  <span dir="ltr">+964 750 4018333</span>
                </div>
                <h5 className="footer-subtitle">
                  {Dictionary.anyQuestion}
                </h5>
                <div className="margin-bottom">
                  support@ayanair.net
                </div>
                <div>
                  Iraq - Kurdistan - Erbil - 40m - English Village Road.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer padding-v-20">
        <div className="page-container">
          <div className="clearfix">
            <p className="pull-start no-margin" dir="ltr">
              © Copyright 2023. Ayan Air. All Rights Reserved
            </p>
            <p className="pull-end no-margin">
              Powered by International Tours AS
            </p>
          </div>
        </div>
      </div>
    </footer>
  );

}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(AyanAirFooter);
