import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { Tooltip } from "antd";

import resaneh from "../../image/footer/resaneh.png";
import passengerRights from "../../image/footer/passenger-rights.png";
import padidehLogo from '../../image/padideh-small.png';
import enamad from "../../image/footer/enamad.png";
import iranPlaneOrganization from "../../image/footer/iran-plane-organization.png";
import samanBank from '../../image/logos/bank-saman.svg';
import iranAirplane from "../../image/footer/iran-airplane.png";
import LayoutConfig from "../../Config/layoutConfig";
import amadeus from "../../image/logos/amadeus.png";
import iata from "../../image/logos/iata.png";
import sabre from "../../image/logos/sabre.png";
import Style3SupportSection from "../Style3Home/Style3SupportSection";

const Footer = props => {


    const { Dictionary, LAN } = props;
    let date = new Date();
    let checkIn = moment(date).add(2, "d").format("YYYY-MM-DD");
    let checkout = moment(date).add(5, "d").format("YYYY-MM-DD");
    const namadSection = (
      <div className={`namads-holder ${(LayoutConfig.themeClassName === "style-2" || LayoutConfig.themeClassName === "style-3")?"":"clearfix"}`}>
        
        <a
          href='http://store.iata.org/ieccacfree'
          className="footer-namad-item"
          target="_blank"
        >
          <Tooltip title={<span className="en-font" >iata code: 33213106</span>} >
            <img src={iata} alt="یاتا" />
          </Tooltip>
        </a>

        {(LayoutConfig.themeClassName === "style-2" || LayoutConfig.themeClassName === "style-3") && <div className="footer-namad-item">
          <img src={samanBank} alt="بانک سامان" />
        </div>}
        
      {process.env.REACT_APP_SERVER_LOCALSERVER==="sunhotel.ir"?
          <a className="footer-namad-item" referrerPolicy="origin" target="_blank" href="https://logo.samandehi.ir/Verify.aspx?id=160388&p=rfthgvkaobpdxlaomcsimcsi">
            <img referrerPolicy="origin" src={resaneh} className="logo-samandehi" alt="" />
          </a>
          :process.env.REACT_APP_SERVER_LOCALSERVER==="itours.ir"?
          <a 
            className="footer-namad-item"
            referrerpolicy="origin" 
            target="_blank" 
            href="https://logo.samandehi.ir/Verify.aspx?id=289746&p=uiwkmcsipfvljyoeaodsgvka"
            >
            <img 
              referrerpolicy="origin" 
              src='https://logo.samandehi.ir/logo.aspx?id=289746&p=odrfaqgwbsiyyndtshwlwlbq' 
              alt="logo-samandehi" 
              id="nbqeoeuksizpjzpewlaofukz"
              />
          </a> 
          :<div className="footer-namad-item">
              <img 
              referrerpolicy="origin" 
              src='https://logo.samandehi.ir/logo.aspx?id=289746&p=odrfaqgwbsiyyndtshwlwlbq' 
              alt="logo-samandehi" 
              id="nbqeoeuksizpjzpewlaofukz"
              />
          </div>
      }
      <a
        className="footer-namad-item"
        href="https://www.caa.gov.ir/paxrights"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={passengerRights}
          className="namad-item"
          alt="حقوق مسافر"
        />
      </a>
      {process.env.REACT_APP_SERVER_LOCALSERVER==="sunhotel.ir"?
      <a className="footer-namad-item" referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=29250&amp;Code=uGKdtCgAdAMGvWshw6E0">
        <img referrerPolicy="origin" src={enamad} className="namad-logo" alt="" id="uGKdtCgAdAMGvWshw6E0" />
      </a>                           
      : process.env.REACT_APP_SERVER_LOCALSERVER==="itours.ir"?
        <a className="footer-namad-item" referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=34040&amp;Code=PsXBCQQuoLeiSeNcJ7sv">
          <img referrerpolicy="origin" src={enamad} alt="" id="PsXBCQQuoLeiSeNcJ7sv" />
        </a>
        :<div className="footer-namad-item">
          <img referrerpolicy="origin" src={enamad} alt="" />
        </div>
      }
      <a
        className="footer-namad-item"
        href="https://www.caa.gov.ir"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={iranPlaneOrganization}
          className="namad-item"
          alt="سازمان هواپیمایی کشور "
        />
      </a>

      <a
        className="footer-namad-item"
        href="http://www.aira.ir/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={iranAirplane}
          className="namad-item"
          alt="انجمن شرکت های هواپیمایی ایران "
        />
      </a>
      <span className="footer-namad-item">
        <img src={padidehLogo} alt="padideh81" className="namad-item" />
      </span>
      
    </div>
    );
    return (
      <footer className="footer">

        {LayoutConfig.themeClassName === "style-3" && <Style3SupportSection />}
        
        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme' ) || (
          <div className="main-footer">
            <div className="page-container">
              {(LayoutConfig.themeClassName === "style-2" || LayoutConfig.themeClassName === "style-3")?
              //style-2 footer start
              <div className="style2footer-row">
                <div className="style2footer-col1">
                  <h5 className="footer-title black">
                    {Dictionary.quickAccess}
                  </h5>
                  {LayoutConfig.hasDomesticFlight?<>
                  <div>
                    <Link to={"/"} className="footer-link">
                      {Dictionary.domesticFlight}
                    </Link>
                  </div>
                  <div>
                    <Link to={"/foreignflight"} className="footer-link">
                      {Dictionary.foreignFlight}
                    </Link>
                  </div>
                  </>
                  :
                  <div>
                    <Link to={"/"} className="footer-link">
                      {Dictionary.foreignFlight}
                    </Link>
                  </div>
                  }

                  {LayoutConfig.hasDomesticHotel?<>
                    <div>
                    <Link to={"/domesticHotel"} className="footer-link">
                      {Dictionary.domesticHotel}
                    </Link>
                  </div>
                  <div>
                    <Link to={"/hotel"} className="footer-link">
                      {Dictionary.foreignHotel}
                    </Link>
                  </div>
                  </>
                  :
                  <div>
                    <Link to={"/hotel"} className="footer-link">
                      {Dictionary.hotel}
                    </Link>
                  </div>
                  }

                  {process.env.REACT_APP_THEME ==='AYANAIRCOM' || <div>
                    <Link to={"/package"} className="footer-link">
                      {Dictionary.package}
                    </Link>
                  </div>}
                </div>
                <div className="style2footer-col2">
                  <h5 className="footer-title black">
                    درباره ی آیتورز
                  </h5>
                  <div>
                    <Link to={"/about"} className="footer-link">
                      {Dictionary.aboutUs}
                    </Link>
                  </div>
                  <div>
                    <Link to={"/contact"} className="footer-link">
                      {Dictionary.contactUs}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={"/terms"}
                      className="footer-link"
                      disabled={
                        LayoutConfig.themeClassName === "padide-com-theme"
                      }
                    >
                      {Dictionary.termsAndConditions}
                    </Link>                    
                  </div>
                </div>

                <div className="style2footer-col3">
                  {namadSection}
                </div>
              </div>
              //style-2 footer end
              :
              <div className="float-row">
                <div className="col-small-12 col-large-6 hidden-small hidden-xsmall">
                  <h5 className="footer-title">
                    {Dictionary.mostVisitedHotel}
                  </h5>
                  <div className="float-row">
                    <div className="col-xsmall-12 col-small-4">
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=75540&location=Antalya&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Antalya}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=488&location=Yerevan&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.yeravan}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=54323&location=Rome&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Rome}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=40977&location=London&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.London}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=75286&location=Istanbul&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Istanbul}{" "}
                      </Link>
                    </div>
                    <div className="col-xsmall-12 col-small-4">
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=65852&location=Oslo&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Oslo}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=84092&location=Athens&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Athens}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=75608&location=Ankara&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Ankara}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=74961&location=Phuket&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Phuket}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=39933&location=Paris&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Paris}{" "}
                      </Link>
                    </div>
                    <div className="col-xsmall-12 col-small-4">
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=27488&location=Madrid&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Madrid}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=57375&location=Florence&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Florence}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=12827&location=Beijing&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Beijing}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=64153&location=Amsterdam&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Amsterdam}{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-small-12 col-large-6">
                  <div className="float-row">
                    <div className="col-xsmall-12 col-small-6">
                      <div className="margin-start-large quick-access-block">
                        <h5 className="footer-title">
                          {Dictionary.quickAccess}
                        </h5>
                        <Link to={"/"} className="footerblock-link">
                          {Dictionary.flight}
                        </Link>
                        <Link to={"/hotel"} className="footerblock-link">
                          {Dictionary.hotel}
                        </Link>
                        {process.env.REACT_APP_THEME ==='AYANAIRCOM' || <Link to={"/package"} className="footerblock-link">
                          {Dictionary.package}
                        </Link>}
                        <Link
                          to={"/terms"}
                          className="footerblock-link"
                          disabled={
                            LayoutConfig.themeClassName === "padide-com-theme"
                          }
                        >
                          {Dictionary.termsAndConditions}
                        </Link>
                        <Link to={"/about"} className="footerblock-link">
                          {Dictionary.aboutUs}
                        </Link>
                        <Link to={"/contact"} className="footerblock-link">
                          {Dictionary.contactUs}
                        </Link>
                      </div>
                    </div>
                    <div className="col-xsmall-12 col-small-6 text-center">
                      {LAN === "IR" ? (
                        <>
                        {namadSection}
                          <br />
                        </>
                      ) : (
                        <h5 className="footer-title">
                          {Dictionary.followUsOnSocialNetworks}:{" "}
                        </h5>
                      )}

                      <div>
                        {LayoutConfig.contacts &&
                        LayoutConfig.contacts.instagram === "disabled" ? (
                          <span className="social-link disabled margin-end">
                            <span className="itours-icon instagram" />
                          </span>
                        ) : (
                          <a
                            href={
                              LayoutConfig.contacts &&
                              LayoutConfig.contacts.instagram
                                ? LayoutConfig.contacts.instagram
                                : LAN === "IR"
                                ? "https://www.instagram.com/itours.ir/"
                                : "https://www.instagram.com/itours_no/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link margin-end"
                          >
                            <span className="itours-icon instagram" />
                          </a>
                        )}

                        <span className="social-link disabled margin-end">
                          <span className="itours-icon twitter" />
                        </span>
                        <span className="social-link disabled">
                          <span className="itours-icon telegram" />
                        </span>
                      </div>
                      {process.env.REACT_APP_SERVER_LOCALSERVER ==="itours.ir"?<div className="margin-top">
                        {/* پشتیبانی 24 ساعته (از 8 صبح تا 6 صبح روز بعد) : <span dir="ltr" className="no-wrap">+98 21 91008091</span> */}
                        پشتیبانی همه روزه از ساعت 9 الی 23 : <span dir="ltr" className="no-wrap">+98 21 91008091</span>
                        <div>
                          شما همچنین می توانید برای ارتباط سریع تر از طریق آدرس ایمیل 
                          <a href="mailto:online@itours.ir" target="_blank" rel="noreferrer" className="margin-start-half margin-end-half" >Online@itours.ir</a>
                          با ما در ارتباط باشید

                        </div>
                      </div>
                      :                
                        <div className="text-center margin-top">
                          {LayoutConfig.contacts?.telephone?.map(telItem=><div key={telItem} dir="ltr">{telItem}</div>)}
                          {LayoutConfig.contacts?.telephone?.length > 0 || <div dir="ltr">{Dictionary.supportNumber}</div>}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        )}

        <div className="bottom-footer">
          <div className={`${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ? "" : "text-center" } page-container`}>
            {(LayoutConfig.themeClassName === "style-2" || LayoutConfig.themeClassName === "style-3")  && <hr/>}
            {LAN === "NO" && <div className="partners-logos">
              <img src={amadeus} alt="amadeus" />
              <img src={sabre} alt="sabre" />
              <img src={iata} alt="iata" />
            </div>}
            {LAN ==="NO" || LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme' || LayoutConfig.themeClassName === "style-2" || LayoutConfig.themeClassName === "style-3"|| <img
              src={
                LayoutConfig.footerLogo
                  ? LayoutConfig.footerLogo
                  : LayoutConfig.logo
              }
              alt={LayoutConfig.companyName}
              className="footer-logo"
            />}
            <div>
              {LAN === "NO" ? (
                LayoutConfig.copyrightText
                  ? LayoutConfig.copyrightText
                  : Dictionary.copyrightText
              )
                : (LayoutConfig.themeClassName === "style-2" || LayoutConfig.themeClassName === "style-3") ? (
                  <div className="style-2-footer-bottom">
                    <p className="semi-bold">
                      &copy;
                      {LayoutConfig.copyrightText
                        ? LayoutConfig.copyrightText
                        : Dictionary.copyrightText}
                    </p>
                    <div>
                      {LayoutConfig.contacts &&
                        LayoutConfig.contacts.instagram === "disabled" ? (
                        <span className="social-link disabled margin-end">
                          <span className="itours-icon instagram" />
                        </span>
                      ) : (
                        <a
                          href={
                            LayoutConfig.contacts &&
                              LayoutConfig.contacts.instagram
                              ? LayoutConfig.contacts.instagram
                              : LAN === "IR"
                                ? "https://www.instagram.com/itours.ir/"
                                : "https://www.instagram.com/itours_no/"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-link margin-end"
                        >
                          <span className="itours-icon instagram" />
                        </a>
                      )}
  
                      {LayoutConfig.contacts?.telegram ? (
                        <a
                          href={LayoutConfig.contacts?.telegram}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-link"
                        >
                          <span className="itours-icon telegram" />
                        </a>                        
                      ):(
                      <span className="social-link disabled">
                        <span className="itours-icon telegram" />
                      </span>
                      )}

                    </div>
                  </div>
                )
                :
                (<span className="small">
                <small>
                  &copy;
                  {LayoutConfig.copyrightText
                    ? LayoutConfig.copyrightText
                    : Dictionary.copyrightText}
                </small>
              </span>)
              }
            </div>
          </div>
        </div>
      </footer>
    );
  
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(Footer);
