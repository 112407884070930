import PropTypes from 'prop-types';
import React from 'react';

HotelBookButton.propTypes = {
  rate: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function HotelBookButton(props) {
  return (
    <button 
      disabled = {props.selectedRoom && props.selectedRoom !== props.bookingToken}
      type={'button'} 
      className={props.className} 
      onClick={() => props.onClick(props.bookingToken)}
    >
      {props.children}  {!!(props.selectedRoom && props.selectedRoom === props.bookingToken) && <span className="loading-inline-circle margin-start-light" />} 
    </button>
  );
}

export default HotelBookButton;
