import { useHistory } from 'react-router-dom';
import LayoutConfig from '../../Config/layoutConfig';

const Back = props => {

    const history = useHistory();

    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    return (
        <button
            type="button"
            className={`button ${isStyle3?"color-1-btn":"yellow-btn"} medium-btn pull-end margin-end-half`}
            onClick={() => { history.goBack() }}
        >
            {props.text || "back"}
        </button>
    );
}

export default Back;