import React, { useEffect, useState,useRef } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import queryString from "query-string";

import ImageGallery from "../Common/ImageGallery";
import SafarmarketWelcome from "../Common/SafarmarketWelcome";
import {
  preReserve,
  setCancelationRoom,
  setHotelDetails,
  setSafarmarketHotelPixel
} from "../../Actions/HotelAction";
import {
  Header,
  Hotel as HotelURL,
  ServerAddress,
} from "../../Enum/Urls";
import { showError } from "../../Actions/VisualDataAction";
import HotelInformation from "../Accomodation/HotelInformation";
// import Room from "../Accomodation/Room";
import SearchRoom from "../Accomodation/RoomSearch";
import HotelForm from "../Accomodation/SearchForm";
import ShowMap from "../Accomodation/showMap";
import Loading from "../Common/Loading";

import HotelMultipleRoom from "./RoomLayout/HotelMultipleRoom";
import HotelSingleRoom from "./RoomLayout/HotelSingleRoom";
import LayoutConfig from "../../Config/layoutConfig";
import FetchProgress from "../Common/FetchProgress";
import HotelDetailSkeleton from "../Common/HotelDetailSkeleton";

const HotelDetail = props => {
    
  const [loading,setLoading] = useState(true);
  const [showSearch,setShowSearch] = useState(true);
  const [selectedRoomToken,setSelectedRoomToken] = useState("");

  const roomsHolderRef = useRef();
  
  const toggleSearch = () => {
    setShowSearch(prevstate => !prevstate);
  };
  
  const parameters = queryString.parse(props.location.search);
  const urlSafarmarketId = LayoutConfig.safarmarket && props.currencyCode ==="IRR" && parameters.utm_medium && parameters.utm_source && parameters?.safarmarketId;
  
  let cookieSafarmarketId;
  let cookies = decodeURIComponent(document.cookie).split(';');
  for (const item of cookies){
    if (item.includes("safarMarketHotelId=")){
      cookieSafarmarketId =item.split("=")[1];
    }
  }
  const smId = urlSafarmarketId || cookieSafarmarketId;

  useEffect(()=>{

    if (urlSafarmarketId){
      const expDate = new Date();
      expDate.setTime(expDate.getTime() + (7*24*60*60*1000));
      document.cookie = `safarMarketHotelId=${urlSafarmarketId}; expires=${expDate.toUTCString()};path=/`;
    }

    if (LayoutConfig.safarmarket && props.currencyCode ==="IRR" && smId) {
      props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/hotel/v1/pixel/itours/2/0/?smId=${smId}`));
    }

    getHotelDetails(smId);
    
    return ()=>{
      props.setSafarmarketPixelValue(undefined);
    }
  },[props.location.search]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);

  const getHotelDetails = async(safarmarketId) => {
    setLoading(true);
        
    let params = new URLSearchParams();
    params.append("Id", parameters.Id);
    parameters.Adults.split(",").forEach((item) =>
      params.append("Adults", item)
    );
    parameters.Children.split(",").forEach((item) =>
      params.append("Children", item)
    );
    if (parameters.Ages.length !== 0) {
      parameters.Ages.split(",").forEach((item) => params.append("Ages", item));
    }
    params.append("Checkin", parameters.Checkin);
    params.append("Checkout", parameters.Checkout);

    if(safarmarketId){
      params.append("metaId",safarmarketId);
      params.append("source",'safarmarket');
    }

    try{
      const response = await axios({
        method: "get",
        url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAccomadtionDetails}`,
        params: params,
        headers: { ...Header },
      });
      if (response){
        props.setHotelDetails(response.data.result)
      }
    }catch(error){
      try {
        props.showError({
          visibility: true,
          content: error.response.data.error.message,
        })
      } catch (e) {
        props.showError({
          visibility: true,
          content: "Internal Error,Please refresh the page",
        })
      }
    }
    setLoading(false);

  };


  const handleSelectRoom = async (bookingToken) => {

    setSelectedRoomToken(bookingToken);

    let params = {};
    let adult = [];
    parameters.Adults.split(",").forEach((item) => adult.push(parseInt(item)));
    params["adults"] = adult;
    let children = [];
    parameters.Children.split(",").forEach((item) =>
      children.push(parseInt(item))
    );
    params["children"] = children;
    if (parameters.Ages.length !== 0) {
      let ages = [];
      parameters.Ages.split(",").forEach((item) => ages.push(parseInt(item)));
      params["ages"] = ages;
    }
    params["checkin"] = parameters.Checkin;
    params["checkout"] = parameters.Checkout;
    params["bookingToken"] = bookingToken;

    if(smId){
      params["metaId"]=smId;
      params["source"]="safarmarket";
    }

    let response = await preReserve(params);
    if (response) {
      if (response.error?.message === "Unavailable" || !response.preReserveKey){
        
        setSelectedRoomToken("");
        
        props.showError({
          visibility:true,
          content:<div>
              {props.Dictionary.UnfortunatelyThisRoomIsNotAvailablePleaseChooseAnotherOne}.
            </div>,
          closable:true,
          icon:"info",
          buttonLabel:props.Dictionary.ok
        })
      }else{
        let url = `/hotel/getPassengers?preReserveKey=${response.preReserveKey}`;
        if(smId){
          url = `/hotel/getPassengers?preReserveKey=${response.preReserveKey}&smid=${smId}`;
        }
        props.history.push(url);
      }
    }
  };

  const scrollToRooms = () => { 
    setTimeout(()=>{
      if(roomsHolderRef){ roomsHolderRef.current.scrollIntoView({behavior: "smooth"})}
    },50)
  };

    const { Dictionary,hotelDetails } = props;
    
    const Facilities = hotelDetails?.features?.find(item => item.keyword==="facilities")?.items || [];
    const RoomsFacilities = hotelDetails?.features?.find(item => item.keyword==="room_facilities")?.items || [];


    // let loadingCell = [];
    // if (this.state.RoomsInfo) {
    //   for (let cnt = 0; cnt < this.state.RoomsInfo.length; cnt++) {
    //     let cashArr = [];
    //     for (
    //       let icnt = 0;
    //       icnt < this.state.RoomsInfo[cnt].rooms.length;
    //       icnt++
    //     ) {
    //       cashArr.push(false);
    //     }
    //     loadingCell[cnt] = cashArr;
    //   }
    // }



    let roomLayout;
    if ( hotelDetails?.availabilities?.length ) {
      if (hotelDetails.availabilities[0].rooms.length === 1) {
        roomLayout = hotelDetails.availabilities.map(availabilityItem => {
          return (
            <HotelSingleRoom
              selectedRoom={selectedRoomToken}
              key={availabilityItem.rates[0].bookingToken}
              hotelFacilities={Facilities}
              roomFacilities={RoomsFacilities}
              room={availabilityItem.rooms[0]}
              rates={availabilityItem.rates}
              date={hotelDetails.date}
              currency={props.currencyCode}
              onSelectRoom={handleSelectRoom}
              location={props.location}
            />
          );
        });
      } else {
        roomLayout = hotelDetails.availabilities.map(availabilityItem => {
          return (
            <HotelMultipleRoom
              selectedRoom={selectedRoomToken}
              key={availabilityItem.rates[0].bookingToken} 
              rooms={availabilityItem.rooms}
              rates={availabilityItem.rates}
              date={hotelDetails.date}
              currency={props.currencyCode}
              onSelectRoom={handleSelectRoom}
              location={props.location}
            />
          );
        });
      }
    } else {
      roomLayout = (
        <div className="box-border card-padding bg-white text-center">
          <div className="margin-bottom">
            <span className="itours-icon error-icon icon-xlarge" />
          </div>
          <div className="text-center font-18">
            <div className="margin-bottom">
              <strong>{Dictionary.SorryNoRoomFoundRightNow}!</strong>
            </div>
            <p>{Dictionary.pleaseTryAnotherHotel}</p>
          </div>
        </div>
      );
    }

    let modifiedImages;
    if (hotelDetails?.images?.length){
      modifiedImages = hotelDetails.images.filter(item => item.sizes[0]?.path).map(item => ({
        large: item.sizes[1]?.path || item.sizes[0]?.path,
        thumbnail: item.sizes[0]?.path
      }))
    }
    let hotelNameId;
    if (hotelDetails){
      hotelNameId ={
        id: hotelDetails.id,
        name: hotelDetails.name
      };
    }
    
    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    return (
      <>
        {!!isStyle3 && <FetchProgress compeleted={!loading} />}
        
        <div className="page-container hotel-detail-skeleton-wrapper">
          

          {loading ? (
            <>
              {isStyle3 ? <HotelDetailSkeleton /> : <Loading fullPage description={Dictionary.getHotelInformation+" ..."}/>}
            </>
          ) : (
            //loading Section
            !!hotelDetails && 
            <div className="section-vertical-padding hotel-details">
              { !!urlSafarmarketId && <SafarmarketWelcome />}
              <div className="hotel-head-info" dir="ltr">
                <HotelInformation
                  model={hotelDetails}
                  currencyCode={props.currencyCode}
                  onScrollToRooms={scrollToRooms}
                />
              </div>

              <div className={`box-border bg-white margin-bottom detail-section ${isStyle3?"":"blue-border-right"}`}>
                <div className="float-row no-gap ltr">
                  <div
                    className={`col-small-12 ${hotelDetails?.features?.length > 0 && LayoutConfig.themeClassName !== "padide-com-theme" ? "col-large-9" : ""}`}
                    dir="ltr"
                  >
                    {hotelDetails.images.length >= 1 ? (
                      <ImageGallery images = {modifiedImages} />
                    ) : hotelDetails.mainPhoto ? (
                      <img
                        src={hotelDetails.mainPhoto}
                        alt={hotelDetails.name}
                        className="full-width hotel-main-1photo"
                      />
                    ) : (
                      <div className="card hotel-no-picture box-border text-center body-direction">
                        <div className="no-image-hint ">
                          <div className="margin-bottom">
                            {Dictionary.noImageHeadingText}
                          </div>
                          <small>{Dictionary.noImageDetailText}</small>
                        </div>
                      </div>
                    )}
                  </div>
                  {hotelDetails.features.length > 0 ? (
                    <div
                      className={
                        LayoutConfig.themeClassName === "padide-com-theme"
                          ? "col-small-12"
                          : "col-small-12 col-large-3"
                      }
                    >
                      <div className="card-padding">
                        <h5 className="normal-title">
                          {Dictionary.specialFacilities}
                        </h5>

                        {hotelDetails.features.map((item, index) => {
                          return (
                            <div key={index} className="facilitie-item">
                              {item.name === "Facilities" && (
                                <div
                                  className="margin-bottom en-font"
                                  id="hotel_facilities"
                                >
                                  <div dir="ltr" className="feature-list">
                                    <div className="feature-items">
                                      {item.items.map(
                                        (detailsItem, detailsIndex) => (
                                          <div
                                            className={
                                              detailsItem.isImportant
                                                ? "is-important"
                                                : null
                                            }
                                            key={detailsIndex}
                                          >
                                            {detailsItem.name}{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/*photo Gallery and hotel info*/}
              {hotelDetails.features.length > 0 ? (
                <div className={`card card-padding bg-white box-border margin-bottom detail-section ${isStyle3?"":"blue-border-right"}`}>
                  {hotelDetails.features.map((item, index) => {
                    return (
                      <div key={index} className="facilitie-item">
                        {item.name === "Object Information" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.hotelContactInformation}
                            </h5>
                            <div dir="ltr">
                              <ul className="float-row no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Building Information" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.SpecificationsForHotelBuildings}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Payment" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.ValidFinancialCreditsForHotelUse}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Facilities" && (
                          <div className="margin-bottom" id="hotel_facilities">
                            <h5 className="normal-title">
                              {Dictionary.hotelFacilities}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Room Facilities" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.roomFacilities}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Distances" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.DistanceFromHotelToPlaces}
                            </h5>
                            <div dir="ltr">
                              <ul className="float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value} m{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {
                hotelDetails.paragraphs && hotelDetails.paragraphs.length > 0 && (
                  <div id="hotel_details">
                    <div
                      className={`card card-padding bg-white box-border margin-bottom ${isStyle3?"":"blue-border-right"}`}
                      dir="ltr"
                    >
                      <h5 className="normal-title body-direction">
                        {" "}
                        {Dictionary.hotelDetails}
                      </h5>
                      <div className="en-font">
                        <div className="textContent">
                          {hotelDetails.paragraphs.map((item, index) => {
                            return (
                              <div key={index}>
                                <div>
                                  <b className="title">{item.title}</b>
                                </div>
                                <div>
                                  <p>{item.para}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )
                // hotel paragraph
              }

              <div className="box-border map-holder">
                <ShowMap data={[props.hotelDetails]} />
              </div>

              {LayoutConfig.themeClassName === "style-2" && <div className="result-page-search-bar">
                <div className="search-form-wrapper">
                  <HotelForm
                    noTabs
                    hotelNameId={hotelNameId}
                  />
                </div>
              </div>}

              {!!hotelNameId && LayoutConfig.themeClassName !== "style-2" &&<div className="result-page-search-bar hotel-detail-searchholder margin-bottom">
                <div className="search-form-header">
                  {Dictionary.selectedHotelAndDates}
                  <button
                    type="button"
                    className="button dark-btn margin-start full-width-mobile"
                    onClick={toggleSearch}
                  >
                    {showSearch
                      ? Dictionary.close
                      : Dictionary.modifySearch}
                  </button>
                </div>
                {showSearch && (
                  <div className="detail-search-holder">
                    <SearchRoom  hotelNameId={hotelNameId} />
                  </div>
                )}
              </div>}

              <div className="room-select-card" id="roomSelectSection" ref={roomsHolderRef} >
                <h5 className="normal-title select-room-title">{Dictionary.selectRoom} </h5>
                <div id={"roomSelect"} className={"select-room-wrapper"}>
                  {roomLayout}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
}
const dispatchMapProp = (dispatch) => ({
  setHotelDetails: hotelData => {
    dispatch(setHotelDetails(hotelData));
  },
  showError: (param) => {
    dispatch(showError(param));
  },
  setCancelationRoom: (param) => {
    dispatch(setCancelationRoom(param));
  },
  setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketHotelPixel(data))
});
const stateMapProp = (state) => ({
  hotelDetails: state.HotelSearch.selectedHotel,
  error: state.VisualData.error,
  currencyCode: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(stateMapProp, dispatchMapProp)(withRouter(HotelDetail));
