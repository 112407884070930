import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-jalaali";
import MultiDatePicker from "react-multi-date-picker";
import { createDatePickerConfig } from "../../helpers/helpers";

import SelectRoom from "./SelectRoom";

class SearchFormRoom extends React.Component {
  state = {
    searchValidator: {
      dates: true,
    },
    validation: false,
    searchParam: {
      hotelId: "",
      adults: [2],
      children: [0],
      ages: [0],
      checkin: null,
      checkout: null,
    },
    searchMode: false,
    calendarIsGregorian: false,
    roomOptionSelect: "2ADT",
    datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
    datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
  };
  isMobile = false;
  selectPassengersOptions = (event) => {
    let option = event.target.value;
    if (option === "2") {
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          adults: [2],
          children: [0],
          ages: [0],
        },
        roomOptionSelect:"2ADT"
      });
    } else if (option === "1") {
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          adults: [1],
          children: [0],
          ages: [0],
        },
        roomOptionSelect:"1ADT"
      });
    } else {
      this.setState({
        roomOptionSelect:"OPTIONS"
      });
    }
  };
  fillFromURL() {
    if (this.props.location.search !== "") {
      this.setState({ calendarIsGregorian: true });
      let parameters = queryString.parse(this.props.location.search);
      this.setState(
        {
          searchParam: {
            ...parameters,
            hotelId : parameters.Id,
            checkin: parameters.Checkin,
            checkout: parameters.Checkout,
            adults: parameters.Adults.split(",").map((item) => Number(item)),
            children: parameters.Children.split(",").map((item) =>
              Number(item)
            ),
            ages: parameters.Ages
              ? parameters.Ages.split(",").map((item) => Number(item))
              : []
          },
        },
        this.checkSelectedPassengers
      );

    }

  }
  checkSelectedPassengers() {
    let value = "OPTIONS";
    const {adults,children} = this.state.searchParam;

    if ( adults.length === 1 && children[0] === 0  &&  adults[0] === 1){
      value = "1ADT"
    }
    if (adults.length === 1 && children[0] === 0  &&  adults[0] === 2){
      value = "2ADT"
    }
    this.setState({
      roomOptionSelect: value
    });
  }
  componentDidMount() {
    if (window.innerWidth <= 767){
      this.isMobile = true;
    }
    this.fillFromURL();
  }
  changeCalendarType = () => {
    this.setState((prevState) => {
      return { calendarIsGregorian: !prevState.calendarIsGregorian };
    });
  };
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.fillFromURL();
    }
  }
  disabledDate = (index, current) => {
    // Can not select days before today and today
    if (index === "checkout") {
      return current && current <= moment(this.state.searchParam.checkin);
    } else {
      return current && current <= moment().endOf("day").subtract(1, "day");
    }
  };
  resultRef = () => {
    console.log(this.myRef);
  };
  defaultDay = new Date();

  handleClick = () => {
    this.setState({
      validation: true,
    });
    if (this.state.searchParam.hotelId && this.state.searchParam.checkin && this.state.searchParam.checkout) {
      let url = `/hotel/details?Id=${this.state.searchParam.hotelId}&Adults=${this.state.searchParam.adults}&Children=${this.state.searchParam.children}&Ages=${this.state.searchParam.ages}&Checkin=${this.state.searchParam.checkin}&Checkout=${this.state.searchParam.checkout}`;
      if (this.state.searchParam.safarmarketId){
        url += `&safarmarketId=${this.state.searchParam.safarmarketId}&utm_source=safarmarket&utm_medium=redirection&utm_medium=hotel`
      }
      this.props.history.push(url);
    }
  };
  handlePassenger = (e) => {
    let passenger = JSON.stringify(this.state.searchParam);
    passenger = JSON.parse(passenger);
    passenger.adults = e.adult;
    passenger.children = e.children;
    passenger.ages = e.age;
    this.setState({
      searchParam: { ...this.state.searchParam, ...passenger },
    });
  };

  toggleDatepicker = () =>{
    if (this.props.LAN ==="IR"){
      if(this.state.datepickerType === "gregorian"){
        this.setState({
          datepickerType:"persian",
          datepickerLocale:"fa"
        })
      }else{
        this.setState({
          datepickerType:"gregorian",
          datepickerLocale:"en"
        })
      }
    }else{
      debugger;
    }
  }

  getCallback = (id, text, type) => {
    let searchParamCatch = this.state.searchParam;
    searchParamCatch.HotelId = id;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };

  checkinChangeHandle = (d) => {
    const e = d.toDate();
    let checkoutDate = this.state.searchParam.checkout;
    if(checkoutDate &&  moment(checkoutDate).isAfter(e)){
      this.setState(prevState=>({
        searchParam: {
          ...prevState.searchParam,
          checkin: moment(e).format('YYYY-MM-DD')
        },
      }),
      this.checkinRef.closeCalendar
    );
    }else{
      this.setState(prevState=>({
          searchParam: {
            ...prevState.searchParam,
            checkin: moment(e).format('YYYY-MM-DD'),
            checkout: moment(e).add(1, 'day').format('YYYY-MM-DD'),
          },
        }),
        () => {
          this.checkinRef.closeCalendar();
          this.checkoutRef.openCalendar();
        }
      );
    }
  }

  render() {
    const { Dictionary, LAN } = this.props;

    const datepickerConfig = createDatePickerConfig(LAN, this.state.datepickerLocale, this.isMobile);

    const night = moment(this.state.searchParam.checkout).diff(
      moment(this.state.searchParam.checkin),
      "days"
    );

    return (
        <div className = "bg-white sm-search-form-room card-padding margin-bottom rounded-medium" >
            <div className="searchbox-inner">
                <div className="searchBar hotel">
                    <div className="float-row half-gap">
                      <div className="col-small-12 col-medium-10">
                        <div className="float-row half-gap">
                          <div className="col-small-5 col-medium-3 col-large-4 margin-bottom-light">
                            <label className="form-label block margin-bottom-light">{Dictionary.checkIn}</label>
                            <div className="relative">
                                <MultiDatePicker
                                    fixMainPosition={process.env.REACT_APP_THEME ==='ITOURS_IR'}
                                    ref={element => {this.checkinRef = element}}
                                    inputMode="none"
                                    calendarPosition={datepickerConfig.calendarPosition}
                                    weekDays={datepickerConfig.weekDays}
                                    digits={datepickerConfig.digits}
                                    inputClass="multi-datepicker-input"
                                    className={datepickerConfig.className}
                                    minDate={this.state.datepickerType === 'persian' ? moment().format('jYYYY/jMM/jDD') : moment().format('YYYY/MM/DD')}
                                    format={'YYYY-MM-DD'}
                                    calendar={this.state.datepickerType}
                                    locale={this.state.datepickerLocale}
                                    onFocusedDateChange={this.checkinChangeHandle}
                                    value={
                                    this.state.datepickerType === 'persian'
                                        ? moment(this.state.searchParam.checkin, 'YYYY/MM/DD').format('jYYYY/jMM/jDD')
                                        : this.state.searchParam.checkin
                                    }>
                                    {this.isMobile && (
                                    <div className="date-picker-title">{Dictionary.checkinDateSelection}</div>
                                    )}                                        
                                    {LAN === 'IR' && (
                                    <button
                                        type="button"
                                        className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font"
                                        onClick={this.toggleDatepicker}>
                                        {this.state.datepickerLocale === 'fa' ? 'تقویم میلادی' : 'تقویم شمسی'}
                                    </button>
                                    )}
                                    {this.isMobile && (
                                    <button className={'close-datepicker-btn'} onClick={() => this.checkinRef.closeCalendar()}>
                                        <span className={`itours-icon gray-close-icon icon-large`} />
                                    </button>
                                    )}
                                </MultiDatePicker>
                              {this.state.validation && !this.state.searchParam.checkin && (
                                  <b className="validator-label">{Dictionary.pleaseSelectCheckinDate}</b>
                              )}
                            </div>
                          </div>
                          <div className="col-small-5 col-medium-3 col-large-4 margin-bottom-light">
                            <label className="form-label block margin-bottom-light">{Dictionary.checkOut}</label>
                            <div className="relative">
                                <MultiDatePicker
                                    fixMainPosition={process.env.REACT_APP_THEME ==='ITOURS_IR'}
                                    ref={element => {this.checkoutRef = element}}
                                    onFocusedDateChange={(d) => {
                                      const e = d.toDate();
                                      this.setState(
                                          {
                                            searchParam: {
                                                ...this.state.searchParam,
                                                checkout: moment(e).format('YYYY-MM-DD'),
                                            },
                                          },
                                          this.checkoutRef.closeCalendar
                                      );
                                    }}
                                    inputMode="none"
                                    calendarPosition={datepickerConfig.calendarPosition}
                                    weekDays={datepickerConfig.weekDays}
                                    digits={datepickerConfig.digits}
                                    inputClass="multi-datepicker-input"
                                    className={datepickerConfig.className}
                                    minDate={
                                    this.state.datepickerType === 'persian'
                                        ? moment(this.state.searchParam.checkin).add(1, 'day').format('jYYYY/jMM/jDD')
                                        : moment(this.state.searchParam.checkin).add(1, 'day').format('YYYY/MM/DD')
                                    }
                                    format={'YYYY-MM-DD'}
                                    calendar={this.state.datepickerType}
                                    locale={this.state.datepickerLocale}
                                    value={
                                    this.state.datepickerType === 'persian'
                                        ? moment(this.state.searchParam.checkout, 'YYYY/MM/DD').format('jYYYY/jMM/jDD')
                                        : this.state.searchParam.checkout
                                    }>
                                    {this.isMobile && (
                                    <div className="date-picker-title">{Dictionary.checkoutDateSelection}</div>
                                    )}                                         
                                    {LAN === 'IR' && (
                                    <button
                                        type="button"
                                        className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font"
                                        onClick={this.toggleDatepicker}>
                                        {this.state.datepickerLocale === 'fa' ? 'تقویم میلادی' : 'تقویم شمسی'}
                                    </button>
                                    )}
                                    {this.isMobile && (
                                    <button className={'close-datepicker-btn'} onClick={() => this.checkoutRef.closeCalendar()}>
                                        <span className={`itours-icon gray-close-icon icon-large`} />
                                    </button>
                                    )}
                                </MultiDatePicker>

                                {this.state.validation && !this.state.searchParam.checkout && (
                                    <b className="validator-label">{Dictionary.pleaseSelectCheckoutDate}</b>
                                )}
                            </div>
                          </div>
                          <div className="col-small-2 col-medium-2 col-large-1 margin-bottom-light">
                            <label className="form-label block margin-bottom-light"> {Dictionary.nights}</label>
                            <span className="form-input nights-fake-input">{night} {Dictionary.nights}</span>
                          </div>
                          <div className="col-small-12 col-medium-4 col-large-3 margin-bottom-light">
                            <label className="form-label block margin-bottom-light"> {Dictionary.passengers}</label>
                            <select
                                className="form-select room-option-toggle-select"
                                onChange={(event) => {
                                this.selectPassengersOptions(event);
                                }}>
                                <option value="2" selected={this.state.roomOptionSelect === '2ADT'}>
                                1 {Dictionary.room}
                                {Dictionary.comma} 2 {Dictionary.adults}
                                {Dictionary.comma} 0 {Dictionary.children}
                                </option>
                                <option value="1" selected={this.state.roomOptionSelect === '1ADT'}>
                                {' '}
                                1 {Dictionary.room}
                                {Dictionary.comma} 1 {Dictionary.adults}
                                {Dictionary.comma} 0 {Dictionary.children}
                                </option>
                                <option value="0" selected={this.state.roomOptionSelect === 'OPTIONS'}>
                                {this.state.roomOptionSelect === 'OPTIONS'
                                    ? this.state.searchParam.adults.length +
                                    ' ' +
                                    Dictionary.room +
                                    Dictionary.comma +
                                    ' ' +
                                    this.state.searchParam.adults.reduce((sum, item) => sum + item, 0) +
                                    ' ' +
                                    Dictionary.adults +
                                    Dictionary.comma +
                                    ' ' +
                                    this.state.searchParam.children.reduce((sum, item) => sum + item, 0) +
                                    ' ' +
                                    Dictionary.children
                                    : Dictionary.moreOptions}
                                </option>
                            </select>
                          </div>
                        </div>
                        {(this.state.roomOptionSelect === "OPTIONS") && (
                          <SelectRoom
                            smSearchRoom
                            onSelectItem={this.handlePassenger}
                            defaultAdult={this.state.searchParam.adults}
                            defaultChildren={this.state.searchParam.children}
                            defaultAges={this.state.searchParam.ages}
                          />
                        )} 
                      </div>                   
                      <div className="col-small-12 col-medium-2">
                          <button
                          className={`searchBtn hotel-search-btn medium-align-label button red-btn full-width ${this.props.safarmarketLayout?"safarmarket-hotel-search-btn":""}`}
                          type="button"
                          onClick={() => {
                              this.handleClick();
                          }}>
                          <span className="itours-icon search-icon margin-end-light" />
                          {Dictionary.search}
                          </button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
const RouterDOM = withRouter(SearchFormRoom);
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(RouterDOM);
