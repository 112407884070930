// import { Card, Col, Icon, Row } from 'antd';
// import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Price from "../../../components/Common/Price";
import LayoutConfig from "../../../Config/layoutConfig";
import defaultRoomImage from "../../../image/default-hotel-image.svg";
import defaultRoomImageStyle2 from "../../../image/hotel-vector.svg";
import HotelBoardPolicy from "./HotelBoardPolicy";
import HotelBookButton from "./HotelBookButton";

HotelSingleRoom.propTypes = {
  room: PropTypes.object.isRequired,
  rates: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  hotelFacilities: PropTypes.array.isRequired,
  roomFacilities: PropTypes.array.isRequired,
};

function HotelSingleRoom(props) {
  const roomFacilities =[ 
    ...props.room.facilities.map(facility =>facility.name),
    ...props.roomFacilities.map(facility => facility.name),
    ...props.hotelFacilities.map(facility => facility.name)
  ];
  const { Dictionary } = props;
  return (
    <div className={"card box-border margin-bottom bg-white"}>
      <div className="card-padding border-bottom normal-title">
        {props.room.name}
      </div>
      <div className="card-padding">
        <div className="float-row">
          <div className="col-small-12 col-medium-2 hidden-xsmall hidden-small">
            <div className="room-image-holder">
              <img src={defaultRoomImage} className="room-image" alt="room" />
            </div>
            <div className="margin-bottom">
              <ul className="simple-bullet-list ">
                {roomFacilities.slice(0, 5).map((facility, index) => {
                  return <li key={index}><span dir="ltr" className="en-font">{facility}</span></li>;
                })}
              </ul>
            </div>
          </div>
          <div className="col-small-12 col-medium-10">
            <div className={"table-holder"}>
              <table className={"hotel-room-table"}>
                <thead>
                  <tr className="hidden-xsmall">
                    <th>{Dictionary.WhatsIncluded}</th>
                    <th>{Dictionary.guests}</th>
                    <th>{Dictionary.totalForStay}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {/* Cancellation element */}
                  {props.rates.map((rate, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="hidden-small hidden-medium hidden-large">
                            {Dictionary.WhatsIncluded}:
                          </div>
                          <HotelBoardPolicy
                            rate={rate}
                            date={props.date}
                            rooms={props.room}
                            location={props.location}
                            currency={props.currency}
                          />
                        </td>
                        <td>
                          <div className="hidden-small hidden-medium hidden-large">
                            {Dictionary.guests}:
                          </div>
                          <div>
                            <span className="itours-icon guest-dark margin-end-light icon-small" />
                            <span className="inline-block margin-end-light">
                              {" "}
                              {props.room.adults}{" "}
                            </span>
                            {props.room.adults > 1 ? (
                              <span className="inline-block margin-end-light">
                                {Dictionary.adults}
                              </span>
                            ) : (
                              <span className="inline-block margin-end-light">
                                {Dictionary.adult}
                              </span>
                            )}
                            {props.room.children > 0 ? (
                              <>
                                <span className="inline-block margin-end-light">
                                  {Dictionary.and}
                                </span>
                                <span className="inline-block margin-end-light">
                                  {props.room.children}
                                </span>
                                <span className="inline-block">
                                  {props.room.children > 1
                                    ? Dictionary.children
                                    : Dictionary.child}
                                </span>
                              </>
                            ) : null}
                          </div>
                        </td>
                        <td>
                          <div className="hidden-small hidden-medium hidden-large">
                            {Dictionary.totalForStay}:
                          </div>
                          <div>
                            {rate.regularPrice !== rate.salePrice ? (
                              <div className="old-price">
                                <Price currency={props.currency}>
                                  {rate.regularPrice}
                                </Price>
                              </div>
                            ) : null}
                            <b className="new-price">
                              <Price currency={props.currency}>
                                {rate.salePrice}
                              </Price>
                            </b>
                            <div>
                              {Dictionary.totalFor} {props.date.duration}{" "}
                              {Dictionary.nights}
                            </div>
                          </div>
                        </td>
                        <td className="select-room-td">
                          <HotelBookButton
                            selectedRoom={props.selectedRoom}
                            className="button red-btn full-width"
                            bookingToken={rate.bookingToken}
                            onClick={props.onSelectRoom}
                          >
                            {Dictionary.bookNow}
                          </HotelBookButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          
        </div>
      </div>
    </div>
  );
}
const stateMapProp = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(stateMapProp)(HotelSingleRoom);
