import Currency from "../Enum/Currency";
export const Header = {
  "Content-Type": "application/json",
  "Accept-Language": "en-US",
  "currency":
    process.env.REACT_APP_THEME === "PADIDEH81COM"
      ? "EUR"
      : process.env.REACT_APP_THEME === "PADIDEH81IR"
      ? "IRR"
      : Currency.getCurrency(),
  "apikey": process.env.REACT_APP_SERVER_TERMINAL,
  "tenantid": process.env.REACT_APP_SERVER_TENANTID,
};
export const ServerAddress = {
  Type: process.env.REACT_APP_SERVER_TYPE,
  User: process.env.REACT_APP_SERVER_USER,
  Hotel: process.env.REACT_APP_SERVER_HOTEL,
  DomesticHotelA : process.env.REACT_APP__SERVER_DomesticHotel_A,
  DomesticHotelB : process.env.REACT_APP__SERVER_DomesticHotel_B,
  DomesticHotelC : process.env.REACT_APP__SERVER_DomesticHotel_C,
  Package: process.env.REACT_APP_SERVER_PACKAGE,
  Payment: process.env.REACT_APP_SERVER_PAYMENT,
  localServer: process.env.REACT_APP_SERVER_LOCALSERVER,
  localIP: process.env.REACT_APP_SERVER_LOCALIP,
  Flight: process.env.REACT_APP_SERVER_FLIGHT,
  Traveller: process.env.REACT_APP_SERVER_TRAVELLER
};

export const Traveller = {
  GetAll:"/api/services/app/Passenger/GetAll",
  Delete:"/api/services/app/Passenger/Delete"
}

export const User = {
  Register: "/api/services/app/Account/Register", //for register new user
  RegisterByInfo: "/api/services/app/Account/RegisterByInfo", //register new user for namin travel
  Login: "/api/TokenAuth/Login", //for login user
  VarficationUser: "/api/services/app/Account/GetUser",
  Forgot: "/api/services/app/Account/ForgotPassword",
  Confrim: "/api/services/app/Account/ConfirmEmail",
  ResetPassword: "/api/services/app/Account/ResetPassword",
  Update: "/api/services/app/Account/Update",
  ChangePassword: "/api/services/app/Account/ChangePassword",
  CreateNewsLetter:"/api/services/app/NewsLetter/Create",
  CreateContactUs:"/api/services/app/ContactUs/Create",
  SendEmailActivation:"​/api/services/app/Account/SendEmailActivation",
  ActivateEmail:"/api/services/app/Account/ActivateEmail",
  
  //for itours ir only:
  IsUsernameExist:"/api/services/app/Account/IsUsernameExist",
  SendOTP:"/api/services/app/OTP/SendOTP",
  RegisterOrLogin:"/api/services/app/OTP/RegisterOrLogin",
  ChangePasswordByAuthorizedUser:"/api/services/app/Account/ChangePasswordByAuthorizedUser",
  ForgotPasswordByPhoneNumber:"/api/services/app/Account/ForgotPasswordByPhoneNumber",
  ResetPasswordByPhoneNumber:"/api/services/app/Account/ResetPasswordByPhoneNumber"
};
export const Flight = {
  GetIpLocation:"/GetIpLocation",
  GetLocation: "/api/services/app/BookingFlight/GetAirport",
  GetDomesticLocation:"/api/services/app/BookingFlight/GetDomesticAirport",
  GetAirline: "/api/services/app/BookingFlight/GetAirline",
  GetFlights: "/api/services/app/BookingFlight/LowFareSearch",
  GetAlternatives: "/api/services/app/BookingFlight/GetAlternatives",
  ValidateFlight: "/api/services/app/BookingFlight/Validate",
  GetPNR: "/api/services/app/BookingFlight/ReservePNR",
  GetRules: "/api/services/app/BookingFlight/GetFlightRules",
  GetReserveByID: "/api/services/app/BookingFlight/GetFlightReserveById",
  GetDirectTicketByID: "/api/services/app/BookingFlight/GetDirectTicketById",
  BankStatus:"/api/services/app/BookingFlight/BankStatus",
  GetTime:"/api/services/app/BookingFlight/GetTime",
  GetDomesticRefundRules:"/api/services/app/DomesticFlightRefundRule/GetDomesticRefundRules",
  GetDomesticRefundRuleDetail:"/api/services/app/DomesticFlightRefundRule/GetDomesticRefundRuleDetail"
};
export const Hotel = {
  GetLocation: "/api/services/app/BookingHotel/GetLocation",
  GetAccomadtion: "/api/services/app/BookingHotel/GetSearch",
  GetAccomadtionDetails: "/api/services/app/BookingHotel/GetHotelById",
  PreReserve: "/api/services/app/BookingHotel/PreReserve",
  GetPreReserveID: "/api/services/app/BookingHotel/GetPreReserveByKey",
  GetReserveByID: "/api/services/app/BookingHotel/GetReserveById",
  PostReserver: "/api/services/app/BookingHotel/Reserve",
  GetVoucherDetails: "/api/services/app/BookingHotel/GetReserveById",
  BookHotel: "/api/services/app/BookingHotel/Book",
  GetRoom: "/api/services/app/BookingHotel/GetRoom",
  GETType: "/api/services/app/Reserve/GetReserveById",
  GetCancellationPolicy: "/api/services/app/BookingHotel/GetCancellationPolicy",
  Availability: "/api/services/app/BookingHotel/Availability",
  GetAvailability: "/api/services/app/BookingHotel/GetAvailability"
};
export const DomesticHotelApiUrls = {
  Availability : '/v2/Hotel/SearchHotels',
  GetAccommodation: '/api/services/app/Accommodation/Get',
  GetLocation : '/api/services/app/Entity/Search',
  GetHotelById : '/v2/Hotel/GetHotelById',
  getPageByUrl : '/v2/Page/GetPageByUrl',
  GetEntityNameByLocation : '/v2/Entity/GetEntityNameByLocation',
  GetRates : '/v2/Comment/GetRates',
  GetRates2 : '/v2/Comment/Rates',
  GetScore : '/v2/Comment/GetScore',
  InsertComment : '/v2/Comment/InsertComment',
  Reserve : '/api/services/app/BookingHotelDomestic/Reserve',
  GetReserve : '/api/services/app/BookingHotelDomestic/GetReserve',
  HotelAvailability : '/api/services/app/Booking/Availability',
  HotelAvailabilityById : '/api/services/app/Booking/AvailabilityByHotelId',
  GetRooms : '/api/services/app/Booking/GetRoom',
  Validate : '/api/services/app/Booking/Validate',
  GetValidate : '/api/services/app/Booking/GetValidate',
  PreReserveHotel : '/api/services/app/Booking/PreReserve',
  Confirm : '/api/services/app/Booking/Confirm',
  GetReserveById : '/api/services/app/Reserve/Get'
}
export const Package = {
  PackageAvailibility: "/api/services/app/Booking/Availability",
  PackageGetAvailability: "/api/services/app/Booking/GetAvailability",
  GetPackageHotelDetailById: "/api/services/app/Booking/GetHotelDetailById",
  PreReserveFlight:"/api/services/app/Booking/PreReserveFlight",
  PreReserveRoom:"/api/services/app/Booking/PreReserveRoom",
  reserve:"/api/services/app/Booking/Reserve",
  Confirm:'/api/services/app/Booking/Confirm',
  PackageValidateFlight:"/api/services/app/Booking/ValidateFlight",
  GetByPrereserveKey:"/api/services/app/PreReserve/Get",
  GetReserveById:"/api/services/app/Reserve/Get",
  GetLocation:"/api/services/app/Booking/GetAirports"
};
export const Reserve = {
  hotel: "/api/services/app/Reserve/GetReserves",
  naminTravelFlight : "/api/services/app/Reserve/GetAll",
  getReserveFromCoordinator : "/api/services/app/Order/Get"
};
export const Payment = {
  GetBanksInformation: "/api/services/app/ReserveBankGateway/GetAll",
  MakeToken: "/api/services/app/ReserveBankGateway/MakeToken",
  GatewayReport: "/api/services/app/BankGatewayLog/GetAll",
  DomesticFlightRefund:"/api/services/app/RefundRequest/Refund",
  GetBalance :"/api/services/app/Deposit/GetBalance",
  GetDepositBanksList:"/api/services/app/UserDepositBankGateway/GetAll",
  MakeTokenDeposit:"/api/services/app/UserDepositBankGateway/MakeToken",
  ConfirmByDeposit:"/api/services/app/DepositReserve/ConfirmByDeposit",
  GetTransactionByReserveId:"/api/services/app/Transaction/GetByReserveId"
};

export const ServerStatus = {
  Success: 1,
  Error: 2,
  SummaryError: 3,
};
