import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Price from "../Common/Price";
import Rate from "../Common/Rate";

const HotelInformation = (props) => {
  const { model } = props;
  const { Dictionary } = props;

  return (
    <>
      <div className="full-width">
        <h3 className="hotel-title en-font">{model.name}</h3>
        {model.address && (
          <div className="hotel-address en-font">
            <span className="itours-icon location-dark margin-right-light icon-small" />
            {model.address}
          </div>
        )}
        <div className="hotelRange">
          <Rate number={model.rating} />
        </div>
      </div>
      <div className="text-right">
        {model.availabilities && model.availabilities.length > 0 ? (
          <>
            <div>
              <b dir="ltr">
                <Price currency={props.currencyCode}>
                  {model.availabilities[0].rates[0].salePrice}
                </Price>
              </b>
              <div className="font-10">{Dictionary.startingPrice}</div>
            </div>
            {props.onScrollToRooms?
              <button type="button" onClick={props.onScrollToRooms} className="button red-btn nowrap">{Dictionary.selectRoom}</button>
            :
              <a
                href="#roomSelect"
                type="button"
                className="button red-btn nowrap"
              >
                {Dictionary.selectRoom}
              </a>
            }
          </>
        ) : null}
      </div>
    </>
  );
};
HotelInformation.propTypes = {
  model: PropTypes.any.isRequired,
  currencyCode: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(HotelInformation);
