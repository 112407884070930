import { useState, useEffect } from "react";
import LayoutConfig from "../../Config/layoutConfig";

const FetchProgress = props => {

    const [percentage,setPercentage ] = useState(0);

    const [closed,close ] = useState(false);

    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    useEffect(() => {
        let timeOut;

        if(props.compeleted){
            setPercentage(100);
            setTimeout(()=>{close(true)},1500);
        }else if (percentage < 85 ) {
            timeOut = setTimeout(() => { setPercentage(prevState => prevState + (percentage < 50 ? 3 : 1)) }, 500);
        }

        return (() => { clearTimeout(timeOut); })
    }, [percentage, props.compeleted]);


    if (closed || !isStyle3) {
        return null;
    }

    return (
        <div className="padding-bottom">
            <div className={`progressbar-wrapper ${props.className || ""}`}>
                <div className="bar-fill" style={{ width: `${percentage}%` }} />
            </div>
        </div>
    )
}

export default FetchProgress;