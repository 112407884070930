import React from 'react';
import {connect} from 'react-redux';
import { Checkbox } from 'antd';

import {setFilter} from '../../Actions/FlightAction';
import Price from "../Common/Price";
import LayoutConfig from '../../Config/layoutConfig';


const AirlineFilter = props => {

    const {Dictionary}=props;
    const {airlinePrice,ethnicAirlinePrice,notEthnicAirlinePrice} = props.filtershow;
    const naminEthnicAirlineFilterList = [
        Dictionary.FreeProfessionalSupport,
        Dictionary.Rebookable,
        Dictionary.EasierToChangeRules,
        Dictionary.FreeExtraBaggage,
        Dictionary.FlexibleTicketService,
        Dictionary.PossibilityToChangeDatesOrRefund
    ];
    const AirlineFilterList = [
        Dictionary.FreeProfessionalSupport,
        Dictionary.RebookableSomeAirlines,
        Dictionary.FlexibleTicketService,
        Dictionary.PossibilityToChangeDatesSomeAirlines
    ];

    if (!airlinePrice){
        return <span>loading</span>;
    }

    return (
        LayoutConfig.themeClassName === 'naminTravel-theme' ? (
            <Checkbox.Group className='full-width' onChange={value => { props.setFilter({ value, filterType: "airlinePrice" }) }}>
                {ethnicAirlinePrice && ethnicAirlinePrice.length > 0 ?
                    <div className="bg-pink padding-v-10">
                        <div className="page-container">
                            <div className="airline-filter-with-list">
                                <ul className="styled-list small white">
                                    {naminEthnicAirlineFilterList.map(item => <li key={item}>{item}</li>)}
                                </ul>
                                <div className="clearfix airline-filter margin-start">
                                    <div className={"airline-table-header pull-start margin-end-light"}>
                                        <div className="airline">{Dictionary.airline}</div>
                                        <div className="cell">{Dictionary.direct}</div>
                                        <div className="cell">1+ {Dictionary.stop}</div>
                                    </div>
                                    <div className="airline-table-holder">
                                        <div className="airline-filter-row">
                                            {ethnicAirlinePrice.map((airlinePriceItem, index) => <div key={index} className="airline-filter-item" dir="ltr">
                                                <img
                                                    className="airline-logo"
                                                    alt={airlinePriceItem.airline.name}
                                                    title={airlinePriceItem.airline.name}
                                                    src={airlinePriceItem.airline.photoUrl}
                                                />

                                                {airlinePriceItem.directPrice ? (
                                                    <Checkbox value={`${airlinePriceItem.airline.code}_direct_ethnic`} className="cell" >
                                                        <Price currency={props.Currency}>{airlinePriceItem.directPrice}</Price>
                                                    </Checkbox>
                                                ) : (
                                                    <div className="cell"> - </div>
                                                )}

                                                {airlinePriceItem.withStopPrice ? (
                                                    <Checkbox value={`${airlinePriceItem.airline.code}_withStop_ethnic`} className="cell" >
                                                        <Price currency={props.Currency}>{airlinePriceItem.withStopPrice}</Price>
                                                    </Checkbox>
                                                ) : (
                                                    <div className="cell"> - </div>
                                                )}

                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                {notEthnicAirlinePrice && notEthnicAirlinePrice.length > 0 ?
                    <div className="bg-gray2 padding-v-10">
                        <div className="page-container">
                            <div className="airline-filter-with-list">
                                <ul className="styled-list small">
                                    {AirlineFilterList.map(item => <li key={item}>{item}</li>)}
                                </ul>
                                <div className="clearfix airline-filter margin-start">
                                    <div className={"airline-table-header pull-start margin-end-light"}>
                                        <div className="airline">{Dictionary.airline}</div>
                                        <div className="cell">{Dictionary.direct}</div>
                                        <div className="cell">1+ {Dictionary.stop}</div>
                                    </div>
                                    <div className="airline-table-holder">
                                        <div className="airline-filter-row">
                                            {notEthnicAirlinePrice.map((airlinePriceItem, index) => <div key={index} className="airline-filter-item" dir="ltr">
                                                <img
                                                    className="airline-logo"
                                                    alt={airlinePriceItem.airline.name}
                                                    title={airlinePriceItem.airline.name}
                                                    src={airlinePriceItem.airline.photoUrl}
                                                />

                                                {airlinePriceItem.directPrice ? (
                                                    <Checkbox value={`${airlinePriceItem.airline.code}_direct_noEthnic`} className="cell" >
                                                        <Price currency={props.Currency}>{airlinePriceItem.directPrice}</Price>
                                                    </Checkbox>
                                                ) : (
                                                    <div className="cell"> - </div>
                                                )}

                                                {airlinePriceItem.withStopPrice ? (
                                                    <Checkbox value={`${airlinePriceItem.airline.code}_withStop_noEthnic`} className="cell" >
                                                        <Price currency={props.Currency}>{airlinePriceItem.withStopPrice}</Price>
                                                    </Checkbox>
                                                ) : (
                                                    <div className="cell"> - </div>
                                                )}

                                            </div>)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </Checkbox.Group>
        ) : (
            <div className="page-container">
                <div className='clearfix airline-filter-wrapper'>
                    <div className={"airline-table-header pull-start margin-end-light"}>
                        <div className="airline">{Dictionary.airline}</div>
                        <div className="cell">{Dictionary.direct}</div>
                        <div className="cell">1+ {Dictionary.stop}</div>
                    </div>
                    <div className="airline-table-holder">
                        <Checkbox.Group className="airline-filter-row" onChange={value => { props.setFilter({ value, filterType: "airlinePrice" }) }}>
                            {airlinePrice.map((airlinePriceItem, index) => <div key={index} className="airline-filter-item" dir="ltr">
                                <img
                                    className="airline-logo"
                                    alt={airlinePriceItem.airline.name}
                                    title={airlinePriceItem.airline.name}
                                    src={airlinePriceItem.airline.photoUrl}
                                />

                                {airlinePriceItem.directPrice ? (
                                    <Checkbox value={`${airlinePriceItem.airline.code}_direct`} className="cell" >
                                        <Price currency={props.Currency}>{airlinePriceItem.directPrice}</Price>
                                    </Checkbox>
                                ) : (
                                    <div className="cell"> - </div>
                                )}

                                {airlinePriceItem.withStopPrice ? (
                                    <Checkbox value={`${airlinePriceItem.airline.code}_withStop`} className="cell" >
                                        <Price currency={props.Currency}>{airlinePriceItem.withStopPrice}</Price>
                                    </Checkbox>
                                ) : (
                                    <div className="cell"> - </div>
                                )}

                            </div>)}
                        </Checkbox.Group>
                    </div>
                </div>
            </div>
        )
    )
}
const dispatchToProps=(dispatch)=>({
    setFilter: data => dispatch(setFilter(data))

});
const stateToProps=(state)=>({
    filtershow:state.FlightSearch.filterShow,
    selectedFilter:state.FlightSearch.filterSelect,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN,
    Currency:state.UserData.Currency
});

export default connect(stateToProps,dispatchToProps)(AirlineFilter)