import React from 'react';
import Form from '../Common/Form';
import {connect} from 'react-redux';
import axios from "axios";
import {ServerAddress, User, Header} from "../../Enum/Urls";
import Validation from "../Common/Validation";
import {showError} from "../../Actions/VisualDataAction"
import LayoutConfig from '../../Config/layoutConfig';
import Loading from '../Common/Loading';
class ChangePassword extends Form{
    state={
        ...this.state,
        loading:false,
        done:false
    };
    FormDOM=null;
    handleSubmit(event){
        const {Dictionary} = this.props;
        super.validateForm(event,()=>{
            if(this.state.DataInput.newPassword===this.state.DataInput.confrimPassword){
                this.setState({...this.state,loading:true})
                if (this.props.LAN === "IR"){
                    this.ChangePasswordByAuthorizedUser(this.state.DataInput);
                }else{
                    this.changePassword(this.state.DataInput);
                }
            }else{
                this.props.showError({visibility: true,content:Dictionary.newPasswordsAreNotEqual,closable:true});
            }

        })
    }

    componentDidMount(){
        super.componentDidMount(this.FormDOM);
    }

    changePassword(param){
        let token=localStorage.getItem("userToken");
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.ChangePassword}`,
            data:{...param},
            headers:{Authorization:`Bearer ${token}`,...Header}
        }).then((result)=>{
            this.setState({done:true});
        }).catch((error)=>{
            try{
                this.props.showError({visibility:true,content:error.response.data.error.message})
            }catch (e) {
                this.props.showError({visibility:true,content:"Internal Error,Please refresh the page"})
            }
        }).finally(()=>{
            this.setState({loading:false})
        })
    }

    ChangePasswordByAuthorizedUser(param){
        let token=localStorage.getItem("userToken");
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.ChangePasswordByAuthorizedUser}`,
            data:{...param},
            headers:{Authorization:`Bearer ${token}`,...Header}
        }).then((result)=>{
            this.setState({done:true});
        }).catch((error)=>{
            try{
                this.props.showError({visibility:true,content:error.response.data.error.message})
            }catch (e) {
                this.props.showError({visibility:true,content:"Internal Error,Please refresh the page"})
            }
        })
    }

    render(){
        const {Dictionary,LAN,error} = this.props;
        const {loading,done} = this.state;
        return(
            <div>
                {(error.visibility)?
                    <div className="showMessage">
                        <div className="content">
                            {error.content}
                        </div>
                        <div className="buttonContainer">
                            <button type="button" onClick={()=>{this.props.showError({visibility:false,connect:null,closable:true})}}> {Dictionary.ok} </button>
                        </div>
                    </div>
                    :
                    (done)?
                        <div className="text-center margin-bottom">
                            <div className="margin-bottom">
                                <div>
                                    <span className="itours-icon check-green icon-large" />
                                </div>
                                <strong>
                                    {Dictionary.yourPasswordHasBeenChangedSuccessfully}.
                                </strong>
                            </div>
                            <div className="buttonContainer clearfix">
                                <button type="button" className="button blue-btn" onClick={()=>{this.setState({done:false})}}> {Dictionary.ok} </button>
                            </div>
                        </div>
                        :
                        loading ?
                        <Loading />
                        :
                        <form onSubmit={this.handleSubmit.bind(this)} className='change-password-form' ref={(node) => this.FormDOM=node}>
                            {LAN === "IR" || <div className={`float-row ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"xlarge-gap":""}`}>
                                <div className="col-small-12 col-medium-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {Dictionary.currentPassword}
                                        </label>
                                        <div>
                                            <input className="form-input placeholder-right en-input-text" data-validator={true} type="password"  name="currentPassword" />
                                            <Validation model={this.state} feild="ValidatorList" name='currentPassword' />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div className={`float-row ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"xlarge-gap":""}`}>
                                <div className="col-small-12 col-medium-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {Dictionary.newPassword}
                                        </label>
                                        <div>
                                            <input className="form-input placeholder-right en-input-text" data-validator={true} type="password" name="newPassword" />
                                            <Validation model={this.state} feild="ValidatorList" name='newPassword' />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-small-12 col-medium-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {Dictionary.newPasswordRepeat}
                                        </label>
                                        <div>
                                            <input className="form-input placeholder-right en-input-text" data-validator={true} type="password" name="confrimPassword"  />
                                            <Validation model={this.state} feild="ValidatorList" name='confrimPassword' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"text-end":""}`}>
                                <button className="button red-btn" type="submit">
                                    {Dictionary.saveChanges}
                                </button>
                            </div>
                        </form>
                }

            </div>
        )
    }
}
const disptachMapProp=(dispatch)=>({
    showError:(param)=>dispatch(showError(param))
});
const stateMapProp=(state)=>({
    error:state.VisualData.error,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default  connect(stateMapProp,disptachMapProp)(ChangePassword)