import { Skeleton } from "antd";

const HotelDetailSkeleton = props => {
    return (
        <div className="padding-mobile">
            {/* <Loading fullPage description={Dictionary.getHotelInformation+" ..."}/> */}

            <div className="justify-between margin-bottom padding-top" dir="ltr">
                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton button-skeleton" />
                <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-right" />
            </div>
            <Skeleton active paragraph={{ rows: 0 }} title={{ width: "60%" }} className="one-line-skeleton text-left" />

            <div className="box-border hotel-gallery-skeleton-wrapper margin-bottom margin-top" dir="ltr">
                <div className="image-item" />

                {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                    <div className="image-item hidden-xsmall" key={item} />
                ))}

                <div className="facilities card-padding body-direction hidden-xsmall">
                    <Skeleton active />
                </div>

            </div>

            <div className="box-border bg-white card-padding margin-bottom hidden-xsmall">
                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton margin-bottom" />
                <div className="bg-white justify-between margin-bottom-large">
                    {[1, 2, 3, 4].map(item => (
                        <div key={item} className="padding-v-5 grow">
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                        </div>
                    ))}
                </div>

                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton margin-bottom" />
                <div className="bg-white justify-between">
                    {[1, 2, 3, 4].map(item => (
                        <div key={item} className="padding-v-5 grow">
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                        </div>
                    ))}
                </div>
                <div className="bg-white justify-between">
                    {[1, 2, 3, 4].map(item => (
                        <div key={item} className="padding-v-5 grow">
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                        </div>
                    ))}
                </div>
            </div>

            <div className="box-border bg-white card-padding margin-bottom-large">
                <Skeleton active className="text-left" />
            </div>

            {!!props.withoutRooms || (
                <>
                    <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton margin-bottom" />

                    {[1, 2, 3, 4].map(item => (
                        <div key={item} className="box-border bg-white margin-bottom">

                            <div className="card-padding ">
                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "140px" }} className="one-line-skeleton" />
                            </div>

                            <hr />

                            <div className="room-item-skeleton card-padding">
                                <div className="image" />

                                <div className="grow hidden-xsmall hidden-small">
                                    <table className="full-width">
                                        <tr>
                                            <td>
                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "140px" }} className="one-line-skeleton" />
                                            </td>
                                            <td>
                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "140px" }} className="one-line-skeleton" />
                                            </td>
                                            <td>
                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "100px" }} className="one-line-skeleton margin-bottom-light" />

                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "170px" }} className="one-line-skeleton" />
                                            </td>
                                            <td width='100px'>
                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "140px" }} className="one-line-skeleton full-width-button-skeleton" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "140px" }} className="one-line-skeleton" />
                                            </td>
                                            <td>
                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "140px" }} className="one-line-skeleton" />
                                            </td>
                                            <td>
                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "100px" }} className="one-line-skeleton margin-bottom-light" />

                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "170px" }} className="one-line-skeleton" />
                                            </td>
                                            <td width='100px'>
                                                <Skeleton active paragraph={{ rows: 0 }} title={{ width: "140px" }} className="one-line-skeleton full-width-button-skeleton" />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="grow hidden-medium hidden-large hidden-xlarge">
                                    <Skeleton active />
                                </div>

                            </div>

                        </div>
                    ))}
                </>
            )}

            <br />

        </div>
    )
}

export default HotelDetailSkeleton;