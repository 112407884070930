import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import ROME from '../../image/ayanair/ads/ROME.jpg';
import DUBAI from '../../image/ayanair/ads/DUBAI.jpg';
import ISTANBUL from '../../image/ayanair/ads/ISTANBUL.jpg';
import LONDON from '../../image/ayanair/ads/LONDON.jpg';
import MOSCOW from '../../image/ayanair/ads/MOSCOW.jpg';
import SANTORINI from '../../image/ayanair/ads/SANTORINI.jpg';
import banner from '../../image/ayanair/ayan-banner.png';

const AyanAirHomeDestinations = props => {
    const { Dictionary } = props;

    const date = new Date();
    const flightDate = moment(date).add(15, "d").format("YYYY-MM-DD");
    const href = (code, name) => `/flight/result?originCodes=EBL&originNames=Erbil&destinationCodes=${code}&destinationNames=${name}&departureDateTimes=${flightDate}&adult=1&child=0&infant=0&cabinClassCode=Economy&airTripType=OneWay&nonStop=false&airlineCode=all&airlineText=`

    const items = [
        { image: SANTORINI, title: Dictionary.Santorini, url: href('JTR', 'Thira%20(JTR)') },
        { image: MOSCOW, title: Dictionary.Moscow, url: href('MOW', 'Moscow,%20Russia(MOW-All%20Airports)') },
        { image: ROME, title: Dictionary.Rome, url: href('ROM', 'Rome,%20Italy(ROM-All%20Airports)') },
        { image: ISTANBUL, title: Dictionary.Istanbul, url: href('IST', 'Istanbul,%20Turkey(IST-All%20Airports)') },
        { image: LONDON, title: Dictionary.London, url: href('LON', 'London,%20United%20Kingdom(LON-All%20Airports)') },
        { image: DUBAI, title: Dictionary.Dubai, url: href('DXB', 'Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)') }
    ];

    return (
        <>
            <section className="bg-white ayanair-banner-section">
                <div className="page-container">
                    <img src={banner} alt="" className="full-width ayanair-banner" />
                </div>
            </section>
            <section className="bg-white section-padding">
                <div className="page-container">
                    <div className="text-center">
                        <h5 className="font-27 x-bordered-title">
                            <b className="uppercase purple bold"> {Dictionary.PopularDestinations} </b>
                            <div className="font-19" > {Dictionary.CheckOurBestAndSpecialOffers} </div>
                        </h5>
                    </div>

                    <div className="ayanair-destinations">
                        {items.map(item => (
                            <Link to={item.url} className="destination-item">
                                <div className="image-wrapper"><img src={item.image} alt={item.title} /></div>
                                <span className="button red-btn font-14 uppercase" > {item.title} </span>
                            </Link>
                        ))}

                    </div>

                </div>
            </section>
        </>
    )
}

const stateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary
});

export default connect(stateToProps)(AyanAirHomeDestinations);