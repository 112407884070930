import React, { useEffect, useState } from "react";
import axios from "axios";

import { ServerAddress ,Header, DomesticHotelApiUrls as HotelURL } from "../../Enum/Urls";
import DomesticHotelItemVertical from "./DomesticHotelItemVertical";

const SimilarHotels = props => {
    const {hotels,checkin,checkout} = props;

    const [availabilityResponse,setAvailabilityResponse] = useState();
    const [showOtherHotels,setShowOtherHotels] = useState(false);

    useEffect(()=>{
        if(hotels && hotels.length){
            const fetchAvailability = async () => {

                setAvailabilityResponse();
                
                const params = {
                  hotelIds : hotels.map(hotelItem => hotelItem.HotelId),
                  checkIn: checkin,
                  checkOut: checkout
                }
                const response = await axios.post(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${HotelURL.HotelAvailabilityById}`,
                  params,
                  {
                    headers: {
                      ...Header,
                      apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                      'Accept-Language': 'fa-IR'
                    }
                  }
                );
                if (response?.data) {
                  setAvailabilityResponse(response.data.result);
                }
              }
              fetchAvailability();            
        }
    },[hotels,checkin,checkout]);


    const toggleOtherHotelsHandle = () => {
      setShowOtherHotels(prevState => !prevState);
    }

    let hotelList = hotels.map(item => ({
      ...item,
      priceInformation: "pending"
    }));

    let nights;

    if(availabilityResponse){
      nights = availabilityResponse.date.duration;
      hotelList = hotels.map(hotelItem => {
        let priceInformation;
        const hotelPriceInfo = availabilityResponse.hotels?.find(pricedHotelItem => pricedHotelItem.id === hotelItem.HotelId);
        if (!hotelPriceInfo){
          priceInformation = "not-priced";
        }else if (hotelPriceInfo.salePrice && hotelPriceInfo.salePrice > 10000){
          priceInformation = hotelPriceInfo;
        }else{
          priceInformation = "need-to-inquire";
        }

        return {
          ...hotelItem,
          priceInformation: priceInformation
        }
      }).filter(hotelItem => hotelItem.priceInformation !== "not-priced")
    }

    if (!hotelList){
        return <div>loading...</div>
    }
    if (!hotelList.length){
      return null;
    }

    return(
        <div className="margin-top">
            <h5 className="normal-title">هتل های مشابه</h5>
            <div className="float-row">
              {hotelList.slice(0,3).map(item => <div className="col-small-12 col-large-4 clear-large-3 margin-bottom" key={item.HotelId}>
                <DomesticHotelItemVertical 
                  hotel={item}
                  checkin={checkin}
                  checkout={checkout}
                  nights={nights}
                />
              </div>)}
              {showOtherHotels && hotelList.slice(3,6).map(item => <div className="col-small-12 col-large-4 clear-large-3 margin-bottom" key={item.HotelId}>
                <DomesticHotelItemVertical 
                  hotel={item}
                  checkin={checkin}
                  checkout={checkout}
                  nights={nights}
                />
              </div>)}
            </div>
            {hotelList.length > 3 && !showOtherHotels && <div className="margin-top">
              <button type="button" className="button blue-btn" onClick={toggleOtherHotelsHandle} >
                سایر هتل های مشابه 
              </button>
            </div>}
           
        </div>
    )
}

export default React.memo(SimilarHotels);