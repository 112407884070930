import { useRef } from "react";
import { useEffect, useState } from "react";

const Select = props => {

    const { onChange, items, inputClassName, placeHolder, wrapperClassName } = props;

    const [value, setValue] = useState(props.value);
    const [open, setOpen] = useState(false);

    const wrapperRef = useRef(null);

    useEffect(() => {
        onChange(value);
    }, [value]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const options = items.map(item => (
        <div
            key={item.value}
            className="custom-select-option"
            onClick={() => { setValue(item.value); setOpen(false); }}
        >
            {item.title}
        </div>
    ));

    const selectedOptionTitle = items.find(x => x.value === value)?.title;

    return (<div ref={wrapperRef} className={`relative ${wrapperClassName || ""}`}>
        <div
            onClick={() => {
                setOpen(true);
            }}
            className={`custom-select-input ${inputClassName}`}
        >
            {selectedOptionTitle || placeHolder || ""}
            <svg viewBox="0 0 24 24" className="caret"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" /></svg>
        </div>
        {!!open && <div className="custom-select-options">{options}</div>}
    </div>)
}

export default Select;