import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./page/App";
import LayoutConfig from "./Config/layoutConfig";

if (LayoutConfig.siteTitle) {
  document.title = LayoutConfig.siteTitle;
} else {
  switch (process.env.REACT_APP_SERVER_CULTURE) {
    case "IR":
      document.title =
        "آیتورز | خرید بلیط هواپیما ، رزرو هتل خارجی ، تور مسافرتی";
      break;
    default:
      document.title = "Itours Travel: Search Hotels & Cheap Flights";
  }
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
