import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LayoutConfig from "../../Config/layoutConfig";
import { DomesticFlight,Package,ForeignFlight,ForeignHotel,DomesticHotel,Flight1,Hotel1,Package1 } from "./icons";


const SearchBoxTab = props =>{
    const {Dictionary} = props;
    
    const FlightIcon = LayoutConfig.themeClassName === 'style-2' ? ForeignFlight : Flight1;
    const DomesticFlightIcon = LayoutConfig.themeClassName === 'style-2' ? DomesticFlight : Flight1;
    const HotelIcon = LayoutConfig.themeClassName === 'style-2' ? ForeignHotel : Hotel1;
    const DomesticHotelIcon = LayoutConfig.themeClassName === 'style-2' ? DomesticHotel : Hotel1;
    const PackageIcon = LayoutConfig.themeClassName === 'style-2' ? Package : Package1;

    return(<div className="overflow-hidden">
        <div className={`${props.withContainer?"page-container":""} mobile-tab-hotel-flight`}>
            <div className="clearfix home-tabs-holder">

                {LayoutConfig.themeClassName === 'style-3' ? (
                    <Link to="/" className={`home-tab ${props.searchType === "flight" || props.searchType === "foreignFlight" ? "home-active-tab" : ""}`}>
                        <svg viewBox="0 0 21.92 22"><path class="cls-3" d="M4.91,13,8.25,9.65,1.57,6s-1-.48-.24-1.2L3.24,2.93s.24-.41,1.19-.08,8.35,2.24,8.35,2.24,3.58-3.2,4-3.61,2.7-2.19,4.37-1,.24,3.62-.31,4.33A59.56,59.56,0,0,1,16.6,9.17c.16.7,2.39,8.39,2.39,8.39a1.26,1.26,0,0,1-.24,1.2l-1.91,1.92s-.72.48-1.19-.48-3.58-6.48-3.58-6.48L9,16.84,9.44,20a1.17,1.17,0,0,1-.24.66,17.47,17.47,0,0,1-1.43,1.26s-.48.48-1-.48-2.39-4.08-2.39-4.08L.62,15.16s-1-.48-.49-1c.13-.15,1.2-1.59,1.44-1.59S4.91,13,4.91,13"/></svg>
                        {Dictionary.flight}
                    </Link>
                ):LayoutConfig.hasDomesticFlight? (
                    <>
                        <Link to="/" className={`home-tab ${props.searchType === "flight" ? "home-active-tab" : ""}`}>
                            <DomesticFlightIcon className={props.searchType === "flight" ? "active" : ""} />
                            {Dictionary.domesticFlight}
                        </Link>
                        <Link to="/foreignflight" className={`home-tab ${props.searchType === "foreignFlight" ? "home-active-tab" : ""}`} >
                            <FlightIcon className={props.searchType === "foreignFlight" ? "active" : ""} />
                            {Dictionary.foreignFlight}
                        </Link>
                    </>
                ):(
                    <Link to="/" className={`home-tab ${props.searchType === "flight" ? "home-active-tab" : ""}`} >
                        <FlightIcon className={props.searchType === "flight" ? "active" : ""} />
                        {Dictionary.foreignFlight}
                    </Link>
                )}

                {LayoutConfig.themeClassName === 'style-3'? (
                    <Link to="/domesticHotel" className={`home-tab ${props.searchType === "domesticHotel" || props.searchType === "hotel" ? "home-active-tab" : ""}`}>
                        <svg viewBox="0 0 21.92 22"><path class="cls-3" d="M18.43,17H15.61V13.49h2.81Zm0-5.91H15.61V7.58h2.81Zm0-5.77H15.61V1.81h2.81Zm-5.16,13H8.65V14.48A1.38,1.38,0,0,1,10,13.06h1.94a1.38,1.38,0,0,1,1.33,1.42ZM12.37,11H9.55V7.58h2.81Zm0-5.77H9.55V1.81h2.81ZM6.31,17H3.49V13.49H6.31Zm0-5.91H3.49V7.58H6.31Zm0-5.77H3.49V1.81H6.31ZM21.92,21.21v-2a.56.56,0,0,0-.56-.56h-.84V1.47A1.2,1.2,0,0,0,19.36.23H2.56A1.2,1.2,0,0,0,1.4,1.47V18.65H.56a.56.56,0,0,0-.56.56v2a.56.56,0,0,0,.56.56h20.8a.56.56,0,0,0,.56-.56"/></svg>
                        {Dictionary.hotel}
                    </Link>
                ):(
                    <>
                        {!!LayoutConfig.hasDomesticHotel && <Link to="/domesticHotel" className={`home-tab ${props.searchType === "domesticHotel" ? "home-active-tab" : ""}`} >
                            <DomesticHotelIcon className={props.searchType === "domesticHotel" ? "active" : ""} />
                            {Dictionary.domesticHotel}
                        </Link>}

                        <Link to="/hotel" className={`home-tab ${props.searchType === "hotel" ? "home-active-tab" : ""}`}>
                            <HotelIcon className={props.searchType === "hotel" ? "active" : ""} />
                            {Dictionary.foreignHotel}
                        </Link>
                    </>
                ) }

                {process.env.REACT_APP_THEME === 'AYANAIRCOM' || <Link to="/package" className={`home-tab ${props.searchType === "package" ? "home-active-tab" : ""}`}>
                    {LayoutConfig.themeClassName === 'style-3'?(
                        <svg viewBox="0 0 21.92 22">
                            <path d="M15.17,3.74V1.41A1.12,1.12,0,0,0,14.1.25H7.73A1.12,1.12,0,0,0,6.67,1.41V3.74"/>
                            <path d="M3.42,21.75H4.85v-18H3.42A3.46,3.46,0,0,0,0,7.2V18.29a3.46,3.46,0,0,0,3.46,3.46m2.72-18h0Zm1.42,9.8L11,8.93h2.31L14.27,11l-3.46,4.84Zm-1.44,8.2h9.6v-18H8.16a4.19,4.19,0,0,1,2.66.63,5.71,5.71,0,0,1,1.69,2.51,15.75,15.75,0,0,1,.75,2H11.82a9.57,9.57,0,0,0-1-2.5A2.41,2.41,0,0,0,8.71,5H6.14V3.74h0Zm15.76-3.46V7.2a3.46,3.46,0,0,0-3.46-3.46H17v18h1.43a3.46,3.46,0,0,0,3.46-3.46"/>
                        </svg>
                    ):(
                        <PackageIcon className={props.searchType === "package" ? "active" : ""} />
                    )}
                    {Dictionary.package}
                </Link>}

            </div>
        </div>
    </div>)
};


const mapStateToProps = state => ({
    Dictionary: state.VisualData.Dictionary
});
export default connect(mapStateToProps)(SearchBoxTab);