import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import moment from "moment";

const Style3HomeSlider = props => {
    
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: true
    };

    const today = moment().format("YYYY-MM-DD");
    const tomorrow = moment().add(1,'days').format("YYYY-MM-DD");

    const setHref = (locationId, locationName) => `/domesticHotel/result?locationId=${locationId}&location=${locationName}&checkin=${today}&checkout=${tomorrow}`;

    const items = [
        {
            title: "سی و سه پل",
            location: "اصفهان",
            image: "https://cdn3.itours.no/Content/itoursir/pol33.webp",
            link: setHref(66, "اصفهان"),
            linkText: "برای سفر به این استان زیبا کلیک کنید"
        },
        {
            title: "بندر چابهار",
            location: "سیستان و بلوچستان",
            image: "https://cdn3.itours.no/Content/itoursir/sistan.webp",
            link: setHref(249, "چابهار"),
            linkText: "برای سفر به این بندر زیبا کلیک کنید"
        },
        {
            title: "گنبد سلطانیه",
            location: "زنجان",
            image: "https://cdn3.itours.no/Content/itoursir/gonbad-soltanieh.webp",
            link: setHref(94, "زنجان"),
            linkText: "برای سفر به این استان زیبا کلیک کنید"
        },
        {
            title: "پل سفید",
            location: "اهواز",
            image: "https://cdn3.itours.no/Content/itoursir/pol-sefid.webp",
            link: setHref(103, "اهواز"),
            linkText: "برای سفر به این شهر زیبا کلیک کنید"
        },
        {
            title: "حرم امام رضا",
            location: "مشهد",
            image: "https://cdn3.itours.no/Content/itoursir/imam-reza.webp",
            link: setHref(168, "مشهد"),
            linkText: "برای سفر به این شهر زیبا کلیک کنید"
        },
        {
            title: "پل طبیعت",
            location: "تهران",
            image: "https://cdn3.itours.no/Content/itoursir/pol-tabiat.webp",
            link: setHref(89, "تهران"),
            linkText: "برای سفر به این شهر زیبا کلیک کنید"
        }
    ]

    return (<section className="bg-white style3-slider-section">
        <div className="page-container overflow-hidden">
            <Slider {...settings} className="margin-x--10">
                {items.map(item => (
                    <a href={item.link} className="style3-slider-item" key={item.title} dir="rtl">
                        <div className="style3-slider-item-inner relative">
                            <img src={item.image} className="style3-slider-banner full-width" alt={item.title}/>                          
                            <div className="style3-slider-text">
                                <div className="item-title-location">
                                    {item.title}
                                    <b className="blue block bold font-22 no-wrap"> {item.location}</b>
                                </div>
                                <div className="divider" />
                                <p className="item-p">
                                    {item.linkText}
                                </p>
                                <span className="yellow-left-carret" />
                            </div>
                        </div>
                    </a>
                ))}
            </Slider>

        </div>
    </section>)
}

const stateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
});

export default connect(stateToProps)(Style3HomeSlider);