import moment from "moment-jalaali";
import React, { useEffect, useState } from 'react';

const DatePickerDrop = (props) => {
    const [value,setValue]=useState(props.emptyDefault?null:moment(props.min,props.format));
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [days, setDays] = useState([]);
    
    const initialYear = props.emptyDefault ? "" : props.shamsi ? moment(props.min, props.format).jYear() : moment(props.min, props.format).get('year');
    const [year, setYear] = useState(initialYear);
    
    const initialMonth = props.emptyDefault ? "" : props.shamsi ? moment(props.min, props.format).jMonth() : moment(props.min, props.format).get('month') + 1 ;
    const [month, setMonth] = useState(initialMonth);
    
    const initialDay = props.emptyDefault ? "" : props.shamsi ? moment(props.min, props.format).jDate() : moment(props.min, props.format).get('date');
    const [day, setDay] = useState(initialDay);

    useEffect(() => {
        generateArr('year');
    }, []);
    
    useEffect(() => {
        generateArr('year');
    }, [props.shamsi]);

    useEffect(() => {
        generateArr('month');
    }, [year]);
    useEffect(() => {
        generateArr('day');
        if (year && month && day){
            let value = moment(`${year}-${month}-${day}`, props.shamsi? "jYYYY-jM-jD" : props.format);
            //props.change(value);
            setValue(value);
        }
    }, [month, months]);
    useEffect(() => {
        if (year && month && day){
            let value = moment(`${year}-${month}-${day}`, props.shamsi? "jYYYY-jM-jD" : props.format);
            //props.change(value);
            setValue(value);
        }
    }, [day]);

    useEffect(()=>{
        if (props.change && value) {
            props.change(moment(value).format('YYYY-MM-DD'));
        }
      },[value]);

    useEffect(()=>{
        if (props.defaultValue){
            
            let defaultDate;

            if(props.shamsi){
                defaultDate = moment(props.defaultValue).format('jYYYY-jM-jD').split("-");
            }else{
                defaultDate = moment(props.defaultValue).format('YYYY-M-D').split("-");
            }
            setYear(defaultDate[0]);
            setMonth(defaultDate[1]);
            setDay(defaultDate[2])
        }else{
            setYear("");
            setMonth("");
            setDay(""); 
            setValue(null);
        }
    },[props.defaultValue, props.shamsi]);

    const generateArr = (type) => {
        let arr = [];
        let min = null;
        let max = null;
        let yearSelected = parseInt(year);
        let monthSelected = parseInt(month);
        // let daySelected = parseInt(day);
        let minDay =  props.shamsi ? moment(props.min, props.format).jDate() : moment(props.min, props.format).get('date');
        let minMonth = props.shamsi ? moment(props.min, props.format).jMonth() : moment(props.min, props.format).get('month') + 1;
        let minYear = props.shamsi ? moment(props.min, props.format).jYear() : moment(props.min, props.format).get('year');
        let maxDay =  props.shamsi ? moment(props.max, props.format).jDate() : moment(props.max, props.format).get('date');
        let maxMonth = props.shamsi ? moment(props.max, props.format).jMonth() : moment(props.max, props.format).get('month') + 1;
        let maxYear = props.shamsi ? moment(props.max, props.format).jYear() : moment(props.max, props.format).get('year');
        if (type === 'year') {
            min = minYear;
            max = maxYear;
        } else if (type === 'month') {
            min = yearSelected === minYear ? minMonth : 1;
            max = yearSelected === maxYear ? maxMonth : 12;
        } else if (type === 'day') {
            if (yearSelected === minYear && monthSelected === minMonth) {
                min = minDay;
            } else {
                min = 1;
            }
            if (yearSelected === maxYear && monthSelected === maxMonth) {
                max = maxDay;
            } else {
                let arr31, arr30;
                if(props.shamsi ){
                    arr31 = [1, 2, 3, 4, 5, 6];
                    arr30 = [7,8,9,10,11,12];
                }else{
                    arr31 = [1, 3, 5, 7, 8, 10, 12];
                    arr30 = [4, 6, 9, 11];
                }
                if (arr31.indexOf(monthSelected) !== -1) {
                    max = 31;
                } else if (arr30.indexOf(monthSelected) !== -1) {
                    max = 30;
                } else {
                    if (yearSelected % 4 === 0) {
                        max = 29;
                    } else {
                        max = 28;
                    }
                }
            }
        }
        for (let cnt = min; cnt <= max; cnt++) {
            arr.push(cnt);
        }
        if (type === 'year') {
            setYears(arr);
        } else if (type === 'month') {
            setMonths(arr);
            if (month && !(month >= arr[0] && month <= arr[arr.length - 1])) {
                setMonth(arr[0]);
            }
        } else {
            setDays(arr);
            if (day && !(day >= arr[0] && day <= arr[arr.length - 1])) {
                setDay(arr[0]);
            }
        }
    };
    const touch =()=>{
        if (props.onTouch){
            props.onTouch();
        }
    }
    return (
        <div className={'date-picker-drop'}>
            <div style={{display:"flex"}}>
                <div style={{width:"33.3%"}}>
                    <select style={{height:"40px"}} value={year} onChange={(e) => {setYear(e.target.value);touch();}}>
                        {props.emptyDefault && <option disabled selected value=""> -- </option>}                        
                        {years.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{width:"33.3%"}}>
                    <select style={{height:"40px"}} value={month} onChange={(e) => {setMonth(e.target.value);touch();}}>
                        {props.emptyDefault && <option disabled selected value=""> -- </option>}
                        {months.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{width:"33.3%"}}>
                    <select style={{height:"40px"}} value={day} onChange={(e) => {setDay(e.target.value);touch();}}>
                        {props.emptyDefault && <option disabled selected value=""> -- </option>}
                        {days.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <input 
                type={'hidden'} 
                name={props.name} 
                value={(value)?moment(value).format('YYYY-MM-DD'):""}
                data-validator-type={props.dataValidatorType?props.dataValidatorType:undefined}
                data-validator={props.dataValidator?props.dataValidator:undefined}
            />
        </div>
    );
};
export default DatePickerDrop;