import React from 'react';
import Error from '../image/404.svg';
import Namin404 from '../image/namintravel/404.svg';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import LayoutConfig from '../Config/layoutConfig';
class NotFound extends React.Component{
    render() {
        const {Dictionary} = this.props;

        return(

            <div className="section-vertical-padding">
                <div className="page-container">
                    {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?<div>
                    <img className="image-404" src={Namin404} alt="404" />
                        <div className='text-center'>
                            <b className='font-22'>
                                {Dictionary.ThePageWasNotFound}!
                            </b>
                            <div className='margin-top margin-bottom'>
                                <Link className="button link-style-btn" to={'/'}>
                                    <u>
                                        {Dictionary.returnToHome}
                                    </u>
                                </Link>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="pageNotFound text-center">
                        <img className="image" src={Error} alt="404" />
                        <div>
                            <p>
                                <b>
                                    {Dictionary.ThePageWasNotFound}
                                </b>
                            </p>
                            <p>
                                404 - {Dictionary.ThePageYouAreTryingToAccessDoesNotExistOnOurSite}
                            </p>
                            <div>
                                <Link className="button blue-btn" to={'/'}>
                                    {Dictionary.returnToHome}
                                </Link>
                            </div>
                        </div>
                    </div>
                    }

                </div>
            </div>
            
            

        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(NotFound)