import React from "react";
import { connect } from "react-redux";
import LayoutConfig from "../../Config/layoutConfig";
import AboutUsAyanair from "./AboutUsAyanair";
class AboutUS extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { Dictionary, LAN } = this.props;
    return (
      <div className="section-vertical-padding bg-white">
        <div className="page-container">
          <h2 className="page-title"> {Dictionary.aboutUs} </h2>
          <div className="justified">
            {LayoutConfig.aboutUsParagraphs ? (
              LayoutConfig.aboutUsParagraphs.map((item, index) => (
                <p key={index}> {item} </p>
              ))
            ) : process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' ? (
              <div dir="ltr">
                <p>
                  Namin Travel is the name you need to remember in the airline industry.
                </p>
                <p>
                  Namin Travel, an emerging brand in travel and tourism, has managed to create a comprehensive system with wonderful facilities using the technology of the world.
                </p>
                <p>
                  Especially those special tickets, which are to visit family and relatives, this is if the need for travel is undeniable.
                </p>
                <p>
                  Namin Travel  has managed to set up an advanced system with many benefits to users in order to alleviate this problem and upgrade the Service  level to residents of the Scandinavian region (Norway, Sweden, Denmark, Finland), given extensive experience in the fields of tourism and tourism, given special contracts with airlines and the use of collective science from highly skilled international engineers
                  Frequent changes and cancellations in the flight system, , Which causes many problems and sometimes irreparable damage to passengers, But Namin Travel to better serve and prevent Damage of dear customers,  Namin Travel has made Special offers, which you can take without needing to see them
                  Among these special features:
                </p>
                <ul className="bullet-list" dir="ltr">
                  <li>Possible to change the free date for a turn.</li>
                  <li>Passengers can provide Ticket with very good prices.</li>
                  <li>The possibility of using more time is available.</li>
                  <li>It is necessary to emphasize that these Ticket have a load of 40 or 46 kg  per passenger.</li>
                  <li>You can easily register your desired ticket with excellent conditions by registering on Namin Travel website.</li>
                  <li>Productivity of up-to-date and unique technology, in Namin Travel collection, it is possible to provide services with a price guarantee, quality guarantee and at any time.</li>
                </ul>
                <br/>
                <p>
                We wish you a good journey.
                </p>

              </div>
            ) : process.env.REACT_APP_THEME ==='AYANAIRCOM'? (
              <AboutUsAyanair />            
            ) : LAN === "IR" ? (
              <>
                <p>جهان امروز ، جهان تغییرات عظیم، ریشه ای، و پیشرفت های بزرگ است. هر صبح، با طلوع خورشید می توانیم انتظار جهشی دیگر در عرصه ی تکنولوژی را داشته باشیم؛ پیشرفتی خارق العاده که برای بشریت فرصت هایی را فراهم نموده است که شاید باور آن در گذشته ای نه چندان دور محال و ناممکن می نمود. تحولات عظیمی که در صنعت گردشگری در ده ساله ی اخیر در سراسر جهان به وقوع پیوسته است اثبات می نماید که این صنعت در مواجهه با این تغییرات بسیار منعطف بوده، و تا چه میزان پیشرفت های تکنولوژیک می تواند آن را دگرگون سازد. بدون تردید متفاوت عمل کردن و تلاش برای دسترسی به منابع بین المللی برای بهره گیری از این پیشرفت تکنولوژیک، رمز عبور کردن از همکاران در این عرصه است . شرکت پدیده هشتاد و یک ( آیتورز ) با بهره گیری از پیشرفت های تکنولوژیک در این عرصه و روابط قوی و مستحکم بین المللی خود، موفق به تولید سیستم رزرواسیون آنلاین بلیط و هتل با ویژگی های منحصر به فرد گردیده است که قابل رقابت و هم تراز با سیستم های رزرواسیون آنلاین در سراسر جهان می باشد.</p>
                <p>این شرکت در سال 1381 فعالیت رسمی خود را در با نام پدیده پدیده هشتاد و یک  برای ارائه انواع خدمات رزرو بلیط ، هتل ، پکیج و سایر خدمات مربوط به سفر و مجموعه آی تی و فناوری اطلاعات (iTours) به منظور خلق انواع سیستم های نرم افزاری به روز مربوط به امور فوق الذکر، آغاز نمود.</p>
                <p>آیتورز به دلیل بهره وری از تکنولوژی روز و منحصر به فرد، باعث شده است تا ارائه خدمات فوق با تضمین قیمت ، گارانتی کیفیت و در هر زمانی به صورت آنلاین امکان پذیر گردد .تفاوت ما با همکارانمان در نحوه ارائه خدمات ما با بهره گیری از سیستمی است که در 5 سال گذشته به صورت متوالی و بی وفقه در حال تولید و گسترش بوده است. امروز ما به پشتوانه ی تلاش، قدمت در این صنعت و حضور در عرصه ی جهانی و بین المللی؛ و فعالیت در طی سالیان متمادی ، با نگاه به آینده ای روشن، این سیستم را به تمام کسانی که طالب بهره مندی از انواع خدمات به روز، آسان و با کیفیت سفر هستند معرفی می نماییم.</p>
                <p>ما همواره قدردان امکاناتی هستیم که تکنولوژی در اختیارمان قرار می دهد.ما با بهره گیری صحیح از این امکانات و تلاش بی وقفه تیم فنی این شرکت، موفق شده ایم گامی بزرگ در این صنعت برداشته و خدماتی ارزشمند به هموطنان خود ارائه دهیم.</p>
              </>
            ) : LAN === "AR" ? (
              <>
                <p>إن العالم اليوم يشهد تغييرات كبيرة وتغييرات جذرية وتقدمًا عظيمًا. كل صباح ، مع شروق الشمس ، يمكننا أن نتوقع قفزة أخرى في التكنولوجيا ؛ تطور ملحوظ أتاح للبشرية فرصًا ربما كان من المستحيل تصديقها في الماضي غير البعيد.اثبتت التغييرات الهائلة التي حدثت في صناعة السياحة حول العالم خلال السنوات العشر الماضية أن الصناعة كانت مرنة للغاية في مواجهة هذه التغييرات ، وإلى أي مدى يمكن للتقدم التكنولوجي أن يغيرها. مما لا شك فيه أن التصرف بشكل مختلف ومحاولة الوصول إلى الموارد الدولية للاستفادة من هذا التقدم التكنولوجي هو مفتاح التغلب على الزملاء في هذا المجال. نجحت شركة "بدیده هشتاد و یک آیتورز"، باستخدام التطورات التكنولوجية في هذا المجال وعلاقاتها الدولية القوية والمتينة ، في إنتاج نظام حجز التذاكر والفنادق عبر الإنترنت مع ميزات فريدة تنافسية ومتساوية. مع أنظمة الحجز عبر الإنترنت في جميع أنحاء العالم.</p>
                <p>في عام 1381 ، بدأت الشركة نشاطها الرسمي تحت اسم "پدیده هشتاد و یک" لتقديم مجموعة متنوعة من خدمات حجز التذاكر والفنادق والباقات والخدمات الأخرى المتعلقة بالسفر وتكنولوجيا المعلومات(آیتورز) من أجل إنشاء مجموعة متنوعة من الأنظمة البرمجیات الحدیثه المتعلقه بالأمور المذكورة أعلاه. </p>
                <p> نظرًا لكفاءة أحدث التقنيات وفريدة من نوعها ، أتاحت شركة "آیتورز" تقديم الخدمات المذكورة أعلاه مع ضمان السعر وضمان الجودة وعلى الإنترنت في أي وقت.الفرق بيننا وبين زملائنا في الطريقة التي نقدم بها خدماتنا باستخدام نظام تم إنتاجه وتوسيعه بشكل مستمر في السنوات الخمس الماضية. اليوم ، نحن مدعومون بالجهود والعصور القديمة في هذه الصناعة والحضور في الساحة العالمية والدولية. ونشاط لسنوات عديدة ، بهدف مستقبل مشرق ، نقدم هذا النظام لجميع أولئك الذين يرغبون في الاستفادة من مجموعة متنوعة من خدمات السفر الحديثة والسهلة والجودة.</p>
                <p>نحن ممتنون دائمًا للتسهيلات التي توفرها لنا التكنولوجيا ، وقد نجحنا في اتخاذ خطوة كبيرة في هذه الصناعة وتقديم خدمات قيمة لمواطنينا باستخدام هذه المرافق بشكل صحيح والجهود قیمه من الفريق الفني للشركة.</p>
              </>
            ) : LAN === "US" ? (
              <>
                <p>
                  We live in a world of great advances and changes. Every day we
                  are awake to new technological advances and in the tourism
                  industry we try to keep up with this development.
                </p>
                <p>
                  We at Itours As, follows this development closely with our own
                  development department, using its strong international
                  relations of lifeguards, to promote products within online
                  booking systems, hotels and reservation systems in the world.
                  Itours AS opened its headquarters in Norway, Oslo in 1995, and
                  has so far worked to promote its customers' wishes for the
                  best possible travel and prices.
                </p>
                <p>
                  itours As has over 25 years of experience in offering its
                  customers the best of travel opportunities and solutions.
                </p>
                <p>
                  With our new and improved online booking system, we will meet
                  the new future that our customers demand and also show them
                  new solutions so that customer experiences are met.
                </p>
                <p>
                  Itours As, hope you join us on our journey into the new
                  technological future.
                </p>
              </>
            ) : (
              <>
                <p>
                  Vi lever i en verden av store fremskritt og forandringer. Hver
                  dag våkner vi til nye teknologiske fremskritt og i
                  reiselivsnæringen prøver vi å holde tritt i denne utviklingen.
                </p>
                <p>
                  Store teknologiske endringer har skapt nye behov og
                  forventninger i markedet, men også mange nye muligheter for
                  oss i reiselivsnæringen. Vi i iTours AS griper de nye
                  mulighetene med åpne armer og har etablert vår egen
                  utviklingsavdeling til å skape morgendagens online
                  bookingsystem. Med over 25 års erfaring, jobber vi
                  kontinuerlig med å kunne tilby de beste reisene til markedets
                  beste priser. Med vårt nye og forbedrede online bookingsystem,
                  har du alt du trenger til din reise på ett sted.
                </p>
                <p>
                  Itours As har over 25 års erfaring med å kunne tilby sine
                  kunder det best av reisemuligheter og løsninger.
                </p>
                <p>
                  Med vår nye og forbedret Online bookingsystem, så vil vi møte
                  den nye fremtiden som kundene våre krever og også vise dem nye
                  løsninger, slik at kunden opplevelser blir oppfylt.
                </p>
                <p>
                  Itours As, håper at dere blir med på vår reise inn i den nye
                  teknologiske fremtiden.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(AboutUS);
