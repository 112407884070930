import React, { Fragment } from 'react';

import {Travel} from '../../Enum/Models';
import HotelItem from '../Accomodation/HotelItem'
import FlightItem from '../Flight/FlightItem';
import { Skeleton } from 'antd';

 const ListItem = props => {

    let {start:firstIndex ,last,data}= props;
    
    let lastIndex = last;
    if (data.length < last+1){
        lastIndex = data.length-1;
    }

    const items = data.slice(firstIndex,lastIndex+1);

    return(
        <>
            {(props.type===Travel.hotel)?
            items.map((item,index)=>(
                <Fragment key={item.id}>
                    {(index === 0  || index === 2) && props.notCompeleted  ? (
                        <div className="box-border margin-bottom hotel-item-skeleton">
                          <div className="image" />

                          <div className="card-padding large">
                            <Skeleton active paragraph={{ rows: 3 }} className="origin" />
                          </div>

                          <div className="card-padding">
                            <Skeleton active paragraph={{ rows: 2 }} className="origin text-end" />
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton" />
                          </div>
                        </div>
                    ) : null }
                    <HotelItem model={item} duration={props.duration}  />
                </Fragment>
            ))
            :
            items.map(item=><FlightItem 
                hasExtraBaggage={item.hasExtraBaggage && (1+1==="%%%extraBaggageDeactive")} 
                domestic={props.domestic}
                model={item} 
                key={item.key}
            />)}
        </>
    )
}

export default ListItem;