import React, {useEffect, useState } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {withRouter} from 'react-router-dom';

// import { toggleAccountWindow } from "../../Actions/VisualDataAction";
import Loading from "../Common/Loading";
import Price from "../Common/Price";
import PackageHotelItem from "./PackageHotelItem";
import PackageFlightItem  from "./PackageFlightItem";
import LayoutConfig from "../../Config/layoutConfig";
import { Skeleton } from "antd";

const PackageSelected = props => {

  const [updatingFlight,setUpdatingFlight] =useState(false);
  const [updatingHotel,setUpdatingHotel] =useState(false);
  // const [requiredLoginModalVisibility,setRequiredLoginModalVisibility] =useState(false);
  // const [naminTravelSelectedPackage,setNaminTravelSelectedPackage] =useState(undefined);

  useEffect(()=>{
    if(props.hotel){
        setUpdatingHotel(true);
        setTimeout(()=>{
          setUpdatingHotel(false);
      },800);
    }
  },[props.hotel]);

  useEffect(()=>{
    if (props.flight){
      setUpdatingFlight(true);
      setTimeout(()=>{
        setUpdatingFlight(false);
     },800);
    }
  },[props.flight]);


  // useEffect(()=>{
  //   if (props.userData && props.userData.isEmailConfirmed ){
  //     if (naminTravelSelectedPackage){
  //         props.history.push(naminTravelSelectedPackage);
  //     }
  //   }
  // },[props.userData]);
  
  const submitHandle=()=>{
    let parameters = queryString.parse(props.location.search);
    //  if( process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%AYANAIRCOM' || (props.userData.userName && props.userData.isEmailConfirmed) ){
      props.history.push(
        `/package/details?hotelId=${props.hotel.id}&flightKey=${encodeURIComponent(props.flight.flightKey)}&Adults=${parameters.adults}&Children=${parameters.children}&Ages=${parameters.ages}&Checkin=${parameters.checkin}&Checkout=${parameters.checkout}&originCode=${parameters.originCode}&originName=${parameters.originName}&destinationCode=${parameters.destinationCode}&destinationName=${parameters.destinationName}`
      );
    // }else{
    //   setRequiredLoginModalVisibility(true);
    //   setNaminTravelSelectedPackage(`/package/details?hotelId=${props.hotel.id}&flightKey=${encodeURIComponent(props.flight.flightKey)}&Adults=${parameters.adults}&Children=${parameters.children}&Ages=${parameters.ages}&Checkin=${parameters.checkin}&Checkout=${parameters.checkout}&originCode=${parameters.originCode}&originName=${parameters.originName}&destinationCode=${parameters.destinationCode}&destinationName=${parameters.destinationName}`);
    // }
  }


  const { Dictionary } = props;
  
  const isStyle3 = LayoutConfig.themeClassName === 'style-3';

  return (
      <div className="selected-package bg-white responsive-bg-transparent box-border margin-bottom">
          {/* {requiredLoginModalVisibility && <div className="itours-modal login-to-book-modal visible">
            <div className="modal-overlay" onClick={()=>{setRequiredLoginModalVisibility(false)}} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <span className="itours-icon user-login icon-xxlarge" />
                <h3 className="normal-title">{props.userData.userName ? "please Confirm Your Email To Continue" :Dictionary.pleaseLoginToContinue} ...</h3>
                <p className="text-center gray semi-bold margin-bottom-large">
                  <span className="itours-icon info-icon icon-small margin-end-light" />                  
                  {props.userData.userName ?
                      Dictionary.pleaseConfirmYourEmailThenReloadThisPageToContinueTheBooking
                    : Dictionary.toProceedWithBookingAtSpecialPriceYouMustLogIn
                  }
                </p>
                <div className="text-center margin-top-large margin-bottom-large">
                  {props.userData.userName ?
                    <button type="button" className="button blue-btn large-btn" onClick={()=>{window.location.reload();}} >
                      {Dictionary.Reload}
                    </button>
                  :<button
                    type="button"
                    className="button red-btn large-btn"
                    onClick={()=>{
                      setRequiredLoginModalVisibility(false);
                      props.toggleAccountWindow();
                    }}
                  >
                    {Dictionary.login}
                  </button>}               
                </div>
              </div>
            </div>
          </div>
        } */}
          {(props.hotel || props.flight || isStyle3) ? <div className="avails-row float-row large-gap selected-package-row">
            <div className="col-small-12 col-medium-9">
                <div className="avails-row float-row large-gap">
                    <div className={`col-small-12 col-medium-6 package-selected-flight-holder ${updatingHotel?"updatingFlight":""}`}>                            
                        {props.hotel ?(
                          <PackageHotelItem model={props.hotel} isSelected /> 
                        ): isStyle3 ?(
                          <>
                            <div className="hotel-summery-image-skeleton margin-bottom" dir="ltr">
                              <div className="image" />
                              <Skeleton active paragraph={{ rows: 2 }} />
                            </div>
                            <Skeleton active paragraph={{ rows: 0 }} className="text-left" />
                            <div className="justify-between margin-bottom">
                              <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                              <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                            </div>
                          </>
                        ):(
                          <Loading />
                        )}
                    </div>
                    <div className={`col-small-12 col-medium-6 package-selected-hotel-holder relative ${updatingFlight?"updatingFlight":""}`}>  
                    {props.flight ? (
                      <PackageFlightItem model={props.flight} isSelected />
                    ) : isStyle3 ? (
                      <div className="flight-item-skeleton" >
                        <div className="route-skeleton">
                          <span className="airline" />
                          <Skeleton active paragraph={{ rows: 2 }} className="origin" />
                          <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} className="one-line-skeleton hidden-xsmall" />
                          <Skeleton active paragraph={{ rows: 2 }} className="origin text-end" />
                        </div>
                        <hr className="margin-top-half margin-bottom-half" />
                        <div className="route-skeleton">
                          <span className="airline" />
                          <Skeleton active paragraph={{ rows: 2 }} className="origin" />
                          <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} className="one-line-skeleton hidden-xsmall" />
                          <Skeleton active paragraph={{ rows: 2 }} className="origin text-end" />
                        </div>
                        <hr className="margin-top-half margin-bottom-half" />
                        <div className="flight-skeleton-footer">
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                        </div>
                      </div>

                    ):(
                      <Loading />
                    )}
                    </div>
                </div>   
            </div>
            <div className="col-small-12 col-medium-3">
              <div className="selected-package-footer">
                  <div className="hidden-medium hidden-large bold">
                      <div className="pair-row">
                      <label>{Dictionary.hotelPrice} : </label>
                          <Price currency={props.currencyCode}>
                              {props.hotel && props.hotel.salePrice}
                          </Price>
                      </div>
                      <div className="pair-row">
                          <label>{Dictionary.flightPrice} : </label>
                          <Price currency={props.currencyCode}>
                              {props.flight && props.flight.airItineraryPricingInfo.itinTotalFare.totalFare}
                          </Price>
                      </div>
                  </div>

                  <div className="package-total-price text-center bold">
                      { props.flight && props.hotel ?
                        <>
                          <div>{Dictionary.totalPrice} :</div>
                          <div dir="ltr">
                            <Price currency={props.currencyCode}>
                            {props.hotel.salePrice + props.flight.airItineraryPricingInfo.itinTotalFare.totalFare}
                            </Price>
                          </div>
                        </>
                        :
                        ""
                      }
                  </div>
                  <div className="selected-package-mobile-action-footer">
                    <div className="hidden-medium hidden-large">
                    {props.flight && <button type="button" className="button blue-btn margin-end-light" onClick={props.onShowFlightsList}>{Dictionary.flightChange}</button>}
                    {props.hotel && <button type="button" className="button blue-btn" onClick={props.onShowHotelsList}>{Dictionary.hotelChange}</button>}
                    </div>
                    <button type="button" onClick={submitHandle} disabled={!props.hotel || !props.flight} className="button red-btn has-arrow"> {Dictionary.continue} </button>
                  </div>
              </div>
            </div>
          </div>
          :
          <Loading />
          }
      </div>
  );

}
const stateMapProps = (state) => {
  return {
    userData:state.UserData.userData,
    currencyCode: state.UserData.Currency,
    night: state.HotelSearch.date.duration,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
  };
};
const mapDispatchToProps = (dispatch) => ({
  // toggleAccountWindow: () => dispatch(toggleAccountWindow())
});


const routerBind = withRouter(PackageSelected);
export default connect(stateMapProps,mapDispatchToProps)(routerBind);
