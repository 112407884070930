import { useEffect, useState } from "react";
import queryString from 'query-string';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Form, Steps, Input, Radio, Checkbox } from 'antd';

import { ServerAddress, Header, DomesticHotelApiUrls } from "../../Enum/Urls";
import { DomesticHotelPreReserve } from "../../Actions/DomesticHotelAction";
import { splitCodeAndNumber } from "../../Actions/UserAction";
import { showError } from "../../Actions/VisualDataAction";
import Loading from "../Common/Loading";
import LayoutConfig from "../../Config/layoutConfig";
import DomesticHotelSummery from "./DomesticHotelSummery";
import { CountryPostalCode } from "../../Enum/Models";
import { onChangeValidation } from "../Common/Validation";
import DomesticHotelCheckoutRoomItem from "./DomesticHotelCheckoutRoomItem";
import StickySides from "../Common/StickySides";
import { getTravellers } from "../../Actions/TravellerAction";

const DomesticHotelGetPassenger = props => {

  const { Step } = Steps;
  const { userData, Dictionary, LAN , verification} = props;

  const { getFieldDecorator } = props.form;

  const preReserveKey = queryString.parse(props.location.search)?.preReserveKey;

  const [validationData, setValidationData] = useState();
  const [hotelData, setHotelData] = useState();
  const [loading, setLoading] = useState(true);
  const [userIsPassenger, setUserIsPassenger] = useState(true);

  const [roomChildAndExtraBed, setRoomChildAndExtraBed] = useState([]);
  
  const [travellers, setTravellers] = useState([]);
  const [travellersLoading, setTravellersLoading] = useState(false);

  const disableUserIsPassenger = () => {
    if (userIsPassenger) {
      setUserIsPassenger(false)
    }
  };

  const fetchTravellers = async () => {
    if (process.env.REACT_APP_THEME !== "ITOURS_IR"){
      return;
    }
    setTravellers([]);
    setTravellersLoading(true);
    
    const response = await getTravellers();
    
    setTravellersLoading(false);

    if (response.data?.result?.items){
      setTravellers(response.data.result.items);    
    }

  } 

  const refetchTravellersData = async () => {
    
    if (process.env.REACT_APP_THEME !== "ITOURS_IR"){
      return;
    }
    
    const response = await getTravellers();

    if (response.data?.result?.items){
      setTravellers(response.data.result.items);    
    }

  } 

  useEffect(()=>{
    if(verification){
      fetchTravellers();
    }

    if (userData && verification) {
      setTimeout(()=>{
        props.form.setFieldsValue({
          [`reserver.gender`]: userData.gender,
          [`reserver.firstName`]: userData.firstName,
          [`reserver.lastName`]: userData.lastName,
          [`reserver.email`]: userData.emailAddress
        });
  
        if(userData.phoneNumber){
          const [code, number] = splitCodeAndNumber(userData.phoneNumber);
          if (number && code) {
            props.form.setFieldsValue({
              [`phoneNumber`]: number,
              [`countryCode`]: code
            });
          }
        }
  
        if (userIsPassenger) {
          props.form.setFieldsValue({
            [`passengers[0].gender`]: userData.gender,
            [`passengers[0].firstName`]: userData.firstName,
            [`passengers[0].lastName`]: userData.lastName,
          });
        }
      },500)

    }

  },[verification, userData]);

  useEffect(() => {
    if (preReserveKey) {
      window.scrollTo(0, 0);
      const fetchPrereserveData = async () => {
        const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.GetValidate}?Id=${preReserveKey}`,
          {
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': 'fa-IR'
            }
          }
        );
        if (response?.data?.result) {
          setValidationData(response.data.result);
          const initialExtra = response.data.result?.rooms?.map(_ => ({ selectedChild: false, selectedExtraBed: 0, childFee: 0, extraBedFee: 0 }));
          setRoomChildAndExtraBed(initialExtra);
        }
      }
      fetchPrereserveData();
    }

  }, [preReserveKey]);


  let hotelId;
  if (validationData) {
    hotelId = validationData.accommodationId
  }
  useEffect(() => {
    if (hotelId) {
      const fetchHotelData = async () => {
        const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetHotelById}?hotelId=${hotelId}`,
          {
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': 'fa-IR'
            }
          }
        );
        if (response?.data) {
          setHotelData(response.data);
          setLoading(false);
        }
      }
      fetchHotelData();
    }
  }, [hotelId]);


  let hotelInformation, reserveInformation;
  if (hotelData) {
    hotelInformation = {
      image: {
        url: hotelData.ImageUrl,
        alt: hotelData.ImageAlt,
        title: hotelData.ImageTitle
      },
      name: `${hotelData.HotelCategoryName} ${hotelData.HotelName} ${hotelData.CityName}`,
      rating: hotelData.HotelRating,
      address: hotelData.Address,
      TopSelling: hotelData.TopSelling,
      Url: hotelData.Url,
      CityId: hotelData.CityId
    }
  }
  if (validationData) {
    reserveInformation = {
      checkin: validationData.checkin,
      checkout: validationData.checkout,
      duration: moment(validationData.checkout).diff(moment(validationData.checkin), 'days'),
      rooms: validationData.rooms.map(roomItem => ({
        name: roomItem.name,
        board: roomItem.boardCode,
        cancellationPolicyStatus: roomItem.cancellationPolicyStatus,
        bed: roomItem.bed,
        extraBed: roomItem.extraBed,
        pricing: roomItem.pricing,
        maxChildAge: roomItem.maxChildAge,
        maxInfantAge: roomItem.maxInfantAge
      })),
      salePrice: validationData.rooms.reduce((totalPrice, roomItem) => {
        const roomItemPrice = roomItem.pricing.find(
          item => item.type === "Room" && item.ageCategoryType === "ADL"
        )?.amount;
        if (roomItemPrice) {
          return totalPrice + +roomItemPrice
        } else {
          return null;
        }
      }, 0),
      boardPrice: validationData.rooms.reduce((totalPrice, roomItem) => {
        const roomItemPrice = roomItem.pricing.find(
          item => item.type === "RoomBoard" && item.ageCategoryType === "ADL"
        )?.amount;
        if (roomItemPrice) {
          return totalPrice + +roomItemPrice
        } else {
          return null;
        }
      }, 0)
    }
  }

  const safarmarketId = queryString.parse(props.location.search)?.smid;


  if (loading) {
    return <Loading fullPage description={Dictionary.GettingPassengerInformation + ' ...'} />;
  }

  const updateRoomChildAndExtraBed = (roomIndex, property, value, fee) => {
    if (roomChildAndExtraBed?.length) {
      setRoomChildAndExtraBed(prevState => {
        const updatedState = [...prevState];
        updatedState[roomIndex][property] = value;
        if (property === "selectedChild") {
          updatedState[roomIndex]["childFee"] = fee;
        }
        if (property === "selectedExtraBed") {
          updatedState[roomIndex]["extraBedFee"] = fee;
        }
        return updatedState
      });
    }
  }


  const updateFirstPassenger = (value, type) => {
    if (userIsPassenger) {
      switch (type) {
        case 'gender':
          props.form.setFieldsValue({
            [`passengers[0].gender`]: value
          });
          break;
        case 'firstName':
          props.form.setFieldsValue({
            [`passengers[0].firstName`]: value
          });
          break;
        case 'lastName':
          props.form.setFieldsValue({
            [`passengers[0].lastName`]: value
          });
          break;
        case 'nationalId':
          props.form.setFieldsValue({
            [`passengers[0].nationalId`]: value
          });
          break;
        default:
          return
      }
    }
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {

      if(err){
        const scrollToError = () => {
          var element = document.getElementsByClassName("has-error")[0];
          var headerOffset = 30;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.scrollY - headerOffset;
        
          window.scrollTo({
               top: offsetPosition,
               behavior: "smooth"
          });
        }  
        scrollToError();

      }
    
      if (!err) {
        setLoading(true);

        let code = values.countryCode;
        let phone = values.phoneNumber;
        if (phone[0] === "0") {
          phone = phone.substring(1)
        }
        if (code.substring(0, 2) === "00") {
          code = "+" + code.substring(2)
        }
        const params = {
          passengers: values.passengers,
          reserver: {
            ...values.reserver,
            userName: userData ? userData.userName : code + phone,
            phoneNumber: code + phone
          },
          specialRequest: values.specialRequest,
          preReserveKey: preReserveKey
        }

        const token = localStorage.getItem("userToken");
        const reserveResponse = await DomesticHotelPreReserve(params, token);

        if (reserveResponse.data && reserveResponse.data.result) {
          const id = reserveResponse.data.result.id;
          const username = reserveResponse.data.result.username;
          setLoading(false);

          if (reserveResponse.data.result.status === 'Pending') {
            props.history.push(`/payment/reserve?username=${username}&reserveId=${id}`);
          } else {

            props.history.push(`/domesticHotel/capacity?reserveId=${id}&username=${username}`);
          }
        } else {
          props.showError({
            visibility: true,
            content: reserveResponse.response?.data?.error?.message || "Internal Error,Please refresh the page",
          });
        }

      }
    });
  };

  const prefixSelector = getFieldDecorator('countryCode', {
    initialValue: LAN === 'IR' ? '0098' : LAN === 'AR' ? "00971" : "0047",
  })(
    <select className="form-select" style={{ width: 120 }} dir="ltr">
      {CountryPostalCode.map(item => <option key={item.code} value={item.value}>
        {item.code}
      </option>)}
    </select>,
  );

  const isStyle3 = LayoutConfig.themeClassName === 'style-3';

  return (
    <div className="get-passenger-form-holder">
      <Form onSubmit={SubmitHandler}  >
        <div className="page-padding getpassenger-page">
          <div className="page-container hotel-get-passenger">
            <Steps current={1}>
              <Step title={Dictionary.selectHotel} />
              <Step title={Dictionary.PassengersInformation} />
              <Step title={"بررسی ظرفیت"} />
              <Step title={Dictionary.paymentTypeOptions} />
            </Steps>

            <div className="getPassengers">
              <StickySides
                main={!!hotelData && (
                  <>
                    <h5 className="normal-title">
                      {Dictionary.reserverContactInformation}({Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail})
                    </h5>
                    <div className={`margin-bottom bg-white responsive-bg-transparent box-border card-padding ${isStyle3?"":"blue-border-right"}`}>
                      <Form.Item className="form-group no-margin-bottom">
                        {getFieldDecorator('reserver.gender', {
                          initialValue: true,
                        })(
                          <Radio.Group onChange={e => { updateFirstPassenger(e.target.value, 'gender') }} >
                            <Radio value={true} className="no-margin">{Dictionary.male}</Radio>
                            <Radio value={false}>{Dictionary.female}</Radio>
                          </Radio.Group>,
                        )}
                      </Form.Item>
                      <div className="float-row">
                        <div className="col-small-12 col-medium-6">
                          <Form.Item label={Dictionary.firstName} className="form-group">
                            {getFieldDecorator('reserver.firstName', {
                              rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                            })(
                              <Input
                                className="form-input"
                                placeholder={Dictionary.firstName}
                                onChange={e => {
                                  onChangeValidation(e, 'alphabet');
                                  updateFirstPassenger(e.target.value, 'firstName');
                                }}
                              />,
                            )}
                          </Form.Item>
                        </div>
                        <div className="col-small-12 col-medium-6">
                          <Form.Item label={Dictionary.lastName} className="form-group">
                            {getFieldDecorator('reserver.lastName', {
                              rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                            })(
                              <Input
                                className="form-input"
                                placeholder={Dictionary.lastName}
                                onChange={(e) => {
                                  onChangeValidation(e, 'alphabet');
                                  updateFirstPassenger(e.target.value, 'lastName');
                                }}
                              />,
                            )}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="float-row">
                        <div className="col-small-12 col-medium-6">
                          <Form.Item className="no-margin form-group" label={Dictionary.email}>
                            {getFieldDecorator('reserver.email', {
                              rules: [{ required: true, message: Dictionary.enterEmail },
                              { type: 'email', message: Dictionary.enteredEmailAddressIsNotValid }
                              ],
                            })(
                              <Input placeholder={Dictionary.email} className="form-input" />
                            )}
                          </Form.Item>
                        </div>
                        <div className="col-small-12 col-medium-6">
                          <Form.Item label={Dictionary.phoneNumber} className="no-margin form-group country-code-pair" >
                            {getFieldDecorator('phoneNumber', {
                              rules: [
                                { required: true, message: Dictionary.thisFieldIsRequired },
                                {
                                  validator: (a, value, c) => {
                                    if (!!value && (value.length < 3 || value.length > 15 || value[0] === "0")) {
                                      return Promise.reject(new Error(Dictionary.enteredPhoneNumberIsNotValid2));
                                    }
                                    return Promise.resolve();
                                  }, message: Dictionary.enteredPhoneNumberIsNotValid2
                                }
                              ],
                            })(<Input
                              addonBefore={prefixSelector}
                              className="placeholder-right en-input-text"
                              onChange={(e) => {
                                onChangeValidation(e, 'number');
                              }}
                            />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="float-row margin-bottom">
                        <div className="col-small-12 col-medium-6">
                          <Form.Item className="no-margin form-group" label={Dictionary.nationalCode}>
                            {getFieldDecorator('reserver.nationalId', {
                              rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                            })(
                              <Input
                                className="form-input"
                                placeholder={Dictionary.nationalCode}
                                onChange={e => {
                                  onChangeValidation(e, 'number');
                                  updateFirstPassenger(e.target.value, 'nationalId');
                                }}
                              />
                            )}
                          </Form.Item>
                        </div>
                      </div>

                    </div>
                    <div className={`box-border bg-white  responsive-bg-transparent get-passenger-form-holder margin-bottom ${isStyle3?"":"blue-border-right"}`}>
                      <div className="hr_padding">
                        <h5 className="title">{Dictionary.PassengersInformation}</h5>
                      </div>
                      <div>

                        {reserveInformation?.rooms?.map((roomItem, roomIndex) => <DomesticHotelCheckoutRoomItem
                          key={roomIndex}
                          form={props.form}
                          roomItem={roomItem}
                          roomIndex={roomIndex}
                          updateRoomChildAndExtraBed={updateRoomChildAndExtraBed}
                          disableUserIsPassenger={disableUserIsPassenger}
                          travellers={travellers}
                          travellersLoading={travellersLoading}
                          refetchTravellersData={refetchTravellersData}
                        />)}

                        <div className="border-top padding-h-20 padding-bottom">
                          <Form.Item className="no-margin-bottom">
                            {getFieldDecorator('acceptRuls', {
                              valuePropName: 'checked',
                              rules: [{ required: true, message: Dictionary.youShouldAcceptThis }]
                            })(<Checkbox className="margin-end-half" />)}
                            {LAN === 'IR' ? (
                              <>
                                <Link to='/terms' className="content-link"> قوانین و مقررات آیتورز </Link> را خوانده ام و با آن موافق هستم.
                              </>
                            ) : (
                              <>
                                {Dictionary.iHaveReadAndAgreeToThe}
                                <Link to='/terms' className="content-link" target="_blank">
                                  {Dictionary.termsAndConditions}
                                </Link>
                              </>
                            )}
                          </Form.Item>

                        </div>
                      </div>
                    </div>

                    <div className={`card bg-white responsive-bg-transparent card-padding box-border ${isStyle3?"":"blue-border-right"}`}>
                      <div className="float-row">
                        <div className="col-small-12 col-large-9">
                          <div className="form-group">
                            <h5 className="normal-title">{Dictionary.description}</h5>
                            <p>
                              {Dictionary.EnterYourRequestsHereAndWeWillShareThemWithTheHotel}.
                              {Dictionary.SpecialRequestsAreNotGuaranteedButTheHotelWillDoEverythingToMeetYourNeeds}
                            </p>

                            <Form.Item className="no-margin form-group" >
                              {getFieldDecorator('specialRequest')(
                                <textarea className="form-textarea"></textarea>
                              )}
                            </Form.Item>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* %%remarks */}
                  </>
                )}
                aside={(
                  <>
                    <DomesticHotelSummery
                      roomChildAndExtraBed={roomChildAndExtraBed}
                      hotelInformation={hotelInformation}
                      reserveInformation={reserveInformation}
                      currencyCode={props.currencyCode}
                      safarmarketId={safarmarketId}
                      showButton={true}
                      cancellationPolicy={hotelInformation.cancellationPolicy}
                    />

                    <div className="box-border top-color-border orange margin-top bg-white">
                      <div className="colored">{Dictionary.pricesToRiseSoon}!</div>
                      <div className="font-12"> {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}. </div>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  currencyCode: state.UserData.Currency,
  userData: state.UserData.userData,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  verification:state.VisualData.Verification
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => { dispatch(showError(param)); }
});

const DomesticHotelGetPassengerForm = Form.create({ name: 'hotelPrereserveForm' })(DomesticHotelGetPassenger);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomesticHotelGetPassengerForm));