import React from "react";
import moment from "moment-jalaali";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import queryString from "query-string";
import ImageGallery from "../Common/ImageGallery";
import Rating from "../Common/Rating";
import Price from "../Common/Price";
import SafarmarketWelcome from "../Common/SafarmarketWelcome";
import { changeSafarmarketLayout } from "../../Actions/VisualDataAction";
import {
  preReserve,
  setCancelationRoom,
  setHotelDetails,
  setSafarmarketHotelPixel
} from "../../Actions/HotelAction";
import {
  Header,
  Hotel as HotelURL,
  ServerAddress,
} from "../../Enum/Urls";
import { showError } from "../../Actions/VisualDataAction";
// import Room from "../Accomodation/Room";
import SearchFormRoom from "./SearchFormRoom";
import { Currency } from "../../Enum/Models";
import Loading from "../Common/Loading";
import HotelSmRoom from "./RoomLayout/HotelSmRoom";
import LayoutConfig from "../../Config/layoutConfig";
import StickySides from "../Common/StickySides";

class HotelDetailSafarmarket extends React.Component {
  state = {
    loading:true,
    selectedRooms:undefined,
    showSelectedRoomDetails:false,
    reserveLoading:false
  };
  
  currecySign = null;



  componentDidMount() {
    this.roomsHolder = React.createRef();

    this.props.setSafarmarketLayout(true);
    //safarmarket:
    let parameters = queryString.parse(this.props.location.search); 
    const smId = parameters.safarmarketId;
    const expDate = new Date();
    expDate.setTime(expDate.getTime() + (7*24*60*60*1000));
    document.cookie = `safarMarketHotelId=${smId}; expires=${expDate.toUTCString()};path=/`;
    this.props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/hotel/v1/pixel/itours/2/0/?smId=${smId}`));
    this.getHotelDetails(smId);

  }
  componentDidUpdate(prevProps){
    if (this.props.location.search !== prevProps.location.search){
      let parameters = queryString.parse(this.props.location.search); 
      const smId = parameters.safarmarketId;
      this.getHotelDetails(smId);
    }
  }
  componentWillUnmount(){
    this.props.setSafarmarketLayout(false);
    this.props.setSafarmarketPixelValue(undefined);
  }

  getHotelDetails = async(safarmarketId) => {
    window.scrollTo(0, 0);
    this.setState({
      loading:true,
      selectedRooms:undefined,
      showSelectedRoomDetails:false
    });
    let parameters = queryString.parse(this.props.location.search);
    
    let params = new URLSearchParams();
    params.append("Id", parameters.Id);
    parameters.Adults.split(",").forEach((item) =>
      params.append("Adults", item)
    );
    parameters.Children.split(",").forEach((item) =>
      params.append("Children", item)
    );
    if (parameters.Ages.length !== 0) {
      parameters.Ages.split(",").forEach((item) => params.append("Ages", item));
    }
    params.append("Checkin", parameters.Checkin);
    params.append("Checkout", parameters.Checkout);

    params.append("metaId",safarmarketId);
    params.append("source",'safarmarket');

    try{
      const response = await axios({
        method: "get",
        url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAccomadtionDetails}`,
        params: params,
        headers: { ...Header },
      });
      if (response){
        this.props.setHotelDetails(response.data.result)
      }
    }catch(error){
      try {
        this.props.showError({
          visibility: true,
          content: error.response.data.error.message,
        })
      } catch (e) {
        this.props.showError({
          visibility: true,
          content: "Internal Error,Please refresh the page",
        })
      }
    }
    this.setState({loading:false});

  };

  // componentDidUpdate(prevProps){

  // }

  reserveHandler = async (bookingToken) => {
    this.setState({
      reserveLoading: true,
    });
    let parameters = queryString.parse(this.props.location.search);
    let params = {};
    let adult = [];
    parameters.Adults.split(",").forEach((item) => adult.push(parseInt(item)));
    params["adults"] = adult;
    let children = [];
    parameters.Children.split(",").forEach((item) =>
      children.push(parseInt(item))
    );
    params["children"] = children;
    if (parameters.Ages.length !== 0) {
      let ages = [];
      parameters.Ages.split(",").forEach((item) => ages.push(parseInt(item)));
      params["ages"] = ages;
    }
    params["checkin"] = parameters.Checkin;
    params["checkout"] = parameters.Checkout;
    params["bookingToken"] = bookingToken;
  
    params["metaId"]=parameters.safarmarketId;
    params["source"]="safarmarket";

    let response = await preReserve(params);
    if (response) {
      if (response.error?.message === "Unavailable"){
        this.setState({reserveLoading:false});
        this.props.showError({
          visibility:true,
          content:<div>
              {this.props.Dictionary.UnfortunatelyThisRoomIsNotAvailablePleaseChooseAnotherOne}.
            </div>,
          closable:true,
          icon:"info",
          buttonLabel:this.props.Dictionary.ok
        })
      }else{
        this.props.history.push(`/hotel/getPassengers?preReserveKey=${response.preReserveKey}&smid=${parameters.safarmarketId}&isFromSafarmarket=true`);
      }
    }
  };

  scrollToRooms = () => { if(this.roomsHolder && this.roomsHolder.current ){ this.roomsHolder.current.scrollIntoView({behavior: "smooth"})}} ;
  
  selectRoomHandler = (rooms,rate) => {
    if (this.state.reserveLoading) {return;};
    this.setState({selectedRooms:{rooms,rate},showSelectedRoomDetails:false});
  }


  passengers = (adults,children) => (<div>
    <span className="inline-block margin-start-light">{adults}</span>
    <span className="inline-block margin-start-light">{adults > 1? this.props.Dictionary.adults : this.props.Dictionary.adult }</span>
    {children > 0 && (
      <>
        <span className="inline-block margin-start-light">{this.props.Dictionary.and}</span>
        <span className="inline-block margin-start-light">{children}</span>
        <span className="inline-block">
            {children > 1
            ? this.props.Dictionary.children
            : this.props.Dictionary.child}
        </span>
      </>
    )}                                  
  </div>)

  render() {
    const { Dictionary } = this.props;
    const { selectedRooms } = this.state;
    
    //calculate nights:
    const parameters = queryString.parse(this.props.location.search); 
    const checkinMoment = moment(parameters.Checkin);
    const checkoutMoment = moment(parameters.Checkout);
    const nights = checkoutMoment.diff(checkinMoment,'days');
    


    let loadingCell = [];
    if (this.state.RoomsInfo) {
      for (let cnt = 0; cnt < this.state.RoomsInfo.length; cnt++) {
        let cashArr = [];
        for (
          let icnt = 0;
          icnt < this.state.RoomsInfo[cnt].rooms.length;
          icnt++
        ) {
          cashArr.push(false);
        }
        loadingCell[cnt] = cashArr;
      }
    }
    if (this.props.currencyCode !== null) {
      let currency = this.props.currencyCode.toString();
      this.currecySign = Currency[currency];
    } else {
      this.currecySign = "???";
    }
    const {hotelDetails } = this.props;

    let roomLayout;
    if (hotelDetails?.availabilities?.length > 0) {
      roomLayout = hotelDetails.availabilities.map((availabilityItem,availabilityIndex) => <React.Fragment key={availabilityIndex}>
        {availabilityItem.rates.map((rateItem,rateIndex)=><React.Fragment key={availabilityIndex+"_"+rateIndex}>
          {(
            (availabilityIndex === 0 && rateIndex === 1) 
            ||
            (availabilityItem.rates.length === 1 && availabilityIndex === 1 && rateIndex === 0) 
          ) && <h5 className="normal-title">سایر اتاق های این هتل</h5> }
            <HotelSmRoom
              reserveLoading={this.state.reserveLoading}
              rooms={availabilityItem.rooms}
              rate={rateItem}
              date={hotelDetails.date}
              currency={this.props.currencyCode}
              onSelectRoom={this.handleSelectRoom}
              location={this.props.location}
              onSelectRoomHandler={() => {this.selectRoomHandler(availabilityItem.rooms,rateItem)}}
              selectedRooms={selectedRooms}
            />
          </React.Fragment>)}
      </React.Fragment>
      );
    } else {
      roomLayout = (
        <div className="box-border card-padding bg-white text-center">
          <div className="margin-bottom">
            <span className="itours-icon error-icon icon-xlarge" />
          </div>
          <div className="text-center font-18">
            <div className="margin-bottom">
              <strong>{Dictionary.SorryNoRoomFoundRightNow}!</strong>
            </div>
            <p>{Dictionary.pleaseTryAnotherHotel}</p>
          </div>
        </div>
      );
    }


    let modifiedImages;
    if (hotelDetails?.images?.length){
      modifiedImages = hotelDetails.images.filter(item => item.sizes[0]?.path).map(item => ({
        large: item.sizes[1]?.path || item.sizes[0]?.path,
        thumbnail: item.sizes[0]?.path
      }))
    }


    return (
        <div className="page-container no-padding-mobile">
          {this.state.loading  ? 
            <Loading fullPage description={Dictionary.getHotelInformation+" ..."}/>
          : (
            hotelDetails && 
            <div className="hotel-details">
              <div className="padding-top">
                <SafarmarketWelcome />
              </div>
                <StickySides
                  main={(
                  <>
                    <div className="relative sm-gallery-wrapper">
                      {hotelDetails.images.length >= 1 ? (<div dir="ltr">
                        <ImageGallery images={modifiedImages} onePhoto />
                      </div>
                      ) : hotelDetails.mainPhoto ? (
                        <img
                          src={hotelDetails.mainPhoto}
                          alt={hotelDetails.name}
                          className="full-width hotel-main-1photo"
                        />
                      ) : (
                        <div className="card hotel-no-picture box-border text-center body-direction">
                          <div className="no-image-hint ">
                            <div className="margin-bottom">
                              {Dictionary.noImageHeadingText}
                            </div>
                            <small>{Dictionary.noImageDetailText}</small>
                          </div>
                        </div>
                      )}
                      <div className="night-on-gallery hidden-small">
                        <span className="itours-icon moon-white icon-small margin-end-light" />
                        {nights} شب
                      </div>
                      <div className="text-on-gallery">
                        <Rating number={hotelDetails.rating} />
                        <h3 className="bold font-22 margin-top en-font">{hotelDetails.name}</h3>
                      </div>
                    </div>
                    <div className="room-select-card" id="roomSelectSection" ref={this.roomsHolder} >
                      <div id="roomSelect" className="select-room-wrapper padding-top">
                        {roomLayout}
                      </div>
                    </div>
                  </>
                  )}
                  aside={(
                    <div className="bg-white card-border card-padding rounded-medium">
                      <div className="sm-check-in-out-wrapper">
                        <div>
                          <strong className="margin-end-light">
                            <span className="itours-icon calendar-dark icon-small margin-end-light font-13" />
                            تاریخ ورود و خروج:
                          </strong>
                          <span className="no-wrap">
                            {moment(checkinMoment).format("jYYYY/jMM/jDD")} - {moment(checkoutMoment).format("jYYYY/jMM/jDD")}
                          </span>
                        </div>
                        <div className="pipe hidden-medium hidden-large" />
                        <strong className="hidden-medium hidden-large">
                          <span className="itours-icon moon-dark icon-small margin-end-light" />
                          {nights} شب
                        </strong>
                      </div>

                      <div className="sm-fix-bottom-mobile">
                        {selectedRooms ? <>
                          <div className="text-center hidden-medium hidden-large">
                            <button type="button" className="button link-style-btn margin-bottom" onClick={() => { this.setState(prevState => { return { showSelectedRoomDetails: !prevState.showSelectedRoomDetails } }) }}>
                              {this.state.showSelectedRoomDetails ? "بستن" : "مشاهده"} جزییات
                              <span className={`css-icon down-caret-css-icon margin-start-light ${this.state.showSelectedRoomDetails ? "" : "reverse"}`} />
                            </button>
                          </div>
                          <div className={`mobile-accardion ${this.state.showSelectedRoomDetails ? "show" : ""}`}>
                            <div className="selected-room-details font-12 sm-font-14">

                              {selectedRooms.rooms.map((room, roomIndex) => <React.Fragment key={roomIndex}>
                                <div className="flex-ends margin-bottom-half">
                                  <strong className="font-14 sm-font-17 semi-bold">{room.name}</strong>
                                  {selectedRooms.rooms.length === 1 && <span className="font-12 no-wrap no-select pointer" onClick={() => { this.setState({ selectedRooms: undefined, showSelectedRoomDetails: false }) }}>
                                    حذف این اتاق <span className="itours-icon icon-small trash" />
                                  </span>}
                                </div>
                                <div className="flex-ends middle margin-bottom-half">
                                  <div>
                                    <span className="margin-end-light">ظرفیت اتاق: </span>
                                  </div>
                                  {this.passengers(room.adults, room.children)}
                                </div>
                              </React.Fragment>)}

                              {selectedRooms.rooms.length === 1 && <div className="flex-ends middle">
                                <div>قیمت اتاق: </div>
                                <Price currency={this.props.currencyCode}>
                                  {selectedRooms.rate.salePrice}
                                </Price>
                              </div>}
                            </div>
                          </div>
                          <div className="selected-room-footer">
                            <div>
                              <div className="inline-medium margin-end-half font-12 sm-font-14 xs-margin-bottom-half">
                                مبلغ کل:
                              </div>
                              {selectedRooms.rate.regularPrice !== selectedRooms.rate.salePrice ? (
                                <div className="old-price inline-medium font-16 sm-font-20">
                                  <Price currency={this.props.currencyCode}>
                                    {selectedRooms.rate.regularPrice}
                                  </Price>
                                </div>
                              ) : null}
                              <b className="new-price inline-medium font-16 sm-font-20">
                                <Price currency={this.props.currencyCode}>
                                  {selectedRooms.rate.salePrice}
                                </Price>
                              </b>
                            </div>
                            <button className="button red-btn" type='buttom' onClick={() => this.reserveHandler(selectedRooms.rate.bookingToken)}>
                              رزرو اتاق
                              {this.state.reserveLoading && <span className="loading-inline-circle margin-start-light" />}
                            </button>
                          </div>
                        </>
                          :
                          <div className="sm-reserve-room-block">
                            <p className="message">اتاقی انتخاب نشده است</p>
                            <button type="submit" disabled className="button color-1-btn" >رزرو اتاق</button>
                          </div>
                        }
                      </div>
                    </div>
                  )}
                />
                <SearchFormRoom safarmarketLayout />
            </div>
          )}
        </div>
    );
  }
}
const dispatchMapProp = (dispatch) => ({
  setHotelDetails: hotelData => {
    dispatch(setHotelDetails(hotelData));
  },
  showError: (param) => {
    dispatch(showError(param));
  },
  setCancelationRoom: (param) => {
    dispatch(setCancelationRoom(param));
  },
  setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketHotelPixel(data)),
  setSafarmarketLayout : data => dispatch (changeSafarmarketLayout(data))
});
const stateMapProp = (state) => ({
  hotelDetails: state.HotelSearch.selectedHotel,
  error: state.VisualData.error,
  currencyCode: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(stateMapProp, dispatchMapProp)(withRouter(HotelDetailSafarmarket));
