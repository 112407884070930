// import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Price from "../../../components/Common/Price";
import LayoutConfig from "../../../Config/layoutConfig";
import defaultRoomImage from "../../../image/default-hotel-image.svg";
import defaultRoomImageStyle2 from "../../../image/hotel-vector.svg";
import HotelBoardPolicy from "./HotelBoardPolicy";
import HotelBookButton from "./HotelBookButton";

HotelMultipleRoom.propTypes = {
  rooms: PropTypes.array.isRequired,
  rates: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

function HotelMultipleRoom(props) {
  const { Dictionary } = props;
  return (
    <div className={"card box-border card-padding margin-bottom bg-white"}>
      <div className={"multy-rooms-titles"}>
        {props.rooms.map((room, index) => {
          return (
            <div key={index} className="items">
              <strong>
                {props.rooms.indexOf(room) + 1}. {room.name}
              </strong>
              <div>
                <div className="inline-block">
                  <img
                    src={defaultRoomImage}
                    className="room-image"
                    alt="room"
                  />
                </div>
                <div className="inline-block">
                  <span className="inline-block margin-end-light">
                    {room.adults}
                  </span>
                  <span className="inline-block margin-end-light">
                    {room.adults > 1 ? Dictionary.adults : Dictionary.adults}
                  </span>
                  {room.children > 0 ? (
                    <>
                      <span className="inline-block margin-end-light">
                        {Dictionary.and}
                      </span>
                      <span className="inline-block margin-end-light">
                        {room.children}
                      </span>
                      <span className="inline-block margin-end-light">
                        {room.children > 1
                          ? Dictionary.children
                          : Dictionary.child}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <table className="hotel-room-table">
        <thead>
          <tr>
            <th>{Dictionary.SelectAnOptionForTheseRooms}</th>
            <th className="hidden-xsmall">
              {Dictionary.totalFor} {props.rooms.length} {Dictionary.roomS}
            </th>
            <th></th>
          </tr>
        </thead>
        {props.rates.map((rate, index) => {
          return (
            <tbody key={index} className="option-item">
              <tr>
                <td>
                  <HotelBoardPolicy
                    rate={rate}
                    date={props.date}
                    rooms={props.rooms}
                    location={props.location}
                    currency={props.currency}
                  />
                  <div className="rooms-capacity">
                    <span className="inline-block margin-end-light">
                      {props.rooms.length}
                    </span>
                    <span className="inline-block margin-end-light">
                      {Dictionary.roomS}:
                    </span>
                    <span className="inline-block margin-end-light">
                      {rate.totalAdults}
                    </span>
                    <span className="inline-block margin-end-light">
                      {rate.totalAdults > 1
                        ? Dictionary.adults
                        : Dictionary.adult}
                    </span>
                    {rate.totalChildren > 0 ? (
                      <>
                        <span className="inline-block margin-end-light">
                          {Dictionary.and}
                        </span>
                        <span className="inline-block margin-end-light">
                          {rate.totalChildren}
                        </span>
                        <span className="inline-block margin-end-light">
                          {rate.totalChildren > 1
                            ? Dictionary.children
                            : Dictionary.child}
                        </span>
                      </>
                    ) : null}
                  </div>
                </td>
                <td>
                  <b className="new-price">
                    <Price currency={props.currency}>{rate.salePrice}</Price>
                  </b>
                </td>
                <td className="select-room-td">
                  <HotelBookButton
                    selectedRoom={props.selectedRoom}
                    className="button red-btn full-width"
                    bookingToken={rate.bookingToken}
                    onClick={props.onSelectRoom}
                  >
                    {Dictionary.book} {props.rooms.length} {Dictionary.roomS}
                  </HotelBookButton>
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
      <div></div>
    </div>
  );
}
const stateMapProp = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(stateMapProp)(HotelMultipleRoom);
