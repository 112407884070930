import { useState } from "react";
import { Tooltip } from "antd";
import { connect } from "react-redux";

import QuantityInput from "../Common/QuantityInput";
import Price from "../Common/Price";

const DomesticHotelDetailRateItem = props => {

  const { Dictionary } = props;
  const [count, setCount] = useState(1);

  const { rate, selectedRoomToken } = props;

  const prices = {
    roomPrice: rate.pricing.find((item) => item.type === 'Room' && item.ageCategoryType === 'ADL')?.amount,
    boardPrice: rate.pricing.find((item) => item.type === 'RoomBoard' && item.ageCategoryType === 'ADL')?.amount,
    extraBedPrice: rate.pricing.find((item) => item.type === 'ExtraBed' && item.ageCategoryType === 'ADL')?.amount,
    childPrice: rate.pricing.find((item) => item.type === 'Room' && item.ageCategoryType === 'CHD')?.amount,
  };

  // const nightly = [];
  // for (const [key, value] of Object.entries(rate.nightly)) {
  //   nightly.push({
  //     date: key,
  //     amount: count * value.amount,
  //     board: value.board ? count * value.board : 0,
  //   });
  // }
  let boardIcon = null;
  switch (rate.board.code) {
    case 'HB':
      boardIcon = <span className="itours-icon dark-grey-spoon margin-end-half" />;
      break;
    case 'FB':
      boardIcon = <span className="itours-icon dark-grey-food margin-end-halfl" />;
      break;
    case 'BB':
      boardIcon = <span className="itours-icon dark-grey-cup margin-end-halfll" />;
      break;
    default:
      boardIcon = null;
  }

  let cancellationElement = null;
  if(rate.cancellationPolicy){
    switch (rate.cancellationPolicy.status) {
  
      case "Refundable":
        cancellationElement = <span className="vertical-align-middle green">
          <span className={`green-checkmark itours-icon margin-end-light icon-small inline-block-middle margin-b-3`} />
          {Dictionary.refundable}
        </span>;
        break;
      case "NonRefundable":
        cancellationElement = <span className="vertical-align-middle"> {Dictionary.nonRefundable} </span>;
        break;
      default:
        cancellationElement = <span>{rate.cancellationPolicy.status}</span>;
    }
  }

  let bookRoomButtonText;
  if (prices.roomPrice && prices.roomPrice > 1000){
    if(rate.availablityType === 'Online'){
      bookRoomButtonText = Dictionary.OnlineBookRoom;
    }else{
      bookRoomButtonText = Dictionary.BookRoom
    }
  }else{
    bookRoomButtonText = "درخواست رزرو"
  }

  return (
    <>

      <tr>
        <td>
          <div className="hidden-small hidden-medium hidden-large">
            {Dictionary.WhatsIncluded}:
          </div>
          <div className="margin-bottom-light">
            {boardIcon}
            <Tooltip title={`${rate.board.code}${rate.board.description && ` --- ${rate.board.description}`}`}>
              <b>
                {rate.board.name} <span className="itours-icon info-icon icon-small" />
              </b>
            </Tooltip>
          </div>

          <div className="margin-bottom-half"> {cancellationElement} </div>

          {!!props.room?.capacity?.extraBed && (
            <div>
              <span className="vertical-align-middle itours-icon hotel-gray icon-small margin-end-light" />
              <span className="vertical-align-middle">{Dictionary.ExtraBed}</span>
            </div>
          )}

        </td>
        <td>

          <div className="hidden-small hidden-medium hidden-large">
            {Dictionary.NumberOfRooms}:
          </div>

          <div>
            <QuantityInput min={1} max={rate.available} onChange={setCount} small />
          </div>

        </td>

        <td className="no-select-highlight">

          <div className="hidden-small hidden-medium hidden-large">
            {Dictionary.totalForStay} ({props.duration} {Dictionary.nights}):
          </div>

          <div>
            {rate.availablityType === 'Completion' ? "--"
            :prices.roomPrice && prices.roomPrice > 1000 ? (
              <div>
                {prices.boardPrice && prices.roomPrice < prices.boardPrice && (
                  <div className="old-price">
                    <Price currency={props.currency}>
                      {count * prices.boardPrice}
                    </Price>
                  </div>
                )}

                <b className="new-price">
                  <Price currency={props.currency}>
                    {count * prices.roomPrice}
                  </Price>
                </b>

                {!!prices.extraBedPrice && (
                  <div>
                    <span className="margin-end-light">{Dictionary.ExtraBedPrice}:</span>

                    <Price currency={props.currency}>
                      {prices.extraBedPrice}
                    </Price>

                  </div>
                )}

              </div>
            ) : (
              <div className="margin-bottom-light purple">قیمت نیازمند استعلام است</div>
            )}
          </div>

        </td>

        <td>
          {rate.availablityType === 'Completion' ?( 
            <p className="purple mb-0 mt-2">{Dictionary.ThisRoomIsFull}</p>
          ):(
            <button
            className={`button blue-btn full-width ${(selectedRoomToken && selectedRoomToken === rate.bookingToken) ? "loading-btn" : "" }`}
            type='buttom'
            disabled={selectedRoomToken || false}
            onClick={() => { props.selectRoomHandle(rate.bookingToken, count); }}
          >
            {bookRoomButtonText}
            {!!selectedRoomToken && selectedRoomToken === rate.bookingToken ? <span className="loading-inline-circle margin-start-light" /> : null}
          </button>
          )}
        </td>
      </tr>

      {rate.description && <tr>
        <td colSpan={4}>{rate.description} <span className="itours-icon info-icon icon-small" /> </td>
      </tr>}

    </>
  )
}

const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  Dictionary: state.VisualData.Dictionary,
  currency: state.UserData.Currency,
});

export default connect(mapStateToProps)(DomesticHotelDetailRateItem);