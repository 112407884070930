import {Flight, FlightFilter} from '../Enum/Models';
import { calculateMinutes } from '../helpers/helpers';

import _ from "lodash";
export const FlightSearch=(state=new Flight(),action)=>{
  switch (action.type) {
      case "SET_AVAILABLE_FILTERS":
          let flightFilter = new FlightFilter();

          const flights = [...action.flights];

          let durationRange = [];
          let stopTimeRange = [];

          flights.forEach(item => {
              const cabinClass = item.airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass.name;
              const airline = item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.name;

              const flightAllSegmentsBaggage = item.airItinerary.originDestinationOptions.flatMap(originDestinationItem => originDestinationItem.flightSegments).map(segment => {
                const segmentAdultBaggage = segment.baggageInformation.find(baggage => baggage.passengerType === "ADT");
                return segmentAdultBaggage;
              });
              for (const baggageItem of flightAllSegmentsBaggage){
                const baggageItemTitle = baggageItem.baggageAllowance ? baggageItem.baggageAllowance +"_"+ baggageItem.unitType : 0;
                if (!flightFilter.baggageDetail.includes(baggageItemTitle)){
                    flightFilter.baggageDetail.push(baggageItemTitle);
                }
              }

              const flightType = item.flightType;

              if (!flightFilter.cabinClass.includes(cabinClass)) {
                  flightFilter.cabinClass.push(cabinClass);
              }
              if (!flightFilter.airline.includes(airline)) {
                  flightFilter.airline.push(airline);
              }
              //add systemic or charter filter:
              if (!flightFilter.flightType.includes(flightType)) {
                  flightFilter.flightType.push(flightType);
              }

              const duration = item.airItinerary.originDestinationOptions.reduce(
                (totalDuration,currentItem) => totalDuration + calculateMinutes(currentItem.journeyDuration),
                0
              );

              const stopTime = item.airItinerary.originDestinationOptions.flatMap(originDestinationItem => originDestinationItem.flightSegments).reduce(
                (totalDuration,currentItem) => totalDuration + calculateMinutes(currentItem.stopTime),
                0  
              );

              if (durationRange.length){
                if (duration > durationRange[1]){
                    durationRange[1] = duration
                }                
                if (duration < durationRange[0]){
                    durationRange[0] = duration
                }
              }else{
                durationRange = [duration,duration];
              }

              if (stopTimeRange.length){
                if (stopTime > stopTimeRange[1]){
                    stopTimeRange[1] = stopTime
                }                
                if (stopTime < stopTimeRange[0]){
                    stopTimeRange[0] = stopTime
                }
              }else{
                stopTimeRange = [stopTime,stopTime];
              }

          });


        flightFilter.duration = durationRange;
        flightFilter.stopTime = stopTimeRange;

          const createAirlinePriceFilter = flightsList => {
              return flightsList.reduce((airlinePrices, flight) => {

                  const { originDestinationOptions } = flight.airItinerary;
                  const flightAirline = originDestinationOptions[0].flightSegments[0].marketingAirline;
                  const flightSegments = originDestinationOptions.flatMap(originDestinationOption => originDestinationOption.flightSegments.map(segmentItem => segmentItem));
                  const isDirect = originDestinationOptions.length === flightSegments.length;
                  const updatingAirlinePriceItem = airlinePrices.find(airlinePriceItem => airlinePriceItem.airline.code === flightAirline.code);

                  if (updatingAirlinePriceItem) {
                      const otherAirlinePrices = airlinePrices.filter(airlinePriceItem => airlinePriceItem.airline.code !== flightAirline.code);
                      if (isDirect) {
                          if (updatingAirlinePriceItem.directPrice && updatingAirlinePriceItem.directPrice < flight.airItineraryPricingInfo.itinTotalFare.totalFare) {
                              return airlinePrices;
                          } else {
                              return ([
                                  ...otherAirlinePrices,
                                  {
                                      ...updatingAirlinePriceItem,
                                      directPrice: flight.airItineraryPricingInfo.itinTotalFare.totalFare
                                  }
                              ])
                          }
                      } else {
                          if (updatingAirlinePriceItem.withStopPrice && updatingAirlinePriceItem.withStopPrice < flight.airItineraryPricingInfo.itinTotalFare.totalFare) {
                              return airlinePrices;
                          } else {
                              return ([
                                  ...otherAirlinePrices,
                                  {
                                      ...updatingAirlinePriceItem,
                                      withStopPrice: flight.airItineraryPricingInfo.itinTotalFare.totalFare
                                  }
                              ])
                          }
                      }
                  } else {
                      if (isDirect) {
                          return ([
                              ...airlinePrices,
                              {
                                  airline: flightAirline,
                                  directPrice: flight.airItineraryPricingInfo.itinTotalFare.totalFare
                              }
                          ]);
                      } else {
                          return ([
                              ...airlinePrices,
                              {
                                  airline: flightAirline,
                                  withStopPrice: flight.airItineraryPricingInfo.itinTotalFare.totalFare
                              }
                          ]);
                      }
                  }
              }, []);
          }

          const airlinePrice = createAirlinePriceFilter(flights);
          const ethnicAirlinePrice = createAirlinePriceFilter(flights.filter(item => item.isEthnic));
          const notEthnicAirlinePrice = createAirlinePriceFilter(flights.filter(item => !item.isEthnic));

          flightFilter.airlinePrice = airlinePrice;
          flightFilter.ethnicAirlinePrice = ethnicAirlinePrice;
          flightFilter.notEthnicAirlinePrice = notEthnicAirlinePrice;

          return {
              ...state,
              filterShow: flightFilter
          };

      case "resetFilters":
        return {
            ...state,
            filterSelect:new FlightFilter()
        }

      case "SET_FILTER":
        return {
            ...state,
            filterSelect:{
                ...state.filterSelect,
                [action.data.filterType]: action.data.value
            }
        }

      case "setFlightData":
          return{
              ...state,
              flightSelected:action.result
          }
      case "setReserveID":
          return{
              ...state,
              reserveId:action.id
          }
      case "FLIGHTERROR":
          return {
              ...state,
              flightError:action.result
          }
      case "FLIGHTRESERVE":
          
          return{
            ...state,
            reserveFlight:action.result  
          }
      case "naminTravelSelectedFlight":
          return{
            ...state,
            naminTravelSelectedFlight:action.data  
          }
      case "safarmarketPixel":
        return{
            ...state,
            safarmarketPixel:action.data  
          }
      case "directTicketByIdResult":
          return{
            ...state,
            directTicketByIdResult:action.data   
          }
      case "getDirectTicketByIdLoading":
          return{
              ...state,
              getDirectTicketByIdLoading:action.data
          }
          
      default:
          return state;
  }
};