import Warranty from '../../image/style3/icons/Warranty.png';
import FlightHotel from '../../image/style3/icons/FlightHotel.png';
import Support from '../../image/style3/icons/Support.png';

const Style3Home3Badge = () => {
    return(
        <section className='three-large-icon-section'>
        <div className='page-container'>
            <div className='three-large-icon-section-inner'>
                <div className='icon-item text-center'>
                    <img src={FlightHotel} alt="flight and hotel search" className='three-large-icon-item' />
                    <h3 className='item-title'>جستجوی همزمان پرواز و هتل</h3>
                </div>
                <div className='icon-item text-center'>
                    <img src={Support} alt="تیم پشتیبانی حرفه ای" className='three-large-icon-item' />
                    <h3 className='item-title'>تیم پشتیبانی حرفه ای</h3>
                </div>
                <div className='icon-item text-center'>
                    <img src={Warranty} alt="تضمین مناسب ترین قیمت" className='three-large-icon-item' />
                    <h3 className='item-title'>تضمین مناسب ترین قیمت</h3>
                </div>
            </div>
        </div>
        </section>
    )
}

export default Style3Home3Badge;