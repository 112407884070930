import React, { useRef, useEffect, useState } from "react";
import queryString from "query-string";
import _ from "lodash";
import axios from "axios";
import { connect } from "react-redux";

import ListItem from "../../components/Common/ListItem";
import Page from "../../components/Common/Page";
import {resetFilter, setHotelAvailableFilters} from "../../Actions/HotelAction";
import {
  Header,
  Hotel as HotelURL,
  ServerAddress,
} from "../../Enum/Urls";
import { showError } from "../../Actions/VisualDataAction";
import HotelForm from "../../components/Accomodation/SearchForm";
import { Travel, Sort as SortTypes ,Filter} from "../../Enum/Models";
import Currency from "../../Enum/Currency";
import HotelFilter from "../../components/Accomodation/FilterForm";
import Loading from "../../components/Common/Loading";
import ShowMap from "../../components/Accomodation/showMap";
import LayoutConfig from "../../Config/layoutConfig";
import HotelFormSearchTOF from "../../components/TOF/Hotel-Form-Search-TOF";
import Sort from "../../components/Accomodation/Sort";
import { Skeleton } from "antd";
import FetchProgress from "../../components/Common/FetchProgress";

const HotelResult = props => {

  const [searchResult,setSearchResult] = useState();
  const [loading,setLoading] = useState(true);
  const [pagination,setPagination] = useState([0,9]);
  const [filterIsVisible,setFilterVisible] = useState(false);
  const [showFetchProgressBar,setShowFetchProgressBar] = useState(false);

  const [availibilityKey,setAvailibilityKey] = useState();
  const [isCompleted,setIsCompleted] = useState(false);

  const [sort,setSort] = useState({
    name:SortTypes.price,
    reverse:false
  });
  const [mapView,setMapView] = useState(false);

  const listHolderRef = useRef();

  const changeSort = type => {
    setSort(prevState => ({
      name:type,
      reverse:prevState.name === type ? !prevState.reverse : false 
    }));
  }

  const resetSearchStates = () => {
    setIsCompleted(false);
    setLoading(true);
    setSearchResult();
    
    setShowFetchProgressBar(false);
    setTimeout(() => {
      setShowFetchProgressBar(true);
      //document.getElementsByTagName("body")[0]?.scrollIntoView({ behavior: "smooth" });  
    }, 100);

    if (props.resetFilter){
      props.resetFilter();
    }
  }

  const getKey = async (params) => {

    try {
      const response = await axios.post(`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.Availability}`,
      params,
      {headers: {...Header}}
      );

      if (response) {
        setAvailibilityKey(response.data?.result);
      }

    } catch (error) {
      
      props.showError({
        visibility: true,
        content: error.response?.data?.error?.message || "Internal Error,Please refresh the page",
      });

    }

  }

  useEffect(() => {

    let fetchInterval = null;

    if (availibilityKey) {

        const fetchData = async () => {

          const response = await axios({
            method: "get",
            url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAvailability}`,
            params: {key : availibilityKey},
            headers: { ...Header },
          });
          if (response){

            setLoading(false)

            setSearchResult(response.data?.result);
            changePage(0,9);
              
              if (response.data.result?.hotels?.length) {
                const filter = new Filter();
                response.data.result.hotels.forEach(hotelItem => {
      
                  if (filter.rating.indexOf(hotelItem.rating) === -1) {
                    filter.rating.push(hotelItem.rating);
                  }
      
                  if (hotelItem.features) {
                    hotelItem.features.forEach(featureItem => {
                      if (!(filter.features.find(item => item.keyword === featureItem.keyword))) {
                        filter.features.push(featureItem);
                      }
                    });
                  }
      
                  if (hotelItem.boards) {
                    hotelItem.boards.forEach(boardItem => {
                      if (!(filter.boards.find(item => item.code === boardItem.code))) {
                        filter.boards.push(boardItem);
                      }
                    });
                  }
      
                  if (hotelItem.regions !== null) {
                    hotelItem.regions.forEach(regionitem => {
                      if (!(filter.regions.find(item => item.id === regionitem.id))) {
                        filter.regions.push(regionitem);
                      }
                    });
                  }

                  if (hotelItem.city !== null) {
                    if (!(filter.cities.find(item => item.id === hotelItem.city.id))) {
                      filter.cities.push(hotelItem.city);
                    }
                  }
      
                });
                props.setHotelAvailableFilters(filter);
              }   

            if(response?.data?.result?.isCompleted){  
              setIsCompleted(true);
              // scrollTolist();
              clearInterval(fetchInterval);
            }

          }
        }

        fetchData();

        fetchInterval = setInterval(() => {

            fetchData();

        }, 3000);
    }

    return () => {
        clearInterval(fetchInterval);
    };

}, [availibilityKey]);

  const scrollTolist = () => { 
    setTimeout(()=>{
      if(listHolderRef ){ listHolderRef.current?.scrollIntoView({behavior: "smooth"})}
    },50)
  };

  const changePage = (first, last) => {
    setPagination([first, last]);
    scrollTolist();
  }

  useEffect (()=>{
    if(props.IPLocation){
      
      let parameters = queryString.parse(props.location.search);
      
      const adults =  parameters.adults.split(",").map(a => +a);
      const children =  parameters.children.split(",").map(a => +a);
      const ages =  parameters.ages.split(",").map(a => +a);

      const params = {
        locationId: parameters.locationId,
        adults: adults,
        children: children,
        ages: ages,
        checkIn: parameters.checkin,
        checkOut: parameters.checkout,
        nationalityCode: (props.ipLocation!== "undefined" && props.IPLocation) ||  Currency.getNationality() 
      }

      getKey(params);
      resetSearchStates();

    }

  },[props.location.search,props.IPLocation]);


  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0, 0);
    },100)
  },[])

  const { Dictionary,selectedFilters } = props;

  const hotels = searchResult?.hotels;
  const duration = searchResult?.date?.duration;

  let sortedFilteredHotels;

  if (hotels && selectedFilters){
    const filteredHotels = hotels.filter( hotelItem => {

      if(selectedFilters.name && !(hotelItem.name.toLowerCase().includes(selectedFilters.name.toLowerCase()))){
        return false;
      }
              
      if(selectedFilters.rating?.length && !(selectedFilters.rating.includes(hotelItem.rating))){
        return false;
      }

      const hotelItemBoardsCode = hotelItem.boards.map(board => board.code);
      if (selectedFilters.boards?.length && selectedFilters.boards.every(checkedBoardItem => !hotelItemBoardsCode.includes(checkedBoardItem))) {
        return false;
      }
      
      const hotelItemRegionsCode = hotelItem.regions.map(region => region.id);
      if (selectedFilters.regions?.length && selectedFilters.regions.every(checkedRegionItem => !hotelItemRegionsCode.includes(checkedRegionItem))) {
        return false;
      }

      const hotelItemCityId = hotelItem.city?.id;
      if (selectedFilters.cities?.length && hotelItemCityId && !selectedFilters.cities.includes(hotelItemCityId)) {
        return false;
      }

      const hotelItemFeatures = hotelItem.features.map(feature => feature.keyword);
      if (selectedFilters.features?.length && selectedFilters.features.some(checkedFacilityItem => !hotelItemFeatures.includes(checkedFacilityItem))) {
        return false;
      }

      return true

    });

    const filteredHotelsSortedByPrice = _.sortBy(filteredHotels,Sort.price);
    sortedFilteredHotels = _.orderBy(filteredHotelsSortedByPrice, sort.name , sort.reverse?'desc':'asc');

  }


  let loadingDescription = Dictionary.SearchingBestPrices + " ...";

  if (process.env.REACT_APP_THEME === 'NAMINTRAVELCOM'){
    loadingDescription = "We look at the price, travel time, number of stops, and baggage allowance to determine which options you might like best."
  }
  
  const isStyle3 = LayoutConfig.themeClassName === 'style-3';

  const totalProperties = searchResult?.totalProperties;
  const fetchedProperties = searchResult?.hotels?.length;

  let fetchPercentage = 0;
  if(totalProperties && fetchedProperties){
    fetchPercentage =  Math.floor(fetchedProperties / totalProperties * 100)+30 ;
  }

  return (
    <div className="padding-bottom-large search-result-page">
      {(LayoutConfig.themeClassName === "padide-com-theme") && (
        <div className="result-inline-search-section">
          <div className="page-container">
            <HotelFormSearchTOF inline={true} />
          </div>
        </div>
      )}

      {!!(isStyle3 && showFetchProgressBar) && <FetchProgress compeleted={!loading} />}
      
      <div className={LayoutConfig.themeClassName === "style-2"?"":"section-vertical-padding"}>
        
        <div className="hotelResult relative">
          
          {loading || (!isCompleted && !hotels?.length) ? (
            <>
              {isStyle3 ? (
                <div className="page-container margin-bottom-large">

                  <Skeleton active paragraph={{ rows: 0 }} title={{ width: "150px" }} className="one-line-skeleton " />
                  <hr className="margin-top margin-bottom" />

                  <div className="float-row padding-top">

                    <div className="col-small-12 col-medium-3 margin-bottom hidden-xsmall hidden-small">
                      <div className="box-border bg-white">
                        <div className="sidebar-map-skeleton-wrapper">
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton" />
                        </div>
                        <div className="card-padding">
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                          <hr className="margin-top" />

                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom margin-top" />
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton margin-bottom" />

                          <hr className="margin-top" />

                          <Skeleton active />
                          <hr className="margin-top" />
                          <Skeleton active />
                          <hr className="margin-top" />
                          <Skeleton active />
                          <hr className="margin-top" />
                          <Skeleton active />
                        </div>
                      </div>
                    </div>

                    <div className="col-small-12 col-medium-9 margin-bottom">

                      <div className="box-border margin-bottom">
                        <div className="card-padding justify-between">
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                        </div>
                        <div className="bg-white padding-h-5 justify-between">
                          {[1, 2, 3, 4, 5].map(item => (
                            <div key={item} className="padding-h-5 padding-v-5 grow">
                              <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton" />
                            </div>
                          ))}
                        </div>
                      </div>

                      <Skeleton active paragraph={{ rows: 0 }} title={{ width: "100px" }} className="one-line-skeleton margin-bottom" />

                      <div className="justify-between margin-bottom">
                        <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton text-end" />
                      </div>

                      {[1, 2, 3, 4].map(item => (
                        <div key={item} className="box-border margin-bottom hotel-item-skeleton">
                          <div className="image" />

                          <div className="card-padding large">
                            <Skeleton active paragraph={{ rows: 3 }} className="origin" />
                          </div>

                          <div className="card-padding">
                            <Skeleton active paragraph={{ rows: 2 }} className="origin text-end" />
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton" />
                          </div>
                        </div>
                      ))}



                    </div>
                  </div>
                </div>
              ) : (
                <Loading fullPage description={loadingDescription} />
              )}
            </>
          ) : hotels ? (
            <>
              {LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "style-2" || (
                <div className="page-container">
                  <h2 className="page-title">{Dictionary.selectHotel}</h2>
                </div>
              )}

              <div className="page-container no-padding-mobile">
                <div className="avails-row float-row">
                  <div className="col-small-12 col-medium-3">
                    <div className="sidebar">
                      <div className="map-btn-holder">
                        {!!sortedFilteredHotels.length && (
                          <button
                            className="map-view-btn"
                            type="button"
                            onClick={()=>{setMapView(prevState => !prevState)}}
                          >
                            {mapView ? Dictionary.listView : Dictionary.viewMap}
                          </button>
                        )}
                      </div>
                      <div className={`box-border bg-white filters-holder ${filterIsVisible? "showfilter": ""}`} >
                        <HotelFilter />
                        <button
                          type="button"
                          className="button red-btn filter-close-btn"
                          onClick={() => {setFilterVisible(prevState => !prevState)}}
                        >
                          {Dictionary.close}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-small-12 col-medium-9">
                    {LayoutConfig.themeClassName === "padide-com-theme" || (
                      <div className="result-page-search-bar hidden-xsmall hidden-small">
                        <div className="search-form-wrapper">
                          <HotelForm
                            collapseMode={true}
                            fixedSearchHolder={true}
                          />
                        </div>
                      </div>
                    )}
                    <div ref={listHolderRef}>
                      {sortedFilteredHotels.length ? (
                        <div className="avails-holder" >

                          <div className={`${isCompleted ? "":"bg-green-skeleton-wrapper" }`}>
                            <div className={`${isCompleted ?"":"bg-green-skeleton"}`} style={{width:`${fetchPercentage}%`}} />
                            <strong className="relative semi-bold">{sortedFilteredHotels.length}</strong> <span className="relative semi-bold"> {Dictionary.propertiesFound}. </span>

                            {!isCompleted && (<>
                              <span className="relative"> ( {Dictionary.searchingForMoreHotels} ) </span>  <span className="relative loading-inline-circle margin-start-light" />
                            </>)}

                          </div>

                          <Sort sort={sort} changeSort={changeSort} />
                          
                          {mapView ? (
                            <div className="showMap">
                              <div>
                                <ShowMap
                                  currency={props.currency}
                                  data={sortedFilteredHotels}
                                  height={700}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="showResult">
                              <ListItem
                                last={pagination[1]}
                                type={Travel.hotel}
                                data={sortedFilteredHotels}
                                start={pagination[0]}
                                duration={duration}
                                notCompeleted={!isCompleted}
                              />
                              {sortedFilteredHotels.length < 10 ? null : (
                                <Page
                                  length={sortedFilteredHotels.length}
                                  itemCurrent={pagination[0]}
                                  itemPerPage={10}
                                  onChangeValue={changePage}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="section-vertical-padding text-center">
                          <div>
                            <span className="itours-icon icon-xlarge error-icon" />
                          </div>
                          <h5 className="normal-title">
                            {Dictionary.NoHotelsFoundForYourRequest}.
                            <div className="page-subtitle">
                              {Dictionary.pleaseTryAgain}.
                            </div>
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="filter-responsive-btn"
                    onClick={() => {setFilterVisible(prevState =>!prevState)}}
                  >
                    {Dictionary.filters}
                  </button>                    
                </div>
              </div>
            </>
          ) : (
            <div className="page-container">
              <div className="section-vertical-padding text-center">
                <div>
                  <span className="itours-icon icon-xlarge error-icon" />
                </div>
                <h5 className="normal-title">
                  {Dictionary.NoHotelsFoundForYourRequest}.
                  <div className="page-subtitle">
                    {Dictionary.pleaseTryAgain}.
                  </div>
                </h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

}
const mapStateToProps = (state) => ({
  city: state.HotelSearch.city,
  selectedFilters: state.HotelSearch.selectedFilters,
  currency: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  IPLocation: state.VisualData.ipLocation
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => {
    dispatch(showError(param));
  },
  setHotelAvailableFilters: (data) => {
    dispatch(setHotelAvailableFilters(data));
  },
  resetFilter : () => {dispatch(resetFilter());}
});
export default connect(mapStateToProps, mapDispatchToProps)(HotelResult);
