import { connect } from "react-redux";
import moment from "moment-jalaali";

import Validation from "../Common/Validation";
import DatePickerDrop from "../Common/datePickerDrop";
import { CountryCode } from "../../Enum/Models";
import { useEffect, useRef, useState } from "react";
import Loading from "../Common/Loading";
import TravellerItem from "../Common/TravellerItem";
import { deleteTraveller } from "../../Actions/TravellerAction";

const PassengerItem = props => {

    const { travellers, index, item, Dictionary, onChangeValidation, state, setPassengerNationality, showPersian, flightSelected, passengerInformation, passportExpMin } = props;

    const [travellersOpen, setTravellersOpen] = useState(false);
    const [selectedTraveller, setSelectedTraveller] = useState(undefined);

    const [birthDate, setBirthDate] = useState(undefined);
    const [passportExpDate, setPassportExpDate] = useState(undefined);

    const [birthDateMessage, setBirthDateMessage] = useState("");
    const [passExpDateMessage, setPassExpDateMessage] = useState("");

    const [deletingItemId, setDeletingItemId] = useState();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deletingConfirmMode, setDeletingConfirmMode] = useState(false);


    const [passengerNameFilter, setPassengerNameFilter] = useState("");

    const [pendingToOpenTravellers, setPendingToOpenTravellers] = useState(false);

    const genderRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const firstNamePersianRef = useRef(null);
    const lastNamePersianRef = useRef(null);
    const nationalityRef = useRef(null);
    const passportRef = useRef(null);
    const nationalCodeRef = useRef(null);

    const fillPassengerInformation = traveller => {

        setSelectedTraveller(traveller);
        let showMessage = false;

        if (firstNameRef?.current){
            if ( traveller.firstname) {
                firstNameRef.current.value = traveller.firstname;
            }else{
                firstNameRef.current.value = "";
            }
        }

        if (lastNameRef?.current) {
            if(traveller.lastname){
                lastNameRef.current.value = traveller.lastname;
            }else{
                lastNameRef.current.value = "";
            }
        }

        if (genderRef?.current) {
            genderRef.current.value = traveller.gender === false ? false : true;
        }

        if (showPersian) {
            if (firstNamePersianRef?.current ) {
                if(traveller.firstnamePersian){
                    firstNamePersianRef.current.value = traveller.firstnamePersian;
                }else{
                    firstNamePersianRef.current.value = "";
                }
            }
            if (lastNamePersianRef?.current ) {
                if(traveller.lastnamePersian){
                    lastNamePersianRef.current.value = traveller.lastnamePersian;
                }else{
                    lastNamePersianRef.current.value = "";
                }
            }
        }

        if (nationalityRef?.current) {
            if(traveller.nationality){
                nationalityRef.current.value = traveller.nationality;
            }else{
                nationalityRef.current.value = "";
            }
        }

        if (traveller.birthDate) {
            if (moment(traveller.birthDate).isAfter(item.birthDateRange.min) && moment(item.birthDateRange.max).isAfter(traveller.birthDate)) {
                setBirthDate(traveller.birthDate);
            } else {
                setBirthDate("");
                showMessage = true;
                const passengerType = item.type === "ADT" ? "بزرگسال" : item.type === "CHD" ? "کودک" : "نوزاد";
                setBirthDateMessage(`تاریخ تولد مسافر انتخاب شده (${moment(traveller.birthDate).format("YYYY/MM/DD")}) برای  ${passengerType} معتبر نیست `);
            }
        } else {
            setBirthDate("");
        }

        if (passportRef?.current) {
            if(traveller.passportNumber){
                passportRef.current.value = traveller.passportNumber;
            }else{
                passportRef.current.value = "";
            }
        }

        if (traveller.passportExpirationDate) {
            if (moment(traveller.passportExpirationDate).isAfter(passportExpMin)) {
                setPassportExpDate(traveller.passportExpirationDate);
            } else {
                setPassportExpDate("");
                showMessage = true;
                setPassExpDateMessage("مدت اعتبار پاسپورت مسافر انتخاب شده کافی نیست.");
            }
        } else {
            setPassportExpDate("");
        }

        if (nationalCodeRef?.current) {
            if(traveller.nationalId){
                nationalCodeRef.current.value = traveller.nationalId;
            }else{
                nationalCodeRef.current.value = "";
            }
        }

        if (!showMessage) {
            setTravellersOpen(false);
        }

    }
    useEffect(() => {
        setTimeout(() => {
            setBirthDateMessage("");
            setPassExpDateMessage("");
        }, 200);
    }, [travellersOpen]);

    useEffect(() => {
        if (props.Verification && pendingToOpenTravellers) {
            setTravellersOpen(true);
        }
    }, [props.Verification, pendingToOpenTravellers]);

    useEffect(()=>{
        if(selectedTraveller){
            setTimeout(()=>{
                if(flightSelected.pricedItinerary.isDomestic && passengerInformation[index].nationality && (passengerInformation[index].nationality === "IR")){
                    if (nationalCodeRef?.current && selectedTraveller.nationalId) {
                        nationalCodeRef.current.value = selectedTraveller.nationalId;
                    }
                }else{
                    if (passportRef?.current && selectedTraveller.passportNumber) {
                        passportRef.current.value = selectedTraveller.passportNumber;
                    }
            
                    if (selectedTraveller.passportExpirationDate) {

                        if (moment(selectedTraveller.passportExpirationDate).isAfter(passportExpMin)) {
                            setPassportExpDate(selectedTraveller.passportExpirationDate);
                        } else {
                            setPassportExpDate("");
                            setPassExpDateMessage("مدت اعتبار پاسپورت مسافر انتخاب شده کافی نیست.");
                        }
                        
                    } else {
                        setPassportExpDate("");
                    }
                }
            },50)
        }
    },[passengerInformation[index].nationality]);

    const filteredTravellers = travellers?.filter(traveller => {
        if (
            passengerNameFilter &&
            !traveller.firstname.toLowerCase().includes(passengerNameFilter.toLocaleLowerCase()) &&
            !traveller.lastname.toLowerCase().includes(passengerNameFilter.toLocaleLowerCase())
        ) {
            return false
        }
        return true;
    })

    const openDeleteConfirm = (id) => {
        setDeletingItemId(id);
        setDeletingConfirmMode(true);
    }

    const deleteItem = async () => {

        if (!deletingItemId || !props.refetchTravellersData) return;

        setDeleteLoading(true);
        const response = await deleteTraveller(deletingItemId);
        setDeleteLoading(false);
        if (response.data) {
            setDeletingItemId();
            setDeletingConfirmMode(false);
            props.refetchTravellersData();
        }
    }

    return (
        <div className={`${(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME === 'AYANAIRCOM' || index === 0) ? "" : "margin-top"} passenger-item`} >

            {!!(process.env.REACT_APP_THEME === "ITOURS_IR" && props.Verification) && <div className={`itours-modal ${travellersOpen ? "visible" : ""}`} >

                <div className="modal-overlay" onClick={() => { setTravellersOpen(false) }} />

                <div className={`modal-inner ${birthDateMessage || passExpDateMessage || (travellers && !travellers.length) ? "small-width" : ""}`}>
                    {(passExpDateMessage || birthDateMessage) ? (
                        <div className="padding-h-20 padding-v-20 text-center">

                            <br />

                            {!!birthDateMessage && <p className="margin-bottom purple"> {birthDateMessage} </p>}
                            {!!passExpDateMessage && <p className="margin-bottom purple"> {passExpDateMessage} </p>}

                            <button
                                type="button"
                                className="button blue-btn"
                                onClick={() => { setTravellersOpen(false); }}
                            >
                                بسیار خوب
                            </button>

                            <br />
                            <br />

                        </div>
                    ) : props.travellersLoading || deleteLoading ? (
                        <div className="padding-h-20 padding-v-20">
                            <Loading />
                        </div>
                    ) :deletingConfirmMode ? (
                        <div className="padding-h-20 padding-v-20 text-center">
                            <h5 className="normal-title">
                                آیا مطمئنید اطلاعات این مسافر پاک شود؟
                            </h5>
                            <br />
                            <div>

                                <button
                                    type="button"
                                    className="button blue-btn"
                                    onClick={() => { setDeletingConfirmMode(false); setDeletingItemId(); }}
                                >
                                    انصراف
                                </button>
                                <button
                                    type="button"
                                    className="button red-btn min-w-55 margin-start"
                                    onClick={deleteItem}
                                >
                                    حذف
                                </button>
                            </div>
                        </div>
                    ) : travellers?.length ? (
                        <div className="padding-h-20 padding-v-20">
                            <div className="clearfix padding-h-5">
                                <h5 className="pull-right font-16 md-font-18 semi-bold padding-h-10 padding-top-half no-margin-bottom"> لیست مسافران سابق </h5>
                                <span
                                    onClick={() => { setTravellersOpen(false) }}
                                    className="itours-icon gray-close-icon icon-large pull-left pointer"
                                />
                            </div>
                            <div className="modal-main">
                                <div className="margin-bottom">
                                    <input
                                        placeholder="جستجوی نام مسافر"
                                        className="form-input padding-h-15 filter-past-passengers"
                                        value={passengerNameFilter}
                                        onChange={e => { setPassengerNameFilter(e.target.value) }}
                                    />
                                </div>

                                {filteredTravellers?.length ? (
                                    <div className="itours-table-holder responsive-table no-scroll-responsive-table">
                                        <table className="itours-table text-center">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" > نام و نام خانوادگی</th>
                                                    <th className="text-center" > جنسیت</th>
                                                    <th className="text-center" > کد ملی / شماره پاسپورت </th>
                                                    <th className="text-center" > تاریخ انقضای پاسپورت </th>
                                                    <th className="text-center" > تاریخ تولد </th>
                                                    <th className="text-center" ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredTravellers.map((traveller, travellerIndex) => (
                                                    <TravellerItem
                                                        key={travellerIndex}
                                                        traveller={traveller}
                                                        fillPassengerInformation={fillPassengerInformation}
                                                        openDeleteConfirm={openDeleteConfirm}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className=" text-center">
                                        <br />
                                        <div className="margin-bottom">
                                            مسافری با نام مورد نظر شما یافت نشد.
                                        </div>
                                        <button
                                            type="button"
                                            className="button blue-btn"
                                            onClick={() => { setPassengerNameFilter("") }}
                                        >
                                            نمایش همه مسافران
                                        </button>
                                        <br />
                                        <br />
                                    </div>
                                )}

                            </div>
                        </div>
                    ) : (
                        <div className="padding-h-20 padding-v-20 text-center">
                            <br />
                            <div className="margin-bottom">
                                اطلاعاتی از مسافران سابق یافت نشد!
                            </div>
                            <button
                                type="button"
                                className="button blue-btn"
                                onClick={() => { setTravellersOpen(false); }}
                            >
                                بستن
                            </button>
                            <br />
                            <br />
                        </div>

                    )}
                </div>
            </div>}

            <div className="padding-h-20 pair-row">
                <div className="passenger-title">
                    {item.type === "ADT" && (
                        <span className="itours-icon icon-large adult margin-end-half" />
                    )}
                    {item.type === "CHD" && (
                        <span className="itours-icon icon-large child margin-end-half" />
                    )}
                    {item.type === "INF" && (
                        <span className="itours-icon icon-large infant margin-end-half" />
                    )}
                    <span>{Dictionary.passenger} </span>
                    <span> {index + 1} </span>
                    <span className="font-12">
                        {item.type === "ADT" && (
                            <>({Dictionary.adult})</>
                        )}
                        {item.type === "CHD" && (
                            <>({Dictionary.child})</>
                        )}
                        {item.type === "INF" && (
                            <>({Dictionary.infant})</>
                        )}
                    </span>
                </div>

                {!!(process.env.REACT_APP_THEME === "ITOURS_IR") && (
                    <button
                        type="button"
                        onClick={() => {
                            if (props.Verification) {
                                setTravellersOpen(true);
                            } else {
                                setPendingToOpenTravellers(true);
                                props.openIrLoginModal();
                            }
                        }}
                        className="button link-style-b-btn"
                    >
                        انتخاب مسافران سابق
                    </button>
                )}
            </div>

            <div className="padding-h-20">
                <div className="float-row">
                    <div className="col-small-12 col-medium-6">
                        <div className="form-group required">
                            <label className="form-label">
                                {Dictionary.firstNameEn}
                            </label>
                            <div className="select-input-pair-holder">
                                <select
                                    ref={genderRef}
                                    className="form-select"
                                    name={`passengers[${index}].gender`}
                                >
                                    <option value="true">
                                        {Dictionary.male}
                                    </option>
                                    <option value="false">
                                        {Dictionary.female}
                                    </option>
                                </select>
                                <input
                                    ref={firstNameRef}
                                    onChange={e => { onChangeValidation(e, "enAlphabet") }}
                                    data-validator-type="alphabet"
                                    data-validator={true}
                                    type="text"
                                    className="form-input"
                                    name={`passengers[${index}].firstName`}
                                />
                            </div>
                            <Validation
                                model={state}
                                feild="ValidatorList"
                                name={`passengers[${index}].firstName`}
                            />
                        </div>
                    </div>
                    <div className="col-small-12 col-medium-6">
                        <div className="form-group required">
                            <label className="form-label">
                                {Dictionary.lastNameEn}
                            </label>
                            <input
                                ref={lastNameRef}
                                onChange={e => { onChangeValidation(e, "enAlphabet") }}
                                data-validator-type="alphabet"
                                type="text"
                                className="form-input"
                                data-validator={true}
                                name={`passengers[${index}].lastName`}
                            />
                            <Validation
                                model={state}
                                feild="ValidatorList"
                                name={`passengers[${index}].lastName`}
                            />
                        </div>
                    </div>
                </div>
                {showPersian && (
                    <div className="float-row">
                        <div className="col-small-12 col-medium-6">
                            <div className="form-group required">
                                <label className="form-label">
                                    {Dictionary.firstName} (
                                    {Dictionary.persian})
                                </label>
                                <div className="select-input-pair-holder">
                                    <input
                                        ref={firstNamePersianRef}
                                        onChange={e => { onChangeValidation(e, "faAlphabet") }}
                                        data-validator-type="farsiAlphabet"
                                        type="text"
                                        className="form-input"
                                        data-validator={true}
                                        name={`passengers[${index}].persianFirstName`}
                                    />
                                </div>
                                <Validation
                                    model={state}
                                    feild="ValidatorList"
                                    name={`passengers[${index}].persianFirstName`}
                                />
                            </div>
                        </div>
                        <div className="col-small-12 col-medium-6">
                            <div className="form-group required">
                                <label className="form-label">
                                    {Dictionary.lastName} (
                                    {Dictionary.persian})
                                </label>
                                <input
                                    ref={lastNamePersianRef}
                                    onChange={e => { onChangeValidation(e, "faAlphabet") }}
                                    data-validator-type="farsiAlphabet"
                                    type="text"
                                    className="form-input"
                                    data-validator={true}
                                    name={`passengers[${index}].persianLastName`}
                                />
                                <Validation
                                    model={state}
                                    feild="ValidatorList"
                                    name={`passengers[${index}].persianLastName`}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="float-row">
                    <div className="col-small-12 col-medium-6">
                        <div className="form-group required">
                            <label
                                htmlFor=""
                                className="form-label"
                            >
                                {Dictionary.birthdayMiladi}
                            </label>
                            {/*<DatePicker format={"YYYY-MM-DD"} defaultValue={moment(this.state.rangeAge[item.type].min)}   showTime={false}  data-validator={true} name={`passengers[${index}].birthDate`}  disabledDate={this.disabledDate.bind(this,item.type)}/>*/}
                            {/*<Validation model={this.state} feild="ValidatorList" name={`passengers[${index}].birthDate`} />*/}
                            <DatePickerDrop
                                format={"YYYY-MM-DD"}
                                name={`passengers[${index}].birthDate`}
                                min={item.birthDateRange.min}
                                max={item.birthDateRange.max}
                                emptyDefault
                                dataValidatorType="datePickerDrop"
                                dataValidator={true}
                                defaultValue={birthDate}
                            />
                            <Validation
                                model={state}
                                feild="ValidatorList"
                                name={`passengers[${index}].birthDate`}
                            />

                        </div>
                    </div>
                    {/* {(LAN === "IR" || this.props.flightSelected.provider === "AmadeusThr" || process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') && ( */}
                    <div className="col-small-12 col-medium-6">
                        <div className="form-group required">
                            <label
                                htmlFor=""
                                className="form-label"
                            >
                                {Dictionary.nationality}
                            </label>
                            <select
                                ref={nationalityRef}
                                className="form-select"
                                onChange={(e) => { setPassengerNationality(e.target.value, index) }}
                                defaultValue={item.nationality}
                                name={`passengers[${index}].nationalityCode`}
                                dir="ltr"
                                data-validator={true}
                            > <option disabled selected value="false"> -- </option>
                                {CountryCode.map((item) => (
                                    <option
                                        value={item.value}
                                        key={item.value}
                                    >
                                        {item.code}
                                    </option>
                                ))}
                            </select>
                            <Validation
                                model={state}
                                feild="ValidatorList"
                                name={`passengers[${index}].nationalityCode`}
                            />
                        </div>
                    </div>
                    {/* )} */}
                </div>

                {/* {(LAN === "IR" || this.props.flightSelected.provider === "AmadeusThr" || process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') && ( */}
                <>
                    {flightSelected.pricedItinerary.isDomestic && passengerInformation[index].nationality && (passengerInformation[index].nationality === "IR") ? (
                        <div className="float-row">
                            <div className="col-small-12 col-medium-6">
                                <div className="form-group required">
                                    <label
                                        htmlFor={`nationalID[${index}]`}
                                        className="form-label"
                                    >
                                        {Dictionary.nationalCode}
                                    </label>
                                    <input
                                        ref={nationalCodeRef}
                                        onChange={e => { onChangeValidation(e, "number", 10) }}
                                        id={`nationalID[${index}]`}
                                        type="text"
                                        className="form-input"
                                        data-validator={true}
                                        name={`passengers[${index}].nationalId`}
                                        data-validator-type="nationalCode"
                                    />
                                    <Validation
                                        model={state}
                                        feild="ValidatorList"
                                        name={`passengers[${index}].nationalId`}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="float-row">
                            <div className="col-xsmall-12 col-small-6">
                                <div className="form-group required">
                                    <label
                                        htmlFor=""
                                        className="form-label"
                                    >
                                        {Dictionary.passportNumber}{" "}
                                    </label>
                                    <input
                                        ref={passportRef}
                                        data-validator-type={passengerInformation[index].nationality === "IR" ? "passportIR" : "passportNumber"}
                                        onChange={e => { onChangeValidation(e, "passport", passengerInformation[index].nationality === "IR" ? 9 : 25) }}
                                        type="text"
                                        className="form-input"
                                        data-validator={true}
                                        name={`passengers[${index}].passportNumber`}
                                    />
                                    <Validation
                                        model={state}
                                        feild="ValidatorList"
                                        name={`passengers[${index}].passportNumber`}
                                    />
                                </div>
                            </div>
                            <div className="col-xsmall-12 col-small-6">
                                <div className="form-group required">
                                    <label
                                        htmlFor=""
                                        className="form-label"
                                    >
                                        {Dictionary.passportExpDate}
                                    </label>
                                    <DatePickerDrop
                                        defaultValue={passportExpDate}
                                        format={"YYYY-MM-DD"}
                                        name={`passengers[${index}].passportExpireDate`}
                                        min={moment(passportExpMin).format()}
                                        max={moment(passportExpMin).add(10, "years").format()}
                                        emptyDefault
                                        dataValidatorType="datePickerDrop"
                                        dataValidator={true}
                                    />
                                    <Validation
                                        model={state}
                                        feild="ValidatorList"
                                        name={`passengers[${index}].passportExpireDate`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
                {/* )} */}
            </div>
            {(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME === 'AYANAIRCOM') && (item.type !== "INF") && <div className="wheelchair-block text-center">
                <h5 className="wheelchair-block-title">{Dictionary.thisServiceIsFree}</h5>
                <div className="checkbox-holder">
                    <input
                        name={`passengers[${index}].hasWheelChair`}
                        className="custom-checkbox"
                        type="checkbox"
                    />
                    <label className="checkbox-label font-14">
                        {Dictionary.clickHereIfYouNeedAWheelchair}
                    </label>
                </div>
            </div>}
            <input
                type="hidden"
                name={`passengers[${index}].code`}
                value={item.type}
            />
        </div>
    )
}


const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
    Verification: state.VisualData.Verification
});
export default connect(mapStateToProps)(PassengerItem);