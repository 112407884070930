import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import adv1 from '../../image/style-2/adv-1.jpg';
import adv2 from '../../image/style-2/adv-2.jpg';
import adv3 from '../../image/style-2/adv-3.jpg';


const Style2HomeAds = () =>{
    const settings = {
        arrows:false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        rtl: true,
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ]
      };
      const slidesContent = [
        { imageUrl: adv1, title: "بلیط هواپیما", subtitle : "دسترسی به بیش از 450 ایرلاین در جهان"},
        { imageUrl: adv2, title: "هتل داخلی و خارجی", subtitle : "انتخاب از میان بیش از 2 میلیون هتل"},
        { imageUrl: adv3, title: "پکیج", subtitle : "خرید همزمان پرواز و هتل"},
      ];

    return(<div className="style-2-adv-slider-holder">
        <div className="page-container">
            <div className="style-2-adv-slider-inner">
                <Slider {...settings}>
                    {slidesContent.map(item => (
                        <div className="style2-adv-item">
                            <img src={item.imageUrl} alt={item.title}  className="adv-item-image" />
                            <h4 className="adv-item-title">{item.title}</h4>
                            <h5 className="adv-item-subtitle">{item.subtitle}</h5>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    </div>)
}

export default Style2HomeAds;