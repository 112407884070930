import { connect } from "react-redux";
import { Link } from "react-router-dom";

import HotelNoImage from "../../image/default-hotel-image.svg";
import Rating from "../Common/Rating";
import Price from "../Common/Price";

const DomesticHotelItemVertical = props => {

    const { hotel, Dictionary } = props;


    let price;
    switch (hotel.priceInformation) {
        case "pending":
            price = <div className="line-height-44"><span className="loading-inline-circle" /></div>;
            break;
        case "not-priced":
            price = null;
            break;
        case "need-to-inquire":
            price = <p className="purple margin-bottom-light">
                قیمت نیازمند استعلام است
            </p>
            break;
        default:
            price = (
                <>
                    <div className="text-end prices-holder line-height-22">
                        {hotel.priceInformation?.discountPercent === 0 ? (
                            <b
                                className="small-margin-bottom text-center"
                                dir="ltr"
                            >
                                <Price currency={props.currencyCode}>
                                    {hotel.priceInformation?.salePrice}
                                </Price>
                            </b>
                        ) : (
                            <>
                                <div className="old-price no-wrap">
                                    <Price currency={props.currencyCode} oldPrice>
                                        {hotel.priceInformation?.boardPrice}
                                    </Price>
                                </div>
                                <b className="small-margin-bottom text-center no-wrap">
                                    <Price currency={props.currencyCode}>
                                        {hotel.priceInformation?.salePrice}
                                    </Price>
                                </b>
                            </>
                        )}
                    </div>
                    <div className="text-end font-12 gray price-for-night line-height-22">
                        <span>{Dictionary.startingPrice}</span> <span className="nowrap"> ({props.nights} {Dictionary.nights}) </span>
                    </div>
                </>
            )
    }

    return (
        <div className={`bg-white domestic-hotel-vertical box-shadow ${props.className || ""}`}>
            
            <img 
                src={hotel.ImageUrl || HotelNoImage} 
                alt={hotel.HotelTypeName + " " + hotel.HotelName + " " + hotel.CityName} 
                className={`cover ${hotel.ImageUrl?"" : "default-cover"}`}
            />

            <div className="card-padding">
                <main >
                    <b className="margin-bottom-light block">{hotel.HotelTypeName} {hotel.HotelName} {hotel.CityName}</b>
                    <div className="margin-bottom-light">
                        <Rating number={hotel.HotelRating} />
                    </div>

                    <p className="gray font-13 address">{hotel.Address}</p>


                    {!price && <div className="bg-light-pink padding-v-10 padding-h-15 margin-top-half">
                        <strong className="margin-bottom-light purple semi-bold">
                            <span className="itours-icon red-clock-icon icon-small margin-end-light" />
                            این مورد را از دست دادید!
                        </strong>
                        <div className="font-13">برای این تاریخ ها هیچ اتاقی در دسترس نداریم</div>
                    </div>}
                </main>

                {!!price && (<footer className="text-end">

                    <div>
                        {price}
                        {!!price && <Link
                            className="button red-btn has-arrow nowrap margin-top-light"
                            target="_blank"
                            to={`/domesticHotel/details?id=${hotel?.HotelId}&checkin=${props.checkin}&checkout=${props.checkout}`}
                        >
                            {Dictionary.seeRooms}
                        </Link>}
                    </div>

                </footer>)}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        Dictionary: state.VisualData.Dictionary,
        LAN: state.VisualData.LAN,
        currencyCode: state.UserData.Currency
    };
};

export default connect(mapStateToProps)(DomesticHotelItemVertical);