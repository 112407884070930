import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import moment from "moment";

const Style3HomeTopDestinations = () => {
    
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        rtl: true,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 3
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2
              },
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 1
              },
            },
          ]
    };

    const tomorrow = moment().add(1,'days').format("YYYY-MM-DD");
    const setLink = (code, name) => `/flight/result?originCodes=THR&originNames=Tehran,%20Iran(THR-All%20Airports)&destinationCodes=${code}&destinationNames=${name}&departureDateTimes=${tomorrow}&adult=1&child=0&infant=0&cabinClassCode=Economy&airTripType=OneWay&nonStop=false&airlineCode=all&airlineText=`;

    const items = [
        {title:"استانبول", link:setLink("IST","Istanbul,%20Turkey(IST-All%20Airports)" ), image:"https://cdn3.itours.no/Content/itoursir/Istanbul.webp"},
        {title:"دبی", link:setLink("DXB","Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)" ), image:'https://cdn3.itours.no/Content/itoursir/Dubai.webp'},
        {title:"بارسلونا", link:setLink("BCN","Barcelona(BCN-All%20Airport)" ), image:'https://cdn3.itours.no/Content/itoursir/Barcelona.webp'},
        {title:"دوحه", link:setLink("DOH","Doha" ), image:'https://cdn3.itours.no/Content/itoursir/Doha.webp'},
        {title:"هامبورگ", link:setLink("HAM","Hamburg,%20Germany(HAM-All%20Airports)" ), image:'https://cdn3.itours.no/Content/itoursir/Hamburg.webp'},
        {title:"لندن", link:setLink("LON","London,%20United%20Kingdom(LON-All%20Airports)" ), image:'https://cdn3.itours.no/Content/itoursir/London.webp'},
        {title:"پاریس", link:setLink("PAR","Paris,%20France(PAR-All%20Airports)" ), image:'https://cdn3.itours.no/Content/itoursir/Paris.webp'},
        {title:"رم", link:setLink("ROM","Rome,%20Italy(ROM-All%20Airports)" ), image:'https://cdn3.itours.no/Content/itoursir/Rome.webp'},
        {title:"شنژن", link:setLink("SZX","Shenzhen" ), image:'https://cdn3.itours.no/Content/itoursir/Shenzhen.webp'},
        {title:"تورنتو", link:setLink("YTO","Toronto,%20Canada(YTO-All%20Airports)" ), image:'https://cdn3.itours.no/Content/itoursir/Toronto.webp'},
        {title:"ونکوور", link:setLink("YVR","Vancouver,%20Canada(YVR-All%20Airports)" ), image:'https://cdn3.itours.no/Content/itoursir/Vancouver.webp'},
        {title:"کیپ تاون", link:setLink("CPT","Cape%20Town" ), image:'https://cdn3.itours.no/Content/itoursir/CapeTown.webp'},
    ]
    
    return (
        <section className="bg-white style3-top-destinations-section">
            <div className="page-container">
                <Slider {...settings} className="margin-x--5">
                {items.map(item => (
                    <div className="padding-h-5" key={item.title} dir="rtl">
                        <a href={item.link} className="destination-carousel-item">
                            <img src={item.image} alt={item.title} className="image full-width" />
                            <div className="relative bg-white padding-v-5 padding-h-15">
                                پرواز به
                                <div className="blue bold font-22">
                                    {item.title}
                                </div>
                                <span className="yellow-left-carret" />
                            </div>
                        </a>
                    </div>
                ))}
            </Slider>

            </div>
        </section>
    )
}

export default Style3HomeTopDestinations;