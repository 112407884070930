import React from "react";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Steps } from "antd";
import queryString from "query-string";

import {getPassengerDomesticFlight,setSafarmarketPixel } from "../../Actions/FlightAction";
import {Header, Flight as FlightURL, ServerAddress} from "../../Enum/Urls";
import Form from "../../components/Common/Form";
import { editMessage ,showError,showAccountWindowIR} from "../../Actions/VisualDataAction";
import Validation from "../../components/Common/Validation";
import Loading from '../../components/Common/Loading';
import formIconOrange from "../../image/form-icon.svg";
import formIconPink from "../../image/form-icon-pink.svg";
import FlighSummary from "../../components/Flight/FlighSummary";
import { CountryPostalCode } from "../../Enum/Models";
import {toggleAccountWindow,SwitchAccountWindow} from "../../Actions/VisualDataAction";
import LayoutConfig from "../../Config/layoutConfig";
import Price from "../../components/Common/Price";
import SafarmarketWelcome from "../../components/Common/SafarmarketWelcome";
import StickySides from "../../components/Common/StickySides";
import PassengerItemDomestic from "../../components/Flight/PassengerItemDomestic";
import { getTravellers } from "../../Actions/TravellerAction";

let source = axios.CancelToken.source();

class GetPassengerDomesticFlight extends Form {
  
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      flightSelected: undefined,
      showPersian: false,
      passportExpMin: undefined,
      passengerInformation: [],
      reserveLoading: false,
      isFromSafarmarket:false,
      getPnrParams:undefined,
      detailsIsOpen:false,
      travellers:[],
      travellersLoading:false
    };

    source = axios.CancelToken.source();
  }

  fetchTravellers = async () => {
    
    this.setState({
      travellers:[],
      travellersLoading:true
    });
    
    const response = await getTravellers();
    
    this.setState({
      travellersLoading:false
    });

    if(response.data?.result?.items){
      this.setState({
        travellers: response.data.result.items
      });
    }

  }

  refetchTravellersData = async () => {        
    const response = await getTravellers();
    if(response.data?.result?.items){
      this.setState({
        travellers: response.data.result.items
      });
    }
  }

  validateFlight = () => {
    let parameters = queryString.parse(this.props.location.search);
    let url = `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${parameters.key}`;
    let metaId, metaSource;
    let cookies = decodeURIComponent(document.cookie).split(';');
    for (const item of cookies) {
      if (item.includes('safarMarketId=')) {
        metaId = item.split('=')[1];
        metaSource = 'safarmarket';
      }
    }
    if (parameters.safarmarketId && parameters.utm_medium && parameters.utm_source && parameters.utm_source === 'safarmarket') {
      metaId = parameters.safarmarketId;
      metaSource = 'safarmarket';
    }
    if (parameters.yabex_id && parameters.utm_source && parameters.utm_source === 'yabex') {
      metaId = parameters.yabex_id;
      metaSource = 'yabex';
    }
    if (metaId && metaSource && (LayoutConfig.safarmarket || LayoutConfig.yabex) && this.props.currencyCode === 'IRR') {
      url = `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${parameters.key}&metaId=${metaId}&source=${metaSource}`;
    }
    axios({
      method: 'post',
      cancelToken: source.token,
      url: url,
      headers: { ...Header },
    })
      .then((response) => {
        this.setState({ ...this.state, flightSelected: response.data.result });
      })
      .catch((error) => {
        if(error.message && error.message !== "unmounted"){
          try {
            this.props.showError({ visibility: true, content: error.response.data.error.message });
          } catch (e) {
            this.props.showError({ visibility: true, content: 'Internal Error,Please refresh the page' });
          }
        }
      });
  };

  componentDidMount() {
    let parameters = queryString.parse(this.props.location.search);
    window.scrollTo(0, 0);
    this.validateFlight();

    //safarmarket:
    let smId;
    let cookies = decodeURIComponent(document.cookie).split(';');
    for (const item of cookies) {
      if (item.includes('safarMarketId=')) {
        smId = item.split('=')[1];
      }
    }
    if (LayoutConfig.safarmarket && this.props.currencyCode === 'IRR' && parameters.safarmarketId && parameters.utm_medium && parameters.utm_source) {
      this.setState({isFromSafarmarket:true});
      smId = parameters.safarmarketId;
      const expDate = new Date();
      expDate.setTime(expDate.getTime() + 168 * 60 * 60 * 1000);
      document.cookie = `safarMarketId=${parameters.safarmarketId}; expires=${expDate.toUTCString()};path=/`;
    }

    if (LayoutConfig.safarmarket && this.props.currencyCode === 'IRR' && smId) {
      this.props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/v1/trace/pixel/itours/2/?smId=${smId}&bck=false`));
    }
    if(this.props.verification){
      this.fetchTravellers();
    }
  }

  componentWillUnmount() {
    this.props.setSafarmarketPixelValue(undefined);
    if (source) {
      source.cancel("unmounted");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { flightSelected } = this.state;

    if(this.props.verification && !prevProps.verification){
      this.fetchTravellers();
    }

    if (flightSelected && flightSelected !== prevState.flightSelected) {
      if (flightSelected.isPriceChanged) {
        this.props.showError({
          visibility: true,
          content: (
            <div>
              {this.props.Dictionary.ThePriceOfThisFlightHasChanged}.
              <br />
              <h5 className="normal-title">
                {this.props.Dictionary.newPrice}:{' '}
                <span dir="ltr">
                  <Price currency={this.props.currencyCode}>{flightSelected.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare}</Price>
                </span>
              </h5>
            </div>
          ),
          closable: true,
          buttonLabel: this.props.Dictionary.ok,
        });
      }

      const data = flightSelected.pricedItinerary.airItinerary.originDestinationOptions;
      const lastRoute = data[data.length - 1];
      const expDate = lastRoute.flightSegments[lastRoute.flightSegments.length - 1].arrivalDateTime;

      let passengerInfo = [];

      let passengerCnt = 0;
      flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown.forEach((item) => {
        let birtDateMin;
        let birthDateMax;
        switch (item.passengerTypeQuantity.code) {
          case 'ADT':
            birtDateMin = moment().subtract(100, 'years').format();
            birthDateMax = moment().subtract(12, 'years').format();
            break;
          case 'CHD':
            birtDateMin = moment().subtract(12, 'years').format();
            birthDateMax = moment().subtract(2, 'years').format();
            break;
          case 'INF':
            birtDateMin = moment().subtract(2, 'years').format();
            birthDateMax = moment().format();
            break;
          default:
            birtDateMin = moment().subtract(100, 'years').format();
            birthDateMax = moment().format();
        }
        if (item.passengerBirthDateRange) {
          birtDateMin = item.passengerBirthDateRange.minBirhDate;
          birthDateMax = item.passengerBirthDateRange.maxBirthDate;
        }
        for (let cnt = 1; cnt <= item.passengerTypeQuantity.quantity; cnt++) {
          passengerCnt++;
          let passitem = {
            id: `passenger-${passengerCnt}`,
            type: item.passengerTypeQuantity.code,
            birthDateRange: { min: birtDateMin, max: birthDateMax },
          };
          passengerInfo.push(passitem);
        }
      });

      this.setState({
        ...this.state,
        passportExpMin: moment(expDate).add(1, 'd'),
        passengerInformation: passengerInfo,
      });
    }

    if (this.props.reserveId !== prevProps.reserveId) {
      this.setState({ ...this.state, reserveLoading: true });

      //safarMarket:
      let smId;
      let cookies = decodeURIComponent(document.cookie).split(';');
      for (const item of cookies) {
        if (item.includes('safarMarketId=')) {
          smId = item.split('=')[1];
        }
      }
      let parameters = queryString.parse(this.props.location.search);
      if (parameters.safarmarketId && parameters.utm_medium && parameters.utm_source) {
        smId = parameters.safarmarketId;
      }
      let reserveUrl = `/payment/reserve?reserveId=${this.props.reserveId}&type=flight`;
      if (smId && LayoutConfig.safarmarket && this.props.currencyCode === 'IRR') {
        reserveUrl = `/payment/reserve?reserveId=${this.props.reserveId}&type=flight&smid=${smId}`;
      }
      this.props.history.push(reserveUrl);
    }
    if(this.props.verification && (this.props.verification !== prevProps.verification) && this.state.getPnrParams){
      let token = localStorage.getItem("userToken");
      this.setState({ ...this.state, reserveLoading: true });

      const params = {...this.state.getPnrParams};
      if(this.props.userData?.id){
        params.reserver.userId = this.props.userData.id;
      }
      if(this.props.userData?.userName){
        params.reserver.username = this.props.userData.userName;
      }
      this.props.getPassengerFlight(params,source.token , token);
    }
  }


  fixNumbers = (str) => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

  FormDOM = [];
  handleSubmit(event) {
    event.preventDefault();
    super.validateForm(event, () => {
      this.props.editMessage(false);
      let dataModelInput = this.state.DataInput;

      let countryCode = dataModelInput.reserver.countryCallingCode;
      if (countryCode.substring(0, 2) === "00"){
        countryCode = "+" + countryCode.substring(2)
      }


      const editedPassengers = dataModelInput.passengers.map((item) => {
        const editingPassenger = { ...item };
        if (editingPassenger.nationalId) {
          editingPassenger.nationalId = this.fixNumbers(editingPassenger.nationalId);
        }
        if (editingPassenger.passportNumber) {
          editingPassenger.passportNumber = this.fixNumbers(editingPassenger.passportNumber);
        }
        return editingPassenger;
      });
      const updatedData = { ...dataModelInput };
      updatedData.passengers = editedPassengers;

      const updatedReserver = { ...updatedData.reserver };
      updatedReserver.phoneNumber = countryCode +this.fixNumbers(updatedData.reserver.phoneNumber);
      updatedReserver.username = this.props.userData?.userName ? this.props.userData.userName : countryCode +this.fixNumbers(updatedData.reserver.phoneNumber);

      if(this.props.userData?.id){
        updatedReserver.userId = this.props.userData.id;
      }

      updatedData.reserver = updatedReserver;

      let token = localStorage.getItem("userToken");
      if (process.env.REACT_APP_SERVER_LOCALSERVER !== 'itours.ir___'){
        this.setState({ ...this.state, reserveLoading: true });
        if (token){
          this.props.getPassengerFlight(updatedData, source.token, token);
        }else{
          this.props.getPassengerFlight(updatedData, source.token);
        }
      }else if (token){
        this.setState({ ...this.state, reserveLoading: true });
        this.props.getPassengerFlight(updatedData, source.token, token);
      }else{
        this.setState({ ...this.state, getPnrParams: updatedData });
        this.props.openIrLoginModal();
      }
    });
  }

  onChangeValidation(event, type, maxLength) {
    if (maxLength) {
      if (event.target.value.length > maxLength) {
        const prevVal = event.target.value.substring(0, maxLength);
        event.target.value = prevVal;
      }
    }
    
    let regex;
    switch (type) {
      case 'number':
        regex = /^[0-9۰-۹٠-٩]*$/;
        break;
      case 'enAlphabet':
        regex = /^[a-zA-Z ]*$/;
        break;
      case 'faAlphabet':
        regex = /^[\u0600-\u06FF\s]+$/;
        break;
      case 'email':
        regex = /[^\u0600-\u06FF\s]/;
        break;
      case 'passport':        
        regex = /^[a-zA-Z0-9۰-۹٠-٩]*$/;
        break;           
      default:
        regex = /^[a-zA-Z ]*$/;
    }

    if (!regex.test(event.target.value)) {
      const prevVal = event.target.value.substring(0, event.target.value.length - 1);
      if (regex.test(prevVal)) {
        event.target.value = prevVal;
      } else {
        event.target.value = '';
      }
    }else{
      if(type === "passport"){
        event.target.value = event.target.value.toUpperCase();
      }
    }
  }
  render() {
    const { flightSelected, passengerInformation, passportExpMin } = this.state;
    const { Dictionary, LAN, userData } = this.props;
    const { Step } = Steps;

    let userInfoStatus = false;
    if (userData && userData.id){
       userInfoStatus = true;
    }

    
    let userPhoneNumber = undefined;
    const formIcon = (LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ? formIconPink : formIconOrange;
    return (
      <div>
        {!flightSelected ? (
          <Loading fullPage description={Dictionary.CheckingFlightInformation + ' ...'} />
        ) : this.state.reserveLoading ? (
          <Loading fullPage />
        ) : (
          <div className="flight getpassengers getpassenger-page page-padding no-padding-mobile">
            <div className="page-container no-padding-mobile flightdetails ">
              <div>
                <Steps current={1}>
                  <Step title={Dictionary.chooseFlightsOrHotels} />
                  <Step title={Dictionary.enterThePassengersInformation} />
                  <Step title={Dictionary.paymentTypeOptions} />
                </Steps>
              </div>
              { this.state.isFromSafarmarket && <SafarmarketWelcome />}
              <div className="bg-white message-block error-message box-border card-padding bold">
              {LAN === "IR" ?
                  <div className="font-18">
                    برای مشاهده اطلاعات بار و قوانین پرواز روی
                    <span className="summary-detail-link" onClick={() => {
                      this.setState({
                        detailsIsOpen: true
                      })
                    }}> جزییات پرواز </span>
                    کلیک کنید.
                  </div>
                  :
                  Dictionary.ClickOnFlightDetailsToViewBaggageInformationAndFlightRules
                }
              </div>
              <form id={'reserveForm'} onSubmit={this.handleSubmit.bind(this)} ref={(node) => (this.FormDOM = node)} noValidate>
                <StickySides 
                  main={(
                    <>
                                          <div className="box-border bg-white passengers-informations">
                        {/* {!this.props.userData.id && (
                          <div className="login-invite">
                              <strong>{Dictionary.alreadyAnItoursMember}</strong>
                              {LAN === "IR" ? (
                              <div className="content">
                                  <span> برای رزرو سریعتر </span>
                                  <span
                                  className="content-link"
                                  onClick={() => {
                                      this.props.SwitchAccountWindow(false);
                                      this.props.toggleAccountWindow();
                                  }}
                                  >
                                  وارد شوید
                                  </span>
                                  .
                              </div>
                              ) : LAN === "AR" ? (
                              <div className="content">
                                  <span
                                  className="content-link"
                                  onClick={() => {
                                      this.props.SwitchAccountWindow(false);
                                      this.props.toggleAccountWindow();
                                  }}
                                  >
                                  تسجيل الدخول{" "}
                                  </span>
                                  <span> للحجز أسرع</span>.
                              </div>
                              ) : (
                              <div className="content">
                                  <span
                                  className="content-link margin-end-half"
                                  onClick={() => {
                                      this.props.SwitchAccountWindow(false);
                                      this.props.toggleAccountWindow();
                                  }}
                                  >
                                  {Dictionary.signIn}
                                  </span>
                                  <span> {Dictionary.now} </span>
                                  <span> {Dictionary.and} </span>
                                  <span> {Dictionary.speedUpYourBooking}!</span>
                              </div>
                              )}
                          </div>
                          )} */}
                        <div className={'get-passenger-form-holder padding-20'}>
                          <h5 className="title">{Dictionary.enterContactDetails}</h5>
                          <div className="icon-hint-holder">
                            <img src={formIcon} className="icon" alt="" />
                            <div className="hint-inner">{Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail}</div>
                          </div>
                          <input
                            value={flightSelected && flightSelected.pricedItinerary ? flightSelected.pricedItinerary.key : ''}
                            type="hidden"
                            name={'key'}
                          />
                          <div className="float-row">
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group required">
                                <label className="form-label">{Dictionary.firstNameEn}</label>
                                <div className="select-input-pair-holder">
                                  <select className="form-select" name={'reserver.gender'} defaultValue={userInfoStatus ? userData.gender : true}>
                                    <option value={true}>{Dictionary.male}</option>
                                    <option value={false}>{Dictionary.female}</option>
                                  </select>
                                  <input
                                    onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                    data-validator-type="alphabet"
                                    className="form-input"
                                    data-validator={true}
                                    placeholder={Dictionary.firstName}
                                    name="reserver.firstName"
                                    type="text"
                                    defaultValue={userInfoStatus ? userData.firstName : ""}
                                  />
                                </div>
                                <Validation model={this.state} feild="ValidatorList" name="reserver.firstName" />
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group required">
                                <label className="form-label">{Dictionary.lastNameEn}</label>
                                <input
                                  onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                  data-validator-type="alphabet"
                                  className="form-input"
                                  data-validator={true}
                                  placeholder={Dictionary.lastName}
                                  name="reserver.lastName"
                                  type="text"
                                  defaultValue={userInfoStatus ? userData.lastName:""}
                                />
                                <Validation model={this.state} feild="ValidatorList" name="reserver.lastName" />
                              </div>
                            </div>
                          </div>
                          <div className="float-row">
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group required">
                                <label htmlFor="" className="form-label">
                                  {Dictionary.email}
                                </label>
                                <input
                                  onChange={e => {this.onChangeValidation(e,"email")}}
                                  data-validator={true}
                                  className="form-input placeholder-right"
                                  placeholder={Dictionary.enterYourEmail}
                                  name="reserver.email"
                                  type="email"
                                  defaultValue={userInfoStatus ? userData.emailAddress:""}
                                />
                                <Validation model={this.state} feild="ValidatorList" name="reserver.email" />
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group required">
                                {LAN !== 'IR' ? (
                                  <label htmlFor="" className="form-label">
                                    {Dictionary.phoneNumber}({Dictionary.optional})
                                  </label>
                                ) : (
                                  <label htmlFor="" className="form-label">
                                    {Dictionary.phoneNumber}
                                  </label>
                                )}

                                <div className="select-input-pair-holder " dir="ltr">
                                  <select
                                    className="form-select"
                                    name={'reserver.countryCallingCode'}
                                    defaultValue={LAN === 'IR' ? '0098' : '0047'}
                                    dir="ltr">
                                    {CountryPostalCode.sort((a, b) => {
                                      let x = a.code.toLowerCase();
                                      let y = b.code.toLowerCase();
                                      if (x < y) {return -1;}
                                      if (x > y) {return 1;}
                                      return 0;
                                    }).map((item) => {
                                      let seleceted = false;
                                      if (userData && userData.phoneNumber) {
                                        let code = item.value;
                                        if (code.substring(0, 2) === '00') {
                                          code = '+' + code.substring(2);
                                        }
                                        if (userData.phoneNumber.includes(code)) {
                                          seleceted = true;
                                          let phone_Number = userData.phoneNumber.replace(code, '');
                                          userPhoneNumber = phone_Number;
                                        }
                                      } else {
                                        if (
                                          (item.value === '0098' && LAN === 'IR') ||
                                          (item.value === '00971' && LAN === 'AR') ||
                                          (item.code === 'Norway (+47)' && LAN !== 'AR' && LAN !== 'IR')
                                        ) {
                                          seleceted = true;
                                        }
                                      }
                                      return (
                                        <option key={item.code} value={item.value} selected={seleceted}>
                                          {item.code}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  <input
                                    onChange={e => {this.onChangeValidation(e,"number")}}
                                    className="form-input placeholder-right"
                                    data-validator={LAN === 'IR'}
                                    placeholder="9XXXXXXXXX"
                                    defaultValue={userPhoneNumber ? userPhoneNumber : undefined}
                                    name="reserver.phoneNumber"
                                    type="tel"
                                  />
                                </div>
                                <Validation model={this.state} feild="ValidatorList" name="reserver.phoneNumber" />
                              </div>
                            </div>
                          </div>
                          {flightSelected && flightSelected.captchaLink ? (
                            <div className="float-row">
                              <div className="col-small-12 col-medium-6">
                                <div className="form-group">
                                  <div className="captchaHolder">
                                    <img src={`//images.weserv.nl/?url=${flightSelected.captchaLink}`} alt={'captcha'} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-small-12 col-medium-6">
                                <div className="form-group">
                                  <input
                                    data-validator-type="number"
                                    className="form-input"
                                    data-validator={true}
                                    placeholder={Dictionary.captchaCode}
                                    name="captchaResult"
                                    type="text"
                                    defaultValue={null}
                                  />
                                  <Validation model={this.state} feild="ValidatorList" name="captchaResult" />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="get-passenger-form-holder padding-20 box-border bg-white">
                        <h5 className="title">{Dictionary.enterTravellerDetails}</h5>
                        <div className="icon-hint-holder">
                          <img src={formIcon} className="icon" alt="" />
                          <div className="hint-inner">
                            {LAN !=="IR" && <span>{Dictionary.EnterYourDetailsAsTheyAppearOnYourPassport}</span>}
                            <span> فقط برای نام و نام خانوادگی فارسی از زبان فارسی استفاده کنید و برای سایر موارد از زبان انگلیسی استفاده کنید.</span>
                          </div>
                        </div>
                        <div className="passengers-information">
                          {passengerInformation.map((item, index) => (
                            <PassengerItemDomestic 
                              key={index}
                              travellers = {this.state.travellers}
                              index = {index}
                              item  = {item}
                              onChangeValidation = {this.onChangeValidation}
                              state = {this.state}
                              flightSelected={flightSelected}
                              passportExpMin={passportExpMin}
                              openIrLoginModal = {this.props.openIrLoginModal}
                              travellersLoading = {this.state.travellersLoading}
                              refetchTravellersData={this.refetchTravellersData}
                            />
                            ))}
                        </div>
                        <div className="border-top">
                          <div className="checkbox-holder has-link">
                            <input className="custom-checkbox" type="checkbox" data-validator={true} name={`acceptRuls`} />
                            <label className="checkbox-label font-14">
                              {LAN === 'IR' ? (
                                <>
                                  <Link to={'/terms'} className="content-link">
                                    قوانین و مقررات آیتورز{' '}
                                  </Link>
                                  را خوانده ام و با آن موافق هستم.
                                </>
                              ) : (
                                <>
                                  {Dictionary.iHaveReadAndAgreeToThe}
                                  <Link to={'/terms'} className="content-link">
                                    {Dictionary.termsAndConditions}
                                  </Link>
                                </>
                              )}
                            </label>
                            <Validation model={this.state} feild="ValidatorList" name={`acceptRuls`} />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  aside={(
                    <aside className={'full-width'}>
                      <FlighSummary
                        hasBackLink
                        detailsIsOpen={this.state.detailsIsOpen}
                        closeDetailsModal={()=>{this.setState({detailsIsOpen:false})}}
                        isDomestic
                        supplierKey={flightSelected.pricedItinerary?.key}
                        flightType={flightSelected.pricedItinerary?.flightType}
                        showFlightAllDetails
                        itinTotalFare={flightSelected.pricedItinerary.airItineraryPricingInfo.itinTotalFare}
                        showButton={true}
                        model={flightSelected.pricedItinerary.airItinerary.originDestinationOptions}
                        currencyCode={this.props.currencyCode}
                        fareInfo={flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown}
                        totalPrice={flightSelected.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare}
                        feild={'passengerFare'}
                      />
                  </aside>
                  )}
                />
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const dispatchToProps = (dispatch) => ({
  editMessage: (param) => {dispatch(editMessage(param));},
  getPassengerFlight: (param,sourceToken,token) => {dispatch(getPassengerDomesticFlight(param,sourceToken,token));},
  toggleAccountWindow: () => {dispatch(toggleAccountWindow());},
  SwitchAccountWindow: (mode) => {dispatch(SwitchAccountWindow(mode));},
  setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketPixel(data)),
  showError: (param) => {dispatch(showError(param));},
  openIrLoginModal: () =>{dispatch(showAccountWindowIR("usernameModal"))}
});
const stateToProps = (state) => ({
  userData: state.UserData.userData,
  currencyCode: state.UserData.Currency,
  reserveId: state.FlightSearch.reserveId,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  verification:state.VisualData.Verification
});
const routerBind = withRouter(GetPassengerDomesticFlight);
export default connect(stateToProps, dispatchToProps)(routerBind);