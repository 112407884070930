import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

class Price extends React.PureComponent{
    static propTypes={
        currency:PropTypes.string.isRequired,
    }
    state={
        price:null
    }
    checkCurrency=()=>{
        if(this.props.children){
            let price=0;
            if(this.props.currency==="NOK" || this.props.currency==="SEK" || this.props.currency==="DKK"){
                price=numeral(this.props.children).format("0,0").replace(/,/g,' ');
            }else{
                price=numeral(this.props.children).format("0,0");
            }
            this.setState({
                price:price
            })
        }else{
            this.setState({
                price:0
            })
        }
    };
    componentDidMount() {
        this.checkCurrency();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!==this.props){
            this.checkCurrency()
        }
    }
    render() {
        let currency = this.props.currency;
        if (currency === "IRR" && this.props.Dictionary.rials ){
            currency = this.props.Dictionary.rials
        }

        let direction = "ltr";
        if (this.props.LAN === "IR"){
            direction="rtl"
        }

        return(
            <span className={`price ${this.props.oldPrice? "line-through" : ""} ${this.props.className || ""}`} dir={direction}>
                <span className="value">{this.state.price}</span> <span className="currency">{currency}</span>
            </span>
        )
    }
}

const MapStateToProps = (state) => {
    return {
        Dictionary: state.VisualData.Dictionary,
        LAN: state.VisualData.LAN
    };
  };

export default connect(MapStateToProps)(Price);