import axios from "axios";
import { Header, ServerAddress, Traveller } from "../Enum/Urls";


export const getTravellers = async () => {
  let token = localStorage.getItem("userToken");
  try {
    let response = await axios.get(`${ServerAddress.Type}${ServerAddress.Traveller}${Traveller.GetAll}`,
      { headers: { Authorization: `Bearer ${token}`, ...Header } }
    );
    return response;
  } catch (error) {
    return error.response?.data || "Internal Error,Please try again";
  }
};

export const deleteTraveller = async (id) => {
  let token = localStorage.getItem("userToken");
  try {
    let response = await axios.delete(`${ServerAddress.Type}${ServerAddress.Traveller}${Traveller.Delete}?Id=${id}`,
      { headers: { Authorization: `Bearer ${token}`, ...Header } }
    );
    return response;
  } catch (error) {
    return error.response?.data || "Internal Error,Please try again";
  }
};


// export const addTravelllller = () => {

//   let token = localStorage.getItem("userToken");
//   let HeaderAthu = null;
//   if (token) {
//     HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
//   } else {
//     HeaderAthu = { ...Header };
//   }

//   const params = {
//     "tenantId": 6,
//     "userId": 20139,
//     "firstname": "Zahra",
//     "firstnamePersian": "زهرا",
//     "lastname": "Mohammadi",
//     "lastnamePersian": "محمدی",
//     "gender": false,
//     "nationalId": "2640006100",
//     "birthDate": "1982-02-02",
//     "nationality": "IR",
//     "email": "zahra1@mohamadi.com",
//     "passportCountry": "IR",
//     "passportExpirationDate": "2029-07-17",
//     "passportNumber": "00500171011",
//     "phoneNumber": "",
//     "id": 0
//   }

//   axios({
//     method: "post",
//     url: `https://traveller.itours.ir/api/services/app/Passenger/Create`,
//     data: { ...params },
//     headers: HeaderAthu,
//   })
//     .then((responsive) => {
//       debugger;
//     })
//     .catch((error) => {
//     });

// };