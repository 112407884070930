import React from 'react'
import moment from 'moment-jalaali';
import { Tag, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import defaultImage from "../../image/default-hotel-image.svg";
import Rate from '../Common/Rate';
import Price from '../Common/Price';
import LayoutConfig from '../../Config/layoutConfig';

const DomesticHotelSummery = React.memo (props => {

    const { safarmarketId, hotelInformation, reserveInformation, roomChildAndExtraBed, Dictionary, LAN } = props;

    const board = code => {
        switch (code) {
            case "BB":
                return "به همراه صبحانه";
            case "HB":
                return "صبحانه + ناهار یا شام";
            case "FB":
                return "تمام وعده های غذایی شامل می شود";
            case "RO":
                return "بدون صبحانه";

            default:
                return code;
        }
    }

    const hasDiscount = reserveInformation?.salePrice && reserveInformation.boardPrice && reserveInformation.boardPrice > reserveInformation.salePrice;

    let childCount = 0;
    let childPrice = 0;
    let extraBedCount = 0;
    let extraBedPrice = 0;
    if (roomChildAndExtraBed?.length) {
        childCount = roomChildAndExtraBed.reduce((total, item) => item.selectedChild ? (total + 1) : total, 0);
        childPrice = roomChildAndExtraBed.reduce((total, item) => item.selectedChild ? (total + item.childFee) : total, 0);
        extraBedCount = roomChildAndExtraBed.reduce((total, item) => total + item.selectedExtraBed, 0);
        extraBedPrice = roomChildAndExtraBed.reduce((total, item) => total + (item.selectedExtraBed * item.extraBedFee), 0);
    }

    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    return (
        <div className="hotel-summary-card box-border bg-white">
            <h5 className="card-title">
                <div className="hr_padding_half">{Dictionary.bookingSummary}</div>
            </h5>
            <div className="hr_padding_half">
                <div className="hotel-head-info">
                    <div
                        className={`hotel-image right2left ${hotelInformation?.image?.url ? "main-hotel-image" : "default-hotel-image"}`}
                        style={{
                            backgroundImage: `url(${hotelInformation?.image?.url || defaultImage})`,
                        }}
                    />
                    <div className="full-width">
                        <h5 className="hotel-title">
                            {hotelInformation.name}
                        </h5>
                        <div className="hotelRange">
                            {!!hotelInformation?.rating && <Rate number={hotelInformation?.rating} />}
                        </div>
                        {hotelInformation.address && <div className="gray font-12">
                            {hotelInformation.address}
                        </div>}
                    </div>
                </div>

                {reserveInformation && <>
                    <div className="pair-row">
                        <span>{Dictionary.checkIn}</span>
                        {safarmarketId ? moment(reserveInformation.checkin).format("jYYYY/jMM/jDD") : <Tooltip placement="top" title={Dictionary.propertyLocalTime}>
                            <div dir="ltr">
                                <span className="semi-bold">
                                    {moment(reserveInformation.checkin).format("YYYY/MM/DD")}
                                </span>
                                <span className="itours-icon icon-small info-icon" />
                            </div>
                        </Tooltip>}
                    </div>
                    <div className="pair-row">
                        <span>{Dictionary.checkOut}</span>
                        {safarmarketId ? moment(reserveInformation.checkOut).format("jYYYY/jMM/jDD") : <Tooltip placement="top" title={Dictionary.propertyLocalTime}>
                            <div dir="ltr">
                                <span className="semi-bold">
                                    {moment(reserveInformation.checkout).format("YYYY/MM/DD")}
                                </span>
                                <span className="itours-icon icon-small info-icon" />
                            </div>
                        </Tooltip>}
                    </div>
                    <div className="pair-row">
                        <span>{Dictionary.lengthOfStay}</span>
                        <span className="semi-bold">
                            {reserveInformation.duration} {Dictionary.nights}
                        </span>
                    </div>
                </>}

                {reserveInformation.rooms?.map((roomItem, roomIndex) => {

                    let childPriceBlock = null;
                    let extraBedPriceBlock = null;

                    if (roomChildAndExtraBed?.length) {
                        const itemInfo = roomChildAndExtraBed[roomIndex];
                        if (itemInfo?.selectedChild) {
                            childPriceBlock = <span> + یک کودک</span>
                        }
                        if (itemInfo?.selectedExtraBed) {
                            let count = null;
                            switch (itemInfo.selectedExtraBed) {
                                case 1:
                                    count = "یک";
                                    break;
                                case 2:
                                    count = "دو";
                                    break;
                                case 3:
                                    count = "سه";
                                    break;
                                default:
                                    count = itemInfo.selectedExtraBed;

                            }
                            extraBedPriceBlock = <span> + {count} تخت اضافه</span>
                        }
                    }

                    return (
                        <div
                            className="clearfix room-item font-14 margin-bottom-light border-top"
                            key={roomIndex}
                        >
                            <div className="margin-bottom-light">
                                <div>
                                    <span className="itours-icon hotel-dark margin-right-light icon-small" />
                                    <span className="semi-bold"> {roomItem.name} </span>
                                </div>
                                <div>
                                    <span className='margin-start-10'>{roomItem.bed} {roomItem.bed > 1 ? Dictionary.guestPlurar : Dictionary.guest} </span> {childPriceBlock} {extraBedPriceBlock}
                                </div>
                                <div>
                                    {board(roomItem.board)}
                                </div>
                                {roomItem.cancellationPolicyStatus === "Refundable" ? (
                                    <Tag className='no-margin' color={"green"}>{Dictionary.refundable}</Tag>
                                ) : roomItem.cancellationPolicyStatus === "NonRefundable" ? (
                                    <Tag className='no-margin' color={"red"}>{Dictionary.nonRefundable}</Tag>
                                ) : (
                                    <Tag className='no-margin' >{roomItem.cancellationPolicyStatus}</Tag>
                                )}
                            </div>
                        </div>
                    );
                })}

                <div className="card-footer">
                    {(reserveInformation?.salePrice && reserveInformation.salePrice > 1000 )? <>
                        {(hasDiscount || !!childCount || !!extraBedCount) && !!reserveInformation.salePrice && <div className="clearfix margin-bottom-light">
                            <span className="pull-start font-14">
                                قیمت اتاق
                            </span>
                            <b className="pull-end semi-bold" dir="ltr">
                                <Price currency={props.currencyCode}>{reserveInformation.boardPrice}</Price>
                            </b>
                        </div>}

                        {!!childCount && !!childPrice && <div className="clearfix margin-bottom-light">
                            <span className="pull-start font-14">
                                {Dictionary.child} (x{childCount})
                            </span>
                            <b className="pull-end semi-bold" dir="ltr">
                                <Price currency={props.currencyCode}>{childPrice}</Price>
                            </b>
                        </div>}

                        {!!extraBedCount && !!extraBedPrice && <div className="clearfix margin-bottom-light">
                            <span className="pull-start font-14">
                                {Dictionary.ExtraBed} (x{extraBedCount})
                            </span>
                            <b className="pull-end semi-bold" dir="ltr">
                                <Price currency={props.currencyCode}>{extraBedPrice}</Price>
                            </b>
                        </div>}

                        {!!hasDiscount && <div className="clearfix margin-bottom-light">
                            <span className="pull-start font-14">
                                تخفیف سایت
                            </span>
                            <b className="pull-end semi-bold" dir="ltr">
                                <Price currency={props.currencyCode}>{reserveInformation.boardPrice - reserveInformation.salePrice}</Price>
                            </b>
                        </div>}

                        <div className="clearfix semi-bold font-16">
                            <span className="pull-start">
                                قیمت نهایی
                            </span>
                            <b className="pull-end" dir="ltr">
                                <Price currency={props.currencyCode}>
                                    {reserveInformation.salePrice + childPrice + extraBedPrice}
                                </Price>
                            </b>
                        </div>
                    </>
                    :
                    "قیمت نیازمند استعلام است ."}


                    {props.showButton && (
                        <div className="confirmButton">
                            <button className={`button ${isStyle3?"yellow-btn pale":"red-btn"} full-width`} type="submit">
                                {(reserveInformation?.salePrice && reserveInformation.salePrice > 1000) ? Dictionary.continueToPayment : "ثبت درخواست" }
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

})

const mapStateToProps = (state) => ({
    error: state.VisualData.error,
    currencyCode: state.UserData.Currency,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
});

export default withRouter(connect(mapStateToProps)(DomesticHotelSummery));