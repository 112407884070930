import React from "react";
import { connect } from "react-redux";

const NaminHomeDestinations = props => {
    const {Dictionary} = props;
    const destinations =[
        {
            continent:Dictionary.Asia,
            countries:[
                {
                    country:Dictionary.Afghanistan,
                    cities:[Dictionary.Kabul]
                },
                {
                    country:Dictionary.Bangladesh,
                    cities:[Dictionary.Dhaka]
                },  
                {
                    country:Dictionary.India,
                    cities:[Dictionary.Delhi,Dictionary.Goa,Dictionary.Mumbai]
                },                
                {
                    country:Dictionary.Mongolia,
                    cities:[Dictionary.UlanBator]
                },
                {
                    country:Dictionary.Nepal,
                    cities:[Dictionary.Kathmandu]
                }, 
                {
                    country:Dictionary.Pakistan,
                    cities:[Dictionary.Islamabad,Dictionary.Karachi,Dictionary.Lahore]
                }, 
                {
                    country:Dictionary.Philippines,
                    cities:[Dictionary.Manila]
                },                
                {
                    country:Dictionary.SriLanka,
                    cities:[Dictionary.Colombo]
                },                         
                {
                    country:Dictionary.Vietnam,
                    cities:[Dictionary.Hanoi,Dictionary.HoChiMinhCity]
                },  
            ]
        },
        {
            continent:Dictionary.MiddleEast,
            countries:[               
                {
                    country:Dictionary.Iran,
                    cities:[Dictionary.Isfahan,Dictionary.Mashhad,Dictionary.Shiraz,Dictionary.Tabriz,Dictionary.Tehran]
                },
                {
                    country:Dictionary.Iraq,
                    cities:[Dictionary.Baghdad,Dictionary.Basra,Dictionary.Erbil,Dictionary.Najaf,Dictionary.Sulaimaniya]
                },                                
                {
                    country:Dictionary.Jordan,
                    cities:[Dictionary.Amman]
                },  
                {
                    country:Dictionary.Kuwait,
                    cities:[Dictionary.KuwaitCity]
                },  
                {
                    country:Dictionary.Lebanon,
                    cities:[Dictionary.Beirut]
                },              
                {
                    country:Dictionary.UnitedArabEmirates,
                    cities:[Dictionary.Dubai]
                }
            ]
        },
        {
            continent:Dictionary.Africa,
            countries:[  
                {
                    country:Dictionary.Burundi,
                    cities:[Dictionary.Bujumbura]
                },  
                {
                    country:Dictionary.Congo,
                    cities:[Dictionary.Kinshasa]
                },
                {
                    country:Dictionary.Djibouti,
                    cities:[Dictionary.Djibouti]
                },                
                {
                    country:Dictionary.Egypt,
                    cities:[Dictionary.Cairo]
                }, 
                {
                    country:Dictionary.Eritrea,
                    cities:[Dictionary.Asmara]
                },                
                {
                    country:Dictionary.Ethiopia,
                    cities:[Dictionary.AddisAbaba]
                },  
                {
                    country:Dictionary.Gambia,
                    cities:[Dictionary.Banjul]
                },                         
                {
                    country:Dictionary.Ghana,
                    cities:[Dictionary.Accra]
                }, 
                {
                    country:Dictionary.Kenya,
                    cities:[Dictionary.Mombasa,Dictionary.Nairobi]
                }, 
                {
                    country:Dictionary.Nigeria,
                    cities:[Dictionary.Lagos]
                },                             
                {
                    country:Dictionary.Rwanda,
                    cities:[Dictionary.Kigali]
                },   
                {
                    country:Dictionary.Senegal,
                    cities:[Dictionary.Dakar]
                },  
                {
                    country:Dictionary.Somalia,
                    cities:[Dictionary.Mogadishu]
                },                               
                {
                    country:Dictionary.Somaliland,
                    cities:[Dictionary.Hargeisa]
                },                             
                {
                    country:Dictionary.Sudan,
                    cities:[Dictionary.Khartoum]
                },                              
                {
                    country:Dictionary.Tanzania,
                    cities:[Dictionary.DaresSalaam]
                },                                                             
                {
                    country:Dictionary.Uganda,
                    cities:[Dictionary.Entebbe]
                }
            ]
        },
        {
            continent:Dictionary.America,
            countries:[ 
                {
                    country:Dictionary.Argentina,
                    cities:[Dictionary.BuenosAires]
                },   
                {
                    country:Dictionary.Bolivia,
                    cities:[Dictionary.LaPaz,Dictionary.SantaCruz]
                },                       
                {
                    country:Dictionary.Brazil,
                    cities:[Dictionary.Fortaleza,Dictionary.RiodeJaneiro,Dictionary.SaoPaulo]
                },                                     
                {
                    country:Dictionary.Chile,
                    cities:[Dictionary.Santiago]
                },                     
                {
                    country:Dictionary.Colombia,
                    cities:[Dictionary.Bogota,Dictionary.Cali]
                }, 
                {
                    country:Dictionary.Ecuador,
                    cities:[Dictionary.Guayaquil,Dictionary.Quito]
                },  
                {
                    country:Dictionary.Panama,
                    cities:[Dictionary.PanamaCity]
                },                      
                {
                    country:Dictionary.Peru,
                    cities:[Dictionary.Lima]
                }
            ]
        }
        // ,
        // {
        //     continent:"Europe",
        //     countries:[
        //     ]
        // }
    ];

    return(<section className="namin-destinations-section">
        <div className="page-container">
            <h3 className="destination-section-title">{Dictionary.destinationsOfTheWorld}</h3>
            <div className="namin-destinations">
                {destinations.map(continentItem=><div className="destination-block">
                    <h4 className="block-title capitalized">{continentItem.continent}</h4>
                    {continentItem.countries.map(countryItem=><div className="dest-country-item">
                        <h5 className="dest-country-title">
                            {countryItem.country}
                        </h5>
                        {countryItem.cities.map(cityItem => <span className="dest-item-link"> {cityItem} </span>)}
                    </div>)}
                </div>)}
            </div>
            {/* <div className="namin-destinations">
                <div className="destination-block">
                    <h4 className="block-title">Asia</h4>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            India
                        </h5>
                        <span href="#" className="dest-item-link">Mumbai (Bombay) </span>
                        <span href="#" className="dest-item-link">Delhi</span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Vietnam
                        </h5>
                        <span href="#" className="dest-item-link">Ho Chi Minh City (Saigon)  </span>
                        <span href="#" className="dest-item-link">Hanoi</span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Philippines
                        </h5>
                        <span href="#" className="dest-item-link">Manila</span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Sri Lanka
                        </h5>
                        <span href="#" className="dest-item-link"> Colombo </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Pakistan
                        </h5>
                        <span href="#" className="dest-item-link">Lahore </span>
                        <span href="#" className="dest-item-link">Islamabad-Rawalpindi	 </span>
                        <span href="#" className="dest-item-link">Karachi	 </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Bangladesh
                        </h5>
                        <span href="#" className="dest-item-link">Dhaka	 </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Afghanistan
                        </h5>
                        <span href="#" className="dest-item-link">Kabul	 </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Nepal
                        </h5>
                        <span href="#" className="dest-item-link">Kathmandu	 </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Mongolia
                        </h5>
                        <span href="#" className="dest-item-link">Ulan Bator	 </span>
                    </div> 
                </div>
                <div className="destination-block">
                    <h4 className="block-title">America</h4>
                </div>
                <div className="destination-block">
                    <h4 className="block-title">Middle East</h4>

                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Lebanon
                        </h5>
                        <span href="#" className="dest-item-link">Beirut </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Israel
                        </h5>
                        <span href="#" className="dest-item-link">Tel Aviv </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Kuwait
                        </h5>
                        <span href="#" className="dest-item-link">Kuwait </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Iraq
                        </h5>
                        <span href="#" className="dest-item-link">Baghdad</span>
                        <span href="#" className="dest-item-link">Basrah</span>
                        <span href="#" className="dest-item-link">Najaf</span>
                        <span href="#" className="dest-item-link">Erbil</span>
                        <span href="#" className="dest-item-link">Sulaimaniyah</span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Jordan
                        </h5>
                        <span href="#" className="dest-item-link">Amman </span>
                    </div>              
                </div>     
                <div className="destination-block">
                    <h4 className="block-title">Europe</h4>
                </div>
                <div className="destination-block">
                    <h4 className="block-title">Africa</h4>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Kenya
                        </h5>
                        <span href="#" className="dest-item-link">Nairobi </span>
                        <span href="#" className="dest-item-link">Mombasa</span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Ghana
                        </h5>
                        <span href="#" className="dest-item-link">Accra </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Uganda
                        </h5>
                        <span href="#" className="dest-item-link">Kampala </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Rwanda
                        </h5>
                        <span href="#" className="dest-item-link">Kigali </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Eritrea
                        </h5>
                        <span href="#" className="dest-item-link">Asmara </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Ethiopia
                        </h5>
                        <span href="#" className="dest-item-link">Addis Ababa </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Senegal
                        </h5>
                        <span href="#" className="dest-item-link">Dakar </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Gambia
                        </h5>
                        <span href="#" className="dest-item-link">Banjul </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Tanzania
                        </h5>
                        <span href="#" className="dest-item-link">Dar es Salaam </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Nigeria
                        </h5>
                        <span href="#" className="dest-item-link">Ikeja </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Sudan
                        </h5>
                        <span href="#" className="dest-item-link">Khartoum </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Somalia
                        </h5>
                        <span href="#" className="dest-item-link">Mogadishu </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Djibouti 
                        </h5>
                        <span href="#" className="dest-item-link">Djibouti </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Egypt
                        </h5>
                        <span href="#" className="dest-item-link">Cairo </span>
                    </div> 
                </div>
            </div> */}
        </div>
    </section>)
}

const stateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary
});

export default connect(stateToProps)(NaminHomeDestinations)