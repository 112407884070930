import React from "react";
import { Checkbox } from "antd";
import { connect } from "react-redux";

import { setFilter } from "../../Actions/FlightAction";

const BaggageFilter = props => {
    const {Dictionary} = props;
    return(
        <div className="baggage-filter-wrapper">
            <div className="text-center">
                <strong>{Dictionary.baggage}</strong>
                <div className="margin-bottom-half font-13 semi-bold">{Dictionary.IncludeInTheTotalPricePerTraveler}</div>
            </div>
            <Checkbox.Group className="text-center full-width" onChange={value => { props.setFilter({ value, filterType: "baggage" }) }}>
                <div className="float-row">
                    <div className="col-small-6">
                        <Checkbox value="withBaggage" className="baggage-filter-item-inner">
                            <div className="semi-bold no-wrap">{Dictionary.WithBaggage}</div>
                            <div className="flex-ends middle">
                                <span className="itours-icon icon-large green-baggage margin-end-light" />
                                <span className="checkbox-label baggage-radio-label" />
                            </div>
                        </Checkbox>
                    </div>
                    <div className="col-small-6">

                        <Checkbox value="withoutBaggage" className="baggage-filter-item-inner">
                            <div className="semi-bold no-wrap">{Dictionary.WithoutBaggage}</div>
                            <div className="flex-ends middle">
                                <span className="itours-icon icon-large red-no-baggage margin-end-light" />
                                <span className="checkbox-label baggage-radio-label" />
                            </div>
                        </Checkbox>
                    </div>
                </div>
            </Checkbox.Group>
        </div>
    )
}


const dispatchToProps = (dispatch) => ({
    setFilter: data => dispatch(setFilter(data))
});
const stateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary
});


export default connect(stateToProps, dispatchToProps)(BaggageFilter);
