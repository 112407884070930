import { connect } from 'react-redux';
import axios from 'axios';

import LayoutConfig from '../../Config/layoutConfig';
import { ServerAddress, Header,User } from "../../Enum/Urls";
import { showError } from '../../Actions/VisualDataAction';
import { getUserAsync } from '../../Actions/UserAction';
import Form from '../Common/Form';
import ProfileEdit from './ProfileEdit';
import ProfileEditNamin from './ProfileEditNamin';
import Loading from '../Common/Loading';

class UserInfo extends Form {
  state = {
    ...this.state,
    loading: false,
    lockItem:true,
    activationLoading:false,
    activationMessage:undefined,
    showModal:false
  };

closeModal = () =>{
  if (!this.state.activationLoading){
    this.setState({showModal:false});
  }
}
  componentDidMount() {
    super.componentDidMount(this.FormDOM);
  }

  FormDOM = [];

  fixNumbers = (str) => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };
  resendEmail =() => {
    this.setState({
      activationLoading:true,
      showModal:true
    });
    const params = {"emailAddress":this.props.userData.emailAddress};
    axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.SendEmailActivation}`, params, {headers: {...Header,},})
    .then(({ data }) => {
      if (data.error) {
         this.setState({
            activationLoading:false,
            activationMessage:{text:data.error.message,success:data.success}
          });
        }else{
          this.setState({
            activationLoading:false,
            activationMessage:{text:this.props.Dictionary.anActivationLinkHasBeenSentToYourEmail,success:data.success}
          });
       }
    })
    .catch((error) => {
      try {
        this.setState({
          activationLoading:false,
          activationMessage:{text:error.response.data.error.message,success:false}
        });
      } catch (e) {
        console.error("e-7");
        this.setState({
          activationLoading:false,
          activationMessage:{text:"Internal Error,Please refresh the page",success:false}
        });
      }
    });
  }

  render() {
    const { Dictionary, LAN, userData } = this.props;
    const { lockItem } = this.state;

    return (<div className="card-padding">
        <div>
          <h3 className="normal-title margin-bottom-large">
            <span className="itours-icon user-profile margin-end" />
            <span className="inline-block">
              {Dictionary.userProfile}
            </span>
          </h3>

          {LAN === "IR" || LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme' || <>
          <div>
            <label className="form-label">
              {Dictionary.email} :{" "}
            </label>
            <b className="inline-block margin-start">
              {userData.emailAddress}
            </b>
          </div>
          <div className="varified margin-bottom">
            {!userData.isEmailConfirmed ? (
              <>
                <div className={`itours-modal ${this.state.showModal ? "itours-modal visible" : ""}`}>
                  <div className="modal-overlay" onClick={this.closeModal} />
                  <div className="modal-inner small-width text-center">
                    <div className="modal-main">
                      {this.state.activationLoading ? <Loading />
                      :
                       (this.state.activationMessage && 
                        <div>
                          <br/><br/>
                            {this.state.activationMessage.success?
                            <span className="itours-icon check-green icon-xlarge" />
                            :
                            <span className="itours-icon error-icon icon-xlarge" />
                            }
                          <div className="margin-top margin-bottom">
                          {this.state.activationMessage.text}.
                          </div>
                          <br/>
                        </div>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="button color-1-btn"
                        onClick={this.closeModal}
                        disabled={this.state.activationLoading}
                      >
                        {Dictionary.ok}
                      </button>
                    </div>
                  </div>
                </div>
                <span className="purple">
                  {Dictionary.yourEmailAddressIsNotConfirmedPleaseCheckEmailrecivedFromUs}
                  <button type="button" className="link-style-btn margin-start" onClick={this.resendEmail} >
                    {Dictionary.resendEmail}
                  </button>
                </span>
              </>
            ) : (
              <span className="green">
                {Dictionary.YourEmailAddressIsConfirmed}
              </span>
            )}
          </div>                      
          </>
          }

          {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ?
            <ProfileEditNamin 
              userData={userData} 
              resendEmail={this.resendEmail} 
              activationLoading={this.state.activationLoading}
              activationMessage={this.state.activationMessage}
              showModal={this.state.showModal}
              closeModal={this.closeModal}
            />
          :lockItem ? (
            <>
              <div>
                <label className="form-label">
                  {Dictionary.firstName} :{" "}
                </label>
                <b className="inline-block margin-start">
                  {userData.firstName.length === 0
                    ? Dictionary.notRegistered
                    : userData.firstName}
                </b>
              </div>
              <div>
                <label className="form-label">
                  {Dictionary.lastName} :{" "}
                </label>
                <b className="inline-block margin-start">
                  {userData.lastName.length === 0
                    ? Dictionary.notRegistered
                    : userData.lastName}
                </b>
              </div>
              <div>
                <label className="form-label">
                  {Dictionary.gender}:{" "}
                </label>
                <b className="inline-block margin-start">
                  {userData.gender
                    ? Dictionary.male
                    : Dictionary.female}
                </b>
              </div>
              <div>
                <label className="form-label">
                  {Dictionary.phoneNumber} :{" "}
                </label>
                <b className="inline-block margin-start">
                  {userData.phoneNumber && userData.phoneNumber.length !== 0
                    ? <span className="inline-block" dir="ltr">{userData.phoneNumber}</span>
                    : Dictionary.notRegistered}
                </b>
              </div>                          
              {LAN === "IR"?(                            
                  <div>
                    <label className="form-label">
                      {Dictionary.email} :{" "}
                    </label>
                    <b className="inline-block margin-start">
                      {userData.emailAddress && userData.emailAddress.length !== 0
                        ? <span className="inline-block" dir="ltr">{userData.emailAddress}</span>
                        : Dictionary.notRegistered}
                    </b>
                  </div>  
                ):null}                        
              <div>
                <label className="form-label">
                  {Dictionary.birthday}:{" "}
                </label>
                <b className="inline-block margin-start">
                  {userData.birthDay == null
                    ? Dictionary.notRegistered
                    : userData.birthDay.split("T")[0]}
                </b>
              </div>
              <div>
                <label className="form-label">
                  {Dictionary.nationality} :{" "}
                </label>
                <b className="inline-block margin-start">
                  {userData.nationalityId == null
                    ? Dictionary.notRegistered
                    : userData.nationalityId}
                </b>
              </div>
              <button
                className="button color-1-btn margin-top"
                type="button"
                onClick={() => this.setState({ lockItem: false })}
              >
                {Dictionary.editInformation}
              </button>
            </>
          ) : (
            <ProfileEdit userData={userData} cancel={() => this.setState({ lockItem: true })} />
          )}
        </div>
        {this.state.MessageContainerShow ? (
          <div className="errorShow">
            {this.state.MessageContainerContent}
          </div>
        ) : null}
    </div>);
  }
}

const stateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  userData: state.UserData.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getUserAsync: (params) => dispatch(getUserAsync(params)),
  showError: (params) => dispatch(showError(params)),
});

export default connect(stateToProps, mapDispatchToProps)(UserInfo);
