const AirlineWarning = props => {

    const {visibility,close} = props;

    return(
    <div className={`itours-modal ${visibility ? "visible" : ""}`} >
        <div className="modal-overlay" onClick={close} />
        <div className="modal-inner small-width">
            <div className="modal-main text-center">
                <div className="image text-center margin-bottom margin-top">
                    <span className={`icon-xlarge itours-icon error-3`}/>
                </div>
                <b className="margin-bottom block font-18">توجه</b>
                <p className="margin-bottom-large">
                    مسئولیت چک کردن عوارض فرودگاهی و عوارض هواپیمایی بعهده مسافرین محترم می باشد.
                </p>
                <button 
                    type="button" 
                    className="button blue-btn margin-bottom"
                    onClick={close}
                >
                    بسیار خوب
                </button>
            </div>
        </div>
    </div>
    )
}
 export default AirlineWarning;