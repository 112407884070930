import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import HotelNoImage from "../../image/default-hotel-image.svg";
import Rating from "../Common/Rating";
import Price from "../Common/Price";
import DomesticHotelPoint from "../Common/DomesticHotelPoint";

const DomesticHotelItem = props => {

    const { hotel, Dictionary } = props;

    let rating;
    switch (hotel.rateInformation) {
        case "pending":
            rating = <div className="guestRatingPlaceHolder margin-bottom" ><span className="loading-inline-circle" /></div>;
            break;
        case "not-rated":
            rating = <div />;
            break;
        default:
            rating = <DomesticHotelPoint point={hotel.rateInformation?.Satisfaction} reviews={hotel.rateInformation?.TotalRowCount} className="margin-bottom" />;
    }

    let price;
    switch (hotel.priceInformation) {
        case "pending":
            price = <div className="line-height-44"><span className="loading-inline-circle" /></div>;
            break;
        case "not-priced":
            price = null;
            break;
        case "need-to-inquire":
            price = <p className="purple margin-bottom-light">
                قیمت نیازمند استعلام است
            </p>
            break;
        default:
            price = (
                <>
                    <div className="text-end prices-holder line-height-22">
                        {hotel.priceInformation?.discountPercent === 0 ? (
                            <b
                                className="small-margin-bottom text-center"
                                dir="ltr"
                            >
                                <Price currency={props.currencyCode}>
                                    {hotel.priceInformation?.salePrice}
                                </Price>
                            </b>
                        ) : (
                            <>
                                <div className="hotel-old-price no-wrap">
                                    <Price currency={props.currencyCode}>
                                        {hotel.priceInformation?.boardPrice}
                                    </Price>
                                </div>
                                <b className="small-margin-bottom text-center no-wrap">
                                    <Price currency={props.currencyCode}>
                                        {hotel.priceInformation?.salePrice}
                                    </Price>
                                </b>
                            </>
                        )}
                    </div>
                    <div className="text-end font-12 gray price-for-night line-height-22">
                        <span>{Dictionary.startingPrice}</span> <span className="nowrap"> ({props.dateInfo?.duration} {Dictionary.nights}) </span>
                    </div>
                </>
            )
    }

    return (
        <div className="margin-bottom bg-white domestic-hotel-item box-shadow">
            <div
                className={`hotel-image ${hotel.ImageUrl ? "" : "default-image"}`}
                style={{ backgroundImage: `url("${hotel.ImageUrl || HotelNoImage}")` }}
            />
            <div className="content">
                <main className="content-main">
                    <b className="margin-end-half">{hotel.HotelTypeName} {hotel.HotelName} {hotel.CityName}</b>
                    <Rating number={hotel.HotelRating} className="inline-block-middle margin-end-half" />
                    <span className="gray-tag font-13">{hotel.HotelTypeName}</span>

                    <p className="gray font-13 margin-bottom-light">{hotel.Address}</p>

                    {!!hotel.Facilities?.length > 0 && <div className="feature-list">
                        <div className="feature-items" >
                            {hotel.Facilities.slice(0, 4).map(item => (
                                <div key={item.Title}>
                                    {item.Title}
                                </div>
                            ))}
                        </div>
                    </div>}

                    {!price && <div className="bg-light-pink padding-v-10 padding-h-15 margin-top-half">
                        <strong className="margin-bottom-light purple semi-bold">
                            <span className="itours-icon red-clock-icon icon-small margin-end-light" />
                            این مورد را از دست دادید!
                        </strong>
                        <div className="font-13">برای این تاریخ ها هیچ اتاقی در دسترس نداریم</div>
                    </div>}
                </main>
                {(!!rating || !!price) && <footer className="content-footer text-end">

                    {rating}

                    <div>
                        {price}
                        {!!price && <Link
                            className="button full-width red-btn has-arrow nowrap margin-top-light"
                            target="_blank"
                            to={`/domesticHotel/details?id=${hotel?.HotelId}&checkin=${props.dateInfo?.checkIn}&checkout=${props.dateInfo?.checkOut}`}
                        >
                            {Dictionary.seeRooms}
                        </Link>}
                    </div>

                </footer>}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        Dictionary: state.VisualData.Dictionary,
        LAN: state.VisualData.LAN,
        currencyCode: state.UserData.Currency
    };
};

export default connect(mapStateToProps)(DomesticHotelItem);